import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/Loading/Loading";
import SearchField from "../../../../components/SearchField/SearchField";
import TableCustome from "../../../../components/Table/TableCustome";
import { getIndividualListMapper } from "../../../../utils/apiDataMappers";
import {
  getEmployeeListById,
  getEmployeeListFranchisorThunk,
  setgetEmployeeList,
} from "../../EmployeeSlice";
import { setDialogSelectedEntity } from "../../BusinessLocationSlice";

const Individual = ({ setIsEdit, setForm }) => {
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState("");
  const { selectedBussinessDetails } = useSelector((state) => state.dashboard);
  const { loading, getEmployeeList } = useSelector((state) => state.employee);
  const { loggedInUserInfo } = useSelector((state) => state.login);
  const headList = ["Individual", "Locations", "Roles", "Action"];
  const headListEdit = ["Individual", "Locations", "Roles"];
  const [bodyList, setBodyList] = useState("");
  useEffect(() => {
    setTimeout(() => {
      if (loggedInUserInfo.accountType === "FRANCHISOR") {
        dispatch(
          getEmployeeListFranchisorThunk({
            businessId: selectedBussinessDetails.businessId,
            userId: loggedInUserInfo.employeeId,
          })
        );
      } else {
        dispatch(
          getEmployeeListById({
            businessId: selectedBussinessDetails.businessId,
            userId: loggedInUserInfo.employeeId,
          })
        );
      }
    }, 200);
    return () => {
      dispatch(setgetEmployeeList(""));
    };
  }, []);
  useEffect(() => {
    if (
      Array.isArray(getEmployeeList)
    ) {
      setBodyList(
        getIndividualListMapper(
          getEmployeeList,
          selectedBussinessDetails.accountId,
          selectedBussinessDetails.businessId
        )
      );
    } else if (getEmployeeList === "") {
      setBodyList("");
    } else {
      setBodyList([]);
    }
  }, [getEmployeeList]);
  useEffect(() => {
    document.querySelector(".css-19kzrtu").style.padding = "1rem 0";
  }, []);

  const handleSearch = (data) => {
    if (
      data.search === "" &&
      getEmployeeList !== "" &&
      Array.isArray(getEmployeeList) &&
      getEmployeeList.length
    ) {
      setBodyList(
        getIndividualListMapper(
          getEmployeeList,
          selectedBussinessDetails.accountId,
          selectedBussinessDetails.businessId
        )
      );
    } else if (
      getEmployeeList !== "" &&
      Array.isArray(getEmployeeList) &&
      getEmployeeList.length
    ) {
      let temp = getIndividualListMapper(
        getEmployeeList,
        selectedBussinessDetails.accountId,
        selectedBussinessDetails.businessId
      ).filter(
        (ele) =>
          ele.Individual.toLowerCase().includes(data.search.toLowerCase()) ||
          ele.Locations.toLowerCase().includes(data.search.toLowerCase()) ||
          ele.Roles.toLowerCase().includes(data.search.toLowerCase())
      );
      setBodyList(temp);
    }
  };
  const editAction = (data) => {
    setIsEdit(data);
    setForm("individualsEdit");
    dispatch(setDialogSelectedEntity(data["Individual"]));
  };
  return (
    <Grid container rowSpacing={2}>
      <Grid item lg={12} md={12} xs={12} sm={12}>
        <SearchField
          id="searchIndividuals"
          value={searchData}
          setValue={setSearchData}
          action={handleSearch}
          placeholder="Search Individuals"
        />
      </Grid>
      {bodyList === "" ? (
        <Stack
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "65vh" }}
        >
          <Loading />
        </Stack>
      ) : (
        <Grid item lg={12} md={12} xs={12} sm={12}>
          {Array.isArray(bodyList) && (
            <TableCustome
              primeSort={"Individual Locations Roles"}
              primeWidth={"Individual"}
              isEdit={
                loggedInUserInfo.accountType === "FRANCHISOR" ||
                loggedInUserInfo.role === "ROLE_ACCOUNT_ADMIN"
                  ? true
                  : selectedBussinessDetails?.properties
                      ?.managerCanManageEmployee
                  ? true
                  : false
              }
              headList={
                loggedInUserInfo.accountType === "FRANCHISOR" ||
                loggedInUserInfo.role === "ROLE_ACCOUNT_ADMIN"
                  ? headList
                  : selectedBussinessDetails?.properties
                      ?.managerCanManageEmployee
                  ? headList
                  : headListEdit
              }
              bodyList={bodyList}
              editAction={editAction}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Individual;
