import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TABLE_TYPE } from "../constant";

const initialState = {
  complianceTableType: TABLE_TYPE.ACTIVE,
};

export const reTableSlice = createSlice({
  name: "reTable",
  initialState,
  reducers: {
    setComplianceTableType: (state, action) => {
      state.complianceTableType = action.payload;
    },
  },
});

export const { setComplianceTableType } = reTableSlice.actions;
export default reTableSlice.reducer;
