import { createSlice } from "@reduxjs/toolkit";
import BasicDetails from "../../pages/NewTemplate/components/BasicDetails/BasicDetails";
const initialState = {
  value: 0,
  steps: [],
  isLastStep: false,
  isFirstStep: true,
  disableAllSteps: true,
};

export const stepperTabsSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    resetState: (state) => initialState,
    navigateToTab: (state, action) => {
      state.value = action.payload;
    },
    nextTab: (state) => {
      state.value += 1;
    },
    prevTab: (state) => {
      state.value -= 1;
    },
    enableAllSteps: (state) => {
      state.disableAllSteps = false;
    },
    disableAllSteps: (state) => {
      state.disableAllSteps = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetState,
  enableNextStep,
  enableAllSteps,
  disableAllSteps,
  nextTab,
  prevTab,
  navigateToTab,
} = stepperTabsSlice.actions;

export default stepperTabsSlice.reducer;
