import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { hideNav } from "../../App.reducer";
import EmailIcon from "@mui/icons-material/Email";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { ReactComponent as Logo } from "../../assets/complianser-logo-with-text.svg";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { login, resetLogin, setPlan } from "./LoginSlice";
import Notification from "../../components/Notification/Notification";
import SNACKBAR_VARIATIONS from "../../constants/snackbar-variations";
import "./SignUpPage.css";
import Footer from "../../components/Footer/Footer";
import LockIcon from "@mui/icons-material/Lock";
import { isTokenValid } from "../../utils/checkTokenValidity";
import { resetAgreement } from "../Agreement/AgreementSlice";
import { resetDashboard } from "../Dashboard/DashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { resetTemplates } from "../Templates/TemplateSlice";
import PersonIcon from "@mui/icons-material/Person";
import Email from "@mui/icons-material/Email";
import { useStyles } from "tss-react/mui";
import BusinessIcon from "@mui/icons-material/Business";
import StoreIcon from "@mui/icons-material/Store";
import { PlansStrings, PlansStringsWithoutBase } from "../../utils/stringToObj";
import DialoCodeAutocomplete from "../../components/FormComponents/DialoCodeAutocomplete";
import storage from "redux-persist/lib/storage";

const SignUpPage = (
  defaultEmail = "",
  formRef,
  isCreateNewUser = false,
  isOptInForNewuser = false,
  isNetwork = false,
  isSignUpFormPage = false
) => {
  const [snackbarOpen, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const closeSnackbar = () => setOpenSnackbar(false);
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const hide = useSelector((state) => state.common.hide);
  const userLoggedIn = useSelector((state) => state.login.userLoggedIn);
  const { loggedInUserInfo } = useSelector((state) => state.login);
  const isError = useSelector((state) => state.login.isError);
  const [planValue, setValue] = useState();
  const { isMobileScreen } = useSelector((state) => state.common);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  useEffect(() => {
    dispatch(resetDashboard());
    storage.removeItem("persist:root");
    clearCacheData();
    localStorage.clear();
    dispatch(resetLogin());
    dispatch(resetAgreement());
    dispatch(resetTemplates());
  }, []);
  useEffect(() => {
    if (!hide) {
      dispatch(hideNav());
    }
  }, [userLoggedIn]);
  // useEffect(() => {
  //   if (userLoggedIn && isTokenValid()) {
  //     // if (loggedInUserInfo.role === "ROLE_USER") {
  //     //   if (loggedInUserInfo.signedESignAgreements) {
  //     //     navigate("/dashboard");
  //     //   } else {
  //     //     navigate("/agreement");
  //     //   }
  //     // }
  //     if (
  //       !loggedInUserInfo.signedESignAgreements &&
  //       loggedInUserInfo.role === "ROLE_ACCOUNT_ADMIN"
  //     ) {
  //       navigate("/agreement");
  //     } else {
  //       navigate("/dashboard");
  //     }
  //   }
  // }, [userLoggedIn]);

  const handleLogin = async (data) => {
    //fixed login

    const result = await dispatch(login(data));
    const res = unwrapResult(result);
    if (res.status && res.status === 200) {
      dispatch(setPlan(res.data.planType));
      if (
        !loggedInUserInfo.signedESignAgreements &&
        loggedInUserInfo.role === "ROLE_ACCOUNT_ADMIN"
      ) {
        navigate("/agreement");
      } else if (res?.data?.accountType === "SUPER_ADMIN") {
        navigate("/admin");
      } else {
        navigate("/dashboard");
      }
    }
    if (!res.status || res.status !== 200) {
      handleSnackbar(
        "Please Enter Correct Username or Password",
        SNACKBAR_VARIATIONS.ERROR
      );
    }
  };

  const handleSnackbar = (message, severity) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
  };

  const displaySnackbar = () => {
    return (
      <Notification
        openSnackbar={snackbarOpen}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    );
  };
  return (
    <div className="login-container">
      <div className="logo-container">
        <Logo className="logoStyle" height={"40px"} />
      </div>
      <div className="login-card-container">
        <Card
          sx={{
            width: { sm: 400, xs: 300 },
            maxHeight: "850px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            position: "fixed",
            height: "87vh",
            marginTop:
              errors.firstName &&
              errors.lastName &&
              errors.companyName &&
              errors.brandName &&
              errors.phoneNumber &&
              errors.iAccept &&
              errors.optIn
                ? {
                    sm: "26.8rem",
                    xs: "26.7rem",
                    ipadMini: "26.8rem",
                    ipadAir: "30.8rem",
                  }
                : errors.username || errors.password
                ? {
                    sm: "-4rem",
                    ipadAir: "0.2rem",
                    xs: "-3.6rem",
                    ipadMini: "-4rem",
                  }
                : {
                    sm: "20rem",
                    xs: "24.9rem",
                    ipadMini: "19.8rem",
                    ipadAir: "28.9rem",
                  },
            flexFlow: "column",
          }}
        >
          {snackbarOpen ? displaySnackbar() : null}

          <CardHeader
            title="Welcome Back"
            titleTypographyProps={{ fontSize: "14px" }}
            sx={{
              backgroundColor: "#164e5f",
              color: "white",
            }}
          />
          <CardContent
            sx={{
              // marginTop: !isCreateNewUser && !isNetwork ? "0rem" : "-1.5rem",
              marginTop: isCreateNewUser ? "0rem" : "-0.2rem",

              paddingBottom: isCreateNewUser ? "1rem" : "0rem",
              paddingTop: "0.5rem",
              marginBottom: isNetwork ? "2rem" : "-2rem",
              height: isCreateNewUser ? "41vh" : "100%",
            }}
          >
            {!isCreateNewUser && !isNetwork && (
              <Typography sx={{ textAlign: "center" }}>
                Sign Up Below
              </Typography>
            )}

            <form
              className="login-form"
              // ref={formRef}
              id="hook-form"
              onSubmit={handleSubmit(handleLogin)}
              style={{
                marginTop: "0.8rem",
                marginBottom: "-.5rem",
                paddingBottom: "0.1rem",
              }}
            >
              <TextField
                sx={{
                  marginTop: "-.5rem",
                  marginBottom: "-.5rem",
                }}
                id="firstName"
                label="Enter First Name"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                {...register("firstName", { required: true })}
              />
              {errors.firstName && (
                <Typography
                  variant="body1"
                  sx={{
                    padding: "0rem",
                    marginTop: "-.4rem",
                    marginBottom: "-.8rem",
                  }}
                  className="error-message"
                >
                  Required field<sup>*</sup>
                </Typography>
              )}
              <TextField
                id="lastName"
                label="Enter Last Name"
                variant="outlined"
                size="small"
                type="text"
                sx={{
                  marginBottom: "-.5rem",
                }}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                {...register("lastName", { required: true })}
              />
              {errors.lastName && (
                <Typography
                  variant="body1"
                  sx={{
                    padding: "0rem",
                    marginTop: "-.4rem",
                    marginBottom: "-.7rem",
                  }}
                  className="error-message"
                >
                  Required field<sup>*</sup>
                </Typography>
              )}
              <TextField
                id="email"
                label="Enter Email"
                variant="outlined"
                size="small"
                type="email"
                sx={{
                  //   marginTop: "-.5rem",
                  marginBottom: "-.5rem",
                }}
                // defaultValue={defaultEmail.email}
                disabled={defaultEmail !== "" ? true : false}
                fullWidth
                InputProps={{
                  placeholder: "Enter Your Email",
                  endAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                aria-invalid={errors.email ? "true" : "false"}
                {...register("email", { required: true })}
              />
              {/* {errors.email && (
                <Typography
                  variant="body1"
                  sx={{
                    padding: "0rem",
                    marginTop: "-.7rem",
                    marginBottom: "-.7rem",
                  }}
                  className="error-message"
                >
                  Required field<sup>*</sup>
                </Typography>
              )} */}
              <TextField
                id="companyName"
                label="Company Legal Name"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon />
                    </InputAdornment>
                  ),
                }}
                {...register("companyName", { required: true })}
              />
              {errors.companyName && (
                <Typography
                  variant="body1"
                  sx={{
                    padding: "0rem",
                    marginTop: "-.9rem",
                    marginBottom: "-.4rem",
                  }}
                  className="error-message"
                >
                  Required field<sup>*</sup>
                </Typography>
              )}
              <TextField
                id="brandName"
                label="Brand Name"
                variant="outlined"
                size="small"
                sx={{
                  marginTop: "-.5rem",
                  marginBottom: "-.5rem",
                }}
                type="text"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <StoreIcon />
                    </InputAdornment>
                  ),
                }}
                {...register("brandName", { required: true })}
              />
              {errors.brandName && (
                <Typography
                  variant="body1"
                  sx={{
                    padding: "0rem",
                    marginTop: "-.4rem",
                    marginBottom: "-.4rem",
                  }}
                  className="error-message"
                >
                  Required field<sup>*</sup>
                </Typography>
              )}
              <FormControl size="small">
                <InputLabel
                  sx={{ fontSize: ".8rem", color: "#A9A9A9" }}
                  id="demo-select-small-label"
                >
                  Select Plan
                </InputLabel>
                {isCreateNewUser || isNetwork ? (
                  isNetwork ? (
                    <Select
                      onChange={(e) => setValue(e.target.value)}
                      value={planValue}
                      labelId="demo-select-small-label"
                      id="demo-simple-select"
                      sx={{ textAlign: "left" }}
                      label="Select Plan"
                      style={{ marginBottom: "0rem" }}
                      // defaultValue={"PLATINUM"}
                      {...register("planType", { required: true })}
                    >
                      {Object.values(PlansStrings).map((ele) => (
                        <MenuItem value={ele} key={ele}>
                          {ele}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      onChange={(e) => setValue(e.target.value)}
                      value={planValue}
                      labelId="demo-select-small-label"
                      id="demo-simple-select"
                      sx={{ textAlign: "left" }}
                      label="Select Plan"
                      style={{ marginBottom: "0rem" }}
                      // defaultValue={"PLATINUM"}
                      {...register("planType", { required: true })}
                    >
                      {Object.values(PlansStringsWithoutBase).map((ele) => (
                        <MenuItem value={ele} key={ele}>
                          {ele}
                        </MenuItem>
                      ))}
                    </Select>
                  )
                ) : (
                  <Select
                    onChange={(e) => setValue(e.target.value)}
                    value={planValue}
                    labelId="demo-select-small-label"
                    id="demo-simple-select"
                    sx={{ textAlign: "left" }}
                    label="Select Plan"
                    style={{ marginBottom: "0rem" }}
                    defaultValue={"PLATINUM"}
                    {...register("planType", { required: true })}
                  >
                    {Object.values(PlansStringsWithoutBase).map((ele) => (
                      <MenuItem disabled={true} value={ele} key={ele}>
                        {ele}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
              <Stack
                width={"198%"}
                spacing={2}
                sx={{
                  marginTop: "-.5rem",
                  marginBottom: "-.5rem",
                }}
              >
                <Stack width={"97.5%"} direction={"row"}>
                  <Stack width={{ sm: "15%", xs: "14%" }} fullWidth spacing={1}>
                    <DialoCodeAutocomplete
                      setValue={setValue}
                      // disabled={allowOnlySSOLogin}
                      register={register("countryCode", { required: true })}
                      defaultValue={"+1"}
                    />
                  </Stack>
                  <Stack
                    sx={{
                      width: isMobileScreen
                        ? {
                            sm: "36.5%",
                            xs: "37.5%",
                            ipadAir: "36.5%",
                            ipadMini: "36.5%",
                            md: "36.5%",
                            lg: "36.6%",
                          }
                        : {
                            sm: "36.5%",
                            xs: "24%",
                            ipadAir: "36.5%",
                            ipadMini: "36.5%",
                            md: "36.5%",
                            lg: "36.6%",
                          },
                    }}
                    // width={
                    //   isMobileScreen
                    //     ? "7rem"
                    //     : {
                    //         sm: "36.5%",
                    //         xs: "50%",
                    //         ipadAir: "35.5%",
                    //         ipadMini: "39%",
                    //         md: "35.5%",
                    //         lg: "36.6%",
                    //       }
                    // }
                    spacing={1}
                  >
                    <TextField
                      id="phone"
                      sx={{ marginLeft: "20px" }}
                      size="small"
                      fullWidth
                      // disabled={allowOnlySSOLogin}
                      placeholder="Enter Phone Number"
                      type="number"
                      aria-invalid={errors.phoneNumber ? "true" : "false"}
                      {...register("phoneNumber", {
                        required: true,
                        maxLength: 10,
                      })}
                    />
                  </Stack>
                </Stack>
                {errors.phoneNumber &&
                  errors.phoneNumber.type === "maxLength" && (
                    <Typography
                      variant="body1"
                      sx={{
                        padding: "0rem",
                        marginTop: "-.5rem",
                        marginBottom: "-.10rem",
                      }}
                      className="error-message"
                    >
                      Number lenght should of 10 digit.
                    </Typography>
                  )}
                {errors.phoneNumber &&
                  errors.phoneNumber.type === "required" && (
                    <Typography
                      variant="body1"
                      sx={{
                        padding: "0rem",
                        marginTop: ".2rem !important",
                        marginBottom: "-.5rem !important",
                      }}
                      className="error-message"
                    >
                      Required field<sup>*</sup>
                    </Typography>
                  )}
              </Stack>
              {!isCreateNewUser && !isNetwork ? (
                <>
                  {!isCreateNewUser && !isNetwork && (
                    <>
                      <FormGroup>
                        <FormControlLabel
                          style={{
                            marginTop: "-.1rem",
                          }}
                          control={<Checkbox />}
                          {...register("optIn", { required: true })}
                          // label="Opt-in to receive text messages on this mobile number"
                          label={
                            <Typography
                              variant="h7"
                              sx={{
                                alignItems: "center",
                                marginTop: "-.1rem",
                                textAlign: "left",
                              }}
                            >
                              Opt-in to receive text messages on this mobile
                              number{" "}
                            </Typography>
                          }
                          sx={{
                            paddingBottom: "-2rem",
                            marginBottom: "-1rem",
                            marginTop: ".5rem",
                            fontWeight: "400",
                            fontSize: "2rem",
                          }}
                        />
                      </FormGroup>

                      <Stack
                        spacing={2}
                        direction={"row"}
                        sx={{ marginLeft: "-0.2rem", marginBottom: "-.3rem" }}
                      >
                        <Typography
                          sx={{
                            // fontWeight: { sm: 450, xs: 15 },
                            // fontSize: { sm: "16px", xs: "15px" },
                            marginLeft: ".5rem !important",
                            marginTop: "-10rem",
                            paddingTop: "0.1rem",
                            textAlign: "left",
                          }}
                        >
                          Opt-in is required to send critical transactional
                          messages such as One Time Passcode (OTP), change of
                          mobile number notification, MFA etc.
                        </Typography>
                      </Stack>
                    </>
                  )}
                  {errors.optIn && (
                    <Typography
                      sx={{
                        padding: "0rem",
                        marginTop: "-.8rem",
                        marginBottom: "-.6rem",
                      }}
                      variant="body1"
                      className="error-message"
                    >
                      Please check this box
                    </Typography>
                  )}
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "-.96rem",
                      marginLeft: "-.65rem",
                    }}
                  >
                    <Checkbox
                      // checked={isShareConcent}
                      // checked={true}
                      // onChange={handleCheckbox}
                      sx={{ height: "fit-content" }}
                      {...register("iAccept", {
                        required: true,
                      })}
                    />
                    <Typography
                      sx={{
                        padding: "0rem",
                        marginTop: ".55rem",
                        color: "#000000",
                        marginLeft: "-.4rem",
                        textAlign: "left",
                      }}
                    >
                      Under penalty of perjury, I declare that I am the owner,
                      office or an authorized agent for the company with legal
                      authority to request and manage the company account with
                      FOCUS451 and all of the above information is current and
                      will keep it current. If my status with the company above
                      changes, I will transfer my credentials to an appropriate
                      individual responsible within the company. Further, I
                      agree to FOCUS451’s standard legal terms located at{" "}
                      <Link>https://www.FOCUS451.com/legal/terms. </Link>
                    </Typography>
                  </Stack>

                  {errors.iAccept && (
                    <Typography
                      variant="body1"
                      sx={{
                        padding: "0rem",
                        marginTop: "-.9rem",
                        marginBottom: "-.5rem",
                      }}
                      className="error-message"
                    >
                      Please check this field
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  {!isCreateNewUser && !isNetwork && (
                    <>
                      <FormGroup>
                        <FormControlLabel
                          style={{
                            marginTop: "-.5rem",
                          }}
                          control={<Checkbox />}
                          // label="Opt-in to receive text messages on this mobile number"
                          label={
                            <Typography
                              variant="h7"
                              sx={{ alignItems: "center", marginTop: "-.1rem" }}
                            >
                              Opt-in to receive text messages on this mobile
                              number{" "}
                            </Typography>
                          }
                          sx={{
                            paddingBottom: "-2rem",
                            marginBottom: "-1rem",
                            marginTop: ".5rem",
                            fontWeight: "400",
                            fontSize: "2rem",
                          }}
                        />
                      </FormGroup>

                      <Stack
                        spacing={2}
                        direction={"row"}
                        sx={{ marginLeft: "-0.2rem" }}
                      >
                        <Checkbox
                          id="manageEmployees"
                          sx={{
                            // marginTop: "-8px",
                            // marginLeft: "-10px",
                            // marginRight: "-10px",
                            padding: "0px",
                            height: "fit-content",
                          }}
                        ></Checkbox>

                        <Typography
                          sx={{
                            // fontWeight: { sm: 450, xs: 15 },
                            // fontSize: { sm: "16px", xs: "15px" },
                            marginLeft: ".5rem !important",
                            marginTop: "-10rem",
                            paddingTop: "0.1rem",
                            textAlign: "left",
                          }}
                        >
                          <>
                            When Opt-in is selected, the system will send
                            critical transactional messages such as One Time
                            Passcode (OTP), change of mobile number
                            notification, MFA etc. Opt-in is recommended to keep
                            your account secure.
                          </>
                          <>
                            If Opt-in is not selected, all such messages will be
                            sent to the user’s current email on record. Opt-out
                            is not recommended.
                          </>
                        </Typography>
                      </Stack>
                    </>
                  )}
                </>
              )}
              {/* {isCreateNewUser && (
            
          )} */}
              <Button
                // ref={formRef}
                id="submit"
                color="success"
                type="submit"
                variant="contained"
                sx={{
                  width: "100%",
                  color: "white",
                  backgroundColor: "#29C34F",
                  ":hover": {
                    backgroundColor: "#2CAB4B",
                  },
                  marginTop: "-.5rem",
                  marginBottom: "1.5rem",
                  display:
                    isCreateNewUser || isNetwork ? "none" : "inline-flex",
                }}
              >
                Sign Up
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
      <Stack
        sx={{
          backgroundColor: "#F5F8FA",
          height: "2vh",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Divider />
        <Footer />
      </Stack>
    </div>
  );
};

export default SignUpPage;
