import { PlansStrings, RoleStrings } from "./stringToObj";

export const bussinessChecker = (role, accountType, plan) => {
  if (role === "ROLE_ACCOUNT_ADMIN") {
    if (
      plan === PlansStrings.DIAMOND ||
      plan === PlansStrings.PLATINUM ||
      plan === PlansStrings.GOLD
    ) {
      return true;
    }
    return false;
  } else if (accountType === "FRANCHISOR") {
    return true;
  } else {
    return false;
  }
};

export const tmpltIndvidualRadioChecker = (role, accountType, plan) => {
  if (role === "ROLE_ACCOUNT_ADMIN") {
    return false;
  } else if (accountType === "FRANCHISOR") {
    if (plan === PlansStrings.DIAMOND || plan === PlansStrings.PLATINUM) {
      return true;
    }
    return false;
  } else {
    return false;
  }
};

//plans

export const isBronze = (plan) => {
  if (plan === PlansStrings.BRONZE) {
    return true;
  }
  return false;
};

export const isUptoSilver = (plan) => {
  if (plan === PlansStrings.BRONZE || plan === PlansStrings.SILVER) {
    return true;
  }
  return false;
};

export const isSilver = (plan) => {
  if (plan === PlansStrings.SILVER) {
    return true;
  }
  return false;
};

export const isGold = (plan) => {
  if (plan === PlansStrings.GOLD) {
    return true;
  }
  return false;
};

export const isUptoGold = (plan) => {
  if (isUptoSilver(plan) || plan === PlansStrings.GOLD) {
    return true;
  }
  return false;
};

//franchisor

export const isFranchisor = (accountType) => {
  if (RoleStrings.FRANCHISOR === accountType) {
    return true;
  }
  return false;
};

export const isFranchisorGold = (accountType, plan) => {
  if (isGold(plan) && isFranchisor(accountType)) {
    return true;
  }
  return false;
};

export const isFranchisorSilver = (accountType, plan) => {
  if (isSilver(plan) && isFranchisor(accountType)) {
    return true;
  }
  return false;
};

export const isFranchisorUptoGold = (accountType, plan) => {
  if (isFranchisor(accountType) && isUptoGold(plan)) {
    return true;
  }
  return false;
};

export const isFranchisorUptoSilver = (accountType, plan) => {
  if (isFranchisor(accountType) && isUptoSilver(plan)) {
    return true;
  }
  return false;
};

export const isFranchisorBronze = (accountType, plan) => {
  if (isFranchisor(accountType) && isBronze(plan)) {
    return true;
  }
  return false;
};

//franchisee

export const isFranchisee = (role) => {
  if (role === RoleStrings.ADMIN) {
    return true;
  }
  return false;
};

export const isFranchiseeGold = (role, plan) => {
  if (isGold(plan) && isFranchisee(role)) {
    return true;
  }
  return false;
};

export const isFranchiseeSilver = (role, plan) => {
  if (isSilver(plan) && isFranchisee(role)) {
    return true;
  }
  return false;
};

export const isFranchiseeUptoGold = (role, plan) => {
  if (isFranchisee(role) && isUptoGold(plan)) {
    return true;
  }
  return false;
};

export const isFranchiseeUptoSilver = (role, plan) => {
  if (isFranchisee(role) && isUptoSilver(plan)) {
    return true;
  }
  return false;
};

export const isFranchiseeBronze = (role, plan) => {
  if (isFranchisee(role) && isBronze(plan)) {
    return true;
  }
  return false;
};

//franchisor and franchisee

export const isFranchiseeFranchisorGold = (role, plan, accountType) => {
  if (isGold(plan) && (isFranchisee(role) || isFranchisor(accountType))) {
    return true;
  }
  return false;
};

export const isFranchiseeFranchisorSilver = (role, plan, accountType) => {
  if (isSilver(plan) && (isFranchisee(role) || isFranchisor(accountType))) {
    return true;
  }
  return false;
};

export const isFranchiseeFranchisorUptoGold = (role, plan, accountType) => {
  if ((isFranchisee(role) || isFranchisor(accountType)) && isUptoGold(role)) {
    return true;
  }
  return false;
};

export const isFranchiseeFranchisorUptoSilver = (role, plan, accountType) => {
  if ((isFranchisee(role) || isFranchisor(accountType)) && isUptoSilver(plan)) {
    return true;
  }
  return false;
};

export const isFranchiseeFranchisorBronze = (role, plan, accountType) => {
  if ((isFranchisee(role) || isFranchisor(accountType)) && isBronze(plan)) {
    return true;
  }
  return false;
};
