import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { ReactComponent as Logo } from "../../assets/complianser-logo-with-text.svg";
import { Alert, Divider, IconButton, Stack, Typography } from "@mui/material";
import "./Login.css";
import Footer from "../../components/Footer/Footer";

const SSOPage = () => {
  return (
    <div className="login-container">
      <div className="logo-container">
        <Logo className="logoStyle" height={"80px"} />
      </div>
      <div className="login-card-container">
        <Card
          sx={{
            width: { sm: 400, xs: 300 },
            position: "fixed",
            marginTop: {
              sm: "-15.8rem",
              ipadAir: "-11.8rem",
              xs: "-14.6rem",
              ipadMini: "-11.8rem",
            },
            flexFlow: "column",
          }}
        >
          <CardHeader
            title={
              window.location.hostname.startsWith("paylient")
                ? "Paylient: PrismHR SSO Login Failed"
                : "INFINITI HR: PrismHR SSO Login Failed"
            }
            titleTypographyProps={{ fontSize: "14px" }}
            sx={{
              backgroundColor: "#164e5f",
              color: "white",
            }}
          />
          <CardContent>
            <Typography color={"error"}>
              Looks like this feature isn't available for your team just yet.
              Please coordinate with your implementation team or company
              administrator to access it.
            </Typography>
          </CardContent>
        </Card>
      </div>
      <Stack
        sx={{
          height: "10vh",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Divider />
        <Footer />
      </Stack>
    </div>
  );
};

export default SSOPage;
