import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import DialoCodeAutocomplete from "./DialoCodeAutocomplete";
import FormTextField from "./FormTextField";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const EmergencyContact = ({
  errors,
  register,
  setValue,
  defaultValueDial,
  disabled,
}) => {
  return (
    <Box sx={{ p: "0 1em" }}>
      <Stack
        width={"100%"}
        direction={"row"}
        justifyContent="flex-start"
        sx={{ marginTop: "1em" }}
      >
        <FiberManualRecordIcon
          sx={{
            marginTop: "0.18em",
            marginLeft: "-1em",
            marginRight: "0.5em",
            color: "#CCCCCC",
            height: "0.5em",
          }}
        />
        <Stack width={"100%"} spacing={2}>
          <Typography variant="h7">{"Emergency Contact"}</Typography>
          <FormTextField
            placeholder={"Enter Emergency Contact Name"}
            setValueName={"emergencyName"}
            register={register}
            errors={errors}
            disabled={disabled}
          />
          <FormTextField
            isMultiLine
            placeholder={"Enter Emergency Contact Address"}
            setValueName={"emergencyAddress"}
            register={register}
            errors={errors}
            disabled={disabled}
          />
          <FormTextField
            placeholder={"Enter Emergency Contact Email"}
            setValueName={"emergencyEmail"}
            register={register}
            errors={errors}
            disabled={disabled}
          />
          <Stack width={"100%"} direction={"row"} spacing={2}>
            <Stack width={"15%"} spacing={1}>
              <DialoCodeAutocomplete
                dialCode="emergencyDialCode"
                setValue={setValue}
                disabled={disabled}
                defaultValue={defaultValueDial}
              />
              {/* <span style={{ color: "#f34f4c", flexWrap: "wrap", width: "100px" }}>
                {errors.emergencyDialCode?.message}
              </span> */}
            </Stack>
            <Stack width={"65%"} spacing={1}>
              <FormTextField
                placeholder={"Enter Mobile Number"}
                register={register}
                errors={errors}
                disabled={disabled}
                setValueName={"emergencyPhone"}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EmergencyContact;
