import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  setProfileLoader,
  updateUserProfileSlice,
  getProfile,
} from "../Dashboard/DashboardSlice";
import { useEffect } from "react";
import Loading from "../../components/Loading/Loading";
import { dateFormat } from "../../utils/dateFormatter";
import DialoCodeAutocomplete from "../../components/FormComponents/DialoCodeAutocomplete";
import { store } from "../../store";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ProfileBody = ({
  setOpenSnackbar,
  setProfileOpenDialog,
  setSnackbarSeverity,
  setSnackbarMessage,
}) => {
  const dispatch = useDispatch();
  const {
    // profile = {},
    profileLoader = true,
  } = useSelector((state) => state.dashboard);
  const loggedInUserInfo = useSelector((state) => state.login.loggedInUserInfo);
  const profile = store.getState().dashboard.profile;

  const [tab, setTab] = useState(0);
  const [isChecked, setIsChecked] = useState(
    profile?.properties?.optInConsentProvided
  );
  const [dialCodeState, setDialCodeState] = useState(
    profile?.properties?.dialCode
  );
  const [emergencyDialCodeState, setEmegencyDialCodeState] = useState(
    profile?.properties?.emergencyContact?.dialCode
  );
  const dob = dateFormat(new Date(profile?.properties?.dob), "-");
  const [dobState, setDobState] = useState(profile?.properties?.dob || "");

  useEffect(() => {
    const obj = {};
    dispatch(setProfileLoader(true));
    obj.firstName = profile?.properties?.firstName;
    obj.lastName = profile?.properties?.lastName;
    obj.dob = profile?.properties?.dob;
    obj.ssn = profile?.properties?.ssn;
    obj.email = profile?.email;
    obj.phone = profile?.properties?.phone;
    obj.dialCode = profile?.properties?.dialCode;
    obj.line1 = profile?.properties?.address?.line1;
    obj.line2 = profile?.properties?.address?.line2;
    obj.city = profile?.properties?.address?.city;
    obj.state = profile?.properties?.address?.state;
    obj.country = profile?.properties?.address?.country;
    obj.pinCode = profile?.properties?.address?.pinCode;
    obj.isChecked = profile?.properties?.optInConsentProvided;
    obj.emergencyContactName = profile?.properties?.emergencyContact?.name;
    obj.emergencyContactAddress =
      profile?.properties?.emergencyContact?.address;
    obj.emergencyContactEmail = profile?.properties?.emergencyContact?.email;
    obj.emergencyContactPhone = profile?.properties?.emergencyContact?.phone;
    obj.emergencyDialCode = profile?.properties?.emergencyContact?.dialCode;
    setIsChecked(profile?.properties?.optInConsentProvided);
    setDialCodeState(profile?.properties?.dialCode);
    setEmegencyDialCodeState(profile?.properties?.emergencyContact?.dialCode);
    reset(obj);
    dispatch(setProfileLoader(false));
  }, [profile]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const schema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup
      .string()
      .email("Please enter valid email")
      .nullable("Email required")
      .required("Email required"),
    line1: yup
      .string()
      .nullable("Address-Line 1 is required")
      .required("Address-Line 1 is required"),
    line2: yup.string().nullable(),
    country: yup
      .string()
      .nullable("Country is required")
      .required("Country is required"),
    state: yup
      .string()
      .nullable("state is required")
      .required("state is required"),
    pinCode: yup
      .string()
      .nullable("Pincode is required")
      .required("Pincode is required"),
    city: yup
      .string()
      .nullable("city is required")
      .required("city is required"),
    phone: yup
      .string()
      .nullable("Contact number is required")
      .required("Contact number is required"),
    dialCode: yup.string().nullable(),
    dob: yup
      .string()
      .nullable("Date of birth is required")
      .required("Date of birth is required"),
    emergencyContactName: yup
      .string()
      .nullable("Emergency Contact Name is required.")
      .required("Emergency Contact Name is required."),
    emergencyContactAddress: yup
      .string()
      .nullable("Emergency Contact Address is required.")
      .required("Emergency Contact Address is required."),
    emergencyContactEmail: yup
      .string()
      .email("Please enter valid email")
      .nullable("Emergency Contact Email is required.")
      .required("Emergency Contact Email is required."),
    emergencyContactPhone: yup
      .string()
      .nullable("Emergency Contact Number is required.")
      .required("Emergency Contact Number is required."),
    emergencyDialCode: yup.string().nullable(),
    ssn: yup.string().nullable(),
    isChecked: yup.bool(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    loggedInUserInfo: { allowOnlySSOLogin },
  } = useSelector((state) => state.login);
  const updateUserProfile = async (data) => {
    try {
      const profileDeepCopy = JSON.parse(JSON.stringify(profile));
      profileDeepCopy.email = data?.email;
      profileDeepCopy.properties.phone = data?.phone;
      profileDeepCopy.properties.dialCode = data?.dialCode;
      profileDeepCopy.properties.dob = dobState;
      profileDeepCopy.properties.address.line1 = data?.line1;
      profileDeepCopy.properties.address.line2 = data?.line2;
      profileDeepCopy.properties.address.city = data?.city;
      profileDeepCopy.properties.address.state = data?.state;
      profileDeepCopy.properties.address.country = data?.country;
      profileDeepCopy.properties.address.pinCode = data?.pinCode;
      profileDeepCopy.properties.optInConsentProvided = isChecked;

      if (!profileDeepCopy) {
        profileDeepCopy = {};
      }

      if (!profileDeepCopy.properties) {
        profileDeepCopy.properties = {};
      }

      // Assign the value to the property
      profileDeepCopy.properties["emergencyContact"] =
        profileDeepCopy.properties["emergencyContact"] || {};
      profileDeepCopy.properties["emergencyContact"]["name"] =
        data.emergencyContactName;
      profileDeepCopy.properties["emergencyContact"]["address"] =
        data.emergencyContactAddress;
      profileDeepCopy.properties["emergencyContact"]["phone"] =
        data.emergencyContactPhone;
      profileDeepCopy.properties["emergencyContact"]["email"] =
        data.emergencyContactEmail;

      const res = await dispatch(
        updateUserProfileSlice({
          employeeId: loggedInUserInfo.employeeId,
          payload: profileDeepCopy,
        })
      )
        .unwrap()
        .then((originalPromiseResult) => {
          dispatch(getProfile(loggedInUserInfo.employeeId));
        })
        .catch((rejectedValueOrSerializedError) => {});

      setOpenSnackbar(true);
      setSnackbarSeverity("success");
      setSnackbarMessage("Profile has been updated successfully");
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarSeverity("error");
      setSnackbarMessage("Profile has not been updated");
    } finally {
      setProfileOpenDialog(false);
    }
  };

  const handleDateChange = (e) => {
    setDobState(e.target.value);
    setValue("dob", e.target.value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          textColor="secondary"
          sx={{
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
          }}
          value={tab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={
              <Typography fontSize={"15px"} fontWeight={600}>
                Edit Profile
              </Typography>
            }
            {...a11yProps(0)}
          />
        </Tabs>
        <div>
          <Button
            id="cancel"
            variant="contained"
            sx={{ background: "#f5f7fa", boxShadow: 1 }}
            onClick={() => setProfileOpenDialog(false)}
          >
            Cancel
          </Button>
          {allowOnlySSOLogin ?null :(<Button
              id="update"
              variant="contained"
              sx={{
                background: "#cc99e5",
                color: "#fff",
                marginLeft: "8px",
                boxShadow: 1,
              }}
              onClick={handleSubmit(updateUserProfile)}
          >
            Update
          </Button>)}

        </div>
      </Box>
      <Box sx={{ padding: 0 }}>
        <TabPanel value={tab} index={0}>
          {profileLoader ? (
            <Loading />
          ) : (
            <Grid
              container
              sx={{
                height: {
                  md: "65vh",
                  xs: "66vh",
                  lg: "74vh",
                  ipadMini: "70vh",
                  ipadAir: "75vh",
                  bigScreen: "75vh",
                },
                maxHeight: {
                  md: "65vh",
                  xs: "66vh",
                  lg: "74vh",
                  ipadMini: "70vh",
                  ipadAir: "75vh",
                  bigScreen: "75vh",
                },
                overflowY: "auto",
                overflowX: "hidden",
                "&::-webkit-scrollbar": {
                  width: 10,
                },
                "&:hover": {
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#9e9e9e",
                    borderRadius: "50px",
                    ":hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.35)",
                    },
                  },
                },
              }}
            >
              <Grid item lg={12} xl={12} bigScreen={12} xs={12} sm={12} md={12}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent="flex-start"
                  sx={{ marginTop: "1em", marginLeft: "2em" }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginTop: "0.2em",
                      marginLeft: "-1em",
                      marginRight: "0.5em",
                      color: "#CCCCCC",
                      height: "0.5em",
                    }}
                  />{" "}
                  <Stack width={"100%"} spacing={2}>
                    <Typography variant="h7">First Name </Typography>
                    <TextField
                      id="firstName"
                      sx={{
                        width: { sm: "350px", xs: "200px" },
                        background: "#f9fafb",
                      }}
                      size="small"
                      defaultValue={profile?.properties?.firstName || ""}
                      {...register("firstName")}
                      disabled={true}
                    />
                  </Stack>
                </Stack>
                <Divider
                  sx={{
                    borderStyle: "dotted",
                    marginLeft: "-2.2em",
                    marginTop: "8px",
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent="flex-start"
                  sx={{ marginTop: "1em", marginLeft: "2em" }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginTop: "0.2em",
                      marginLeft: "-1em",
                      marginRight: "0.5em",
                      color: "#CCCCCC",
                      height: "0.5em",
                    }}
                  />
                  <Stack width={"100%"} spacing={2}>
                    <Typography variant="h7">Last Name </Typography>
                    <TextField
                      id="lastName"
                      sx={{
                        width: { sm: "350px", xs: "200px" },
                        background: "#f9fafb",
                      }}
                      size="small"
                      defaultValue={profile?.properties?.lastName || ""}
                      {...register("lastName")}
                      disabled={true}
                    />
                  </Stack>
                </Stack>
                <Divider
                  sx={{
                    borderStyle: "dotted",
                    marginLeft: "-2.2em",
                    marginTop: "8px",
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent="flex-start"
                  sx={{ marginTop: "1em", marginLeft: "2em" }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginTop: "0.2em",
                      marginLeft: "-1em",
                      marginRight: "0.5em",
                      color: "#CCCCCC",
                      height: "0.5em",
                    }}
                  />
                  <Stack width={"100%"} spacing={2}>
                    <Typography variant="h7">Email Address </Typography>
                    <TextField
                      id="email"
                      sx={{ width: { sm: "350px", xs: "200px" } }}
                      size="small"
                      defaultValue={profile?.email || ""}
                      placeholder="Enter Valid Email Address"
                      disabled={allowOnlySSOLogin}
                      {...register("email")}
                    />
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.email?.message}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider sx={{ borderStyle: "dotted", marginLeft: "-2.2em" }} />
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent="flex-start"
                  sx={{ marginTop: "1em", marginLeft: "2em" }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginTop: "0.2em",
                      marginLeft: "-1em",
                      marginRight: "0.5em",
                      color: "#CCCCCC",
                      height: "0.5em",
                    }}
                  />
                  <Stack width={"100%"} spacing={2}>
                    <Typography variant="h7">Address </Typography>
                    <TextField
                      id="line1"
                      sx={{ width: { sm: "350px", xs: "200px" } }}
                      size="small"
                      fullwidth
                      disabled={allowOnlySSOLogin}
                      placeholder="Enter Address"
                      defaultValue={profile?.properties?.address?.line1 || ""}
                      {...register("line1")}
                    />{" "}
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.line1?.message}
                    </Typography>
                    <TextField
                      id="line2"
                      sx={{ width: { sm: "350px", xs: "200px" } }}
                      size="small"
                      fullWidth
                      disabled={allowOnlySSOLogin}
                      placeholder="Enter Address"
                      defaultValue={profile?.properties?.address?.line2 || ""}
                      {...register("line2")}
                    />{" "}
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.line2?.message}
                    </Typography>
                    <TextField
                      id="city"
                      sx={{ width: { sm: "350px", xs: "200px" } }}
                      size="small"
                      fullWidth
                      disabled={allowOnlySSOLogin}
                      placeholder="Enter City"
                      defaultValue={profile?.properties?.address?.city || ""}
                      {...register("city")}
                    />{" "}
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.city?.message}
                    </Typography>
                    <TextField
                      id="state"
                      sx={{ width: { sm: "350px", xs: "200px" } }}
                      size="small"
                      disabled={allowOnlySSOLogin}
                      fullWidth
                      placeholder="Enter State"
                      defaultValue={profile?.properties?.address?.state || ""}
                      {...register("state")}
                    />{" "}
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.state?.message}
                    </Typography>
                    <TextField
                      id="country"
                      sx={{ width: { sm: "350px", xs: "200px" } }}
                      size="small"
                      disabled={allowOnlySSOLogin}
                      fullWidth
                      placeholder="Enter country"
                      defaultValue={profile?.properties?.address?.country || ""}
                      {...register("country")}
                    />{" "}
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.country?.message}
                    </Typography>
                    <TextField
                      id="zip"
                      sx={{ width: { sm: "350px", xs: "200px" } }}
                      size="small"
                      disabled={allowOnlySSOLogin}
                      fullWidth
                      placeholder="Enter pinCode"
                      defaultValue={profile?.properties?.address?.pinCode || ""}
                      {...register("pinCode")}
                    />{" "}
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.pinCode?.message}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider sx={{ borderStyle: "dotted", marginLeft: "-2.2em" }} />
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent="flex-start"
                  sx={{ marginTop: "1em", marginLeft: "2em" }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginTop: "0.2em",
                      marginLeft: "-1em",
                      marginRight: "0.5em",
                      color: "#CCCCCC",
                      height: "0.5em",
                    }}
                  />
                  <Stack width={"100%"} spacing={2}>
                    <Typography variant="h7">
                      Mobile Number (sms capable){" "}
                    </Typography>
                    <Stack width={"100%"} direction={"row"}>
                      <Stack width={{ sm: "15%", xs: "28%" }} spacing={1}>
                        <DialoCodeAutocomplete
                          setValue={setValue}
                          disabled={allowOnlySSOLogin}
                          defaultValue={dialCodeState || "+1"}
                        />
                        <Typography
                          variant="body1"
                          style={{
                            color: "#f34f4c",
                            flexWrap: "wrap",
                            width: "100px",
                          }}
                        >
                          {errors.dialCode?.message}
                        </Typography>
                      </Stack>
                      <Stack
                        width={{
                          sm: "36.5%",
                          xs: "50%",
                          ipadAir: "35.5%",
                          ipadMini: "39%",
                          md: "35.5%",
                          lg: "36.6%",
                        }}
                        spacing={1}
                      >
                        <TextField
                          id="phone"
                          sx={{ width: "100%", marginLeft: "20px" }}
                          size="small"
                          defaultValue={profile?.properties?.phone || ""}
                          {...register("phone")}
                          fullWidth
                          disabled={allowOnlySSOLogin}
                          placeholder="Enter Phone Number"
                          type="tel"
                        />{" "}
                        <Typography
                          variant="body1"
                          style={{
                            color: "#f34f4c",
                            flexWrap: "wrap",
                            width: "200px",
                            marginLeft: "2em",
                          }}
                        >
                          {errors.phone?.message}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
                <Divider
                  sx={{
                    borderStyle: "dotted",
                    marginLeft: "-2.2em",
                    marginTop: "1em",
                  }}
                />
              </Grid>

              <Grid item lg={12} xs={12} sm={12} md={12}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent="flex-start"
                  sx={{ marginTop: "1em", marginLeft: "2em" }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginTop: "0.2em",
                      marginLeft: "-1em",
                      marginRight: "0.5em",
                      color: "#CCCCCC",
                      height: "0.5em",
                    }}
                  />
                  <Stack
                    width={{ sm: "100%", xs: "200px" }}
                    spacing={2}
                    sx={{ display: "flex" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px" }}>
                        <Checkbox
                          id="OptIn"
                          disabled={allowOnlySSOLogin}
                          sx={{ padding: "0px", alignItems: "top" }}
                          checked={isChecked}
                          onChange={() => {
                            setValue("isChecked", !isChecked);
                            setIsChecked(!isChecked);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                      <Typography variant="h7" sx={{ alignItems: "center" }}>
                        Opt-in to receive text messages on this mobile number{" "}
                      </Typography>
                    </div>
                    <Stack style={{ width: "95%" }}>
                      <Typography
                        variant="body3"
                        fontWeight={600}
                        style={{ marginLeft: "-2.5em" }}
                      >
                        <>
                          When Opt-in is selected, the system will send critical
                          transactional messages such as One Time Passcode
                          (OTP), change of mobile number notification, MFA etc.
                          Opt-in is recommended to keep your account secure.
                        </>
                        <br />
                        <>
                          If Opt-in is not selected, all such messages will be
                          sent to the user’s current email on record. Opt-out is
                          not recommended.
                        </>
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Divider
                  sx={{
                    borderStyle: "dotted",
                    marginLeft: "-2.2em",
                    marginTop: "8px",
                  }}
                />
              </Grid>

              <Grid item lg={12} xs={12} sm={12} md={12}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent="flex-start"
                  sx={{ marginTop: "1em", marginLeft: "2em" }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginTop: "0.2em",
                      marginLeft: "-1em",
                      marginRight: "0.5em",
                      color: "#CCCCCC",
                      height: "0.5em",
                    }}
                  />
                  <Stack width={"100%"} spacing={2}>
                    <Typography variant="h7">Date of Birth </Typography>
                    <TextField
                      disabled={allowOnlySSOLogin}
                      id="dob"
                      sx={{ width: { sm: "350px", xs: "200px" } }}
                      size="small"
                      type="date"
                      fullWidth
                      placeholder="Enter DOM MM-DD-YYYY"
                      defaultValue={profile?.properties?.dob || ""}
                      onChange={handleDateChange}
                    />{" "}
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.dob?.message}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider sx={{ borderStyle: "dotted", marginLeft: "-2.2em" }} />
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent="flex-start"
                  sx={{ marginTop: "1em", marginLeft: "2em" }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginTop: "0.2em",
                      marginLeft: "-1em",
                      marginRight: "0.5em",
                      color: "#CCCCCC",
                      height: "0.5em",
                    }}
                  />
                  <Stack width={"100%"} spacing={2}>
                    <Typography variant="h7">
                      Social Security Number{" "}
                    </Typography>
                    <TextField
                      id="ssn"
                      sx={{
                        width: { sm: "350px", xs: "200px" },
                        background: "#f9fafb",
                      }}
                      size="small"
                      type="text"
                      fullWidth
                      placeholder="Enter SSN"
                      disabled={true}
                      defaultValue={profile?.properties?.ssn || ""}
                      {...register("ssn")}
                    />{" "}
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.ssn?.message}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider sx={{ borderStyle: "dotted", marginLeft: "-2.2em" }} />
              </Grid>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent="flex-start"
                  sx={{ marginTop: "1em", marginLeft: "2em" }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginTop: "0.2em",
                      marginLeft: "-1em",
                      marginRight: "0.5em",
                      color: "#CCCCCC",
                      height: "0.5em",
                    }}
                  />
                  <Stack width={"100%"} spacing={2}>
                    <Typography variant="h7">Emergency Contact </Typography>
                    <TextField
                      id="emergencyName"
                      sx={{ width: { sm: "350px", xs: "200px" } }}
                      size="small"
                      type="text"
                      disabled={allowOnlySSOLogin}
                      fullWidth
                      defaultValue={
                        profile?.properties?.emergencyContact?.name || ""
                      }
                      placeholder="Enter Emergency Contact Name"
                      {...register("emergencyContactName")}
                    />{" "}
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.emergencyContactName?.message}
                    </Typography>
                    <TextField
                      id="emergencyContact"
                      sx={{ width: { sm: "350px", xs: "200px" } }}
                      size="small"
                      type="text"
                      disabled={allowOnlySSOLogin}
                      fullWidth
                      defaultValue={
                        profile?.properties?.emergencyContact?.address || ""
                      }
                      placeholder="Enter Emergency Contact Address"
                      multiline={true}
                      rows={4}
                      {...register("emergencyContactAddress")}
                    />{" "}
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.emergencyContactAddress?.message}
                    </Typography>
                    <TextField
                      id="emergencyEmail"
                      sx={{ width: { sm: "350px", xs: "200px" } }}
                      size="small"
                      type="text"
                      disabled={allowOnlySSOLogin}
                      fullWidth
                      defaultValue={
                        profile?.properties?.emergencyContact?.email || ""
                      }
                      placeholder="Enter Emergency Contact Email"
                      {...register("emergencyContactEmail")}
                    />{" "}
                    <Typography variant="body1" style={{ color: "#f34f4c" }}>
                      {errors.emergencyContactEmail?.message}
                    </Typography>
                    <Grid item sx={{ display: "flex" }}>
                      <Stack width={{ sm: "15%", xs: "28%" }} spacing={1}>
                        <DialoCodeAutocomplete
                          disabled={allowOnlySSOLogin}
                          setValue={setValue}
                          dialCode="emergencyDialCode"
                          defaultValue={emergencyDialCodeState || "+1"}
                        />
                      </Stack>
                      <Stack
                        width={{
                          sm: "36.5%",
                          xs: "50%",
                          ipadAir: "35.5%",
                          ipadMini: "39%",
                          md: "35.5%",
                          lg: "36.6%",
                        }}
                        spacing={1}
                      >
                        <TextField
                          id="emergencyPhone"
                          sx={{ width: "100%", marginLeft: "20px" }}
                          size="small"
                          {...register("emergencyContactPhone")}
                          fullWidth
                          disabled={allowOnlySSOLogin}
                          placeholder="Enter Phone Number"
                          defaultValue={
                            profile?.properties?.emergencyContact?.phone || ""
                          }
                          type="tel"
                        />{" "}
                        <Typography
                          variant="body1"
                          style={{
                            color: "#f34f4c",
                            flexWrap: "wrap",
                            width: "200px",
                            marginLeft: "2em",
                          }}
                        >
                          {errors.emergencyContactPhone?.message}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Stack>
                </Stack>
                <Divider
                  sx={{
                    borderStyle: "dotted",
                    marginLeft: "-2.2em",
                    marginBottom: "2rem",
                  }}
                />
              </Grid>
            </Grid>
          )}
        </TabPanel>
      </Box>
    </Box>
  );
};

export default ProfileBody;
