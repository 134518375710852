import { PlansStrings } from "../../utils/stringToObj";
import { calculatePrice } from "./utils";

export const paymentData = [
  {
    "HQ Plan": "Platinum",
    "Location Plan": "Gold",
    "#Loc": 42,
    "Transaction Date": "Mar 28, 2024",
    "Subscription Change date": "Mar 28, 2024",
    "Total Amount Charged": "$1500000",
    Status: "Completed",
    Code: "ABC123",
    "Payment Account Used": "ICICI Bank **** 5678",
  },

  {
    "HQ Plan": "Gold",
    "Location Plan": "Silver",
    "#Loc": 19,
    "Transaction Date": "Apr 01, 2024",
    "Subscription Change date": "Mar 28, 2024",
    "Total Amount Charged": "$80",
    Status: "Failed",
    Code: "DEF456",
    "Payment Account Used": "SBI Bank **** 9876",
  },
  {
    "HQ Plan": "Silver",
    "Location Plan": "Bronze",
    "#Loc": 73,
    "Transaction Date": "Apr 05, 2024",
    "Subscription Change date": "Mar 28, 2024",
    "Total Amount Charged": "$220",
    Status: "Pending",
    Code: "-",
    "Payment Account Used": "Axis Bank **** 2468",
  },
  {
    "HQ Plan": "Platinum",
    "Location Plan": "Platinum",
    "#Loc": 57,
    "Transaction Date": "Apr 08, 2024",
    "Subscription Change date": "Mar 28, 2024",
    "Total Amount Charged": "$190",
    Status: "Completed",
    Code: "GHI789",
    "Payment Account Used": "HDFC Bank **** 1357",
  },
  {
    "HQ Plan": "Gold",
    "Location Plan": "Platinum",
    "#Loc": 31,
    "Transaction Date": "Apr 12, 2024",
    "Subscription Change date": "Mar 28, 2024",
    "Total Amount Charged": "$120",
    Status: "Pending",
    Code: "-",
    "Payment Account Used": "Citi Bank **** 3690",
  },
  {
    "HQ Plan": "Bronze",
    "Location Plan": "Gold",
    "#Loc": 88,
    "Transaction Date": "Apr 15, 2024",
    "Subscription Change date": "Mar 28, 2024",
    "Total Amount Charged": "$300",
    Status: "Failed",
    Code: "JKL012",
    "Payment Account Used": "IDFC Bank **** 8024",
  },
  {
    "HQ Plan": "Platinum",
    "Location Plan": "Silver",
    "#Loc": 23,
    "Transaction Date": "Apr 18, 2024",
    "Subscription Change date": "Mar 28, 2024",
    "Total Amount Charged": "$250",
    Status: "Completed",
    Code: "MNO345",
    "Payment Account Used": "Kotak Bank **** 1593",
  },
  {
    "HQ Plan": "Silver",
    "Location Plan": "Gold",
    "#Loc": 64,
    "Transaction Date": "Apr 21, 2024",
    "Subscription Change date": "Mar 28, 2024",
    "Total Amount Charged": "$180",
    Status: "Pending",
    Code: "-",
    "Payment Account Used": "Canara Bank **** 7531",
  },
  {
    "HQ Plan": "Gold",
    "Location Plan": "Bronze",
    "#Loc": 17,
    "Transaction Date": "Apr 24, 2024",
    "Subscription Change date": "Mar 28, 2024",
    "Total Amount Charged": "$90",
    Status: "Failed",
    Code: "PQR678",
    "Payment Account Used": "Bank of Baroda **** 4680",
  },
  {
    "HQ Plan": "Platinum",
    "Location Plan": "Gold",
    "#Loc": 50,
    "Transaction Date": "Apr 27, 2024",
    "Subscription Change date": "Mar 28, 2024",
    "Total Amount Charged": "$200",
    Status: "Completed",
    Code: "STU901",
    "Payment Account Used": "Union Bank **** 2345",
  },
];

export const subscriptiondata = (
  plan,
  numberOfLoc = 0,
  locationPlan = PlansStrings.BASE,
  pricing = null
) => {
  return [
    {
      Subscritpion: "HQ Module",
      Plan: plan,
      "Monthly Dues": `$${calculatePrice(
        plan,
        numberOfLoc || 0,
        pricing ? pricing["HQ"][plan] : null
      )}`,
    },
    {
      Subscritpion: "Location Module",
      Plan: locationPlan,
      "Monthly Dues": `$${calculatePrice(
        locationPlan,
        numberOfLoc || 0,
        pricing ? pricing["Location"][plan] : null
      )}`,
    },
  ];
};

export const calculateTotalAmount = (amountString) => {
  let amountArr = amountString.split("$");
  return parseFloat(amountArr[1]) + parseFloat(amountArr[2]);
};
