import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

const GlobalLoader = ({ isLoading }) => {
  return (
    <Backdrop
      sx={{
        color: "white",
        backgroundColor: "#696969",
        zIndex: 9,
      }}
      open={isLoading}
    >
      <CircularProgress color={"primary"} />
    </Backdrop>
  );
};

export default GlobalLoader;
