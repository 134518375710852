import { ajaxHandler } from "./ajaxHandler";

export const verifyOTP = async (payload) => {
  const response = await ajaxHandler(
    "post",
    `focus451/employee/verifyOtp`,
    payload
  );
  return response;
};

export const confirmUser = async (payload) => {
  const response = await ajaxHandler(
    "post",
    `focus451/employee/confirmUser`,
    payload
  );
  return response;
};

export const eSignAgreement = async (payload) => {
  const response = await ajaxHandler(
    "put",
    `focus451/employee/eSignAgreement/${payload.employeeId}`,
    payload
  );
  return response;
};

export const eSignAgreementNewUser = async (payload) => {
  const response = await ajaxHandler(
    "put",
    `focus451/employee/resetPassword/${payload.employeeId}/${payload.token}`,
    payload.data
  );
  return response;
};
export const eSignAgreementOldUser = async (payload) => {
  const response = await ajaxHandler(
    "put",
    `focus451/employee/resetPassword/${payload.employeeId}/${payload.token}`,
    payload.data
  );
  return response;
};
