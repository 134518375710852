export const TABLE_TYPE = {
  ALL: "all",
  ACTIVE: "active",
  CORPORATE: "corporate",
};

export const DOCUMENT_TYPE = {
  ALL: "all",
  OVERDUE: "overdue",
  PENDING: "pending",
  COMPLIANT: "compliant",
  CCI: "cci",
};

export const ROW_TYPE = {
  GET_BY_BUSINESS: "getByBusiness",
  GET_BY_LOCATION: "getByLocation",
  GET_BY_DEPARTMENT: "getByDepartment",
  MAIN: "Main",
  LOCATION: "Location",
  DEPARTMENT: "Department",
};

export const CCI_COMPLIANCE_TYPE = {
  EMPLOYEE: "Employee Compliance",
  BUSINESS: "Business Compliance",
};

export const CCI_ROW_TYPE = {
  BUSINESS: "business",
  LOCATION: "location",
  DEPARTMENT: "department",
  EMPLOYEE: "employee",
};
