import React, { useState } from "react";
import { ajaxHandler } from "../../services/ajaxHandler";
import { useSelector } from "react-redux";

const useDefaultBankAccount = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const clientId = useSelector(
    (state) => state.login.loggedInUserInfo.accountId
  );
  const fetchData = async ({ plaidID, bankName, onSuccess = null }) => {
    setLoading(true);
    try {
      const response = await ajaxHandler(
        "put",
        `focus451/paymentGateway/makeDefault?plaidAccountId=${plaidID}&userClientId=${clientId}&institutionName=${bankName}`
      );

      if (response.status === 200) {
        setData(response.data);
        if (onSuccess) {
          onSuccess();
        }
      } else {
        throw new Error(response.data || "Failed to fetch data");
      }
    } catch (error) {
      setError(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchData };
};

export default useDefaultBankAccount;
