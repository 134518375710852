import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBusinessComplianceDetailsByUserRole,
  getBusinessDetailsById,
  getLocationComplianceDetailsByBusiness,
  getTasksByUserId,
  getByBusiness,
  getDepartmentComplianceDetailsByLocation,
  getByLocation,
  getByDepartment,
  getBusinessComplianceDetailsByAllUserRole,
  getuserProfile,
  updateUserProfile,
  getMyDashboard,
  getBusinessComplianceDetailsByUserRoleForCorporate,
  getLocationsForMap,
  getLocationComplianceDetailsByBusinessAll,
  getDepartmentComplianceDetailsByLocationAll,
  getLocationComplianceDetailsByBusinessCorporate,
  getLocationsDocuments,
  getLocationsCompliant,
  PostNewBusiness,
  getLocationComplDocuments,
  DownloadLocationComplDocuments,
  getDocTemplate,
  getTaskHistory,
  getByBusinessAll,
  getByBusinessCompliant,
  getByBusinessPending,
  DownloadSupplementalDocs,
  getByBusinessOverdue,
  getByBusinessAllCompliant,
  getByBusinessAllPending,
  getByBusinessAllOverdue,
  getByLocationCompliant,
  getByLocationPending,
  getByLocationOverdue,
  getByLocationAll,
  getByLocationAllCompliant,
  getByLocationAllPending,
  getByLocationAllOverdue,
  getByDepartmentCompliant,
  getByDepartmentPending,
  getByDepartmentOverdue,
  getByDepartmentAll,
  getByDepartmentAllCompliant,
  getByDepartmentAllPending,
  getByDepartmentAllOverdue,
  getDetailedComplianceStatsForEmpAll,
  getDetailedComplianceStatsForEmpCompliant,
  getDetailedComplianceStatsForEmpPending,
  getDetailedComplianceStatsForEmpAllOverdue,
  DownloadLocationSupportingDocuments,
  getDocsByBusinessAll,
  getDocsByBusinessCompliant,
  getDocsByBusinessPending,
  getDocsByBusinessOverdue,
  getDocsByLocationAll,
  getDocsByLocationCompliant,
  getDocsByLocationPending,
  getDocsByLocationOverdue,
  editBusinessDetailsById,
  getFranchises,
  getRegions,
  createLocation,
  createFranchisee,
  getCciApi,
} from "../../services/dashboard";

export const createLocationThunk = createAsyncThunk(
  "dashboard/createLocationThunk",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await createLocation(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const createFranchiseeThunk = createAsyncThunk(
  "dashboard/createFranchiseeThunk",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await createFranchisee(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRegionsThunk = createAsyncThunk(
  "dashboard/getRegionsThunk",
  async (franchisorId, { rejectWithValue }) => {
    try {
      const response = await getRegions(franchisorId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFranchisesThunk = createAsyncThunk(
  "dashboard/getFranchisesThunk",
  async (accountId, { rejectWithValue }) => {
    try {
      const response = await getFranchises(accountId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDashboardData = createAsyncThunk(
  "dashboard/dashboardAPI",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await getBusinessComplianceDetailsByUserRole(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessComplianceDetailsByAllUserRoleId = createAsyncThunk(
  "dashboard/getBusinessComplianceDetailsByAllUserRoleId",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await getBusinessComplianceDetailsByAllUserRole(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessComplianceDetailsByUserForCorporate = createAsyncThunk(
  "dashboard/getBusinessComplianceDetailsByUserForCorporate",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await getBusinessComplianceDetailsByUserRoleForCorporate(
        userId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessDetails = createAsyncThunk(
  "dashboard/businessDetailsAPI",
  async (businessId, { rejectWithValue }) => {
    try {
      const response = await getBusinessDetailsById(businessId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editBusinessDetailsThunk = createAsyncThunk(
  "dashboard/editBusinessDetails",
  async (businessId, { rejectWithValue }) => {
    try {
      const response = await editBusinessDetailsById(businessId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getAllTasksByUserId = createAsyncThunk(
  "dashboard/getAllTasksByUserId",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await getTasksByUserId(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMyDashboardDetail = createAsyncThunk(
  "dashboard/myDashboardDetail",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await getMyDashboard(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDetailedCompStatsForEmpAll = createAsyncThunk(
  "dashboard/getDetailedCompStatsForEmpAll",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await getDetailedComplianceStatsForEmpAll(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDetailedCompStatsForEmpCompliant = createAsyncThunk(
  "dashboard/getDetailedCompStatsForEmpCompliant",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await getDetailedComplianceStatsForEmpCompliant(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDetailedCompStatsForEmpPending = createAsyncThunk(
  "dashboard/getDetailedCompStatsForEmpPending",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await getDetailedComplianceStatsForEmpPending(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDetailedCompStatsForEmpOverdue = createAsyncThunk(
  "dashboard/getDetailedCompStatsForEmpOverdue",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await getDetailedComplianceStatsForEmpAllOverdue(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationComplianceDetails = createAsyncThunk(
  "dashboard/getLocationComplianceDetailsByBussinessIdUserId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getLocationComplianceDetailsByBusiness(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationComplianceDetailsAll = createAsyncThunk(
  "dashboard/getLocationComplianceDetailsAllByBussinessIdUserId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getLocationComplianceDetailsByBusinessAll(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationComplianceDetailsCorporate = createAsyncThunk(
  "dashboard/getLocationComplianceDetailsAllByBussinessIdUserIdCorporate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getLocationComplianceDetailsByBusinessCorporate(
        data
      ); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusiness = createAsyncThunk(
  "dashboard/getBusiness",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByBusiness(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessCompliant = createAsyncThunk(
  "dashboard/getBusinessCompliant",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByBusinessCompliant(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessPending = createAsyncThunk(
  "dashboard/getBusinessPending",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByBusinessPending(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessOverdue = createAsyncThunk(
  "dashboard/getBusinessOverdue",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByBusinessOverdue(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDocByBusinessAll = createAsyncThunk(
  "dashboard/getDocByBusinessAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getDocsByBusinessAll(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDocByBusinessCompliant = createAsyncThunk(
  "dashboard/getDocByBusinessCompliant",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getDocsByBusinessCompliant(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDocByBusinessPending = createAsyncThunk(
  "dashboard/getDocByBusinessPending",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getDocsByBusinessPending(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDocByBusinessOverdue = createAsyncThunk(
  "dashboard/getDocByBusinessOverdue",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getDocsByBusinessOverdue(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDocByLocationAll = createAsyncThunk(
  "dashboard/getDocByLocationAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getDocsByLocationAll(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDocByLocationCompliant = createAsyncThunk(
  "dashboard/getDocByLocationCompliant",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getDocsByLocationCompliant(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDocByLocationPending = createAsyncThunk(
  "dashboard/getDocByLocationPending",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getDocsByLocationPending(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDocByLocationOverdue = createAsyncThunk(
  "dashboard/getDocByLocationOverdue",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getDocsByLocationOverdue(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessAll = createAsyncThunk(
  "dashboard/getBusinessAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByBusinessAll(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessAllCompliant = createAsyncThunk(
  "dashboard/getBusinessAllCompliant",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByBusinessAllCompliant(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessAllPending = createAsyncThunk(
  "dashboard/getBusinessAllPending",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByBusinessAllPending(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessAllOverdue = createAsyncThunk(
  "dashboard/getBusinessAllOverdue",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByBusinessAllOverdue(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveNewBusiness = createAsyncThunk(
  "business/createNewBusiness",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await PostNewBusiness(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getDepartmentComplianceDetailsByLocationBusinessId =
  createAsyncThunk(
    "dashboard/getDepartmentComplianceDetailsByLocationBusinessId",
    async (data, { rejectWithValue }) => {
      try {
        const response = await getDepartmentComplianceDetailsByLocation(data); //data is businessId, userId,
        return response;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

export const getDepartmentComplianceDetailsByLocationBusinessIdAll =
  createAsyncThunk(
    "dashboard/getDepartmentComplianceDetailsByLocationBusinessIdAll",
    async (data, { rejectWithValue }) => {
      try {
        const response = await getDepartmentComplianceDetailsByLocationAll(
          data
        ); //data is businessId, userId,
        return response;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

export const getLocations = createAsyncThunk(
  "dashboard/getLocations",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByLocation(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationCompliant = createAsyncThunk(
  "dashboard/getLocationCompliant",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByLocationCompliant(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationPending = createAsyncThunk(
  "dashboard/getLocationPending",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByLocationPending(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationOverdue = createAsyncThunk(
  "dashboard/getLocationOverdue",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByLocationOverdue(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationAll = createAsyncThunk(
  "dashboard/getLocationAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByLocationAll(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationAllCompliant = createAsyncThunk(
  "dashboard/getLocationAllCompliant",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByLocationAllCompliant(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationAllPending = createAsyncThunk(
  "dashboard/getLocationAllPending",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByLocationAllPending(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationAllOverdue = createAsyncThunk(
  "dashboard/getLocationAllOverdue",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByLocationAllOverdue(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDepartments = createAsyncThunk(
  "dashboard/getDepartments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByDepartment(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDepartmentCompliant = createAsyncThunk(
  "dashboard/getDepartmentCompliant",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByDepartmentCompliant(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDepartmentPending = createAsyncThunk(
  "dashboard/getDepartmentPending",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByDepartmentPending(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDepartmentOverdue = createAsyncThunk(
  "dashboard/getDepartmentOverdue",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByDepartmentOverdue(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDepartmentAll = createAsyncThunk(
  "dashboard/getDepartmentAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByDepartmentAll(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDepartmentAllCompliant = createAsyncThunk(
  "dashboard/getDepartmentAllCompliant",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByDepartmentAllCompliant(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDepartmentAllPending = createAsyncThunk(
  "dashboard/getDepartmentAllPending",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByDepartmentAllPending(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDepartmentAllOverdue = createAsyncThunk(
  "dashboard/getDepartmentAllOverdue",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getByDepartmentAllOverdue(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationsForMapAction = createAsyncThunk(
  "dashboard/getLocationsForMap",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getLocationsForMap(data);
      response.forEach((location) => {
        const geoCodes = location.geoCode.split(",").reduce((result, token) => {
          var [key, value] = token.split("=");
          result[key] = parseFloat(value);
          return result;
        }, {});
        location.geoCode = geoCodes;
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationsDocsForMapAction = createAsyncThunk(
  "dashboard/getLocationsDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getLocationsDocuments(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationsForCompliant = createAsyncThunk(
  "dashboard/getLocationsCompliant",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getLocationsCompliant(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationsDocumentsForCompliant = createAsyncThunk(
  "dashboard/getLocationComplDocuments",
  async (taskId, { rejectWithValue }) => {
    try {
      const response = await getLocationComplDocuments(taskId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadLocationsDocumentsForCompliant = createAsyncThunk(
  "dashboard/DownloadLocationComplDocuments",
  async (taskId, { rejectWithValue }) => {
    try {
      const response = await DownloadLocationComplDocuments(taskId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const DownloadSupplementalDocsSlice = createAsyncThunk(
  "dashboard/DownloadSupplementalDocsSlice",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DownloadSupplementalDocs(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadLocationSupportingDocumentsforCompliant = createAsyncThunk(
  "dashboard/DownloadLocationSupportingDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DownloadLocationSupportingDocuments(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDoctemplateonLocation = createAsyncThunk(
  "dashboard/getDocTemplate",
  async (templateId, { rejectWithValue }) => {
    try {
      const response = await getDocTemplate(templateId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTaskHistorybyId = createAsyncThunk(
  "dashboard/getTaskHistory",
  async (taskId, { rejectWithValue }) => {
    try {
      const response = await getTaskHistory(taskId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProfile = createAsyncThunk(
  "dashboard/getProfile",
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await getuserProfile(employeeId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserProfileSlice = createAsyncThunk(
  "dashboard/updateUserProfileSlice",
  async (data, { rejectWithValue }) => {
    try {
      const { employeeId, payload } = data;
      const response = await updateUserProfile(employeeId, payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCCiThunk = createAsyncThunk(
  "dashboard/getCci",
  async (data, { rejectWithValue }) => {
    try {
      const { accountId, complianceType, businessId } = data;
      const response = await getCciApi(accountId, complianceType, businessId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  bussinessNotification: {
    message: "",
    openSnackbar: false,
    severity: "success",
  },
  loading: false,
  loaderDashboard: true,
  loaderCompliance: false,
  tableLoading: false,
  taskLoading: false,
  bussinessLoading: false,
  bussinessCompliantLoading: false,
  bussinessPendingLoading: false,
  bussinessOverdueLoading: false,

  docsByBussinessAllLoading: false,
  docsByBussinessCompliantLoading: false,
  docsByBussinessPendingLoading: false,
  docsByBussinessOverdueLoading: false,

  docsByLocationAllLoading: false,
  docsByLocationCompliantLoading: false,
  docsByLocationPendingLoading: false,
  docsByLocationOverdueLoading: false,

  bussinessAllLoading: false,
  bussinessAllCompliantLoading: false,
  bussinessAllPendingLoading: false,
  bussinessAllOverdueLoading: false,

  locationLoading: false,
  locationCompliantLoading: false,
  locationPendingLoading: false,
  locationOverdueLoading: false,
  locationAllLoading: false,
  locationAllCompliantLoading: false,
  locationAllPendingLoading: false,
  locationAllOverdueLoading: false,

  departmentLoading: false,
  departmentCompliantLoading: false,
  departmentPendingLoading: false,
  departmentOverdueLoading: false,
  departmentAllLoading: false,
  departmentAllCompliantLoading: false,
  departmentAllPendingLoading: false,
  departmentAllOverdueLoading: false,

  empComplianceStatsAllLoading: false,
  empComplianceStatsCompliantLoading: false,
  empComplianceStatsPendingLoading: false,
  empComplianceStatsOverdueLoading: false,

  isError: false,
  errorMessage: "",
  tableType: "",
  dashboardDetails: [],
  selectedBussinessDetails: [],
  agreementPopState: false,

  dashboardtasksDetails: [],
  businessLocationComplianceDetails: {},

  businessLocationComplianceDetailsAll: {},
  businessLocationComplianceDetailsCorporate: {},

  businessDetails: [],
  businessDetailsCompliant: [],
  businessDetailsPending: [],
  businessDetailsOverdue: [],

  businessDetailsAll: [],
  businessDetailsAllCompliant: [],
  businessDetailsAllPending: [],
  businessDetailsAllOverdue: [],

  docsByBusinessDetailsAll: [],
  docsByBusinessDetailsCompliant: [],
  docsByBusinessDetailsPending: [],
  docsByBusinessDetailsOverdue: [],

  docsByLocationDetailsAll: [],
  docsByLocationDetailsCompliant: [],
  docsByLocationDetailsPending: [],
  docsByLocationDetailsOverdue: [],

  NewBusiness: [],

  locationDetailsForMap: [],
  locationDetailsForMapApiCalled: false,
  LocationDocsforMap: [],
  locationdocsmap: [],
  docshistorybyid: [],
  downloadlocationdocs: "",
  getDocTemplatelocation: "",
  downloadSupplementaldocument: [],
  downloadlocationsupportingdocs: [],
  departmentComplianceByLocation: {},
  departmentComplianceByLocationAll: {},

  locationDetails: [],
  locationDetailsCompliant: [],
  locationDetailsPending: [],
  locationDetailsOverdue: [],

  locationDetailsAll: [],
  locationDetailsAllCompliant: [],
  locationDetailsAllPending: [],
  locationPayload: "",
  locationDetailsAllOverdue: [],
  type: "TYPE",
  complianceTabsData: [
    {
      compliant: 0,
      geoCode: "lat=37.7258563,lng=-122.4014303",
      locationAddress:
        "building:null,line1:1485 Bayshore Blvd,line2:null,city:San Francisco,state:CA,country:US,pinCode:94124",
      locationId: 1202,
      locationName: "PMI San Francisco",
      nonCompliant: 0,
      overdue: 2,
      regionId: 0,
      stateCode: null,
      total: 2,
    },
  ],
  departmentDetails: [],
  departmentDetailsCompliant: [],
  departmentDetailsPending: [],
  departmentDetailsOverdue: [],
  departmentDetailsAll: [],
  departmentDetailsAllCompliant: [],
  departmentDetailsAllPending: [],
  departmentDetailsAllOverdue: [],

  empComplianceStatsAll: [],
  empComplianceStatsCompliant: [],
  empComplianceStatsPending: [],
  empComplianceStatsOverdue: [],
  downloadDoc: [],

  businessComplianceByAllUserRole: [],
  profile: {},
  profileLoader: true,

  selectedDashboard: {
    name: "Management Dashboard",
    selected: true,
  },
  myDashboardDetail: [],
  myDashboardLoader: false,
  businessComplianceByUserForCorporate: [],
  franchises: [],
  selectedFranchise: {},
  regions: [],
  newLocation: {
    apiResponse: null,
    apiProccessed: false,
    stopAllApiCall: false,
  },
  newFranchisee: {
    apiResponse: null,
    apiProccessed: false,
  },
  locationSearchBarVisible: false,
  cci: {},
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    showLocationSearchBar: (state, action) => {
      state.locationSearchBarVisible = true;
    },
    hideLocationSearchBar: (state, action) => {
      state.locationSearchBarVisible = false;
    },
    setSuccessNewLocation: (state, action) => {
      state.newLocation.apiProccessed = true;
      state.newLocation.stopAllApiCall = true;
      state.newLocation.apiResponse = action.payload;
    },
    setErrorNewLocation: (state, action) => {
      state.newLocation.apiProccessed = true;
      state.newLocation.stopAllApiCall = true;
      state.newLocation.apiResponse = action.payload;
    },
    resetNewLocation: (state, action) => {
      state.newLocation.apiResponse = null;
      state.newLocation.stopAllApiCall = false;
      state.newLocation.apiProccessed = false;
    },
    setSuccessNewFranchisee: (state, action) => {
      state.newFranchisee.apiResponse = action.payload;
      state.newLocation.stopAllApiCall = true;
      state.newFranchisee.apiProccessed = true;
    },
    setErrorNewFranchisee: (state, action) => {
      state.newFranchisee.apiResponse = action.payload;
      state.newLocation.stopAllApiCall = true;
      state.newFranchisee.apiProccessed = true;
    },
    resetNewFranchisee: (state, action) => {
      state.newFranchisee.apiResponse = null;
      state.newFranchisee.apiProccessed = false;
      state.newLocation.stopAllApiCall = false;
    },
    setSelectedFranchise: (state, action) => {
      state.selectedFranchise = action.payload;
    },
    resetSelectedFranchise: (state, action) => {
      state.selectedFranchise = {};
    },
    resetLocationDocMap: (state, action) => {
      state.locationdocsmap = [];
    },
    setAgreementPopUp: (state, action) => {
      state.agreementPopState = action.payload;
    },
    resetLoactionMarker: (state, action) => {
      state.locationDetailsForMap = [];
    },

    resetBusinessDetails: (state, action) => {
      state.businessDetails = [];
      state.businessDetailsCompliant = [];
      state.businessDetailsPending = [];
      state.businessDetailsOverdue = [];
      state.businessDetailsAll = [];
      state.businessDetailsAllCompliant = [];
      state.businessDetailsAllPending = [];
      state.businessDetailsAllOverdue = [];
    },
    //business compliance
    resetDocsByBusinessDetails: (state, action) => {
      state.docsByBusinessDetailsAll = [];
      state.docsByBusinessDetailsCompliant = [];
      state.docsByBusinessDetailsPending = [];
      state.docsByBusinessDetailsOverdue = [];
    },
    resetDocsByLocationDetails: (state, action) => {
      state.docsByLocationDetailsAll = [];
      state.docsByLocationDetailsCompliant = [];
      state.docsByLocationDetailsPending = [];
      state.docsByLocationDetailsOverdue = [];
    },
    resetLocationDetails: (state, action) => {
      state.locationDetails = [];
      state.locationDetailsCompliant = [];
      state.locationDetailsPending = [];
      state.locationDetailsOverdue = [];
      state.locationDetailsAll = [];
      state.locationDetailsAllCompliant = [];
      state.locationDetailsAllPending = [];
      state.locationDetailsAllOverdue = [];
    },
    resetDepartmentDetails: (state, action) => {
      state.departmentDetails = [];
      state.departmentDetailsCompliant = [];
      state.departmentDetailsPending = [];
      state.departmentDetailsOverdue = [];
      state.departmentDetailsAll = [];
      state.departmentDetailsAllCompliant = [];
      state.departmentDetailsAllPending = [];
      state.departmentDetailsAllOverdue = [];
    },
    resetDetailedEmpStats: (state, action) => {
      state.empComplianceStatsAll = [];
      state.empComplianceStatsCompliant = [];
      state.empComplianceStatsPending = [];
      state.empComplianceStatsOverdue = [];
    },
    resetDashboard: (state) => initialState,
    setProfileLoader: (state, action) => {
      state.profileLoader = action.payload;
    },
    setTypeMap: (state, action) => {
      state.type = action.payload;
    },
    setSelectedDashboard: (state, action) => {
      state.selectedDashboard = action.payload;
    },
    setTabsData: (state, action) => {
      state.complianceTabsData = action.payload;
    },
    setTableType: (state, action) => {
      state.tableType = action.payload;
    },
    setBussinessNotification: (state, action) => {
      state.bussinessNotification = action.payload;
    },
    setSelectedBussinessDetails: (state, action) => {
      state.selectedBussinessDetails = action.payload;
    },
    setTemplateDocPayload: (state, action) => {
      state.locationdocsmap = action.payload;
    },
    setDocumentPayload: (state, action) => {
      state.downloadDoc = action.payload;
    },
    setLoaderDashboard: (state, action) => {
      state.loaderDashboard = action.payload;
    },
    resetGetDocTemplate: (state, action) => {
      state.getDocTemplatelocation = "";
    },
    resetAllTasksByUserId: (state, action) => {
      state.dashboardtasksDetails = [];
    },
    resetDownloadLocationDocs: (state, action) => {
      state.downloadlocationdocs = "";
    },
    resetdownloadlocationsupportingdocs: (state, action) => {
      state.downloadlocationsupportingdocs = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCCiThunk.fulfilled, (state, action) => {
      state.cci = action.payload;
    });
    builder.addCase(createLocationThunk.fulfilled, (state, action) => {
      state.newLocation.apiResponse = action.payload.data;
      state.newLocation.apiProccessed = true;
    });
    builder.addCase(createLocationThunk.rejected, (state, action) => {
      state.newLocation.apiProccessed = true;
      state.newLocation.apiResponse = action.payload.data;
    });
    builder.addCase(createFranchiseeThunk.fulfilled, (state, action) => {
      state.newFranchisee.apiResponse = action.payload;
      state.newFranchisee.apiProccessed = true;
    });
    builder.addCase(createFranchiseeThunk.rejected, (state, action) => {
      state.newFranchisee.apiResponse = action.payload;
      state.newFranchisee.apiProccessed = true;
    });
    builder.addCase(getRegionsThunk.fulfilled, (state, action) => {
      state.regions = action.payload;
    });
    builder.addCase(getRegionsThunk.rejected, (state, action) => {
      state.regions = [];
    });
    builder.addCase(getFranchisesThunk.fulfilled, (state, action) => {
      state.franchises = action.payload;
    });
    builder.addCase(getFranchisesThunk.rejected, (state, action) => {
      state.franchises = [];
    });
    builder.addCase(getDashboardData.pending, (state, action) => {
      state.loading = true;
      state.tableLoading = true;
    });
    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.dashboardDetails = action.payload;
      state.tableLoading = false;
      state.loading = false;
    });
    builder.addCase(getDashboardData.rejected, (state, action) => {
      state.isError = true;
      state.tableLoading = false;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });
    builder.addCase(getBusinessDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBusinessDetails.fulfilled, (state, action) => {
      state.selectedBussinessDetails = action.payload;
      state.loading = false;
    });
    builder.addCase(getBusinessDetails.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });
    //dashboard tasks
    builder.addCase(getAllTasksByUserId.pending, (state, action) => {
      state.loading = true;
      state.taskLoading = true;
    });
    builder.addCase(getAllTasksByUserId.fulfilled, (state, action) => {
      state.loading = false;
      state.taskLoading = false;
      state.dashboardtasksDetails = action.payload;
    });
    builder.addCase(getAllTasksByUserId.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.taskLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    // builder.addCase(setTaskLoading, (state, action) => {
    //   state.taskLoading = action.payload;
    // });
    //my-dashboard
    builder.addCase(getMyDashboardDetail.pending, (state, action) => {
      state.myDashboardLoader = true;
    });
    builder.addCase(getMyDashboardDetail.fulfilled, (state, action) => {
      state.myDashboardDetail = action.payload;
      state.myDashboardLoader = false;
    });
    builder.addCase(getMyDashboardDetail.rejected, (state, action) => {
      state.isError = true;
      state.myDashboardLoader = false;
      state.errorMessage = action.payload.detailMessage;
    });

    // detailedEmpCompStats - all
    builder.addCase(getDetailedCompStatsForEmpAll.pending, (state, action) => {
      state.empComplianceStatsAllLoading = true;
    });
    builder.addCase(
      getDetailedCompStatsForEmpAll.fulfilled,
      (state, action) => {
        state.empComplianceStatsAll = action.payload;
        state.empComplianceStatsAllLoading = false;
      }
    );
    builder.addCase(getDetailedCompStatsForEmpAll.rejected, (state, action) => {
      state.isError = true;
      state.empComplianceStatsAllLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //compliant
    builder.addCase(
      getDetailedCompStatsForEmpCompliant.pending,
      (state, action) => {
        state.empComplianceStatsCompliantLoading = true;
      }
    );
    builder.addCase(
      getDetailedCompStatsForEmpCompliant.fulfilled,
      (state, action) => {
        state.empComplianceStatsCompliant = action.payload;
        state.empComplianceStatsCompliantLoading = false;
      }
    );
    builder.addCase(
      getDetailedCompStatsForEmpCompliant.rejected,
      (state, action) => {
        state.isError = true;
        state.empComplianceStatsCompliantLoading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );

    //pending
    builder.addCase(
      getDetailedCompStatsForEmpPending.pending,
      (state, action) => {
        state.empComplianceStatsPendingLoading = true;
      }
    );
    builder.addCase(
      getDetailedCompStatsForEmpPending.fulfilled,
      (state, action) => {
        state.empComplianceStatsPending = action.payload;
        state.empComplianceStatsPendingLoading = false;
      }
    );
    builder.addCase(
      getDetailedCompStatsForEmpPending.rejected,
      (state, action) => {
        state.isError = true;
        state.empComplianceStatsPendingLoading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );

    //overdue
    builder.addCase(
      getDetailedCompStatsForEmpOverdue.pending,
      (state, action) => {
        state.empComplianceStatsOverdueLoading = true;
      }
    );
    builder.addCase(
      getDetailedCompStatsForEmpOverdue.fulfilled,
      (state, action) => {
        state.empComplianceStatsOverdue = action.payload;
        state.empComplianceStatsOverdueLoading = false;
      }
    );
    builder.addCase(
      getDetailedCompStatsForEmpOverdue.rejected,
      (state, action) => {
        state.isError = true;
        state.empComplianceStatsOverdueLoading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );

    //loocationComplianceDetails
    builder.addCase(getLocationComplianceDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLocationComplianceDetails.fulfilled, (state, action) => {
      state.businessLocationComplianceDetails = {
        ...state.businessLocationComplianceDetails,
        ...action.payload,
      };

      state.loading = false;
    });
    builder.addCase(getLocationComplianceDetails.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });
    //loocationComplianceDetailsAll
    builder.addCase(
      getLocationComplianceDetailsAll.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getLocationComplianceDetailsAll.fulfilled,
      (state, action) => {
        state.businessLocationComplianceDetailsAll = {
          ...state.businessLocationComplianceDetailsAll,
          ...action.payload,
        };

        state.loading = false;
      }
    );
    builder.addCase(
      getLocationComplianceDetailsAll.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );

    // downloadLocationsDocumentsForCompliant
    builder.addCase(
      downloadLocationsDocumentsForCompliant.pending,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      downloadLocationsDocumentsForCompliant.fulfilled,
      (state, action) => {
        state.downloadlocationdocs = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      downloadLocationsDocumentsForCompliant.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );
    // downloadLocationSupportingDocumentsforCompliant
    builder.addCase(
      downloadLocationSupportingDocumentsforCompliant.pending,
      (state, action) => {
        state.loaderDashboard = true;
      }
    );
    builder.addCase(
      downloadLocationSupportingDocumentsforCompliant.fulfilled,
      (state, action) => {
        state.downloadlocationsupportingdocs = action.payload;
        state.loaderDashboard = false;
      }
    );
    builder.addCase(
      downloadLocationSupportingDocumentsforCompliant.rejected,
      (state, action) => {
        state.isError = true;
        state.loaderDashboard = true;
      }
    );
    //DownloadSupplementalDocsSlice
    builder.addCase(DownloadSupplementalDocsSlice.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(
      DownloadSupplementalDocsSlice.fulfilled,
      (state, action) => {
        state.downloadSupplementaldocument = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(DownloadSupplementalDocsSlice.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
    });
    // docshistorybyid
    builder.addCase(getTaskHistorybyId.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getTaskHistorybyId.fulfilled, (state, action) => {
      state.docshistorybyid = action.payload;
      state.loading = false;
    });
    builder.addCase(getTaskHistorybyId.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //getLocationsForCompliant
    builder.addCase(getLocationsForCompliant.pending, (state, action) => {
      // state.loading = true;
      state.loaderCompliance = true;
    });
    builder.addCase(getLocationsForCompliant.fulfilled, (state, action) => {
      // state.loading = false;
      state.loaderCompliance = false;
      state.locationPayload = action.payload;
    });
    builder.addCase(getLocationsForCompliant.rejected, (state, action) => {
      state.isError = true;
      // state.loading = false;
      state.loaderCompliance = false;
      state.errorMessage = action.payload.detailMessage;
    });

    // getLocationsDocumentsForCompliant
    builder.addCase(
      getLocationsDocumentsForCompliant.pending,
      (state, action) => {
        // state.loading = false;
        state.loaderDashboard = true;
      }
    );
    builder.addCase(
      getLocationsDocumentsForCompliant.fulfilled,
      (state, action) => {
        // state.loading = false;
        state.locationdocsmap = action.payload.data;
      }
    );
    builder.addCase(
      getLocationsDocumentsForCompliant.rejected,
      (state, action) => {
        state.isError = true;
        // state.loading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );
    // getDocTemplate
    builder.addCase(getDoctemplateonLocation.pending, (state, action) => {
      // state.loading = false;
      state.loaderDashboard = true;
    });
    builder.addCase(getDoctemplateonLocation.fulfilled, (state, action) => {
      state.getDocTemplatelocation = action.payload.data;
      state.loaderDashboard = false;
    });
    builder.addCase(getDoctemplateonLocation.rejected, (state, action) => {
      state.loaderDashboard = true;
      state.isError = true;
      // state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //loocationComplianceDetails Corporate
    builder.addCase(
      getLocationComplianceDetailsCorporate.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getLocationComplianceDetailsCorporate.fulfilled,
      (state, action) => {
        state.businessLocationComplianceDetailsCorporate = {
          ...state.businessLocationComplianceDetailsCorporate,
          ...action.payload,
        };

        state.loading = false;
      }
    );
    builder.addCase(
      getLocationComplianceDetailsCorporate.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );

    //get by business
    builder.addCase(getBusiness.pending, (state, action) => {
      state.loading = true;
      state.bussinessLoading = true;
    });
    builder.addCase(getBusiness.fulfilled, (state, action) => {
      state.businessDetails = action.payload;
      state.loading = false;
      state.bussinessLoading = false;
    });
    builder.addCase(getBusiness.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.bussinessLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //compliant
    builder.addCase(getBusinessCompliant.pending, (state, action) => {
      state.loading = true;
      state.bussinessCompliantLoading = true;
    });
    builder.addCase(getBusinessCompliant.fulfilled, (state, action) => {
      state.businessDetailsCompliant = action.payload;
      state.loading = false;
      state.bussinessCompliantLoading = false;
    });
    builder.addCase(getBusinessCompliant.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.bussinessCompliantLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Pending
    builder.addCase(getBusinessPending.pending, (state, action) => {
      state.loading = true;
      state.bussinessPendingLoading = true;
    });
    builder.addCase(getBusinessPending.fulfilled, (state, action) => {
      state.businessDetailsPending = action.payload;
      state.loading = false;
      state.bussinessPendingLoading = false;
    });
    builder.addCase(getBusinessPending.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.bussinessPendingLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Overdue
    builder.addCase(getBusinessOverdue.pending, (state, action) => {
      state.loading = true;
      state.bussinessOverdueLoading = true;
    });
    builder.addCase(getBusinessOverdue.fulfilled, (state, action) => {
      state.businessDetailsOverdue = action.payload;
      state.loading = false;
      state.bussinessOverdueLoading = false;
    });
    builder.addCase(getBusinessOverdue.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.bussinessOverdueLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //get by businessAll
    builder.addCase(getBusinessAll.pending, (state, action) => {
      state.loading = true;
      state.bussinessAllLoading = true;
    });
    builder.addCase(getBusinessAll.fulfilled, (state, action) => {
      state.businessDetailsAll = action.payload;
      state.loading = false;
      state.bussinessAllLoading = false;
    });
    builder.addCase(getBusinessAll.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.bussinessAllLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //compliant
    builder.addCase(getBusinessAllCompliant.pending, (state, action) => {
      state.loading = true;
      state.bussinessAllCompliantLoading = true;
    });
    builder.addCase(getBusinessAllCompliant.fulfilled, (state, action) => {
      state.businessDetailsAllCompliant = action.payload;
      state.loading = false;
      state.bussinessAllCompliantLoading = false;
    });
    builder.addCase(getBusinessAllCompliant.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.bussinessAllCompliantLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Pending
    builder.addCase(getBusinessAllPending.pending, (state, action) => {
      state.loading = true;
      state.bussinessAllPendingLoading = true;
    });
    builder.addCase(getBusinessAllPending.fulfilled, (state, action) => {
      state.businessDetailsAllPending = action.payload;
      state.loading = false;
      state.bussinessAllPendingLoading = false;
    });
    builder.addCase(getBusinessAllPending.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.bussinessAllPendingLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Overdue
    builder.addCase(getBusinessAllOverdue.pending, (state, action) => {
      state.loading = true;
      state.bussinessAllOverdueLoading = true;
    });
    builder.addCase(getBusinessAllOverdue.fulfilled, (state, action) => {
      state.businessDetailsAllOverdue = action.payload;
      state.loading = false;
      state.bussinessAllOverdueLoading = false;
    });
    builder.addCase(getBusinessAllOverdue.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.bussinessAllOverdueLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //get docs by business -- business compliance
    builder.addCase(getDocByBusinessAll.pending, (state, action) => {
      state.loading = true;
      state.docsByBussinessAllLoading = true;
    });
    builder.addCase(getDocByBusinessAll.fulfilled, (state, action) => {
      state.docsByBusinessDetailsAll = action.payload;
      state.loading = false;
      state.docsByBussinessAllLoading = false;
    });
    builder.addCase(getDocByBusinessAll.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.docsByBussinessAllLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //compliant
    builder.addCase(getDocByBusinessCompliant.pending, (state, action) => {
      state.loading = true;
      state.docsByBussinessCompliantLoading = true;
    });
    builder.addCase(getDocByBusinessCompliant.fulfilled, (state, action) => {
      state.docsByBusinessDetailsCompliant = action.payload;
      state.loading = false;
      state.docsByBussinessCompliantLoading = false;
    });
    builder.addCase(getDocByBusinessCompliant.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.docsByBussinessCompliantLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Pending
    builder.addCase(getDocByBusinessPending.pending, (state, action) => {
      state.loading = true;
      state.docsByBussinessPendingLoading = true;
    });
    builder.addCase(getDocByBusinessPending.fulfilled, (state, action) => {
      state.docsByBusinessDetailsPending = action.payload;
      state.loading = false;
      state.docsByBussinessPendingLoading = false;
    });
    builder.addCase(getDocByBusinessPending.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.docsByBussinessPendingLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Overdue
    builder.addCase(getDocByBusinessOverdue.pending, (state, action) => {
      state.loading = true;
      state.docsByBussinessOverdueLoading = true;
    });
    builder.addCase(getDocByBusinessOverdue.fulfilled, (state, action) => {
      state.docsByBusinessDetailsOverdue = action.payload;
      state.loading = false;
      state.docsByBussinessOverdueLoading = false;
    });
    builder.addCase(getDocByBusinessOverdue.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.docsByBussinessOverdueLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //get docs by location -- business compliance
    builder.addCase(getDocByLocationAll.pending, (state, action) => {
      state.loading = true;
      state.docsByLocationAllLoading = true;
    });
    builder.addCase(getDocByLocationAll.fulfilled, (state, action) => {
      state.docsByLocationDetailsAll = action.payload;
      state.loading = false;
      state.docsByLocationAllLoading = false;
    });
    builder.addCase(getDocByLocationAll.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.docsByLocationAllLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //compliant
    builder.addCase(getDocByLocationCompliant.pending, (state, action) => {
      state.loading = true;
      state.docsByLocationCompliantLoading = true;
    });
    builder.addCase(getDocByLocationCompliant.fulfilled, (state, action) => {
      state.docsByLocationDetailsCompliant = action.payload;
      state.loading = false;
      state.docsByLocationCompliantLoading = false;
    });
    builder.addCase(getDocByLocationCompliant.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.docsByLocationCompliantLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Pending
    builder.addCase(getDocByLocationPending.pending, (state, action) => {
      state.loading = true;
      state.docsByLocationPendingLoading = true;
    });
    builder.addCase(getDocByLocationPending.fulfilled, (state, action) => {
      state.docsByLocationDetailsPending = action.payload;
      state.loading = false;
      state.docsByLocationPendingLoading = false;
    });
    builder.addCase(getDocByLocationPending.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.docsByLocationPendingLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Overdue
    builder.addCase(getDocByLocationOverdue.pending, (state, action) => {
      state.loading = true;
      state.docsByLocationOverdueLoading = true;
    });
    builder.addCase(getDocByLocationOverdue.fulfilled, (state, action) => {
      state.docsByLocationDetailsOverdue = action.payload;
      state.loading = false;
      state.docsByLocationOverdueLoading = false;
    });
    builder.addCase(getDocByLocationOverdue.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.docsByLocationOverdueLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    builder.addCase(
      getDepartmentComplianceDetailsByLocationBusinessId.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getDepartmentComplianceDetailsByLocationBusinessId.fulfilled,
      (state, action) => {
        // state.departmentComplianceByLocation = action.payload;
        state.departmentComplianceByLocation = {
          ...state.departmentComplianceByLocation,
          ...action.payload,
        };
        state.loading = false;
      }
    );
    builder.addCase(
      getDepartmentComplianceDetailsByLocationBusinessId.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );

    //departments All
    builder.addCase(
      getDepartmentComplianceDetailsByLocationBusinessIdAll.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getDepartmentComplianceDetailsByLocationBusinessIdAll.fulfilled,
      (state, action) => {
        // state.departmentComplianceByLocation = action.payload;
        state.departmentComplianceByLocationAll = {
          ...state.departmentComplianceByLocationAll,
          ...action.payload,
        };
        state.loading = false;
      }
    );
    builder.addCase(
      getDepartmentComplianceDetailsByLocationBusinessIdAll.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );

    //get by location
    builder.addCase(getLocations.pending, (state, action) => {
      state.loading = true;
      state.locationLoading = true;
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.locationDetails = action.payload;
      state.locationLoading = false;
      state.loading = false;
    });
    builder.addCase(getLocations.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.locationLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //compliant
    builder.addCase(getLocationCompliant.pending, (state, action) => {
      state.loading = true;
      state.locationCompliantLoading = true;
    });
    builder.addCase(getLocationCompliant.fulfilled, (state, action) => {
      state.locationDetailsCompliant = action.payload;
      state.loading = false;
      state.locationCompliantLoading = false;
    });
    builder.addCase(getLocationCompliant.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.locationCompliantLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Pending
    builder.addCase(getLocationPending.pending, (state, action) => {
      state.loading = true;
      state.locationPendingLoading = true;
    });
    builder.addCase(getLocationPending.fulfilled, (state, action) => {
      state.locationDetailsPending = action.payload;
      state.loading = false;
      state.locationPendingLoading = false;
    });
    builder.addCase(getLocationPending.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.locationPendingLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Overdue
    builder.addCase(getLocationOverdue.pending, (state, action) => {
      state.loading = true;
      state.locationOverdueLoading = true;
    });
    builder.addCase(getLocationOverdue.fulfilled, (state, action) => {
      state.locationDetailsOverdue = action.payload;
      state.loading = false;
      state.locationOverdueLoading = false;
    });
    builder.addCase(getLocationOverdue.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.locationOverdueLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //get by LocationAll
    builder.addCase(getLocationAll.pending, (state, action) => {
      state.loading = true;
      state.locationAllLoading = true;
    });
    builder.addCase(getLocationAll.fulfilled, (state, action) => {
      state.locationDetailsAll = action.payload;
      state.loading = false;
      state.locationAllLoading = false;
    });
    builder.addCase(getLocationAll.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.locationAllLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //compliant
    builder.addCase(getLocationAllCompliant.pending, (state, action) => {
      state.loading = true;
      state.locationAllCompliantLoading = true;
    });
    builder.addCase(getLocationAllCompliant.fulfilled, (state, action) => {
      state.locationDetailsAllCompliant = action.payload;
      state.loading = false;
      state.locationAllCompliantLoading = false;
    });
    builder.addCase(getLocationAllCompliant.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.locationAllCompliantLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Pending
    builder.addCase(getLocationAllPending.pending, (state, action) => {
      state.loading = true;
      state.locationAllPendingLoading = true;
    });
    builder.addCase(getLocationAllPending.fulfilled, (state, action) => {
      state.locationDetailsAllPending = action.payload;
      state.loading = false;
      state.locationAllPendingLoading = false;
    });
    builder.addCase(getLocationAllPending.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.locationAllPendingLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Overdue
    builder.addCase(getLocationAllOverdue.pending, (state, action) => {
      state.loading = true;
      state.locationAllOverdueLoading = true;
    });
    builder.addCase(getLocationAllOverdue.fulfilled, (state, action) => {
      state.locationDetailsAllOverdue = action.payload;
      state.loading = false;
      state.locationAllOverdueLoading = false;
    });
    builder.addCase(getLocationAllOverdue.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.locationAllOverdueLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //get by department
    builder.addCase(getDepartments.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDepartments.fulfilled, (state, action) => {
      state.departmentDetails = action.payload;
      state.loading = false;
    });
    builder.addCase(getDepartments.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //compliant
    builder.addCase(getDepartmentCompliant.pending, (state, action) => {
      state.loading = true;
      state.departmentCompliantLoading = true;
    });
    builder.addCase(getDepartmentCompliant.fulfilled, (state, action) => {
      state.departmentDetailsCompliant = action.payload;
      state.loading = false;
      state.departmentCompliantLoading = false;
    });
    builder.addCase(getDepartmentCompliant.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.departmentCompliantLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Pending
    builder.addCase(getDepartmentPending.pending, (state, action) => {
      state.loading = true;
      state.departmentPendingLoading = true;
    });
    builder.addCase(getDepartmentPending.fulfilled, (state, action) => {
      state.departmentDetailsPending = action.payload;
      state.loading = false;
      state.departmentPendingLoading = false;
    });
    builder.addCase(getDepartmentPending.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.departmentPendingLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Overdue
    builder.addCase(getDepartmentOverdue.pending, (state, action) => {
      state.loading = true;
      state.departmentOverdueLoading = true;
    });
    builder.addCase(getDepartmentOverdue.fulfilled, (state, action) => {
      state.departmentDetailsOverdue = action.payload;
      state.loading = false;
      state.departmentOverdueLoading = false;
    });
    builder.addCase(getDepartmentOverdue.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.departmentOverdueLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //get by DepartmentAll
    builder.addCase(getDepartmentAll.pending, (state, action) => {
      state.loading = true;
      state.departmentAllLoading = true;
    });
    builder.addCase(getDepartmentAll.fulfilled, (state, action) => {
      state.departmentDetailsAll = action.payload;
      state.loading = false;
      state.departmentAllLoading = false;
    });
    builder.addCase(getDepartmentAll.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.departmentAllLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //compliant
    builder.addCase(getDepartmentAllCompliant.pending, (state, action) => {
      state.loading = true;
      state.departmentAllCompliantLoading = true;
    });
    builder.addCase(getDepartmentAllCompliant.fulfilled, (state, action) => {
      state.departmentDetailsAllCompliant = action.payload;
      state.loading = false;
      state.departmentAllCompliantLoading = false;
    });
    builder.addCase(getDepartmentAllCompliant.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.departmentAllCompliantLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Pending
    builder.addCase(getDepartmentAllPending.pending, (state, action) => {
      state.loading = true;
      state.departmentAllPendingLoading = true;
    });
    builder.addCase(getDepartmentAllPending.fulfilled, (state, action) => {
      state.departmentDetailsAllPending = action.payload;
      state.loading = false;
      state.departmentAllPendingLoading = false;
    });
    builder.addCase(getDepartmentAllPending.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.departmentAllPendingLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    //Overdue
    builder.addCase(getDepartmentAllOverdue.pending, (state, action) => {
      state.loading = true;
      state.departmentAllOverdueLoading = true;
    });
    builder.addCase(getDepartmentAllOverdue.fulfilled, (state, action) => {
      state.departmentDetailsAllOverdue = action.payload;
      state.loading = false;
      state.departmentAllOverdueLoading = false;
    });
    builder.addCase(getDepartmentAllOverdue.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.departmentAllOverdueLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    builder.addCase(
      getBusinessComplianceDetailsByAllUserRoleId.pending,
      (state, action) => {
        state.loading = true;
        state.tableLoading = true;
      }
    );
    builder.addCase(
      getBusinessComplianceDetailsByAllUserRoleId.fulfilled,
      (state, action) => {
        state.businessComplianceByAllUserRole = action.payload;
        state.tableLoading = false;
        state.loading = false;
      }
    );
    builder.addCase(
      getBusinessComplianceDetailsByAllUserRoleId.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.tableLoading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );
    // getLocationsForMapAction

    builder.addCase(getLocationsForMapAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLocationsForMapAction.fulfilled, (state, action) => {
      state.locationDetailsForMap = action.payload;
      state.loading = false;
      state.locationDetailsForMapApiCalled = true;
    });
    builder.addCase(getLocationsForMapAction.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.locationDetailsForMapApiCalled = true;
      // state.errorMessage = action.payload;
    });

    // getLocationsDocsForMapAction
    builder.addCase(getLocationsDocsForMapAction.pending, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getLocationsDocsForMapAction.fulfilled, (state, action) => {
      state.LocationDocsforMap = action.payload;
      state.loading = false;
    });
    builder.addCase(getLocationsDocsForMapAction.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = "";
    });

    builder.addCase(getProfile.pending, (state, action) => {
      state.profileLoader = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.profileLoader = false;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.isError = true;
      state.profileLoader = false;
      state.errorMessage = "";
    });

    builder.addCase(updateUserProfileSlice.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateUserProfileSlice.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateUserProfileSlice.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = "";
    });

    builder.addCase(
      getBusinessComplianceDetailsByUserForCorporate.pending,
      (state, action) => {
        state.loading = true;
        state.tableLoading = true;
      }
    );
    builder.addCase(
      getBusinessComplianceDetailsByUserForCorporate.fulfilled,
      (state, action) => {
        state.businessComplianceByUserForCorporate = action.payload;
        state.tableLoading = false;
        state.loading = false;
      }
    );
    builder.addCase(
      getBusinessComplianceDetailsByUserForCorporate.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.tableLoading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );
  },
});
export const {
  showLocationSearchBar,
  hideLocationSearchBar,
  setProfileLoader,
  setSelectedBussinessDetails,
  setSelectedDashboard,
  setTypeMap,
  setTableType,
  setTabsData,
  resetBusinessDetails,
  resetLocationDetails,
  resetDepartmentDetails,
  resetDetailedEmpStats,
  setTemplateDocPayload,
  setDocumentPayload,
  setBussinessNotification,
  setLoaderDashboard,
  resetDocsByBusinessDetails,
  resetDocsByLocationDetails,
  resetDocumentPayload,
  resetGetDocTemplate,
  setAgreementPopUp,
  resetDashboard,
  resetAllTasksByUserId,
  resetLocationDocMap,
  resetDownloadLocationDocs,
  resetdownloadlocationsupportingdocs,
  setSelectedFranchise,
  resetSelectedFranchise,
  resetNewLocation,
  setSuccessNewLocation,
  setErrorNewLocation,
  setErrorNewFranchisee,
  setSuccessNewFranchisee,
  resetNewFranchisee,
  resetLoactionMarker,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
