import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useSelector } from "react-redux";

const IndividualLocationRoleEditFranchisor = ({
  setValue,
  errorField,
  selectedChecks,
  setSelectedChecks,
  selectRole,
  setSelectRole,
}) => {
  const { getLocationFranchisorResponse } = useSelector(
    (state) => state.employee
  );
  const handleChangeParentCheckBox = (ele) => {
    let tempData = JSON.parse(JSON.stringify(selectedChecks));
    if (tempData[ele.regionId]) {
      delete tempData[ele.regionId];
      setSelectedChecks(tempData);
    } else {
      tempData[ele.regionId] = true;
      setSelectedChecks(tempData);
    }
  };

  return (
    <Grid container>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <Box sx={{ p: "0 1em" }}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} spacing={2}>
              <Typography variant="h7">Individual Role</Typography>
              <Stack width={"55%"}>
                <Select
                  id="selectRoleDropdown"
                  value={selectRole}
                  onChange={(e) => {
                    setValue("selectRole", e.target.value);
                    setSelectRole(e.target.value);
                  }}
                  size="small"
                >
                  <MenuItem
                    id="regionManager"
                    sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                    value={"Region"}
                  >
                    Region Manager
                  </MenuItem>
                  <MenuItem
                    id="regularManager"
                    sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                    value={"Regular"}
                  >
                    Regular Manager
                  </MenuItem>
                </Select>
              </Stack>
              {selectRole !== "Regular" ? (
                <Box>
                  <Table
                    sx={{
                      width: "100%",
                      p: 0,
                      maxHeight: "35vh",
                      overflowY: "auto",
                    }}
                  >
                    {getLocationFranchisorResponse.length &&
                    Array.isArray(getLocationFranchisorResponse)
                      ? getLocationFranchisorResponse.map((ele, idx) => {
                          return (
                            <TableBody
                              key={idx}
                              sx={{
                                width: "100%",
                                border: errorField[ele.locationId]
                                  ? "0.5px solid #f34f4c"
                                  : "",
                              }}
                            >
                              <TableRow sx={{ width: "100%" }}>
                                <TableCell
                                  sx={{
                                    borderTop:
                                      idx === 0 ? "0.5px solid #F3F3F3" : "",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    padding: "0.2em 2em",
                                    alignItems: "center",
                                    backgroundColor:
                                      selectedChecks &&
                                      selectedChecks[ele.regionId]
                                        ? "#F8FBFA"
                                        : "white",
                                  }}
                                >
                                  <Checkbox
                                    id={`location-${idx}`}
                                    checked={
                                      selectedChecks &&
                                      selectedChecks[ele.regionId]
                                    }
                                    onChange={() =>
                                      handleChangeParentCheckBox(ele)
                                    }
                                  />
                                  <Typography variant="body1">
                                    {ele.name}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          );
                        })
                      : null}
                  </Table>
                </Box>
              ) : null}
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default IndividualLocationRoleEditFranchisor;
