import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { setLoggedInUserInfo, ssoLoginValidate } from "./LoginSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Loading from "../../components/Loading/Loading";
import { useSearchParams } from "react-router-dom";
import SSOPage from "./SSOPage";
import storage from "redux-persist/lib/storage";

const SSOLoader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const peo_id = searchParams.get("peo_id");
  const key = searchParams.get("key");
  const validateSSO = async () => {
    const result = await dispatch(ssoLoginValidate({ key, peo_id }));
    const response = await unwrapResult(result);
    if (response.status === 200) {
      dispatch(setLoggedInUserInfo(response.data));
      if (
        !response.data.signedESignAgreements &&
        response.data.role === "ROLE_ACCOUNT_ADMIN"
      ) {
        navigate("/agreement");
      } else if (response?.data?.accountType === "SUPER_ADMIN") {
        navigate("/admin");
      } else {
        navigate("/dashboard");
      }
    } else {
      setLoading(false);
    }
  };
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  useEffect(() => {
    storage.removeItem("persist:root");
    clearCacheData();
    localStorage.clear();
    validateSSO();
  }, []);

  return loading ? <Loading /> : <SSOPage />;
};

export default SSOLoader;
