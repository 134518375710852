import { ajaxHandler } from "./ajaxHandler";
import downloadLocationsDocumentsForCompliant from "../pages/Dashboard/DashboardSlice";
import { env } from "../env";

const baseUrl = env.REACT_APP_BASE_URL;
export const getBusinessComplianceDetailsByUserRole = async (userId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/getBusinessComplianceDetailsByUserRole/${userId}/employee/active`
  );
  return response.data.businesses;
};

export const getBusinessComplianceDetailsByAllUserRole = async (userId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/getBusinessComplianceDetailsByUserRole/${userId}/employee/all`
  );
  return response.data.businesses;
};

export const getBusinessComplianceDetailsByUserRoleForCorporate = async (
  userId
) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/getBusinessComplianceDetailsByUserRole/${userId}/corporate/all`
  );
  return response.data.businesses;
};

export const getBusinessDetailsById = async (businessId) => {
  const response = await ajaxHandler("get", `focus451/business/${businessId}`);
  return response.data;
};

export const editBusinessDetailsById = async (payload) => {
  const response = await ajaxHandler(
    "put",
    `focus451/business/${payload.id}`,
    payload.data
  );
  return response;
};

export const createBusiness = async (businessId) => {
  const response = await ajaxHandler("post", `focus451/business`);
  return response.data;
};

export const PostNewBusiness = async (payload) => {
  const response = await ajaxHandler("post", `focus451/business/`, payload);
  return response.data;
};

//dashboard task get api
export const getTasksByUserId = async (userId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/task/dashBoard/${userId}`
  );
  return response.data;
};

export const getMyDashboard = async (userId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/detailedComplianceStatsForEmployee/${userId}/compliant`
  );
  return response.data;
};

// DetailedComplianceStatsForEmployees
export const getDetailedComplianceStatsForEmpAll = async (empId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/detailedComplianceStatsForEmployee/${empId}/all`
  );
  return response.data;
};

export const getDetailedComplianceStatsForEmpCompliant = async (empId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/detailedComplianceStatsForEmployee/${empId}/compliant`
  );
  return response.data;
};

export const getDetailedComplianceStatsForEmpPending = async (empId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/detailedComplianceStatsForEmployee/${empId}/pending`
  );
  return response.data;
};

export const getDetailedComplianceStatsForEmpAllOverdue = async (empId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/detailedComplianceStatsForEmployee/${empId}/overdue`
  );
  return response.data;
};

export const getLocationComplianceDetailsByBusiness = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/getLocationComplianceDetailsByBusiness/${payload.businessId}/${payload.userId}/employee/active`
  );
  return { [payload.businessId]: response.data.locations };
};

export const getLocationComplianceDetailsByBusinessAll = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/getLocationComplianceDetailsByBusiness/${payload.businessId}/${payload.userId}/employee/all`
  );
  return { [payload.businessId]: response.data.locations };
};

export const getLocationComplianceDetailsByBusinessCorporate = async (
  payload
) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/getLocationComplianceDetailsByBusiness/${payload.businessId}/${payload.userId}/corporate/all`
  );
  return { [payload.businessId]: response.data.locations };
};

//onclick on business open modal
export const getByBusiness = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByBusiness/${payload.businessId}/${payload.userId}/all/active`
  );
  return response.data;
};

export const getByBusinessCompliant = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByBusiness/${payload.businessId}/${payload.userId}/compliant/active`
  );
  return response.data;
};

export const getByBusinessPending = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByBusiness/${payload.businessId}/${payload.userId}/pending/active`
  );
  return response.data;
};

export const getByBusinessOverdue = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByBusiness/${payload.businessId}/${payload.userId}/overdue/active`
  );
  return response.data;
};

export const getByBusinessAll = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByBusiness/${payload.businessId}/${payload.userId}/all/all`
  );
  return response.data;
};

export const getByBusinessAllCompliant = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByBusiness/${payload.businessId}/${payload.userId}/compliant/all`
  );
  return response.data;
};

export const getByBusinessAllPending = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByBusiness/${payload.businessId}/${payload.userId}/pending/all`
  );
  return response.data;
};

export const getByBusinessAllOverdue = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByBusiness/${payload.businessId}/${payload.userId}/overdue/all`
  );
  return response.data;
};

export const getDocsByBusinessAll = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/document/getByBusiness/${payload.businessId}/${payload.userId}/all`
  );
  return response.data;
};

export const getDocsByBusinessCompliant = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/document/getByBusiness/${payload.businessId}/${payload.userId}/compliant`
  );
  return response.data;
};

export const getDocsByBusinessPending = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/document/getByBusiness/${payload.businessId}/${payload.userId}/pending`
  );
  return response.data;
};

export const getDocsByBusinessOverdue = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/document/getByBusiness/${payload.businessId}/${payload.userId}/overdue`
  );
  return response.data;
};

export const getDocsByLocationAll = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/document/getByLocation/${payload.locationId}/${payload.userId}/all`
  );
  return response.data;
};

export const getDocsByLocationCompliant = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/document/getByLocation/${payload.locationId}/${payload.userId}/compliant`
  );
  return response.data;
};

export const getDocsByLocationPending = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/document/getByLocation/${payload.locationId}/${payload.userId}/pending`
  );
  return response.data;
};

export const getDocsByLocationOverdue = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/document/getByLocation/${payload.locationId}/${payload.userId}/overdue`
  );
  return response.data;
};

export const getDepartmentComplianceDetailsByLocation = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/getDepartmentComplianceDetailsByLocation/${payload.businessId}/${payload.userId}/employee/active`
  );
  return { [payload.businessId]: response.data.departments };
};

export const getDepartmentComplianceDetailsByLocationAll = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/getDepartmentComplianceDetailsByLocation/${payload.businessId}/${payload.userId}/employee/all`
  );
  return { [payload.businessId]: response.data.departments };
};

export const getByLocation = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByLocation/${payload.locationId}/${payload.userId}/all/active`
  );
  return response.data;
};

export const getByLocationCompliant = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByLocation/${payload.locationId}/${payload.userId}/compliant/active`
  );
  return response.data;
};

export const getByLocationPending = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByLocation/${payload.locationId}/${payload.userId}/pending/active`
  );
  return response.data;
};

export const getByLocationOverdue = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByLocation/${payload.locationId}/${payload.userId}/overdue/active`
  );
  return response.data;
};

export const getByLocationAll = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByLocation/${payload.locationId}/${payload.userId}/all/all`
  );
  return response.data;
};

export const getByLocationAllCompliant = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByLocation/${payload.locationId}/${payload.userId}/compliant/all`
  );
  return response.data;
};

export const getByLocationAllPending = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByLocation/${payload.locationId}/${payload.userId}/pending/all`
  );
  return response.data;
};

export const getByLocationAllOverdue = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByLocation/${payload.locationId}/${payload.userId}/overdue/all`
  );
  return response.data;
};

export const getByDepartment = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByDepartment/${payload.departmentId}/${payload.userId}/all/active`
  );
  return response.data;
};

export const getByDepartmentCompliant = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByDepartment/${payload.departmentId}/${payload.userId}/compliant/active`
  );
  return response.data;
};

export const getByDepartmentPending = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByDepartment/${payload.departmentId}/${payload.userId}/pending/active`
  );
  return response.data;
};

export const getByDepartmentOverdue = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByDepartment/${payload.departmentId}/${payload.userId}/overdue/active`
  );
  return response.data;
};

export const getByDepartmentAll = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByDepartment/${payload.departmentId}/${payload.userId}/all/all`
  );
  return response.data;
};

export const getByDepartmentAllCompliant = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByDepartment/${payload.departmentId}/${payload.userId}/compliant/all`
  );
  return response.data;
};

export const getByDepartmentAllPending = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByDepartment/${payload.departmentId}/${payload.userId}/pending/all`
  );
  return response.data;
};

export const getByDepartmentAllOverdue = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employee/getByDepartment/${payload.departmentId}/${payload.userId}/overdue/all`
  );
  return response.data;
};

export const getuserProfile = async (employeeId) => {
  const response = await ajaxHandler("get", `focus451/employee/${employeeId}`);
  return response.data;
};

export const updateUserProfile = async (employeeId, payload) => {
  const response = await ajaxHandler(
    "put",
    `focus451/employee/editUsesrProfile/${employeeId}`,
    payload
  );
  return response.data;
};
export const getLocationsForMap = async (data) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/franchisor/getLocationCompliantByStateCode/all/${data.employeeId}/${data.status}`
  );
  return response.data;
};

export const getLocationsDocuments = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/franchisor/getLocationCompliantDocuments/${payload.locationId}/${payload.userId}/${payload.statusofdocs}`
  );
  return response.data;
};

export const getLocationsCompliant = async (data) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/franchisor/getLocationCompliantById/${data.id}/${data.status}`
  );
  return response.data;
};

export const getLocationComplDocuments = async (taskId) => {
  const response = await ajaxHandler("get", `focus451-document/task/${taskId}`);
  return response;
};

export const DownloadLocationComplDocuments = async (taskId) => {
  let response;
  const completeUrl = `${baseUrl}focus451-document/task/download/${taskId}`;
  try {
    response = await fetch(completeUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "x-auth-token": localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An unexpected error occurred.", error);
  }
  if (response.status == 200) {
    return response.blob();
  }
};

export const DownloadLocationSupportingDocuments = async (payload) => {
  let response;
  const completeUrl = `${baseUrl}focus451-document/${payload.status}/download/${payload.taskId}/${payload.supportId}`;
  try {
    response = await fetch(completeUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "x-auth-token": localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An unexpected error occurred.", error);
  }
  if (response.status == 200) {
    return response.blob();
  }
};

export const DownloadSupplementalDocs = async (payload) => {
  let response;
  const completeUrl = `${baseUrl}focus451-document/template/download/${payload.templateId}/${payload.supportId}`;
  try {
    response = await fetch(completeUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "x-auth-token": localStorage.getItem("token"),
      },
    });
  } catch (error) {
    throw new Error("An unexpected error occurred.", error);
  }
  if (response.status == 200) {
    return response.blob();
  }
};

export const getDocTemplate = async (templateId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/template/${templateId}/content`
  );
  return response;
};

export const getTaskHistory = async (taskId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/audit/getAuditDetailsByDocumentId/${taskId}`
  );
  return response.data;
};

export const getFranchises = async (accountId) => {
  const response = await ajaxHandler("get", `focus451/account/getFranchises/${accountId}`)
  return response.data;
}

export const getRegions = async (franchisorId) => {
  const response = await ajaxHandler('get', `focus451/region/getByFranchisor/${franchisorId}`)
  return response.data;
}

export const createLocation = async (payload) => {
  const response = await ajaxHandler('post', 'focus451/location', payload)
  return response;
}

export const createFranchisee = async (payload) => {
  const response = await ajaxHandler('post', 'focus451/account/create/franchisee', payload)
  return response.data;
}

export const getCciApi = async (accountId, complianceType, businessId) => {
  const response = await ajaxHandler(
    'get',
    `focus451-document/dashboard/rollingCCI/${accountId}/${encodeURIComponent(complianceType)}/business/${businessId}`)
  return response.data
}