import React, { useEffect, useState } from "react";
import { hideNav, showNav } from "../../App.reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
  Modal,
  Box,
  Checkbox,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PaymentButton from "./PaymentButton";
import SubscriptionTable from "./SubscriptionTable";
import Loading from "../../components/Loading/Loading";
import { useNavigate } from "react-router";
import PaymentHistoryTable from "./PaymentHistoryTable";
import useBankAccounts from "../../api/hooks/useBankAccounts";
import useDefaultBankAccount from "../../api/hooks/useDefaultBankAccount";
import DeleteIcon from "@mui/icons-material/Delete";
import useDeleteBankAccount from "../../api/hooks/useDeleteBankAccount";
import {
  getSingleAccountThunk,
  putSingleAccountThunk,
} from "../admin/adminslice";
import {
  setMessageKey,
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../components/Notification/Notification.slice";
import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";

const Billing = () => {
  const loggedInUserInfo = useSelector((state) => state.login.loggedInUserInfo);
  const { locationDetailsForMap } = useSelector((state) => state.dashboard);
  const { singleAccount } = useSelector((state) => state.admin);
  const [planHQ, setPlanHQ] = useState(loggedInUserInfo.planType);
  const paymentStatus = useSelector(
    (state) => state.login.loggedInUserInfo.failedPaymentStatus
  );
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedAccIsDefault, setSelectedAccIsDefault] = useState(false);
  const [openDefaultBankAccountModal, setOpenDefaultBankAccountModal] =
    useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState({});
  const [plaidId, setPlaidId] = useState(null);

  const {
    data,
    loading: bankListLoading,
    error,
    fetchData,
  } = useBankAccounts();

  const {
    data: defaultResponse,
    loading: defaultApiLoading,
    error: defaultError,
    fetchData: setDefault,
  } = useDefaultBankAccount();

  const {
    data: deleteresponse,
    loading: deleteLoading,
    error: deleteError,
    deleteAccount,
  } = useDeleteBankAccount();
  const [loading, setLoading] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    p: 4,
  };
  const dispatch = useDispatch();
  const headList = [
    "HQ Plan",
    "Location Plan",
    "#Loc",
    "Transaction Date",
    "Subscription Change date",
    "Total Amount Charged",
    "Status",
    "Code",
    "Payment Account Used",
  ];

  useEffect(() => {
    dispatch(getSingleAccountThunk(loggedInUserInfo.accountId));
    fetchData();
    if (loggedInUserInfo.accountType === "FRANCHISOR") {
      setLoading(false);
    } else {
      navigate("/dashboard", { replace: true });
    }
    dispatch(hideNav());
    return () => {
      dispatch(showNav());
    };
  }, []);
  const updatePlanHQ = async () => {
    let data = _.cloneDeep(singleAccount);
    data.properties.planType = planHQ;
    const response = await dispatch(
      putSingleAccountThunk({
        id: loggedInUserInfo.accountId,
        data,
      })
    );
    const res = unwrapResult(response);

    if (res.status === 200) {
      dispatch(setMessageKey("propertyValue"));

      dispatch(setMultiNotificationVariant("success"));
      dispatch(
        setMultiNotificationData([
          {
            propertyValue: `Plan Updated Successfully.`,
          },
        ])
      );
      setOpenModal(false);
      navigate("/login", { replace: true });
    } else if (res.data !== "" && res.status !== 200) {
      dispatch(setMessageKey("message"));

      dispatch(setMultiNotificationVariant("error"));
      let errorArray = res?.data?.validationErrors?.length
        ? res?.data.validationErrors
        : [{ message: "Something went Wrong" }];
      dispatch(setMultiNotificationData(errorArray));
    }
  };
  return (
    <>
      {loading ? (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
          }}
        >
          <Loading />
        </Stack>
      ) : (
        <>
          {openModal ? (
            <Modal open={true} onClose={() => setOpenModal(false)}>
              <Box sx={style}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Change of plans may result in additional charges. Please
                  review the subscription agreement to understand how new
                  charges will be calculated. If you understand and agree to the
                  change and new charges as per the subscription agreement,
                  please put a checkmark in the box below and click on “Accept”.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  To implement this change, the system will log you out
                  automatically, and you will need to log back in.
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  width={"100%"}
                  mt={2}
                >
                  <Checkbox
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  <Button
                    disabled={!isChecked}
                    sx={{ backgroundColor: "#93c", color: "white" }}
                    variant="contained"
                    onClick={updatePlanHQ}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </Modal>
          ) : null}
          {Boolean(plaidId) ? (
            <Modal open={true} onClose={() => setPlaidId(null)}>
              <Box sx={style}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Are you sure you want to delete this bank account from your
                  billing information? This action cannot be undone.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {data?.length > 1 &&
                    selectedAccIsDefault &&
                    "Since this is your default payment account, by deleting this account from your profile your secondary bank account will be used as the default for your subscription payments."}
                  {data?.length === 1 &&
                    "Please add one to maintain an active subscription."}
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  width={"100%"}
                  mt={2}
                >
                  <Button
                    sx={{ backgroundColor: "#93c", color: "white" }}
                    variant="contained"
                    onClick={() =>
                      deleteAccount({
                        plaidAccountId: plaidId,
                        onSuccess: () => {
                          fetchData();
                          setPlaidId(null);
                        },
                      })
                    }
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setPlaidId(null)}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </Modal>
          ) : null}
          {openDefaultBankAccountModal ? (
            <Modal
              open={true}
              onClose={() => setOpenDefaultBankAccountModal(false)}
            >
              <Box sx={style}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  This account will be used for all future payments due on this
                  account. Please confirm it by clicking on the button below.
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  width={"100%"}
                  mt={2}
                >
                  <Button
                    sx={{ backgroundColor: "#93c", color: "white" }}
                    variant="contained"
                    onClick={() => {
                      setDefault({
                        ...selectedBankAccount,
                        onSuccess: () => {
                          fetchData();
                          setOpenDefaultBankAccountModal(false);
                        },
                      });
                    }}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setOpenDefaultBankAccountModal(false)}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Box>
            </Modal>
          ) : null}
          <Stack spacing={2} p={2} mt={4}>
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
              <Typography variant="h4">Billing</Typography>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <PaymentButton
                  isDisable={data?.length >= 2}
                  fetchData={fetchData}
                />
                <div onClick={() => navigate("/dashboard", { replace: true })}>
                  <CloseIcon color="gray" />
                </div>
              </Stack>
            </Stack>
            <Grid container>
              {paymentStatus ? (
                <Grid item xs={12} paddingBottom={"1rem"}>
                  <Typography variant="h5" color={"red"}>
                    This account is in suspension, due to payment failure.
                    Please update payment account and retry last payment amount
                    to make the account active.{" "}
                  </Typography>
                </Grid>
              ) : null}

              {!paymentStatus ? (
                <Grid item xs={12} lg={6} p={1}>
                  <Card
                    sx={{
                      padding: "1rem",
                      borderRadius: "1rem",
                      border: "1px solid #164e5f",
                    }}
                  >
                    <CardHeader
                      title={`Current plans for ${
                        locationDetailsForMap.length || 0
                      } Locations :`}
                      sx={{ color: "#164e5f" }}
                    />

                    <CardContent>
                      <SubscriptionTable setPlanHQ={setPlanHQ} />
                      <Button
                        variant="contained"
                        sx={{ mt: 2, backgroundColor: "#93c", color: "white" }}
                        onClick={() => setOpenModal(true)}
                      >
                        Confirm Changes
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ) : null}

              <Grid item xs={12} lg={paymentStatus ? 12 : 6} p={1}>
                <Card
                  sx={{
                    padding: "1rem",
                    borderRadius: "1rem",
                    border: "1px solid #164e5f",
                    paddingBottom: "4rem",
                  }}
                >
                  <CardHeader
                    title="Payment Accounts (Maximum 2)"
                    sx={{ color: "#164e5f" }}
                  />

                  <CardContent>
                    <Stack
                      spacing={2}
                      direction={paymentStatus ? "row" : "column"}
                      width={"100%"}
                    >
                      {data?.length
                        ? data.slice(0, 2).map((bank) => (
                            <>
                              {bank?.institutions?.institutionAccountDetails
                                ?.length
                                ? bank?.institutions?.institutionAccountDetails.map(
                                    (account) => (
                                      <Stack
                                        sx={{
                                          borderRadius: "0.5rem",
                                          border: "1px solid #e4e9ec",
                                          padding: "0.5rem",
                                          paddingLeft: "1rem",
                                          width: paymentStatus ? "50%" : "100%",
                                        }}
                                      >
                                        <Typography variant="h5">
                                          {bank?.institutions.institutionName}
                                        </Typography>
                                        <Typography variant="body1">
                                          {`XXXX XXXX `}
                                          {account.maskedAccountId}
                                        </Typography>

                                        <Stack
                                          direction={"row"}
                                          paddingTop={"0.5rem"}
                                          alignItems={"center"}
                                          justifyContent={"flex-start"}
                                        >
                                          {account.defaultAccount ? (
                                            <Button
                                              disableRipple
                                              sx={{
                                                color: "#93c",
                                                backgroundColor: "white",
                                                ":hover": {
                                                  color: "#93c",
                                                  backgroundColor: "white",
                                                  cursor: "default",
                                                },
                                              }}
                                              onClick={() => {
                                                return;
                                              }}
                                              variant="contained"
                                            >
                                              {" "}
                                              Default
                                            </Button>
                                          ) : (
                                            <Button
                                              sx={{
                                                color: "#93c",
                                                backgroundColor: "white",
                                                ":hover": {
                                                  color: "#93c",
                                                  backgroundColor: "white",
                                                },
                                              }}
                                              variant="contained"
                                              onClick={() => {
                                                setSelectedBankAccount({
                                                  plaidID:
                                                    account.bankAccountId,
                                                  bankName:
                                                    bank?.institutions
                                                      .institutionName,
                                                });
                                                setOpenDefaultBankAccountModal(
                                                  true
                                                );
                                              }}
                                            >
                                              Set as default
                                            </Button>
                                          )}
                                          <IconButton
                                            onClick={() => {
                                              setSelectedAccIsDefault(
                                                account.defaultAccount
                                              );
                                              setPlaidId(account.bankAccountId);
                                            }}
                                          >
                                            <DeleteIcon
                                              sx={{ color: "#f34f4c" }}
                                            />
                                          </IconButton>
                                        </Stack>
                                      </Stack>
                                    )
                                  )
                                : null}
                            </>
                          ))
                        : "Let’s get started by clicking the “Add Account” button above to add your account"}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12}>
                <Card
                  sx={{
                    padding: "1rem",
                    borderRadius: "1rem",
                    border: "1px solid #164e5f",
                    marginTop: "1rem",
                  }}
                >
                  <CardHeader
                    title="Payment and Plan History"
                    sx={{ color: "#164e5f" }}
                  />

                  <CardContent>
                    <Stack>
                      <PaymentHistoryTable headList={headList} />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Stack>
        </>
      )}
    </>
  );
};

export default Billing;
