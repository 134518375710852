import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  bussinessSelectedChecksAI: {},
  templateProperties: {
    templateType: "EMPLOYEE",
    visibleToEmployee: "no", // Is this template visible to Individuals?
    employeeCanInitiate: "no", //Can this template be initiated by Individuals?
    employeeHireStageApplicable: "PRE_HIRE",
    multiAssignable: "no",
    forMultiOwnerAssignment: false, //not used
  },
  applicableAts: [],
  applicableAtToFranchiser: {}, //not used
};

export const AdminIndividualSlice = createSlice({
  name: "adminIndividual",
  initialState,
  reducers: {
    setTemplatePropertiesAI: (state, action) => {
      state.templateProperties = action.payload;
    },
    resetAI: (state) => initialState,
    setBussinessSelectedChecksAI: (state, action) => {
      state.bussinessSelectedChecksAI = action.payload;
    },
    setEmployeeHireStageApplicable: (state, action) => {
      state.templateProperties.employeeHireStageApplicable = action.payload;
    },
    setVisibleToEmployee: (state, action) => {
      state.templateProperties.visibleToEmployee = action.payload;
    },
    setEmployeeCanInitiate: (state, action) => {
      state.templateProperties.employeeCanInitiate = action.payload;
    },
    setMultiAssignable: (state, action) => {
      state.templateProperties.multiAssignable = action.payload;
    },
  },
});

export const {
  resetAI,
  setTemplatePropertiesAI,
  setBussinessSelectedChecksAI,
  setEmployeeCanInitiate,
  setMultiAssignable,
  setEmployeeHireStageApplicable,
  setVisibleToEmployee,
} = AdminIndividualSlice.actions;
export default AdminIndividualSlice.reducer;
