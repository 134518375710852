import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewTemplateAPI,
  getAdminLocationList,
  getAdminRoleList,
  getFranchissorDeparmentsList,
  getFranchissorLoctionList,
  getFullTemplate,
  getSharedTemplate,
  getSignatoriesList,
  gettemplateDocsPDF,
  suppoertingDocsUploader,
  templateDocsDocumentUploader,
  templateEdit,
} from "../../services/reNewTemplateAPIs";
import { templateArrayMapper } from "../../utils/templateArrayMapper";

export const getSignatoriesListThunk = createAsyncThunk(
  "reNewTemplate/getSignatoriesList",
  async (accountId, { rejectWithValue }) => {
    try {
      const response = await getSignatoriesList(accountId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getFranchissorDeparmentsListThunk = createAsyncThunk(
  "reNewTemplate/getFranchissorDeparmentsList",
  async (accountId, { rejectWithValue }) => {
    try {
      const response = await getFranchissorDeparmentsList(accountId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFranchissorLoctionListThunk = createAsyncThunk(
  "reNewTemplate/getFranchissorLoctionList",
  async (accountId, { rejectWithValue }) => {
    try {
      const response = await getFranchissorLoctionList(accountId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const createNewTemplateThunk = createAsyncThunk(
  "reNewTemplate/createNewTemplate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await createNewTemplateAPI(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const templateDocsDocumentUploaderThunk = createAsyncThunk(
  "reNewTemplate/templateDocsDocumentUploader",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await templateDocsDocumentUploader(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSharedTemplateThunk = createAsyncThunk(
  "reNewTemplate/getSharedTemplate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSharedTemplate(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const suppoertingDocsUploaderThunk = createAsyncThunk(
  "reNewTemplate/suppoertingDocsUploader",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await suppoertingDocsUploader(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAdminLocationListThunk = createAsyncThunk(
  "reNewTemplate/getAdminLocationList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAdminLocationList(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAdminRoleListThunk = createAsyncThunk(
  "reNewTemplate/getAdminRoleList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAdminRoleList(payload);
      return { data: response, id: payload };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getFullTemplateThunk = createAsyncThunk(
  "reNewTemplate/getFullTemplate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getFullTemplate(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const templateEditThunk = createAsyncThunk(
  "reNewTemplate/templateEdit",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await templateEdit(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const gettemplateDocsPDFThunk = createAsyncThunk(
  "reNewTemplate/gettemplateDocsPDF",
  async (accountId, { rejectWithValue }) => {
    try {
      const response = await gettemplateDocsPDF(accountId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const initialState = {
  sharedTemplateList: [],
  shareDialogConcent: false,
  templateDocsPDF: "",
  templateDisable: false,
  editTemplateResponse: "",
  templateName: "",
  templateId: "",
  getTemplate: "",
  getTemplateLoading: false,
  adminRolesList: {},
  adminLocationsList: [],
  postTemplateDocsDocumentResponse: "",
  franchisorDepartmentList: [],
  postTemplateResponse: "",
  franchisorLocationList: [],
  loading: false,
  isReloadTemplate: false,
  templateDocsDocument: "",
  businessSignatoryList: [],
  franchisorRegionDataFB: {},
  fullTemplate: {
    accountId: 0,
    name: "",
    description: "",
    templateStatus: "INACTIVE",
    effectiveFromTime: null,
    fillableFieldPropertiesList: [],
    supportingDocuments: [],
    templateProperties: {
      versionApplyRule: "DEFAULT",
      isFranchisorCompliant: "no", //Is this template required for Brand compliance?
      isBusinessCompliant: "no", // Is this template required for business compliance?
      templateType: "EMPLOYEE",
      version: "Draft",
      forManualAssignment: false,
      notificationPeriod: 30,
      expiryMonths: null,
      actionRequired: "READ_AND_FILL",
      workflowDirection: "EMPLOYEE_ONLY",
      businessSignatory: "",
      pdfName: "",
      supportingDocRequired: false, //supplemented docs select
    },
  },
};

export const reNewTemplateSlice = createSlice({
  name: "reNewTemplate",
  initialState,
  reducers: {
    setsharedTemplateList: (state, action) => {
      state.sharedTemplateList = action.payload;
    },
    setGetTemplateLoading: (state, action) => {
      state.getTemplateLoading = action.payload;
    },
    setReNewfillableFieldPropertiesList: (state, action) => {
      state.fullTemplate.fillableFieldPropertiesList = action.payload;
    },
    setShareDialogConcent: (state, action) => {
      state.shareDialogConcent = action.payload;
    },
    setTemplateDisable: (state, action) => {
      state.templateDisable = action.payload;
    },
    setVersionApplyRule: (state, action) => {
      state.fullTemplate.templateProperties.versionApplyRule = action.payload;
    },
    setFranchisorRegionDataFB: (state, action) => {
      state.franchisorRegionDataFB = action.payload;
    },
    setTemplateGlobalName: (state, action) => {
      state.templateName = action.payload;
    },
    setTemplateId: (state, action) => {
      state.templateId = action.payload;
    },
    setAccountId: (state, action) => {
      state.fullTemplate.accountId = action.payload;
    },
    resetReNewTemplate: (state) => initialState,
    setPDFName: (state, action) => {
      state.fullTemplate.templateProperties.pdfName = action.payload;
    },
    setsupportingDocRequired: (state, action) => {
      state.fullTemplate.templateProperties.supportingDocRequired =
        action.payload;
    },
    setNotificationPeriod: (state, action) => {
      state.fullTemplate.templateProperties.notificationPeriod = action.payload;
    },
    setExpiryMonths: (state, action) => {
      state.fullTemplate.templateProperties.expiryMonths = action.payload;
    },
    setIsFranchisorCompliant: (state, action) => {
      state.fullTemplate.templateProperties.isFranchisorCompliant =
        action.payload;
    },
    setIsBusinessCompliant: (state, action) => {
      state.fullTemplate.templateProperties.isBusinessCompliant =
        action.payload;
    },
    setBusinessSignatory: (state, action) => {
      state.fullTemplate.templateProperties.businessSignatory = action.payload;
    },
    setIsReloadTemplate: (state, action) => {
      state.isReloadTemplate = action.payload;
    },
    setTemplateDocsDocument: (state, action) => {
      state.templateDocsDocument = action.payload;
    },
    setActionRequired: (state, action) => {
      state.fullTemplate.templateProperties.actionRequired = action.payload;
    },
    setWorkflowDirection: (state, action) => {
      state.fullTemplate.templateProperties.workflowDirection = action.payload;
    },
    setSupportingDocuments: (state, action) => {
      state.fullTemplate.supportingDocuments = action.payload;
    },
    setEffectiveFromTime: (state, action) => {
      state.fullTemplate.effectiveFromTime = action.payload;
    },
    setTemplateType: (state, action) => {
      state.fullTemplate.templateProperties.templateType = action.payload;
    },
    setTemplateName: (state, action) => {
      state.fullTemplate.name = action.payload;
    },
    setTemplateDescription: (state, action) => {
      state.fullTemplate.description = action.payload;
    },
    setTemplateProperties: (state, action) => {
      state.fullTemplate.templateProperties = action.payload;
    },
    setFullTemplate: (state, action) => {
      state.fullTemplate = action.payload;
    },
    setForManualAssignment: (state, action) => {
      state.fullTemplate.templateProperties.forManualAssignment =
        action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSignatoriesListThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSignatoriesListThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.businessSignatoryList = action.payload;
    });
    builder.addCase(getSignatoriesListThunk.rejected, (state, action) => {
      state.loading = false;
      state.businessSignatoryList = [];
    });

    //franchisor departmnet list
    builder.addCase(
      getFranchissorDeparmentsListThunk.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getFranchissorDeparmentsListThunk.fulfilled,
      (state, action) => {
        state.loading = false;
        state.franchisorDepartmentList = action.payload;
      }
    );
    builder.addCase(
      getFranchissorDeparmentsListThunk.rejected,
      (state, action) => {
        state.loading = false;
        state.franchisorDepartmentList = [];
      }
    );

    //franchisor location list

    builder.addCase(getFranchissorLoctionListThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      getFranchissorLoctionListThunk.fulfilled,
      (state, action) => {
        state.loading = false;
        state.franchisorLocationList = action.payload;
      }
    );
    builder.addCase(
      getFranchissorLoctionListThunk.rejected,
      (state, action) => {
        state.loading = false;
        state.franchisorLocationList = [];
      }
    );

    //createNewTemplateThunk franchisor

    builder.addCase(createNewTemplateThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createNewTemplateThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.postTemplateResponse = action.payload;
    });
    builder.addCase(createNewTemplateThunk.rejected, (state, action) => {
      state.loading = false;
      state.postTemplateResponse = "";
    });

    //template docs uploader

    builder.addCase(
      templateDocsDocumentUploaderThunk.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      templateDocsDocumentUploaderThunk.fulfilled,
      (state, action) => {
        state.loading = false;
        state.postTemplateDocsDocumentResponse = action.payload;
      }
    );
    builder.addCase(
      templateDocsDocumentUploaderThunk.rejected,
      (state, action) => {
        state.loading = false;
        state.postTemplateDocsDocumentResponse = "";
      }
    );

    //get admin locations
    builder.addCase(getAdminLocationListThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAdminLocationListThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.adminLocationsList = action.payload;
    });
    builder.addCase(getAdminLocationListThunk.rejected, (state, action) => {
      state.loading = false;
      state.adminLocationsList = [];
    });

    ///get admin roles list getAdminRoleListThunk
    builder.addCase(getAdminRoleListThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAdminRoleListThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.adminRolesList[action.payload.id] = action.payload.data;
    });
    builder.addCase(getAdminRoleListThunk.rejected, (state, action) => {
      state.loading = false;
    });

    //getfulltemplate api
    builder.addCase(getFullTemplateThunk.pending, (state, action) => {
      state.getTemplateLoading = true;
    });
    builder.addCase(getFullTemplateThunk.fulfilled, (state, action) => {
      if (action.payload.status !== 200) {
        state.getTemplateLoading = "error";
        state.getTemplate = action.payload.data;
      } else {
        //dowsnt store api data
        state.getTemplate = "success";
      }
    });
    builder.addCase(getFullTemplateThunk.rejected, (state, action) => {
      state.getTemplateLoading = "error";
      state.getTemplate = "error";
    });

    //edit template

    builder.addCase(templateEditThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(templateEditThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.editTemplateResponse = action.payload.data;
    });
    builder.addCase(templateEditThunk.rejected, (state, action) => {
      state.loading = "error";
      state.editTemplateResponse = action.payload;
    });

    //getTemplatedocs pdf
    builder.addCase(gettemplateDocsPDFThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(gettemplateDocsPDFThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.templateDocsPDF = action.payload.data;
    });
    builder.addCase(gettemplateDocsPDFThunk.rejected, (state, action) => {
      state.loading = false;
      state.templateDocsPDF = action.payload;
    });

    //shared template list getSharedTemplateThunk
    builder.addCase(getSharedTemplateThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSharedTemplateThunk.fulfilled, (state, action) => {
      state.loading = false;
      // state.sharedTemplateList =
      //   action.payload.content && action.payload.content.length
      //     ? templateArrayMapper(action.payload.content, true)
      //     : [];
    });
    builder.addCase(getSharedTemplateThunk.rejected, (state, action) => {
      state.loading = false;
      // state.sharedTemplateList = action.payload.data;
    });
  },
});

export const {
  setsharedTemplateList,
  setTemplateDisable,
  setVersionApplyRule,
  setForManualAssignment,
  setTemplateType,
  setTemplateId,
  setPDFName,
  setsupportingDocRequired,
  resetReNewTemplate,
  setFranchisorRegionDataFB,
  setTemplateDocsDocument,
  setTemplateGlobalName,
  setIsBusinessCompliant,
  setIsFranchisorCompliant,
  setExpiryMonths,
  setNotificationPeriod,
  setTemplateName,
  setEffectiveFromTime,
  setAccountId,
  setTemplateDescription,
  setFullTemplate,
  setTemplateProperties,
  setSupportingDocuments,
  setGetTemplateLoading,
  setShareDialogConcent,
  setActionRequired,
  setWorkflowDirection,
  setBusinessSignatory,
  setIsReloadTemplate,
  setReNewfillableFieldPropertiesList,
} = reNewTemplateSlice.actions;
export default reNewTemplateSlice.reducer;
