import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import { styled } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  itemsTemplates,
  employeeNavItems,
  itemsWithoutTemplates,
  userDashBoardItems,
  itemsTemplatesForNetwork,
} from "../../mock-data/mock";
import AppRouter from "../../AppRouter";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import logo from "../../assets/complianser-logo-with-text.svg";
import logo2 from "../../assets/complianser-logo-single.svg";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import {
  Button,
  Menu,
  MenuItem,
  Stack,
  SwipeableDrawer,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";

import {
  getBusinessDetails,
  setSelectedDashboard,
  getProfile,
  setSelectedBussinessDetails,
  getBusinessComplianceDetailsByAllUserRoleId,
  getDashboardData,
  getBusinessComplianceDetailsByUserForCorporate,
  showLocationSearchBar,
} from "../../pages/Dashboard/DashboardSlice";
import "./NavDrawer.css";
import BussinessDetailsDialog from "../../pages/Dashboard/components/BussinessDetailsDialog/BussinessDetailsDialog";
import BusinessCreateDialog from "../../pages/Dashboard/components/BusinessCreate/BusinessCreateDialog";
import {
  logout,
  setCurrentTemplateTabIndex,
  setLoggedInUserBusinesses,
} from "../../pages/Login/LoginSlice";
import Dialog from "../Dialog/Dialog";
import ProfileBody from "../../pages/Profile/ProfileBody";
import Notification from "../Notification/Notification";
import {
  setNewTemplateData,
  setSelectedTemplateRow,
} from "../../pages/Templates/TemplateSlice";
import SNACKBAR_VARIATIONS from "../../constants/snackbar-variations";
import AgreementDialog from "../../pages/Agreement/AgreementDialog";
import {
  getAdminLocationListThunk,
  getFranchissorDeparmentsListThunk,
  getFranchissorLoctionListThunk,
  getSignatoriesListThunk,
  resetReNewTemplate,
  setAccountId,
  setIsReloadTemplate,
  setTemplateType,
} from "../../pages/RENewTemplate/ReNewTemplateSlice";
import { resetState } from "../StepperTabs/StepperTabs.slice";
import { resetFB } from "../../pages/RENewTemplate/FranchisorBussiness/FranchisorBussinessSlice";
import { resetFI } from "../../pages/RENewTemplate/FranchisorIndividual/FranchisorIndividualSlice";
import { resetAB } from "../../pages/RENewTemplate/AdminBussiness/AdminBussinessSlice";
import { resetAI } from "../../pages/RENewTemplate/AdminIndividual/AdminIndividualSlice";
import { setDialogSelectedEntity } from "../../pages/Dashboard/BusinessLocationSlice";
import { bussinessChecker } from "../../utils/miscFunctions";
import { resetShare } from "../../pages/RENewTemplate/ShareSlice";
import { PlansStrings } from "../../utils/stringToObj";
import SignUpFrom from "../../pages/Login/SignUpFrom";
import { useRef } from "react";
import { closeAlert, showAlert } from "../../core-ui/alert/alert-slice";
import FooterNavbar from "../Footer/FooterNavbar";
import storage from "redux-persist/lib/storage";
const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: theme.palette.common.darkblue,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.common.darkblue,
  overflowX: "hidden",
  height: "100%",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, size }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: size ? drawerWidth : 0,
    width: size ? `calc(100% - ${drawerWidth}px)` : "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  // backgroundColor: theme.palette.secondary.main,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    height: `calc(100vh - ${73}px)`,
    overflow: "hidden",
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    // height: "95vh",
    height: `calc(100vh - ${73}px)`,
    overflow: "hidden",
  }),
}));

const useStyles = makeStyles()((theme) => {
  return {
    navBarContainer: {
      display: "flex",
      width: "100%",
    },
    toolBar: {
      color: theme.palette.secondary.main,
    },
    list: {
      backgroundColor: theme.palette.secondary.main,
    },
    drawerHeader: {
      backgroundColor: theme.palette.primary.main,
    },
    appBarItem: {
      marginLeft: "1rem",
      height: "45px",
      lineHeight: "45px",
      cursor: "pointer",
    },
    activeAppBarItem: {
      borderBottom: `2px solid ${theme.palette.common.white}`,
    },
  };
});

const NavDrawer = ({ appHeaderItems }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [profileOpenDialog, setProfileOpenDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogCreateTitle, setDialogCreateTitle] = useState("");
  const loggedInUserInfo = useSelector((state) => state.login.loggedInUserInfo);
  const plan = useSelector((state) => state.login.plan);
  const tableType = useSelector((state) => state.dashboard.tableType);
  const {
    profile = {},
    agreementPopState,
    selectedDashboard: selectedDashboardRedux,
  } = useSelector((state) => state.dashboard);
  const locationPath = useLocation();

  const { isIpadScreen } = useSelector((state) => state.common);
  const [open, setOpen] = React.useState(isIpadScreen);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [snackbarOpen, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const closeSnackbar = () => setOpenSnackbar(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);

  const [networkForCreateNewUser, setNetworkForCreateNewUser] = useState(false);
  const menuOpen = Boolean(anchorEl);
  const [items, setItems] = useState(
    loggedInUserInfo.accountType === "FRANCHISOR"
      ? itemsTemplatesForNetwork
      : loggedInUserInfo.role === "ROLE_USER"
      ? userDashBoardItems
      : loggedInUserInfo.role === "ROLE_ACCOUNT_ADMIN" ||
        loggedInUserInfo.accountType === "FRANCHISOR"
      ? itemsTemplates
      : itemsWithoutTemplates
  );
  const [navItems, setNavItems] = React.useState([...items]);
  const [appBarItems, setAppbarItems] = React.useState([...appHeaderItems]);
  const formRef = useRef(null);
  const handleOpenNewDialog = () => {
    setOpenCreateDialog(true);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const handleSnackbar = (message, severity) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
  };

  const handleCloseNewDialog = (originalPromiseResult) => {
    if (
      originalPromiseResult !== null &&
      originalPromiseResult.status === 201
    ) {
      handleSnackbar(
        "Business Created Successfully!!!",
        SNACKBAR_VARIATIONS.SUCCESS
      );
      dispatch(
        setLoggedInUserBusinesses({
          businessId: originalPromiseResult.data.businessId,
          businessName: originalPromiseResult.data.legalName,
          role: null,
          franchisorRole: null,
          locations: [],
        })
      );
      tableType === "all" &&
        dispatch(
          getBusinessComplianceDetailsByAllUserRoleId(
            loggedInUserInfo.employeeId
          )
        );
      tableType === "active" &&
        dispatch(getDashboardData(loggedInUserInfo.employeeId));
      tableType === "corporate" &&
        dispatch(
          getBusinessComplianceDetailsByUserForCorporate(
            loggedInUserInfo.employeeId
          )
        );
    }
    setOpenCreateDialog(false);
  };
  useEffect(() => {
    if (loggedInUserInfo.role === "ROLE_USER") {
      dispatch(
        setSelectedDashboard({
          name: "My Dashboard",
          icon: AdminPanelSettingsOutlinedIcon,
          selected: true,
        })
      );
    } else {
      mapDashboardData();
      if (selectedDashboardRedux.name.includes("My")) {
        if (loggedInUserInfo.role !== "ROLE_USER") {
          setAppbarItems([
            {
              name: "Management Dashboard",
              selected: false,
            },
            {
              name: "My Dashboard",
              selected: true,
            },
          ]);
        } else {
          setAppbarItems([
            {
              name: "Management Dashboard",
              selected: true,
            },
            {
              name: "My Dashboard",
              selected: false,
            },
          ]);
        }
        dispatch(
          setSelectedDashboard({
            name: "My Dashboard",
            icon: AdminPanelSettingsOutlinedIcon,
            selected: true,
          })
        );
      } else {
        dispatch(
          setSelectedDashboard({
            name: "Management Dashboard",
            icon: DashboardCustomizeOutlinedIcon,
            selected: true,
          })
        );
        setAppbarItems([
          {
            name: "Management Dashboard",
            selected: true,
          },
          {
            name: "My Dashboard",
            selected: false,
          },
        ]);
      }
    }
    if (location.pathname.includes("dashboard")) {
      dispatch(getProfile(loggedInUserInfo.employeeId));
    }
  }, [loggedInUserInfo?.dashboardHierarchy?.businesses]);
  useEffect(() => {
    if (appBarItems[1]?.selected) setNavItems([...employeeNavItems]);
  }, [appBarItems]);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);

    dispatch(setSelectedBussinessDetails(""));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAccMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccMenuClose = async (event) => {
    if (event.target.innerHTML === "Logout") {
      const response = await dispatch(logout());
      const status = unwrapResult(response);
      clearCacheData();
      storage.removeItem("persist:root");
      localStorage.clear();
      navigate("/login", { replace: true });
    }
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(closeAlert());
    storage.removeItem("persist:root");
    clearCacheData();
    localStorage.clear();
    navigate("/login", { replace: true });
    setAnchorEl(null);
  };
  const handleNavItemClicked = (navItem) => {
    const existingNavItems = navItems.map((item) => {
      if (item.itemName === navItem.itemName) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      return item;
    });
    setNavItems(existingNavItems);
    navigate(navItem.path);
  };

  const manageActiveAppBarItem = (appBarItem) => {
    const existingAppbarItems = appBarItems.map((item) => {
      if (appBarItem.name === item.name) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      return item;
    });
    setAppbarItems(existingAppbarItems);
  };

  const handleAppbarItemClicked = (appBarItem) => {
    manageActiveAppBarItem(appBarItem);
    if (appBarItem.name === "My Dashboard") {
      navigate("/dashboard");
      setNavItems([...employeeNavItems]);
    } else {
      setNavItems([...items]);
    }
    dispatch(setSelectedDashboard(JSON.parse(JSON.stringify(appBarItem))));
  };

  const handleBussinessClicked = (business) => {
    setDialogTitle(business.businessName);
    dispatch(setSelectedBussinessDetails({ businessId: business.businessId }));
    if (
      loggedInUserInfo.accountType === "FRANCHISOR" ||
      loggedInUserInfo.role === "ROLE_ACCOUNT_ADMIN" ||
      loggedInUserInfo.role === "ROLE_BUSINESS_MANAGER"
    ) {
      dispatch(getBusinessDetails(business.businessId));
    }
    setOpenDialog(true);
  };
  const createNewTemp = () => {
    const newTemplateData = {
      templateProperties: {
        templateType: "",
        isBusinessCompliant: "no",
        isFranchisorCompliant: "no",
        visibleToEmployee: "no",
        employeeCanInitiate: "no",
        multiAssignable: "no",
        workflowDirection: "",
        notificationPeriod: 30,
        expiryMonths: 0,
        employeeHireStageApplicable: "PRE_HIRE",
        forMultiOwnerAssignment: false,
        forManualAssignment: false,
        actionRequired: "",
        supportingDocRequired: false,
        version: "",
        pdfName: "",
      },
      applicableAts: [],
      applicableAtToFranchiser: {},
      fillableFieldPropertiesList: [],
      supportingDocuments: [],
      effectiveFromTime: "",
      templateStatus: "",
      name: "",
      description: "",
      accountId: 0,
      supplementalDocFileArr: [],
    };
    dispatch(setNewTemplateData(newTemplateData));
    dispatch(setSelectedTemplateRow({}));
    dispatch(setIsReloadTemplate(false));
    handleNewTemplate();
    navigate("/templates/new");
  };
  const handleNewBussinessClicked = (e, business) => {
    e.stopPropagation();
    // setDialogTitle(business.businessName);
    // dispatch(getBusinessDetails(business.businessId));
    // setOpenDialog(true);
    if (business.itemName === "NETWORK") {
      setNetworkForCreateNewUser(true);
    } else if (business.itemName === "TEMPLATES") {
      dispatch(resetShare());
      dispatch(setCurrentTemplateTabIndex(0));
      createNewTemp();
    } else {
      setOpenCreateDialog(true);
    }
  };
  const handleNewTemplate = () => {
    dispatch(resetState());
    if (loggedInUserInfo.accountType === "FRANCHISOR") {
      dispatch(resetFB());
      dispatch(resetFI());
      dispatch(resetReNewTemplate());
      dispatch(setAccountId(loggedInUserInfo.accountId));
      dispatch(setTemplateType("FRANCHISOR_EMPLOYEE"));
      dispatch(getSignatoriesListThunk(loggedInUserInfo.accountId));
      dispatch(
        getFranchissorLoctionListThunk(
          loggedInUserInfo.dashboardHierarchy.franchisorId
        )
      );
      dispatch(getFranchissorDeparmentsListThunk(loggedInUserInfo.accountId));
    } else {
      dispatch(resetAB());
      dispatch(resetAI());
      dispatch(resetReNewTemplate());
      dispatch(setAccountId(loggedInUserInfo.accountId));
      dispatch(setTemplateType("EMPLOYEE"));
      dispatch(getAdminLocationListThunk(loggedInUserInfo.accountId));
    }
  };
  // extracts initials of logged in user & displays in avatar
  const stringAvatar = (name) => {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  const mapDashboardData = () => {
    const bussinessess = items.findIndex(
      (item) => item.itemName === "BUSINESSES"
    );
    items[bussinessess].children =
      loggedInUserInfo?.dashboardHierarchy?.businesses;
    setNavItems(items);
  };
  const handleOpenDialogEditProfile = () => {
    setProfileOpenDialog(true);
    dispatch(getProfile(loggedInUserInfo.employeeId));
  };

  const size = useMediaQuery("(min-width: 430px)");
  return (
    <>
      {networkForCreateNewUser ? (
        <Dialog
          isCreateNewUser={true}
          isCloseButtonHidden={true}
          open={true}
          handleClose={() => {
            dispatch(resetShare());
            // setOpenShareDialog(false);
            setNetworkForCreateNewUser(false);
          }}
          key={"newAccountNetwork"}
          headerText={"New Account"}
          headerButtons={[
            {
              btnName: "Cancel",
              onClick: () => {
                dispatch(resetShare());
                // setOpenShareDialog(false);
                setNetworkForCreateNewUser(false);
              },
              btnColor: "#F5F7FA",
              btnTextColor: "black",
            },
            {
              btnName: "Save",

              onClick: () => {
                formRef.current.click();
              },
              btnColor: "#CD99E5",
              btnTextColor: "white",
            },
          ]}
        >
          <SignUpFrom
            isOptInForNewuser={true}
            // isCreateNewUser={false}
            isNetwork={true}
            formRef={formRef}
          />
        </Dialog>
      ) : null}
      <Box className={classes.navBarContainer}>
        <CssBaseline />
        <AppBar sx={{ boxShadow: 0 }} position="fixed" open={open} size={size}>
          {snackbarOpen && (
            <Notification
              openSnackbar={snackbarOpen}
              severity={snackbarSeverity}
              message={snackbarMessage}
              handleClose={closeSnackbar}
            />
          )}
          <Toolbar sx={{ display: "flex" }}>
            {!open || !size ? (
              <img
                style={{ marginLeft: "-0.5rem", height: "35px" }}
                src={logo2}
                alt="company logo"
              />
            ) : null}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{
                marginLeft: open ? -1.3 : size ? 4 : 0,
                marginRight: 1,
              }}
            >
              {open ? (
                <MenuIcon
                  sx={{
                    "&:hover": { color: "#ccc" },
                  }}
                />
              ) : (
                <MenuIcon
                  sx={{
                    "&:hover": { color: "#ccc" },
                  }}
                />
              )}
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ fontSize: !size ? "17px" : "17px", marginLeft: "1rem" }}
            >
              {loggedInUserInfo?.employeeName
                ? `HI ${loggedInUserInfo?.employeeName
                    .split(" ")[0]
                    .toUpperCase()}`
                : "HI USER"}
            </Typography>
            {loggedInUserInfo.role !== "ROLE_USER" && size ? (
              appBarItems.map((appBarItem) => (
                <Typography
                  sx={{ "&:hover": { color: "#ccc" } }}
                  key={appBarItem.name}
                  onClick={() => handleAppbarItemClicked(appBarItem)}
                  variant="body1"
                  component="div"
                  className={`${classes.appBarItem} ${
                    appBarItem.selected ? classes.activeAppBarItem : ""
                  }`}
                >
                  {appBarItem.name}
                </Typography>
              ))
            ) : (
              <Grid>
                {appBarItems.map((appBarItem) => (
                  <>
                    {loggedInUserInfo.role !== "ROLE_USER" ? (
                      <Tooltip title={appBarItem.name}>
                        <IconButton
                          color="inherit"
                          id="demo-positioned-button"
                          key={appBarItem.name}
                          onClick={() => handleAppbarItemClicked(appBarItem)}
                          edge="end"
                          sx={{
                            marginLeft: "auto",
                            marginRight: "0.2rem",
                          }}
                        >
                          {appBarItem.name === "My Dashboard" ? (
                            <AdminPanelSettingsOutlinedIcon />
                          ) : (
                            appBarItem.name === "Management Dashboard" && (
                              <DashboardCustomizeOutlinedIcon />
                            )
                          )}
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </>
                ))}
              </Grid>
            )}
            <Tooltip title="My Account">
              <IconButton
                id="account"
                color="inherit"
                aria-controls={menuOpen ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
                onClick={handleAccMenuClick}
                edge="end"
                sx={{
                  marginLeft: "auto",
                  float: "right",
                }}
              >
                <AccountCircleOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip sx={{ marginLeft: "0.8rem" }} title="Support">
              <IconButton
                id="support"
                color="inherit"
                aria-controls={menuOpen ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
                onClick={(event) => {
                  window.open("https://focus451.freshdesk.com/support/home");
                }}
                edge="end"
              >
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleAccMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{ marginTop: "0.8rem", marginLeft: "0.8rem" }}
            >
              <MenuItem
                disableRipple={true}
                sx={{
                  ":hover": {
                    backgroundColor: "white",
                    cursor: "default",
                  },
                }}
              >
                <Grid
                  container
                  gap={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Avatar
                      {...stringAvatar(
                        loggedInUserInfo?.employeeName
                          ? loggedInUserInfo?.employeeName
                          : "Hi User"
                      )}
                    />
                  </Grid>
                  <Grid item>
                    {loggedInUserInfo?.employeeName
                      ? loggedInUserInfo?.employeeName
                      : "Hi User"}
                  </Grid>
                </Grid>
              </MenuItem>
              <Divider />
              <MenuItem
                sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                onClick={handleAccMenuClose}
              >
                <Grid
                  container
                  gap={1}
                  justifyContent="space-between"
                  alignItems="baseline"
                  onClick={handleOpenDialogEditProfile}
                >
                  <Grid item>Edit Profile</Grid>
                  <Grid item>
                    <Person2OutlinedIcon fontSize="10px" />
                  </Grid>
                </Grid>
              </MenuItem>
              {loggedInUserInfo.accountType === "FRANCHISOR" ? (
                <MenuItem
                  sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                  onClick={() => navigate("/billing")}
                >
                  <Grid
                    container
                    gap={1}
                    justifyContent="space-between"
                    alignItems="baseline"
                  >
                    <Grid item>Billing</Grid>
                    <Grid item>
                      <AttachMoneyIcon fontSize="10px" />
                    </Grid>
                  </Grid>
                </MenuItem>
              ) : null}

              <MenuItem
                sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                onClick={handleLogout}
              >
                <Grid
                  container
                  gap={1}
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Grid item>Logout</Grid>
                  <Grid item>
                    <LogoutIcon fontSize="10px" />
                  </Grid>
                </Grid>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        {size ? (
          <Drawer
            sx={{ "& .MuiDrawer-paper": { borderWidth: 0 } }}
            variant="permanent"
            open={open}
            className={classes.toolBar}
          >
            <DrawerHeader className={classes.drawerHeader}>
              <img
                src={logo}
                alt="company logo"
                style={{
                  display: "block",
                  margin: "auto",
                  alignItems: "center",
                  height: "35px",
                }}
              />
            </DrawerHeader>
            <Divider />
            <List sx={{ height: "100vh" }}>
              {navItems?.map((navItem, index) => {
                return (
                  <>
                    <ListItem
                      key={navItem.itemName}
                      disablePadding
                      sx={{
                        display: "block",
                        color: "white",
                        marginTop: navItem.itemName.includes("Dashboard")
                          ? "0.5rem"
                          : "2rem",
                      }}
                      onClick={() => handleNavItemClicked(navItem)}
                      className={navItem.selected ? "nav-item-active" : ""}
                    >
                      <ListItemButton
                        sx={{
                          maxHeight: 30,
                          justifyContent: open ? "initial" : "center",
                          alignItems: "center",
                          px: 2.5,
                        }}
                      >
                        <Tooltip title={!open ? navItem.itemName : null}>
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              color: navItem.selected ? "#36BFEB" : "white",
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {index === 0 ? (
                              <HomeOutlinedIcon
                                sx={{ height: "1.2rem", mr: "-0.5rem" }}
                              />
                            ) : index && index === 1 ? (
                              <FolderOpenOutlinedIcon
                                sx={{ height: "1.2rem", mr: "-0.5rem" }}
                              />
                            ) : (
                              <FileCopyOutlinedIcon
                                sx={{ height: "1.2rem", mr: "-0.5rem" }}
                              />
                            )}
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                          primary={
                            index === 0 ? (
                              <>{navItem.itemName}</>
                            ) : (
                              <>
                                {navItem.itemName}
                                {/* {bussinessChecker(
                          loggedInUserInfo.role,
                          loggedInUserInfo.accountType,
                          plan
                        ) ? ( */}
                                {locationPath.pathname !== "/templates/new" ||
                                navItem.itemName !== "TEMPLATES" ? (
                                  <IconButton
                                    disableRipple
                                    id={
                                      navItem.itemName === "BUSINESSES"
                                        ? "add-business"
                                        : navItem.itemName === "TEMPLATES" &&
                                          "add-template"
                                    }
                                    sx={{
                                      marginLeft:
                                        navItem.itemName.length > 9 ? 1.2 : 2,
                                      color: "white",
                                    }}
                                    onClick={(e) =>
                                      handleNewBussinessClicked(e, navItem)
                                    }
                                  >
                                    {!loggedInUserInfo.allowOnlySSOLogin &&
                                    navItem.itemName === "BUSINESSES" &&
                                    loggedInUserInfo.planType ==
                                      PlansStrings.DIAMOND ? (
                                      <AddCircleOutlineOutlinedIcon />
                                    ) : navItem.itemName === "TEMPLATES" ? (
                                      <AddCircleOutlineOutlinedIcon />
                                    ) : navItem.itemName === "NETWORK" ? (
                                      <AddCircleOutlineOutlinedIcon
                                        sx={{ marginLeft: "0.68rem" }}
                                      />
                                    ) : null}
                                  </IconButton>
                                ) : null}

                                {/* ) : null} */}
                              </>
                            )
                          }
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                    <Stack
                      sx={
                        navItem.itemName === "BUSINESSES"
                          ? {
                              height: "40vh",
                              overflowY: open ? "auto" : "hidden",
                              overflowX: "hidden",
                              "&::-webkit-scrollbar": {
                                width: 10,
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "#2b697c",
                              },
                              "&:hover": {
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#9e9e9e",
                                  borderRadius: "50px",
                                  ":hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.35)",
                                  },
                                },
                              },
                            }
                          : {}
                      }
                    >
                      {navItem?.children?.map((text) => (
                        <ListItem
                          key={text.businessName}
                          // disablePadding
                          sx={{
                            // display: "block",
                            color: "#adadad",
                            p: "0.2rem 0 0 0.3rem",
                            m: 0,
                            ":hover": {
                              backgroundColor: "rgba(0,0,0,0.15)",
                            },
                          }}
                          onClick={() => handleBussinessClicked(text)}
                          // className={text.selected ? "nav-item-active" : ""}
                        >
                          <ListItemButton
                            id={text.businessName}
                            sx={{
                              // minHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              p: 0,
                              m: 0,
                              px: 2.5,
                            }}
                          >
                            {open ? (
                              <ListItemText
                                primary={text.businessName}
                                sx={{
                                  opacity: open ? 1 : 0,
                                  fontSize: "0.2rem",
                                }}
                              />
                            ) : null}
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </Stack>
                  </>
                );
              })}
              {open && (
                <ListItem sx={{ position: "absolute", bottom: 10 }}>
                  <FooterNavbar />
                </ListItem>
              )}
            </List>
          </Drawer>
        ) : (
          <SwipeableDrawer
            anchor="left"
            open={open}
            sx={{ "& .MuiDrawer-paper": { borderWidth: 0 } }}
            className={classes.toolBar}
            onClose={toggleDrawer(open)}
            onOpen={toggleDrawer(open)}
          >
            <DrawerHeader className={classes.drawerHeader}></DrawerHeader>
            <List
              sx={{ height: "100vh" }}
              className={classes.list}
              onKeyDown={toggleDrawer(open)}
            >
              {navItems.map((navItem, index) => {
                return (
                  <>
                    <ListItem
                      key={navItem.itemName}
                      disablePadding
                      sx={{ display: "block", color: "white" }}
                      onClick={() => handleNavItemClicked(navItem)}
                      className={navItem.selected ? "nav-item-active" : ""}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <Tooltip title={!open ? navItem.itemName : null}>
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              color: navItem.selected ? "#36BFEB" : "white",
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {index === 0 ? (
                              <HomeOutlinedIcon />
                            ) : index && index === 1 ? (
                              <FolderOpenOutlinedIcon />
                            ) : (
                              <FolderCopyOutlinedIcon />
                            )}
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                          primary={
                            index === 0 ? (
                              <>
                                {!size && navItem.itemName === "Dashboard" ? (
                                  <div style={{ width: "10.25rem" }}>
                                    {navItem.itemName}
                                  </div>
                                ) : (
                                  <>{navItem.itemName}</>
                                )}
                              </>
                            ) : (
                              <>
                                {navItem.itemName}
                                {loggedInUserInfo.role ===
                                  "ROLE_ACCOUNT_ADMIN" ||
                                loggedInUserInfo.franchisorRole ===
                                  "ROLE_FRANCHISOR_ACCOUNT_ADMIN" ? (
                                  <IconButton
                                    sx={{
                                      marginLeft:
                                        navItem.itemName.length > 9 ? 5.5 : 6.1,
                                      color: "white",
                                    }}
                                    onClick={(e) =>
                                      handleNewBussinessClicked(e, navItem)
                                    }
                                  >
                                    <AddCircleOutlineOutlinedIcon />
                                  </IconButton>
                                ) : null}
                              </>
                            )
                          }
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                    <Stack
                      sx={
                        navItem.itemName === "BUSINESSES"
                          ? {
                              height: "40vh",
                              overflowY: open ? "auto" : "hidden",
                              overflowX: "hidden",
                              "&::-webkit-scrollbar": {
                                width: 10,
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "#2b697c",
                              },
                              "&:hover": {
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#9e9e9e",
                                  borderRadius: "50px",
                                  ":hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.35)",
                                  },
                                },
                              },
                            }
                          : {}
                      }
                    >
                      {navItem?.children?.map((text) => (
                        <ListItem
                          key={text.businessName}
                          // disablePadding
                          sx={{
                            // display: "block",
                            color: "#adadad",
                            p: 0,
                            m: 0,
                            ":hover": { backgroundColor: "rgba(0,0,0,0.15)" },
                          }}
                          onClick={() => handleBussinessClicked(text)}
                          // className={text.selected ? "nav-item-active" : ""}
                        >
                          <ListItemButton
                            sx={{
                              // minHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              p: 0,
                              m: 0,
                              px: 2.5,
                            }}
                          >
                            {open ? (
                              <ListItemText
                                primary={text.businessName}
                                sx={{
                                  opacity: open ? 1 : 0,
                                  fontSize: "0.2rem",
                                }}
                              />
                            ) : null}
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </Stack>
                  </>
                );
              })}
              <ListItem sx={{ position: "absolute", bottom: 10 }}>
                <FooterNavbar />
              </ListItem>
            </List>
          </SwipeableDrawer>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 0,
          }}
        >
          <DrawerHeader />
          <AppRouter />
        </Box>
        {openDialog ? (
          <BussinessDetailsDialog
            openDialog={openDialog}
            dialogTitle={dialogTitle}
            handleOpenDialog={handleOpenDialog}
            handleCloseDialog={handleCloseDialog}
          />
        ) : null}
        {agreementPopState ? <AgreementDialog /> : null}
        {profileOpenDialog && (
          <Dialog
            open={profileOpenDialog}
            headerText={`Manage Profile`}
            handleClickOpen={true}
            handleClose={() => setProfileOpenDialog(false)}
            size="md"
            isBussiness
          >
            <ProfileBody
              setOpenSnackbar={setOpenSnackbar}
              setProfileOpenDialog={setProfileOpenDialog}
              setSnackbarSeverity={setSnackbarSeverity}
              setSnackbarMessage={setSnackbarMessage}
            />
          </Dialog>
        )}
        {openCreateDialog ? (
          <BusinessCreateDialog
            openDialog={openCreateDialog}
            dialogTitle={dialogCreateTitle}
            handleOpenDialog={handleOpenNewDialog}
            handleCloseDialog={handleCloseNewDialog}
          />
        ) : null}
      </Box>
    </>
  );
};

export default NavDrawer;
