import { ajaxHandler } from "./ajaxHandler";
export const getEmployeeList = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/employeeDetailsByBusiness/${payload.businessId}/${payload.userId}`
  );
  return response.data;
};
export const getEmployeeListFranchisor = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/franchisor/getFranchisorEmployeeDetailsByBusiness/${payload.businessId}/${payload.userId}`
  );
  return response.data;
};

export const getIndividualHistory = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/audit/getAuditDetailsByEmployeeId/${payload}`
  );
  return response.data;
};

export const postEmployee = async (payload) => {
  const response = await ajaxHandler("post", `focus451/employee/`, payload);
  return response;
};

export const findByBusinessAndEmployeeRole = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451/location/findByBusinessAndEmployeeRole/${payload.businessId}/${payload.userId}`
  );
  return response.data;
};
export const getLocationFranchisor = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451/region/getByFranchisor/${payload}`
  );
  return response.data;
};
export const updateEmployee = async (payload) => {
  const response = await ajaxHandler(
    "put",
    `focus451/employee/${payload.employeeId}`,
    payload.payload
  );
  return response;
};

export const getEmployeeById = async (employeeId) => {
  const response = await ajaxHandler("get", `focus451/employee/${employeeId}`);
  return response.data;
};

export const getIndividualDocs = async (employeeId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/dashboard/detailedComplianceStatsForEmployee/${employeeId}/all?ignoreCompliance=true`
  );
  return response.data;
};

export const getPreviewDoc = async (employeeId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/task/${employeeId}`
  );
  return response.data;
};
export const getPreviewDocHistory = async (employeeId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/audit/getAuditDetailsByDocumentId/${employeeId}`
  );
  return response.data;
};

export const downloadDoc = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/task/download/${payload.employeeId}/${payload.docId}`
  );
  return response.data;
};
export const downloadDocPDF = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/task/download/${payload}`
  );
  return response.data;
};

export const resendOTP = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451/employee/sendActivationMail/${payload}`
  );
  return response.data;
};


//  