import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import theme from "../../theme";
import usePaymentTable from "../../api/hooks/usePaymentTable";
import usePaymentRetry from "../../api/hooks/usePaymentRetry";
import useGenerateOTP from "../../api/hooks/useGenerateOTP";
import useValidateOTP from "../../api/hooks/useValidateOTP";
import { useDispatch } from "react-redux";
import {
  setMessageKey,
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../components/Notification/Notification.slice";

const getPaginatedItems = (pageNumber, itemsArray, itemsPerPage) => {
  if (!itemsArray?.length) return [];
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, itemsArray.length);
  return itemsArray.slice(startIndex, endIndex);
};

const PaymentHistoryTable = () => {
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    p: 4,
  };

  const itemsPerPage = 5;
  const headList = [
    "HQ Plan",
    "Location Plan",
    "#Loc",
    "Transaction Date",
    "Subscription Change date",
    "Total Amount Charged",
    "Payment Account Used",
    "Status",
  ];
  const tableCellWidth = {
    "HQ Plan": "8%",
    "Location Plan": "8%",
    "#Loc": "4%",
    "Transaction Date": "10%",
    "Subscription Change date": "10%",
    "Total Amount Charged": "10%",
    Status: "30%",
    "Payment Account Used": "20%",
  };

  const {
    data: paymentData,
    loading: paymentLoading,
    error: paymentListError,
    fetchData: paymentDataFetch,
  } = usePaymentTable();

  const {
    data: retryData,
    loading: retryLoading,
    error: retryError,
    retryPayment,
  } = usePaymentRetry();
  const {
    data: otpRes,
    loading: otpLoading,
    error: otpError,
    fetchData: fetchOtp,
  } = useGenerateOTP();

  const {
    data: validateOTPres,
    loading: validateOTPLoading,
    error: validateOTPError,
    validateOTP,
  } = useValidateOTP();

  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);
  const [otp, setOtp] = useState("");

  const handlePaymentRetry = () => {
    setOpenModal(false);
    retryPayment({
      onSuccess: () => {
        paymentDataFetch();
        setOpenModal(false);
        dispatch(setMessageKey("propertyValue"));
        dispatch(setMultiNotificationVariant("success"));
        dispatch(
          setMultiNotificationData([
            {
              propertyValue: `Payment Successful`,
            },
          ])
        );
      },
    });
  };

  useEffect(() => {
    paymentDataFetch();
  }, []);

  const handlePagination = (e, page) => {
    setPage(page);
  };

  const localBodyList = useMemo(() => {
    return getPaginatedItems(page, paymentData || [], itemsPerPage);
  }, [page, paymentData]);
  const count = Math.ceil((paymentData?.length || 0) / itemsPerPage);

  return (
    <>
      {openModal ? (
        <Modal open={true} onClose={() => setOpenModal(false)}>
          <Box sx={style}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              By clicking “Retry”, the system will retry processing the
              subscription charges with your preferred default payment account.
              Any bank charges arising out of payment failure will be added to
              your dues. If you would like to use another payment account,
              please add it as the default payment account and then come back to
              this screen. At this time, would you like to retry processing the
              charges with your current default payment account?
            </Typography>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              mt={2}
            >
              <TextField
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
                size="small"
              />

              <Button
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#93c",
                  ":hover": {
                    color: "white",
                    backgroundColor: "#93c",
                    cursor: "pointer",
                  },
                }}
                disabled={validateOTPLoading}
                onClick={() =>
                  validateOTP({ otp, onSuccess: handlePaymentRetry })
                }
              >
                Validate OTP
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </Button>
            </Stack>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              mt={2}
            >
              <Typography>Didn't get otp? </Typography>
              <Button
                sx={{
                  color: "#93c",
                  backgroundColor: "white",
                  ":hover": {
                    color: "#93c",
                    backgroundColor: "white",
                    cursor: "pointer",
                  },
                }}
                onClick={fetchOtp}
              >
                Resend OTP
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : null}
      <Paper
        sx={{
          width: "100%",
          boxShadow: 0,
        }}
      >
        <TableContainer
          sx={{
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&:hover": {
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#9e9e9e",
                borderRadius: "50px",

                ":hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.35)",
                },
              },
            },
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headList.map((ele, idx) => {
                  return (
                    <TableCell
                      sx={{
                        width: tableCellWidth[ele],
                        maxWidth: tableCellWidth[ele],
                        justifyContent: "center",
                        flexDirection: "row-reverse",
                        padding: "0.6rem 1rem",
                        border: "0.5px  solid #EEF2F4",
                        backgroundColor: "#FBFBFC",
                      }}
                      key={idx}
                      scope="row"
                      component={"th"}
                    >
                      <Typography>{ele}</Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {localBodyList?.length ? (
                localBodyList.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {Object.keys(row).map((key) => (
                        <React.Fragment key={key}>
                          <TableCell
                            sx={{
                              color: theme.palette.common.grey,
                              width: tableCellWidth[key],
                              maxWidth: tableCellWidth[key],
                              border: "0.5px  solid #EEF2F4",
                              padding: "1em 1em",
                            }}
                          >
                            <Stack direction={"row"} spacing={2}>
                              <Typography>{row[key]}</Typography>
                              {key === "Status" &&
                              row[key].includes("FAILED") &&
                              index === 0 ? (
                                <div
                                  onClick={() => {
                                    fetchOtp();
                                    setOpenModal(true);
                                  }}
                                >
                                  <ReplayIcon sx={{ color: "#93c" }} />
                                </div>
                              ) : null}
                            </Stack>
                          </TableCell>
                        </React.Fragment>
                      ))}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow
                  sx={{
                    border: "0.5px  solid #EEF2F4",
                    height: "2vh",
                    padding: "0.5em 2em",
                  }}
                >
                  <Typography sx={{ padding: "1em 10%" }}>
                    Nothing To Show here
                  </Typography>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {paymentData?.length > 4 ? (
          <Pagination
            page={page}
            onChange={handlePagination}
            sx={{ paddingTop: "1rem" }}
            count={count}
          />
        ) : null}
      </Paper>
    </>
  );
};

export default PaymentHistoryTable;
