export const pagesData = [
  {
    title: "E-Sign Agreement",
    firstTerm: "I accept above E-SIGN Disclosure and Consent Agreement.",
    secondTerm:
      "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts – just the same as a pen-and-paper signature and initial.",
    iFrameUrl: "https://www.complianser.com/legal/e-sign-agreement",
  },

  {
    title: "Privacy Policy Agreement",
    firstTerm: "I accept above Inc. Privacy Policy Agreement.",
    secondTerm:
      "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts – just the same as a pen-and-paper signature and initial.",
    iFrameUrl: "https://www.complianser.com/legal/privacy-policy",
  },
  {
    title: "Terms Of Use Agreement Agreement",
    firstTerm: "I accept above Enterprise Term Of Use License Agreement.",
    secondTerm:
      "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts – just the same as a pen-and-paper signature and initial.",
    iFrameUrl: "https://www.complianser.com/legal/terms-of-use",
  },
  {
    title: "SaaS Agreement",
    firstTerm: "I accept above Enterprise SaaS License Agreement.",
    secondTerm:
      "I ACKNOWLEDGE THAT I HAVE READ THIS AGREEMENT, UNDERSTAND IT AND AGREE TO BE BOUND BY IT. IN CONNECTION WITH SUCH ACKNOWLEDGEMENT, I ELECT TO LICENSE AND PAY FOR THE SERVICE.",
    iFrameUrl: "https://www.complianser.com/legal/saas-agreement",
  },
  {
    title: "Beta Testing Agreement",
    firstTerm: "I accept above Inc. Beta Testing Agreement.",
    secondTerm:
      "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts – just the same as a pen-and-paper signature and initial.",
    iFrameUrl: "https://www.complianser.com/legal/beta-testing-agreement",
  },
];

export const userPagesData = [
  {
    title: "E-Sign Agreement",
    firstTerm: "I accept above E-SIGN Disclosure and Consent Agreement.",
    secondTerm:
      "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts – just the same as a pen-and-paper signature and initial.",
    iFrameUrl: "https://www.complianser.com/legal/e-sign-agreement",
  },

  {
    title: "Privacy Policy Agreement",
    firstTerm: "I accept above Inc. Privacy Policy Agreement.",
    secondTerm:
      "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts – just the same as a pen-and-paper signature and initial.",
    iFrameUrl: "https://www.complianser.com/legal/privacy-policy",
  },
  {
    title: "Terms Of Use Agreement Agreement",
    firstTerm: "I accept above Enterprise Term Of Use License Agreement.",
    secondTerm:
      "I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts – just the same as a pen-and-paper signature and initial.",
    iFrameUrl: "https://www.complianser.com/legal/terms-of-use",
  },
];
