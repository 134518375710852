import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import CommonFormHeadButton from "../../../../components/CommonTableHead/CommonFormHeadButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  getLocationByIdDataMapper,
  postLocationDataMapper,
  postUpdateLocationDataMapper,
} from "../../../../utils/apiDataMappers";
import { useDispatch, useSelector } from "react-redux";
import {
  editLocationById,
  getLocationListById,
  saveBusinessLocation,
  setLoactinDataById,
  setEditActionResponse,
  setPostLocationResponse,
  setDialogSelectedEntity,
} from "../../BusinessLocationSlice";
import DialoCodeAutocomplete from "../../../../components/FormComponents/DialoCodeAutocomplete";
import { setBussinessNotification } from "../../DashboardSlice";
import {
  setMessageKey,
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../../../components/Notification/Notification.slice";
const LocationForm = ({ editData, setIndex, setSelected, setIsEdit }) => {
  const schema = yup.object().shape({
    bussinessName: yup
      .string()
      .nullable("Business Name is required")
      .required("Business Name is required"),
    brandLocationNumber: yup
      .string()
      .required("Brand Location is required")
      .nullable("Brand Location is required"),
    line1: yup
      .string()
      .nullable("Address line 1 is required")
      .required("Address line 1 is required"),
    line2: yup.string().nullable("Address line 1 is required"),
    country: yup
      .string()
      .nullable("Country is required")
      .required("Country is required"),
    state: yup
      .string()
      .nullable("State is required")
      .required("State is required"),
    pinCode: yup
      .string()
      .nullable("Pin Code is required")
      .required("Pin Code is required"),
    city: yup
      .string()
      .nullable("City is required")
      .required("City is required"),
    phone: yup
      .string()
      .nullable("Phone Number is required")
      .required("Phone Number is required"),
    // dialCode: yup
    //   .string()
    //   .nullable("Phone Code is required")
    //   .required("Phone Code is required"),
  });
  const intialValue = {
    bussinessName: "",
    brandLocationNumber: "",
    line1: "",
    line2: "",
    country: "",
    state: "",
    pinCode: "",
    city: "",
    phone: "",
    dialCode: "+1",
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: intialValue,
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const TextFieldStyle = { width: "50%" };
  const dispatch = useDispatch();
  const { locationData, editLocationData, postBusinessLocation } = useSelector(
    (state) => state.businessLocation
  );
  const { selectedBussinessDetails } = useSelector((state) => state.dashboard);
  const loggedInUserInfo = useSelector((state) => state.login.loggedInUserInfo);
  const franchiseName = useMemo(() => {
    if (locationData.businessId) {
      return loggedInUserInfo?.dashboardHierarchy?.businesses?.find(
        (buss) => buss.businessId === locationData.businessId
      )?.properties?.franchiseName;
    }
    return "";
  }, [locationData]);

  useEffect(() => {
    editData.id && dispatch(getLocationListById(editData.id));
  }, [editData]);
  useEffect(() => {
    if (editLocationData?.status === 200) {
      dispatch(setMessageKey("propertyValue"));

      dispatch(setMultiNotificationVariant("success"));
      dispatch(
        setMultiNotificationData([
          {
            propertyValue: `Location Updated Successfully`,
          },
        ])
      );

      handleCancel();
    } else if (editLocationData && editLocationData !== "" && editLocationData?.status !== 200) {
      dispatch(setMessageKey("message"));

      dispatch(setMultiNotificationVariant("error"));
      let errorArray = editLocationData?.data?.validationErrors?.length
        ? editLocationData?.data.validationErrors
        : [{ message: "Something went Wrong" }];
      dispatch(setMultiNotificationData(errorArray));
    }
  }, [editLocationData]);
  useEffect(() => {
    if (postBusinessLocation.status === 201) {
      dispatch(setMessageKey("propertyValue"));

      dispatch(setMultiNotificationVariant("success"));
      dispatch(
        setMultiNotificationData([
          {
            propertyValue: `Location Added Successfully`,
          },
        ])
      );

      handleCancel();
    } else if (
      postBusinessLocation !== "" &&
      postBusinessLocation.status !== 200
    ) {
      dispatch(setMessageKey("message"));
      dispatch(setMultiNotificationVariant("error"));
      let errorArray = postBusinessLocation?.data?.validationErrors?.length
        ? postBusinessLocation?.data.validationErrors
        : [{ message: "Something went Wrong" }];
      dispatch(setMultiNotificationData(errorArray));
    }
  }, [postBusinessLocation]);
  useEffect(() => {
    locationData.accountId && locationData !== ""
      ? reset(getLocationByIdDataMapper(locationData))
      : reset();
  }, [locationData]);
  const submit = (data) => {
    if (editData.id) {
      dispatch(
        editLocationById(postUpdateLocationDataMapper(data, locationData))
      );
    } else {
      let objFetchData = postLocationDataMapper(
        data,
        selectedBussinessDetails.accountId,
        selectedBussinessDetails.businessId
      );
      dispatch(saveBusinessLocation(objFetchData));
    }
  };
  const handleCancel = () => {
    dispatch(setDialogSelectedEntity(""));

    dispatch(setPostLocationResponse(""));
    dispatch(setEditActionResponse(""));
    dispatch(setLoactinDataById(""));
    reset();
    setIndex(1);
    setSelected("");
    setIsEdit(false);
  };

  return (
    <div>
      <CommonFormHeadButton
        showSave={false}
        setIsEdit={setIsEdit}
        title={editData !== "" ? "Location Details" : "New Location"}
        submit={handleSubmit(submit)}
        setIndex={setIndex}
        setSelected={setSelected}
        handleCancel={handleCancel}
      />

      <Grid
        container
        sx={{
          paddingLeft: "1.5em",
          height: {
            md: "65vh",
            xs: "160vh",
            lg: "75vh",
            ipadMini: "70vh",
            ipadAir: "70vh",
            bigScreen: "69h",
            sm: "65vh",
          },
          maxHeight: {
            md: "65vh",
            sm: "65vh",
            xs: "160vh",
            lg: "75vh",
            ipadMini: "70vh",
            ipadAir: "70vh",
            bigScreen: "69vh",
          },
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&:hover": {
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#9e9e9e",
              borderRadius: "50px",
              ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.35)",
              },
            },
          },
        }}
      >
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} spacing={2}>
              <Typography variant="h7">Brand Location Number</Typography>
              <TextField
                disabled
                id="brandLocationNum"
                sx={TextFieldStyle}
                size="small"
                placeholder="Enter Brand Location Number"
                {...register("brandLocationNumber")}
              />
              <Typography variant="body1" style={{ color: "#f34f4c" }}>
                {errors.brandLocationNumber?.message}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"}>
              <Typography variant="h7">Location Name</Typography>
              <Stack
                direction={"row"}
                flexGrow={1}
                alignItems={"center"}
                spacing={2}
              >
                <Typography>
                  {selectedBussinessDetails.properties.franchiseName}
                </Typography>
                <TextField
                  disabled
                  id="name"
                  // sx={TextFieldStyle}
                  size="small"
                  placeholder="Enter Name"
                  {...register("bussinessName")}
                />
              </Stack>
              {errors.bussinessName ? (
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.bussinessName?.message}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} spacing={2}>
              <Typography variant="h7">Address</Typography>
              <TextField
                disabled
                id="line1"
                sx={TextFieldStyle}
                size="small"
                fullwidth="true"
                placeholder="Enter Address Line 1"
                {...register("line1")}
              />
              {errors.line1 ? (
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.line1?.message}
                </Typography>
              ) : null}
              <TextField
                disabled
                id="line2"
                sx={TextFieldStyle}
                size="small"
                fullwidth="true"
                placeholder="Enter Address Line 2"
                {...register("line2")}
              />
              {errors.line2 ? (
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.line2?.message}
                </Typography>
              ) : null}
              <TextField
                disabled
                id="city"
                sx={TextFieldStyle}
                size="small"
                fullwidth="true"
                placeholder="Enter City"
                {...register("city")}
              />
              {errors.city ? (
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.city?.message}
                </Typography>
              ) : null}
              <TextField
                disabled
                id="state"
                sx={TextFieldStyle}
                size="small"
                fullwidth="true"
                placeholder="Enter State"
                {...register("state")}
              />
              {errors.state ? (
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.state?.message}
                </Typography>
              ) : null}
              <TextField
                disabled
                id="country"
                sx={TextFieldStyle}
                size="small"
                fullwidth="true"
                placeholder="Enter Country"
                {...register("country")}
              />
              {errors.country ? (
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.country?.message}
                </Typography>
              ) : null}
              <TextField
                disabled
                id="zip"
                sx={TextFieldStyle}
                size="small"
                fullwidth="true"
                placeholder="Enter Zip"
                {...register("pinCode")}
              />
              {errors.pinCode ? (
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.pinCode?.message}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12} pb={2}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} spacing={2}>
              <Typography variant="h7">Phone Number</Typography>
              <Stack width={"100%"} direction={"row"}>
                <Stack width={"15%"} spacing={1}>
                  <DialoCodeAutocomplete
                    disabled
                    setValue={setValue}
                    defaultValue={
                      locationData !== "" && locationData.properties.dialCode
                        ? locationData.properties.dialCode
                        : "+1"
                    }
                  />
                </Stack>
                <Stack width={"35%"} spacing={1}>
                  <TextField
                    disabled
                    id="phone"
                    sx={{ marginLeft: "20px" }}
                    size="small"
                    {...register("phone")}
                    fullwidth="true"
                    placeholder="Enter Phone Number"
                    type="tel"
                  />
                  {errors.phone ? (
                    <Typography
                      variant="body1"
                      style={{
                        color: "#f34f4c",
                        flexWrap: "wrap",
                        width: "200px",
                        marginLeft: "2em",
                      }}
                    >
                      {errors.phone?.message}
                    </Typography>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default LocationForm;
