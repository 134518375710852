import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Profile from "../Profile/Profile";
import Location from "../Location/Location";
import Roles from "../Roles/Roles";
import { Button, Stack } from "@mui/material";
import { Grid } from "@mui/material";
import Individual from "../Individuals/Individual";
import { useDispatch, useSelector } from "react-redux";
import Signatory from "../Signatory/Signatory";
import { saveSignatoryThunk } from "../../SignatorySlice";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  editBusinessDetailsThunk,
  getBusinessDetails,
  setBussinessNotification,
} from "../../DashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { bussinessProfileEditDataMapper } from "../../../../utils/apiDataMappers";
import { setDialogSelectedEntity } from "../../BusinessLocationSlice";
import {
  setMessageKey,
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../../../components/Notification/Notification.slice";
import { isUptoGold } from "../../../../utils/miscFunctions";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BussinessDetails({
  setForm,
  value,
  setValue,
  setIsEdit,
  handleCloseDialog,
}) {
  const schema = yup.object().shape({
    legalName: yup.string().nullable().required("Business Name is required"),
    line1: yup.string().nullable().required("Address line 1 is required"),
    country: yup.string().nullable().required("country is required"),
    state: yup.string().nullable().required("State is required"),
    pinCode: yup.string().nullable().required("pinCode is required"),
    city: yup.string().nullable().required("City is required"),
    phone: yup.string().nullable().required("Phone Number is required"),
    // dialCode: yup.string().nullable().required("Phone Code is required"),
    federalIdentificationNumber: yup
      .string()
      .nullable()
      .required("Federal Tax Id is required"),
  });
  const ssoSchema = yup.object().shape({});
  const {
    loggedInUserInfo: { role, accountType, planType, allowOnlySSOLogin },
  } = useSelector((state) => state.login);
  const {
    register,
    handleSubmit,
    reset,
    setValue: setFormValue,
    formState: { errors },
  } = useForm({
    defaultValues: { legalName: "" },
    resolver: yupResolver(allowOnlySSOLogin ? ssoSchema : schema),
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const { selectedBussinessDetails } = useSelector((state) => state.dashboard);
  const [changedObj, setChangedObj] = React.useState({});
  const [profileMangeCheckbox, setProfileMangeCheckbox] = React.useState(false);

  const selectForm = {
    1: "locationNew",
    2: "roleNew",
    3: "individualsNew",
  };
  const selectFormNonAdmin = {
    0: "locationNew",
    1: "roleNew",
    2: "individualsNew",
  };
  const selectFormFranchisor = {
    1: "roleNew",
    2: "individualsNew",
  };
  const HeaderText = {
    roleNew: "Create a New Role",
    locationNew: "Create a New Location",
    individualsNew: "Create a New Individual",
  };
  React.useEffect(() => {
    if (selectedBussinessDetails.legalName) {
      setProfileMangeCheckbox(
        selectedBussinessDetails.properties.managerCanManageEmployee
      );
    }
  }, [selectedBussinessDetails]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCreateClick = () => {
    if (role !== "ROLE_ACCOUNT_ADMIN" && accountType !== "FRANCHISOR") {
      setForm(selectFormNonAdmin[value]);
      if (HeaderText[selectFormNonAdmin[value]]) {
        dispatch(
          setDialogSelectedEntity(HeaderText[selectFormNonAdmin[value]])
        );
      }
    } else if (accountType === "FRANCHISOR") {
      setForm(selectFormFranchisor[value]);
      if (HeaderText[selectFormFranchisor[value]]) {
        dispatch(
          setDialogSelectedEntity(HeaderText[selectFormFranchisor[value]])
        );
      }
    } else {
      setForm(selectForm[value]);
      if (HeaderText[selectForm[value]]) {
        dispatch(setDialogSelectedEntity(HeaderText[selectForm[value]]));
      }
    }
  };
  const submitForm = async (data) => {
    const result = await dispatch(
      editBusinessDetailsThunk({
        id: selectedBussinessDetails.businessId,
        data: bussinessProfileEditDataMapper(
          selectedBussinessDetails,
          data,
          profileMangeCheckbox
        ),
      })
    );
    const res = unwrapResult(result);
    if (res.status === 200) {
      dispatch(setMessageKey("propertyValue"));

      dispatch(setMultiNotificationVariant("success"));
      dispatch(
        setMultiNotificationData([
          {
            propertyValue: `Profile Updated Successfully`,
          },
        ])
      );
      dispatch(getBusinessDetails(selectedBussinessDetails.businessId));
    } else {
      dispatch(setMessageKey("message"));

      dispatch(setMultiNotificationVariant("error"));
      let errorArray = res?.data?.validationErrors?.length
        ? res?.data.validationErrors
        : [{ message: "Something went Wrong" }];
      dispatch(setMultiNotificationData(errorArray));
    }
  };
  const submitSignatory = async () => {
    if (Object.keys(changedObj).length) {
      let temp = 0;
      Object.keys(changedObj).forEach((ele, idx) => {
        if (idx === Object.keys(changedObj).length - 1) {
          temp = ele;
        } else {
          dispatch(
            saveSignatoryThunk({
              accountId: selectedBussinessDetails.accountId,
              employeeId: changedObj[ele],
              name: ele,
            })
          );
        }
      });
      const result = await dispatch(
        saveSignatoryThunk({
          accountId: selectedBussinessDetails.accountId,
          employeeId: changedObj[temp],
          name: temp,
        })
      );
      const res = unwrapResult(result);
      if (res.status === 200) {
        dispatch(
          setBussinessNotification({
            message: "Signatories Updated Successfully",
            openSnackbar: true,
            severity: "success",
          })
        );
      } else {
        dispatch(
          setBussinessNotification({
            message: "Something went wrong",
            openSnackbar: true,
            severity: "error",
          })
        );
      }
    }
    setChangedObj({});
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        {accountType === "FRANCHISOR" ? (
          <>
            {isUptoGold(planType) ? (
              <Tabs
                textColor="secondary"
                sx={{
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  id="profile"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 0 ? 600 : 400}
                    >
                      {"Profile"}
                    </Typography>
                  }
                  {...a11yProps(0)}
                />

                <Tab
                  id="individuals"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 1 ? 600 : 400}
                    >
                      Individuals
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            ) : (
              <Tabs
                textColor="secondary"
                sx={{
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  id="profile"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 0 ? 600 : 400}
                    >
                      {"Profile"}
                    </Typography>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  id="roles"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 1 ? 600 : 400}
                    >
                      Roles
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  id="individuals"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 2 ? 600 : 400}
                    >
                      Individuals
                    </Typography>
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  id="signatory"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 3 ? 600 : 400}
                    >
                      Signatory
                    </Typography>
                  }
                  {...a11yProps(3)}
                />
              </Tabs>
            )}
          </>
        ) : role === "ROLE_ACCOUNT_ADMIN" ? (
          <>
            {isUptoGold(planType) ? (
              <Tabs
                textColor="secondary"
                sx={{
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  id="profile"
                  sx={{
                    textTransform: "none",
                  }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 0 ? 600 : 400}
                    >
                      {"Profile"}
                    </Typography>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  id="locations"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 1 ? 600 : 400}
                    >
                      Locations
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            ) : (
              <Tabs
                textColor="secondary"
                sx={{
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  id="profile"
                  sx={{
                    textTransform: "none",
                  }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 0 ? 600 : 400}
                    >
                      {"Profile"}
                    </Typography>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  id="locations"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 1 ? 600 : 400}
                    >
                      Locations
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  id="roles"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 2 ? 600 : 400}
                    >
                      Roles
                    </Typography>
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  id="individuals"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 3 ? 600 : 400}
                    >
                      Individuals
                    </Typography>
                  }
                  {...a11yProps(3)}
                />
              </Tabs>
            )}
          </>
        ) : (
          <>
            {isUptoGold(planType) ? (
              <Tabs
                textColor="secondary"
                sx={{
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  id="locations"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 0 ? 600 : 400}
                    >
                      {"Locations"}
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            ) : (
              <Tabs
                textColor="secondary"
                sx={{
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  id="locations"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 0 ? 600 : 400}
                    >
                      {"Locations"}
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  id="roles"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 1 ? 600 : 400}
                    >
                      Roles
                    </Typography>
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  id="individuals"
                  sx={{ textTransform: "none" }}
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 2 ? 600 : 400}
                    >
                      Individuals
                    </Typography>
                  }
                  {...a11yProps(3)}
                />
              </Tabs>
            )}
          </>
        )}

        {value === 1 && role === "ROLE_ACCOUNT_ADMIN" ? null : value !== 0 &&
          role === "ROLE_ACCOUNT_ADMIN" &&
          !allowOnlySSOLogin ? (
          <Button
            id="createNew"
            variant="outlined"
            sx={{
              border: "1px solid #93c!important",
              color: "#93c",
              marginBottom: "0.5em",
              ":hover": { color: "white", backgroundColor: "#93c" },
            }}
            onClick={handleCreateClick}
          >
            Create New
            <AddCircleOutlineOutlinedIcon sx={{ marginLeft: "0.5rem" }} />
          </Button>
        ) : value !== 0 && value !== 3 && accountType === "FRANCHISOR" ? (
          <Button
            id="creatNew"
            variant="outlined"
            sx={{
              border: "1px solid #93c!important",
              color: "#93c",
              marginBottom: "0.5em",
              ":hover": { color: "white", backgroundColor: "#93c" },
            }}
            onClick={handleCreateClick}
          >
            Create New
            <AddCircleOutlineOutlinedIcon sx={{ marginLeft: "0.5rem" }} />
          </Button>
        ) : value === 2 &&
          role !== "ROLE_ACCOUNT_ADMIN" &&
          accountType !== "FRANCHISOR" &&
          selectedBussinessDetails?.properties?.managerCanManageEmployee ? (
          <Button
            id="creatNew"
            variant="outlined"
            sx={{
              border: "1px solid #93c!important",
              color: "#93c",
              marginBottom: "0.5em",
              ":hover": { color: "white", backgroundColor: "#93c" },
            }}
            onClick={handleCreateClick}
          >
            Create New
            <AddCircleOutlineOutlinedIcon sx={{ marginLeft: "0.5rem" }} />
          </Button>
        ) : (accountType === "FRANCHISOR" || role === "ROLE_ACCOUNT_ADMIN") &&
          value === 0 ? (
          <Stack
            direction={"row"}
            sx={{ marginBottom: "0.5em" }}
            justifyContent="space-between"
          >
            <Button
              id="cancel"
              variant="contained"
              sx={{
                padding: "5px 20px",
                marginRight: "1em",
                color: "black",
                backgroundColor: "#F5F7FA",
              }}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              id="save"
              onClick={handleSubmit(submitForm)}
              variant="contained"
              sx={{
                padding: "5px 20px",
                color: "white",
                backgroundColor: "#CD99E5",
                ":active": {
                  backgroundColor: "#8D2FBC",
                  color: "white",
                  border: "1px solid #CD99E5",
                },
              }}
            >
              Save
            </Button>
          </Stack>
        ) : value === 3 &&
          accountType === "FRANCHISOR" &&
          isUptoGold(planType) ? (
          <Stack
            direction={"row"}
            sx={{ marginBottom: "0.5em" }}
            justifyContent="space-between"
          >
            <Button
              id="cancel"
              variant="contained"
              sx={{
                padding: "5px 20px",
                marginRight: "1em",
                color: "black",
                backgroundColor: "#F5F7FA",
              }}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              id="save"
              onClick={submitSignatory}
              // sx={{
              //   borderRadius: 0,
              //   padding: "5px 20px",
              //   textTransform: "none",
              //   color: "white",
              //   backgroundColor: "#CD99E5",
              //   border: "1px solid #CD99E5",
              //   ":hover": {
              //     color: "white",
              //     backgroundColor: "#CD99E5",
              //     border: "1px solid #CD99E5",
              //     // borderColor: "black",
              //   },
              //   ":active": {
              //     backgroundColor: "#8D2FBC",
              //     color: "white",
              //     border: "1px solid #CD99E5",
              //   },
              // }}
              variant="contained"
              sx={{
                padding: "5px 20px",
                color: "white",
                backgroundColor: "#CD99E5",
                ":active": {
                  backgroundColor: "#8D2FBC",
                  color: "white",
                  border: "1px solid #CD99E5",
                },
              }}
            >
              Save
            </Button>
          </Stack>
        ) : null}
      </Box>

      {accountType === "FRANCHISOR" ? (
        <Box
          sx={{
            height: {
              lg: "75vh",
              ipadMini: "75vh",
              ipadAir: "75vh",
              bigScreen: "75vh",
            },
            maxHeight: {
              lg: "75vh",
              ipadMini: "75vh",
              ipadAir: "75vh",
              bigScreen: "75vh",
            },
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&:hover": {
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#9e9e9e",
                borderRadius: "50px",
                ":hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.35)",
                },
              },
            },
          }}
        >
          {isUptoGold(planType) ? (
            <>
              <TabPanel value={value} index={0}>
                <Profile
                  setValue={setFormValue}
                  profileMangeCheckbox={profileMangeCheckbox}
                  setProfileMangeCheckbox={setProfileMangeCheckbox}
                  register={register}
                  reset={reset}
                  errors={errors}
                />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Individual setForm={setForm} setIsEdit={setIsEdit} />
              </TabPanel>
            </>
          ) : (
            <>
              <TabPanel value={value} index={0}>
                <Profile
                  setValue={setFormValue}
                  profileMangeCheckbox={profileMangeCheckbox}
                  setProfileMangeCheckbox={setProfileMangeCheckbox}
                  register={register}
                  reset={reset}
                  errors={errors}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Roles setForm={setForm} setIsEdit={setIsEdit} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Individual setForm={setForm} setIsEdit={setIsEdit} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Signatory
                  changedObj={changedObj}
                  setChangedObj={setChangedObj}
                />
              </TabPanel>
            </>
          )}
        </Box>
      ) : role === "ROLE_ACCOUNT_ADMIN" ? (
        <Box
          sx={{
            height: {
              lg: "75vh",
              ipadMini: "75vh",
              ipadAir: "75vh",
              bigScreen: "75vh",
            },
            maxHeight: {
              lg: "75vh",
              ipadMini: "75vh",
              ipadAir: "75vh",
              bigScreen: "75vh",
            },
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&:hover": {
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#9e9e9e",
                borderRadius: "50px",
                ":hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.35)",
                },
              },
            },
          }}
        >
          {isUptoGold(planType) ? (
            <>
              <TabPanel value={value} index={0}>
                <Profile
                  setValue={setFormValue}
                  profileMangeCheckbox={profileMangeCheckbox}
                  setProfileMangeCheckbox={setProfileMangeCheckbox}
                  register={register}
                  reset={reset}
                  errors={errors}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Location setForm={setForm} setIsEdit={setIsEdit} />
              </TabPanel>
            </>
          ) : (
            <>
              <TabPanel value={value} index={0}>
                <Profile
                  setValue={setFormValue}
                  profileMangeCheckbox={profileMangeCheckbox}
                  setProfileMangeCheckbox={setProfileMangeCheckbox}
                  register={register}
                  reset={reset}
                  errors={errors}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Location setForm={setForm} setIsEdit={setIsEdit} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Roles setForm={setForm} setIsEdit={setIsEdit} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Individual setForm={setForm} setIsEdit={setIsEdit} />
              </TabPanel>
            </>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            height: {
              lg: "75vh",
              ipadMini: "75vh",
              ipadAir: "75vh",
              bigScreen: "75vh",
            },
            maxHeight: {
              lg: "75vh",
              ipadMini: "75vh",
              ipadAir: "75vh",
              bigScreen: "75vh",
            },
            overflowY: "scroll",
          }}
        >
          {isUptoGold(planType) ? (
            <>
              <TabPanel value={value} index={0}>
                <Location setForm={setForm} setIsEdit={setIsEdit} />
              </TabPanel>
            </>
          ) : (
            <>
              <TabPanel value={value} index={0}>
                <Location setForm={setForm} setIsEdit={setIsEdit} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Roles setForm={setForm} setIsEdit={setIsEdit} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Individual setForm={setForm} setIsEdit={setIsEdit} />
              </TabPanel>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
