import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import "./AgreementPage.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { resetOptIn } from "./AgreementSlice";
import { ReactComponent as Logo } from "../../assets/complianser-logo-with-text.svg";

const Agreement = ({
  page,
  role,
  handleSubmit,
  pagesData,
  handleOptIn,
  confirmUserThunk,
  open,
  setOpen,
  agreements,
}) => {
  const location = useLocation();
  const { loggedInUserInfo } = useSelector((state) => state.login);
  const { optIn } = useSelector((state) => state.agreement);
  const { isRevisedAgreement, setIsRevisedAgreement } = useState(true);
  const [isDashboard, setIsDashboard] = useState(
    window.location.href.split("/")[
      window.location.href.split("/").length - 1
    ] === "dashboard"
  );
  const [showNotice, setShowNotice] = useState(true);
  const dispatch = useDispatch();
  // const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    dispatch(resetOptIn());
    setOpen(false); // setOpen(false);
  };

  return (
    <>
      {!location.pathname.includes("reset") ? (
        <div
          className="login-container"
          style={{
            overflowY: "auto",
          }}
        >
          <div className="logo-container">
            <Logo className="logoStyle" width={"35%"} height={"35%"} />
          </div>
          <div className="login-card-container">
            <Card
              // className="media tabMargin"
              sx={{
                height: {
                  lg: isDashboard ? "62vh" : "570px",
                  xl: isDashboard ? "65vh" : "570px",
                  ipadAir: isDashboard ? "50vh" : "65vh",
                  ipadMini: isDashboard ? "58vh" : "65vh",
                  md: isDashboard ? "62vh" : "56vh",
                  ipadAirLand: isDashboard ? "60vh" : "56.3vh",
                  xs: isDashboard ? "70vh" : "60vh",
                  bigScreen: isDashboard ? "550px" : "580px",
                },
                overflow: { xs: "auto", lg: "auto", xl: "auto" },

                width: {
                  xs: 300,
                  sm: 500,
                  ipadMini: 600,
                  ipadAir: 750,
                  md: isDashboard ? 600 : 1000,
                },
                position: isDashboard ? "relative" : "absolute",
                marginTop: isDashboard
                  ? "0"
                  : {
                      xs: "-1.5rem",
                      sm: "13rem",
                      ipadMini: "12rem",
                      ipadAir: "17rem",
                      md: "14.6rem",
                      lg: "13.3rem",
                      xl: "13.3rem",
                      bigScreen: "14rem",
                      msm: "13rem",
                    },
              }}
            >
              <CardHeader
                title={pagesData[page].title}
                titleTypographyProps={{ fontSize: "14px" }}
                sx={{ backgroundColor: "#164e5f", color: "white" }}
              />
              <CardContent>
                <Grid container spacing={2} p={2}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xl={12}
                    sm={12}
                    xs={12}
                    pl={5}
                    sx={{ paddingTop: "-2rem", marginTop: "-1rem" }}
                  ></Grid>

                  <Grid item lg={12} md={12} xl={12} sm={12} xs={12} pl={5}>
                    <iframe
                      id="iFrame"
                      style={{
                        width: "100%",
                        minHeight: "25vh",
                        maxHeight: "25vh",
                        overflowY: "auto",
                      }}
                      src={pagesData[page].iFrameUrl}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} sm={12} xs={12} pl={5}>
                    <Stack direction={"row"}>
                      <Typography
                        variant="h6"
                        textAlign={"left"}
                        sx={{ fontSize: "17px", color: "#333" }}
                      >
                        By clicking I Agree Button Below
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} sm={12} xs={12} pl={5}>
                    <Stack>
                      <Stack direction={"row"}>
                        <Typography
                          variant="body2"
                          textAlign={"left"}
                          sx={{ fontSize: "13px", color: "#333" }}
                        >
                          1.
                        </Typography>
                        <Typography
                          variant="body2"
                          textAlign={"left"}
                          sx={{ fontSize: "0.8rem" }}
                          display="flex"
                        >
                          {pagesData[page].firstTerm}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"}>
                        <Typography
                          variant="body2"
                          textAlign={"left"}
                          sx={{ fontSize: "13px", color: "#333" }}
                        >
                          2.
                        </Typography>
                        <Typography
                          variant="body2"
                          textAlign={"left"}
                          sx={{ fontSize: "13px", color: "#333" }}
                        >
                          {pagesData[page].secondTerm}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
                    <Button
                      onClick={() => {
                        handleSubmit();
                      }}
                      size="small"
                      color="success"
                      variant="contained"
                      sx={{
                        width: "100%",
                        color: "white",
                        backgroundColor: "#29C34F",
                        ":hover": {
                          backgroundColor: "#2CAB4B",
                        },
                      }}
                    >
                      Agree
                    </Button>
                  </Grid>
                  {!loggedInUserInfo.signedESignAgreements && !isDashboard ? (
                    // true
                    // true
                    <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
                      <Stack
                        direction={"row"}
                        justifyContent="flex-start"
                        alignItems={"flex-start"}
                      >
                        <Checkbox
                          defaultChecked={false}
                          checked={optIn}
                          onChange={handleOptIn}
                          sx={{ marginRight: "0.5em", p: 0 }}
                        />
                        <Typography
                          variant="body2"
                          textAlign={"left"}
                          sx={{ fontSize: "13px", color: "#333" }}
                        >
                          Opt-in to receive one time passcode on my current
                          mobile number on record. If Opt-in is not selected,
                          all such texts will be send to my current email on
                          record
                        </Typography>
                      </Stack>
                    </Grid>
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          </div>
        </div>
      ) : (
        <Card
          // className="media tabMargin"
          sx={{
            height: {
              lg: isDashboard ? "62vh" : "570px",
              xl: isDashboard ? "65vh" : "570px",
              ipadAir: isDashboard ? "50vh" : "65vh",
              ipadMini: isDashboard ? "58vh" : "65vh",
              md: isDashboard ? "62vh" : "56vh",
              ipadAirLand: isDashboard ? "60vh" : "56.3vh",
              xs: isDashboard ? "70vh" : "60vh",
              bigScreen: isDashboard ? "550px" : "580px",
            },
            overflow: { xs: "auto", lg: "auto", xl: "auto" },

            width: {
              xs: 300,
              sm: 500,
              ipadMini: 600,
              ipadAir: 750,
              md: isDashboard ? 600 : 1000,
            },
            position: isDashboard ? "relative" : "absolute",
            marginTop: isDashboard
              ? "0"
              : {
                  xs: "-1.5rem",
                  sm: "13rem",
                  ipadMini: "12rem",
                  ipadAir: "17rem",
                  md: "14.6rem",
                  lg: "13.3rem",
                  xl: "13.3rem",
                  bigScreen: "14rem",
                  msm: "13rem",
                },
          }}
        >
          <CardHeader
            title={pagesData[page].title}
            titleTypographyProps={{ fontSize: "14px" }}
            sx={{ backgroundColor: "#164e5f", color: "white" }}
          />
          <CardContent>
            <Grid container spacing={2} p={2}>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                sm={12}
                xs={12}
                pl={5}
                sx={{ paddingTop: "-2rem", marginTop: "-1rem" }}
              ></Grid>

              <Grid item lg={12} md={12} xl={12} sm={12} xs={12} pl={5}>
                <iframe
                  id="iFrame"
                  style={{
                    width: "100%",
                    minHeight: "25vh",
                    maxHeight: "25vh",
                    overflowY: "auto",
                  }}
                  src={pagesData[page].iFrameUrl}
                />
              </Grid>
              <Grid item lg={12} md={12} xl={12} sm={12} xs={12} pl={5}>
                <Stack direction={"row"}>
                  <Typography
                    variant="h6"
                    textAlign={"left"}
                    sx={{ fontSize: "17px", color: "#333" }}
                  >
                    By clicking I Agree Button Below
                  </Typography>
                </Stack>
              </Grid>
              <Grid item lg={12} md={12} xl={12} sm={12} xs={12} pl={5}>
                <Stack>
                  <Stack direction={"row"}>
                    <Typography
                      variant="body2"
                      textAlign={"left"}
                      sx={{ fontSize: "13px", color: "#333" }}
                    >
                      1.
                    </Typography>
                    <Typography
                      variant="body2"
                      textAlign={"left"}
                      sx={{ fontSize: "0.8rem" }}
                      display="flex"
                    >
                      {pagesData[page].firstTerm}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"}>
                    <Typography
                      variant="body2"
                      textAlign={"left"}
                      sx={{ fontSize: "13px", color: "#333" }}
                    >
                      2.
                    </Typography>
                    <Typography
                      variant="body2"
                      textAlign={"left"}
                      sx={{ fontSize: "13px", color: "#333" }}
                    >
                      {pagesData[page].secondTerm}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  size="small"
                  color="success"
                  variant="contained"
                  sx={{
                    width: "100%",
                    color: "white",
                    backgroundColor: "#29C34F",
                    ":hover": {
                      backgroundColor: "#2CAB4B",
                    },
                  }}
                >
                  Agree
                </Button>
              </Grid>
              {!loggedInUserInfo.signedESignAgreements && !isDashboard ? (
                // true
                // true
                <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
                  <Stack
                    direction={"row"}
                    justifyContent="flex-start"
                    alignItems={"flex-start"}
                  >
                    <Checkbox
                      defaultChecked={false}
                      checked={optIn}
                      onChange={handleOptIn}
                      sx={{ marginRight: "0.5em", p: 0 }}
                    />
                    <Typography
                      variant="body2"
                      textAlign={"left"}
                      sx={{ fontSize: "13px", color: "#333" }}
                    >
                      Opt-in to receive one time passcode on my current mobile
                      number on record. If Opt-in is not selected, all such
                      texts will be send to my current email on record
                    </Typography>
                  </Stack>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Agreement;
