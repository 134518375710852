import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "../../../../components/Dialog/Dialog";
import Notification from "../../../../components/Notification/Notification";
import { setBussinessNotification } from "../../DashboardSlice";
import BussinessDetails from "../BussinessDetails/BussinessDetails";
import IndividulaCreateForm from "../Individuals/IndividulaCreateForm";
import IndividulaEditForm from "../Individuals/IndividulaEditForm";
import LocationForm from "../Location/LocationForm";
import RoleForm from "../Roles/RoleForm";
import { setDialogSelectedEntity } from "../../BusinessLocationSlice";
import { resetSignatory } from "../../SignatorySlice";
const BussinessDetailsDialog = ({
  openDialog,
  dialogTitle,
  handleOpenDialog,
  handleCloseDialog,
}) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const {
    loggedInUserInfo: { role, accountType },
  } = useSelector((state) => state.login);
  const [value, setValue] = React.useState(0);
  const { bussinessNotification } = useSelector((state) => state.dashboard);
  useEffect(() => {
    return () => {
      dispatch(resetSignatory());
      dispatch(setDialogSelectedEntity(""));
      dispatch(
        setBussinessNotification({
          message: "",
          openSnackbar: false,
          severity: "success",
        })
      );
    };
  }, []);
  const handleClose = () => {
    dispatch(
      setBussinessNotification({
        message: "",
        openSnackbar: false,
        severity: "success",
      })
    );
  };
  return (
    <Dialog
      open={openDialog}
      headerText={dialogTitle}
      handleClickOpen={handleOpenDialog}
      handleClose={handleCloseDialog}
      isBussiness
    >
      {bussinessNotification.openSnackbar ? (
        <Stack
          sx={{
            position: "fixed",
            zIndex: 12,
            direction: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Notification
            autoHideDuration={2000}
            vertical="top"
            message={bussinessNotification.message}
            openSnackbar={bussinessNotification.openSnackbar}
            severity={bussinessNotification.severity}
            handleClose={handleClose}
          />
        </Stack>
      ) : null}
      {form === "" ? (
        <BussinessDetails
          handleCloseDialog={handleCloseDialog}
          value={value}
          setValue={setValue}
          setForm={setForm}
          setIsEdit={setIsEdit}
        />
      ) : form === "roleNew" ? (
        <RoleForm
          editData={isEdit ? { id: isEdit } : ""}
          setIndex={setValue}
          setSelected={setForm}
          setIsEdit={setIsEdit}
        />
      ) : form === "locationNew" ? (
        <LocationForm
          editData={isEdit ? { id: isEdit } : ""}
          setIndex={setValue}
          setSelected={setForm}
          setIsEdit={setIsEdit}
        />
      ) : form === "individualsNew" ? (
        <IndividulaCreateForm
          setIndex={setValue}
          setSelected={setForm}
          setIsEdit={setIsEdit}
        />
      ) : form === "individualsEdit" ? (
        <IndividulaEditForm
          editData={isEdit}
          setIndex={setValue}
          setSelected={setForm}
          setIsEdit={setIsEdit}
        />
      ) : null}
    </Dialog>
  );
};

export default BussinessDetailsDialog;
