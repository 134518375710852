import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Loading from "../../../../components/Loading/Loading";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  getIndividualHistoryThunk,
  setIndividualHistory,
} from "../../EmployeeSlice";

const IndividualHistory = ({ id }) => {
  const dispatch = useDispatch();
  const { individualHistory } = useSelector((state) => state.employee);
  const [loading, setLoading] = useState(true);
  const columns = [
    { id: "time", label: "Time" },
    { id: "userid", label: "UserID" },
    { id: "username", label: "User Name" },
    { id: "time", label: "Action" },
    { id: "ip", label: "IP" },
    {
      id: "sessionid",
      label: "Session ID",
      maxWidth: individualHistory.width ? individualHistory.width : "150px",
    },
  ];
  useEffect(() => {
    if (id) {
      dispatch(getIndividualHistoryThunk(id));
    }
    return () => {
      dispatch(setIndividualHistory(""));
    };
  }, [id]);

  useEffect(() => {
    individualHistory.name ? setLoading(false) : setLoading(true);
  }, [individualHistory]);

  return (
    <div style={{ paddingTop: "0.5rem", overflowX: "hidden" }}>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            padding: "0px 0px 0px 4px",
          }}
        >
          <div id="divcontent" item style={{ lineHeight: "0.52857" }}>
            <div style={{ fontFamily: "inherit", lineHeight: "0.52857" }}>
              <div>
                <body
                  style={{
                    fontWeight: "lighter",
                    lineHeight: 1.1,
                    fontSize: "23px",
                    color: "#333",
                    padding: "5px 0 30px 0",
                    marginTop: "2rem",
                  }}
                >
                  {individualHistory.name}
                </body>
              </div>
              <div>
                <body
                  style={{
                    fontSize: "13px",
                    fontWeight: "lighter",
                    color: "#333",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    Hire Stage:
                  </span>{" "}
                  {individualHistory.hireStage}
                </body>
              </div>
              <div>
                <body
                  style={{
                    fontSize: "13px",
                    fontWeight: "lighter",
                    color: "#333",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    Business Name:
                  </span>{" "}
                  {individualHistory.businessName}
                </body>
              </div>
              <div>
                <body
                  style={{
                    fontSize: "13px",
                    fontWeight: "lighter",
                    color: "#333",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    Description:
                  </span>{" "}
                  {individualHistory.description}
                </body>
              </div>
              <div>
                <body
                  style={{
                    fontSize: "13px",
                    fontWeight: "lighter",
                    color: "#333",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    Created Time:
                  </span>{" "}
                  {individualHistory.createdTime}
                </body>
              </div>
              <div>
                <body
                  style={{
                    fontSize: "13px",
                    fontWeight: "lighter",
                    color: "#333",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    Time Zone:
                  </span>{" "}
                  UTC
                </body>
              </div>
            </div>
          </div>

          <div id="divcontents">
            <TableContainer>
              <TableContainer stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => {
                      return (
                        <TableCell
                          style={{
                            fontFamily: "sans-serif",
                            fontWeight: "lighter",
                            background: "#fafbfd",
                            fontSize: "13px",
                            color: "#333",
                            padding: "15px 60px 15px 0",
                            verticalAlign: "bottom",
                            minWidth: column.maxWidth,
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {column.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(individualHistory.auditTrailBean) &&
                    individualHistory.auditTrailBean.map((historytable) => (
                      <TableRow box-sizing={"border-box"}>
                        <TableCell
                          style={{
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                          }}
                        >
                          {historytable.createdTime}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                          }}
                        >
                          {historytable.loginId}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                          }}
                        >
                          {historytable.userName}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                          }}
                        >
                          {historytable.operation}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                          }}
                        >
                          {historytable.ipAddress}
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: individualHistory.width
                              ? individualHistory.width
                              : "150px",
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                            whiteSpace: "nowrap",
                            flexWrap: "nowrap",
                            overflowX: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {historytable.sessionId}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </TableContainer>
            </TableContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default IndividualHistory;
