import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FormTextField from "./FormTextField";
import { Box, Stack, Typography } from "@mui/material";
const FormFields = ({
  label,
  disabled = false,
  type = "text",
  placeholder,
  setValueName,
  errors,
  register,
  hideError,
  width,
  isMultiLine = false,
}) => {
  return (
    <Box sx={{ p: "0 1em" }}>
      <Stack
        width={"100%"}
        direction={"row"}
        justifyContent="flex-start"
        sx={{ marginTop: "1em" }}
      >
        <FiberManualRecordIcon
          sx={{
            marginTop: "0.18em",
            marginLeft: "-1em",
            marginRight: "0.5em",
            color: "#CCCCCC",
            height: "0.5em",
          }}
        />{" "}
        <Stack alignItems={"flex-start"} width={"100%"} spacing={2}>
          <Typography variant="body1" color={"#333"}>
            {label}
          </Typography>
          <FormTextField
            type={type}
            hideError={hideError}
            disabled={disabled}
            isMultiLine={isMultiLine}
            placeholder={placeholder}
            register={register}
            errors={errors}
            setValueName={setValueName}
            width={width}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default FormFields;
