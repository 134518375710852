import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  postMasterDepartment,
  getMasterDepartmentsListByBusiness,
  updateMasterDepartment,
  getMasterDepartmentsListById,
} from "../../services/department";

export const getMasterDepartmentListByBusinessId = createAsyncThunk(
  "businessLocation/getMasterDepartmentListById",
  async (businessId, { rejectWithValue }) => {
    try {
      const response = await getMasterDepartmentsListByBusiness(businessId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveMasterDepartment = createAsyncThunk(
  "businessLocation/createNewMasterDepartment",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postMasterDepartment(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getMasterDepartmentsById = createAsyncThunk(
  "businessLocation/getMasterDepartmentsById",
  async (locationId, { rejectWithValue }) => {
    try {
      const response = await getMasterDepartmentsListById(locationId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editMasterDepartment = createAsyncThunk(
  "businessLocation/editMasterDepartment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateMasterDepartment(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: false,
  isError: false,
  errorMessage: "",
  getMasterDepartmentsList: "",
  postMasterDepartment: [],
  masterDepartmentData: [],
  editMasterDepartmentData: "",
};

export const masterDepartmentSlice = createSlice({
  name: "masterDepartment",
  initialState,
  reducers: {
    setGetMasterDepartmentsList: (state, action) => {
      state.getMasterDepartmentsList = action.payload;
    },
    setPostMasterDepartment: (state, action) => {
      state.postMasterDepartment = action.payload;
    },
    setEditMasterDepartmentData: (state, action) => {
      state.editMasterDepartmentData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getMasterDepartmentListByBusinessId.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getMasterDepartmentListByBusinessId.fulfilled,
      (state, action) => {
        state.getMasterDepartmentsList = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      getMasterDepartmentListByBusinessId.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );

    builder.addCase(saveMasterDepartment.pending, (state, action) => {
      state.loading = true;
      state.loading = true;
    });
    builder.addCase(saveMasterDepartment.fulfilled, (state, action) => {
      state.postMasterDepartment = action.payload;
      state.loading = false;
    });
    builder.addCase(saveMasterDepartment.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    builder.addCase(getMasterDepartmentsById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMasterDepartmentsById.fulfilled, (state, action) => {
      state.masterDepartmentData = action.payload;
      state.loading = false;
    });
    builder.addCase(getMasterDepartmentsById.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    builder.addCase(editMasterDepartment.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editMasterDepartment.fulfilled, (state, action) => {
      state.editMasterDepartmentData = action.payload;
      state.loading = false;
    });
    builder.addCase(editMasterDepartment.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const {
  setPostMasterDepartment,
  setEditMasterDepartmentData,
  setGetMasterDepartmentsList,
} = masterDepartmentSlice.actions;
export default masterDepartmentSlice.reducer;
