import { ajaxHandler } from "./ajaxHandler";

//1
export const getSignatoriesList = async (accountId) => {
  const response = await ajaxHandler(
    "get",
    `focus451/account/signatories/${accountId}`
  );
  return response.data;
};

export const getFranchissorDeparmentsList = async (accountId) => {
  const response = await ajaxHandler(
    "get",
    `focus451/department/getAllFranchisorDepartmentsByAccountId/${accountId}`
  );
  return response.data;
};

export const getFranchissorLoctionList = async (accountId) => {
  const response = await ajaxHandler(
    "get",
    `focus451/business/getBusinessByFranchisorId?franchisorId=${accountId}`
  );
  return response;
};

export const createNewTemplateAPI = async (payload) => {
  const response = await ajaxHandler(
    "post",
    `focus451-document/template/`,
    payload
  );
  return response;
};

export const templateDocsDocumentUploader = async (payload) => {
  const response = await ajaxHandler(
    "post",
    `focus451-document/template/${payload.id}/content`,
    payload.data
  );
  return response;
};

export const suppoertingDocsUploader = async (payload) => {
  const response = await ajaxHandler(
    "post",
    `focus451-document/template/upload/${payload.id}/${payload.docId}`,
    payload.data
  );
  return response;
};

export const getAdminLocationList = async (accountId) => {
  const response = await ajaxHandler(
    "get",
    `focus451/business/findByAccount/${accountId}`
  );
  return response.data;
};

export const getSharedTemplate = async (accountId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/template/${accountId}/shared?page=0&limit=100`
  );
  return response;
};

export const getAdminRoleList = async (accountId) => {
  const response = await ajaxHandler(
    "get",
    `focus451/department/masterDepartment/${accountId}`
  );
  return response.data;
};
export const getFullTemplate = async (templateId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/template/${templateId}`
  );
  return response;
};

export const templateEdit = async (payload) => {
  const response = await ajaxHandler(
    "put",
    `focus451-document/template/${payload.id}`,
    payload.data
  );
  return response;
};

export const gettemplateDocsPDF = async (templateId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/template/${templateId}/content/`
  );
  return response;
};
//
