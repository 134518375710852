export const sortByAsceOrder = (key, tableBodyData) => {
  const result = [...tableBodyData].sort((a, b) => {
    if (typeof a[key] !== "number") {
      let first = a[key]?.toLowerCase();
      let second = b[key]?.toLowerCase();
      if (first < second) {
        return -1;
      }

      if (first > second) {
        return 1;
      }
    } else {
      if (a[key] < b[key]) {
        return -1;
      }

      if (a[key] > b[key]) {
        return 1;
      }
    }
    return 0;
  });
  return result;
  // }
};

export const sortByDescOrder = (key, tableBodyData) => {
  const result = [...tableBodyData].sort((a, b) => {
    if (typeof a[key] !== "number") {
      let first = a[key]?.toLowerCase();
      let second = b[key]?.toLowerCase();

      if (first < second) {
        return 1;
      }

      if (first > second) {
        return -1;
      }
    } else {
      if (a[key] < b[key]) {
        return 1;
      }

      if (a[key] > b[key]) {
        return -1;
      }
    }

    return 0;
  });
  return result;
  // }
};
