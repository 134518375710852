import "./Signup.css";
import "./Login.css";
import { ReactComponent as Logo } from "../../assets/complianser-logo-with-text.svg";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import SignUpFrom from "./SignUpFrom";
import Footer from "../../components/Footer/Footer";
import { useState } from "react";
const SignUp = () => {
  const [isSignUpFormPage, setIsSignUpFormPage] = useState(true);
  return (
    <>
      <div className="signup-container">
        <div
          className="sigup-left"
          style={{ flexWrap: "wrap", display: "flex", position: "relative" }}
        >
          {/*  */}
          <SignUpFrom isSignUpFormPage={isSignUpFormPage} />
        </div>
        <div className="sigup-right">
          <Stack sx={{ height: "100vh", justifyContent: "center" }}>
            <Logo className="logoStyle" height={"80px"} width={"95%"} />
          </Stack>
        </div>
      </div>
      <Stack
        sx={{
          height: "5vh",
          position: "sticky",
          bottom: "0",
          width: "100%",
        }}
      >
        <Divider />
        <Footer />
      </Stack>
    </>
  );
};

export default SignUp;
