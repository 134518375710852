import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/Loading/Loading";
import { signatoryMainTableDataMapper } from "../../../../utils/apiDataMappers";
import {
  getSignatoryMainTableThunk,
  setSignatoryMainTable,
} from "../../SignatorySlice";
import SignatorySelectTableWrapper from "./SignatorySelectTableWrapper";
import SignatoryTable from "./SignatoryTable";
import CloseIcon from "@mui/icons-material/Close";
const Signatory = ({ changedObj, setChangedObj }) => {
  const [dialogOpen, setdialogOpen] = useState(false);

  const {
    loggedInUserInfo: { accountId },
  } = useSelector((state) => state.login);
  const [selectDialogData, setSelectDialogData] = useState("");
  const { selectedBussinessDetails } = useSelector((state) => state.dashboard);
  const { signatoryMainTable } = useSelector((state) => state.signatory);
  const [bodyList, setBodyList] = useState("");
  const disptch = useDispatch();
  useEffect(() => {
    disptch(
      getSignatoryMainTableThunk({
        accountId: accountId,
        businessId: selectedBussinessDetails.businessId,
      })
    );
    return () => {
      disptch(setSignatoryMainTable(""));
    };
  }, []);
  useEffect(() => {
    signatoryMainTable !== "" &&
      setBodyList(signatoryMainTableDataMapper(signatoryMainTable));
  }, [signatoryMainTable]);
  const handleChange = (data) => {
    setSelectDialogData(data);
    setdialogOpen(true);
  };
  const listChanger = (data) => {
    let temp = JSON.parse(JSON.stringify(bodyList));
    let index = 0;
    temp.forEach((ele, idx) => {
      if (ele["Signatory"] === data.Signatory) {
        index = idx;
      }
    });
    temp[index] = data;
    setBodyList(temp);
  };
  const handleSelect = (data) => {
    if (data.Signatory) {
      if (changedObj[data.Signatory]) {
        if (changedObj[data.Signatory] !== data.employeeId) {
          let temp = JSON.parse(JSON.stringify(changedObj));
          temp[data.Signatory] = data.employeeId;
          setChangedObj(temp);
          listChanger(data);
        }
      } else {
        let temp = JSON.parse(JSON.stringify(changedObj));
        temp[data.Signatory] = data.employeeId;
        setChangedObj(temp);
        listChanger(data);
      }
    }
    setdialogOpen(false);
  };
  return (
    <>
      {dialogOpen ? (
        <Dialog
          open={dialogOpen}
          fullWidth
          PaperProps={{
            sx: {
              maxWidth: "none",
              width: "750px",
              maxHeight: "75%",
              minHeight: "75%",
            },
          }}
        >
          <DialogContent>
            <Stack>
              <Stack direction={"row"} width="100%">
                <Typography style={{ fontSize: "17px", color: "#333" }}>
                  Signatory -{" "}
                  {selectDialogData.Signatory
                    ? selectDialogData.Signatory
                    : "Signatory"}
                </Typography>
                <IconButton
                  id="open"
                  aria-label="close"
                  onClick={() => setdialogOpen(false)}
                  sx={{
                    position: "absolute",
                    right: 6,
                    top: 6,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack sx={{ marginTop: "-2rem" }}>
                <SignatorySelectTableWrapper
                  data={selectDialogData}
                  handleSelect={handleSelect}
                />
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      ) : null}
      <Stack ml={-3} mr={-3}>
        {" "}
        {bodyList !== "" ? (
          <SignatoryTable bodyList={bodyList} handleChange={handleChange} />
        ) : (
          <Stack
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "65vh" }}
          >
            <Loading />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default Signatory;
