export const PlansStrings = {
  PLATINUM: "PLATINUM",
  DIAMOND: "DIAMOND",
  GOLD: "GOLD",
  SILVER: "SILVER",
  BRONZE: "BRONZE",
  BASE: "BASE",
};
export const PlansStringsWithoutBase = {
  PLATINUM: "PLATINUM",
  DIAMOND: "DIAMOND",
  GOLD: "GOLD",
  SILVER: "SILVER",
  BRONZE: "BRONZE",
};

export const RoleStrings = {
  ADMIN: "ROLE_ACCOUNT_ADMIN",
  FRANCHISOR: "FRANCHISOR",
  USER: "ROLE_USER",
};

export const PlansStringsWithoudDiamond = {
  PLATINUM: "PLATINUM",
  GOLD: "GOLD",
  SILVER: "SILVER",
  BRONZE: "BRONZE",
  BASE: "BASE",
};
export const PlansStringsWithoudDiamondAndBase = {
  PLATINUM: "PLATINUM",
  GOLD: "GOLD",
  SILVER: "SILVER",
  BRONZE: "BRONZE",
};
