import React, { useState } from "react";
import { ajaxHandler } from "../../services/ajaxHandler";
import { useSelector } from "react-redux";

const usePaymentRetry = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const employeeId = useSelector(
    (state) => state.login.loggedInUserInfo.employeeId
  );
  const retryPayment = async ({ onSuccess }) => {
    setLoading(true);

    try {
      const response = await ajaxHandler(
        "post",
        `focus451/paymentGateway/getPayment?employeeId=${employeeId}`
      );

      if (response.status === 200) {
        setData(response.data);
        if (onSuccess) onSuccess();
      } else {
        throw new Error(response.data || "Failed to fetch data");
      }
    } catch (error) {
      setError(error.message || "An error occurred");
      
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, retryPayment };
};

export default usePaymentRetry;
