import React, { useState } from "react";
import { ajaxHandler } from "../../services/ajaxHandler";
import { useSelector } from "react-redux";

const useDeleteBankAccount = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const clientId = useSelector(
    (state) => state.login.loggedInUserInfo.accountId
  );
  const deleteAccount = async ({ plaidAccountId, onSuccess }) => {
    setLoading(true);

    try {
      const response = await ajaxHandler(
        "delete",
        `focus451/paymentGateway/account?accountId=${clientId}&plaidAccountId=${plaidAccountId}`
      );

      if (response.status === 200) {
        setData(response.data);
        if (onSuccess) onSuccess();
      } else {
        throw new Error(response.data || "Failed to fetch data");
      }
    } catch (error) {
      setError(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, deleteAccount };
};

export default useDeleteBankAccount;
