import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CommonFormHeadButton from "../../../../components/CommonTableHead/CommonFormHeadButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch, useSelector } from "react-redux";
import { postIndividualDataMapper } from "../../../../utils/apiDataMappers";
import { saveEmployee, setSaveEmployeeResponse } from "../../EmployeeSlice";
import DialoCodeAutocomplete from "../../../../components/FormComponents/DialoCodeAutocomplete";
import { setBussinessNotification } from "../../DashboardSlice";
import {
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../../../components/Notification/Notification.slice";
import { setDialogSelectedEntity } from "../../BusinessLocationSlice";
const IndividulaCreateForm = ({ setIndex, setSelected, setIsEdit }) => {
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .nullable("First Name is required")
      .required("First Name is required"),
    lastName: yup
      .string()
      .required("Last Name is required")
      .nullable("Last Name is required"),

    email: yup
      .string()
      .nullable("EmaIl address  required")
      .required("Email address  required"),
    loginId: yup
      .string()
      .nullable("Login Id is required")
      .required("Login Id is required"),
    employmentStage: yup
      .string()
      .nullable("Employment Stage is required")
      .required("Employment Stage is required")
      .test("defaultCheck", "Employment Stage is required", (option) => {
        if (option === "se") {
          return false;
        }
        return true;
      }),
    phone: yup
      .string()
      .nullable("Phone Number is required")
      .required("Phone Number is required"),
    // dialCode: yup
    //   .string()
    //   .nullable("Phone Code is required")
    //   .required("Phone Code is required"),
  });
  const intialValue = {
    employmentStage: "",
    dialCode: "+1",
    phone: "",
    loginId: "",
    email: "",
    userName: "",
    lastName: "",
    firstName: "",
  };
  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: intialValue,
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { selectedBussinessDetails } = useSelector((state) => state.dashboard);
  const {
    postEmployee,
    selectedChecks,
    selectedChecksManager,
    selectedDrawerManager,
  } = useSelector((state) => state.employee);
  const {
    loggedInUserInfo: { role, accountType },
  } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  useEffect(() => {
    if (postEmployee && postEmployee.status === 201) {
      dispatch(setMultiNotificationVariant("success"));
      dispatch(
        setMultiNotificationData([
          {
            propertyValue: `Individual Added Successfully`,
          },
        ])
      );
      handleCancel();
    } else if (postEmployee) {
      dispatch(setMultiNotificationVariant("error"));
      let errorArray = postEmployee?.data?.validationErrors?.length
        ? postEmployee?.data.validationErrors
        : [{ propertyValue: "Something went Wrong" }];
      dispatch(setMultiNotificationData(errorArray));
    }
  }, [postEmployee]);
  const submit = (data) => {
    dispatch(
      saveEmployee(
        postIndividualDataMapper(
          data,
          selectedBussinessDetails.accountId,
          selectedBussinessDetails.businessId
        )
      )
    );
  };
  const [loginId, setLoginId] = useState("");
  const [employmentStageState, setEmploymentStageState] = useState("se");
  const setLoginIdUsingNameFields = (data, idx) => {
    let temp = loginId.split(".");
    temp[idx] = data.toLowerCase();
    let final = temp.join(".");
    if (!final.includes(".")) {
      setLoginId(`${final}.`);
      setValue("loginId", `${final}.`);
    } else {
      setLoginId(temp.join("."));
      setValue("loginId", temp.join("."));
    }
  };

  const setLoginIdField = (data) => {
    setValue("loginId", data);
    setLoginId(data);
  };
  const handleEmplyomentStage = (data) => {
    setValue("employmentStage", data.target.value);
    setEmploymentStageState(data.target.value);
  };

  const handleCancel = () => {
    dispatch(setDialogSelectedEntity(""));

    dispatch(setSaveEmployeeResponse(""));
    reset();
    if (accountType === "FRANCHISOR") {
      setIndex(2);
    } else {
      setIndex(3);
    }
    setSelected("");
    setIsEdit(false);
  };
  return (
    <div>
      <CommonFormHeadButton
        setIsEdit={setIsEdit}
        title={"New Individual"}
        submit={handleSubmit(submit)}
        setIndex={setIndex}
        setSelected={setSelected}
        handleCancel={handleCancel}
      />
      <Grid
        container
        sx={{
          paddingLeft: "1.5em",

          // height: {
          //   md: "65vh",
          //   xs: "160vh",
          //   lg: "63vh",
          //   ipadMini: "70vh",
          //   ipadAir: "70vh",
          //   bigScreen: "69h",
          //   sm: "65vh",
          // },
          maxHeight: {
            md: "65vh",
            sm: "65vh",
            xs: "160vh",
            lg: "65vh",
            ipadMini: "70vh",
            ipadAir: "70vh",
            bigScreen: "69vh",
          },
          overflowY: "auto",
        }}
        marginLeft={2}
      >
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} spacing={2}>
              <Typography variant="h7">First Name</Typography>
              <TextField
                id="firstName"
                sx={{ width: { lg: "350px", xs: "200px", sm: "350px" } }}
                size="small"
                placeholder="Enter First Name"
                {...register("firstName", {
                  onChange: (e) => setLoginIdUsingNameFields(e.target.value, 0),
                })}
              />
              <Typography variant="body1" style={{ color: "#f34f4c" }}>
                {errors.firstName?.message}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} spacing={2}>
              <Typography variant="h7">Last Name</Typography>
              <TextField
                id="lastName"
                sx={{ width: { lg: "350px", xs: "200px", sm: "350px" } }}
                size="small"
                placeholder="Enter Last Name"
                {...register("lastName", {
                  onChange: (e) => setLoginIdUsingNameFields(e.target.value, 1),
                })}
              />
              <Typography variant="body1" style={{ color: "#f34f4c" }}>
                {errors.lastName?.message}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} spacing={2}>
              <Typography variant="h7">Email Address</Typography>
              <TextField
                id="email"
                sx={{ width: { lg: "350px", xs: "200px", sm: "350px" } }}
                size="small"
                type={"email"}
                placeholder="Enter Email"
                {...register("email")}
              />
              <Typography variant="body1" style={{ color: "#f34f4c" }}>
                {errors.email?.message}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} spacing={2}>
              <Typography variant="h7">Login Id</Typography>
              <TextField
                id="loginId"
                sx={{ width: { lg: "350px", xs: "200px", sm: "350px" } }}
                size="small"
                placeholder="Enter Login Id"
                value={loginId}
                onChange={(e) => setLoginIdField(e.target.value)}
              />
              <Typography variant="body1" style={{ color: "#f34f4c" }}>
                {errors.loginId?.message}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} spacing={2}>
              <Typography variant="h7">Mobile Number (sms capable)</Typography>
              <Stack width={"100%"} direction={"row"}>
                <Stack
                  width={{
                    xs: "30%",
                    sm: "15%",
                    ipadAir: "15%",
                    ipadMini: "16%",
                  }}
                  spacing={1}
                >
                  <DialoCodeAutocomplete setValue={setValue} />
                  <Typography
                    variant="body1"
                    style={{
                      color: "#f34f4c",
                      flexWrap: "wrap",
                      width: "100px",
                    }}
                  >
                    {/* {errors.dialCode?.message} */}
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <TextField
                    id="phone"
                    sx={{
                      width: { sm: "230px", xs: "100px" },
                      marginLeft: "20px",
                    }}
                    size="small"
                    {...register("phone")}
                    fullwidth="true"
                    placeholder="Enter Phone Number"
                    type="tel"
                  />
                  <Typography
                    style={{
                      color: "#f34f4c",
                      flexWrap: "wrap",
                      width: { sm: "230px", xs: "120px" },
                      marginLeft: "2em",
                    }}
                  >
                    {errors.phone?.message}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} spacing={2}>
              <Typography variant="h7">Employment Stage</Typography>
              <Stack direction={"row"}>
                <Stack spacing={1}>
                  <Select
                    id="employementStageDropdown"
                    defaultValue={"se"}
                    size="small"
                    sx={{ width: { lg: "350px", xs: "200px", sm: "350px" } }}
                    value={employmentStageState}
                    onChange={handleEmplyomentStage}
                  >
                    <MenuItem
                      id="selectEmpStage"
                      sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                      value="se"
                    >
                      Select Employment Stage
                    </MenuItem>
                    <MenuItem
                      id="preHire"
                      sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                      value={"PRE_HIRE"}
                    >
                      Pre-Hire
                    </MenuItem>
                    <MenuItem
                      id="prehire"
                      sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                      value="HIRE"
                    >
                      {" "}
                      Hire
                    </MenuItem>
                    <MenuItem
                      id="notHired"
                      sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                      value="NOT_HIRED"
                    >
                      Not Hired
                    </MenuItem>
                    <MenuItem
                      id="manage"
                      sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                      value="MANAGE"
                    >
                      Manage (Active)
                    </MenuItem>
                    <MenuItem
                      id="retire"
                      sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                      value="RETIRE"
                    >
                      {" "}
                      Retire (Active)
                    </MenuItem>
                    <MenuItem
                      id="terminated"
                      sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                      value="TERMINATED"
                    >
                      {" "}
                      Terminated
                    </MenuItem>
                  </Select>
                  <Typography variant="body1" style={{ color: "#f34f4c" }}>
                    {errors.employmentStage?.message}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default IndividulaCreateForm;
