import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  franchisorRegionData: {},
  templateProperties: {
    visibleToEmployee: "no", //not used
    employeeCanInitiate: "no", //not used
    employeeHireStageApplicable: "PRE_HIRE", //not used here
    multiAssignable: "no", //not used
    forMultiOwnerAssignment: false, // Is this template required to be signed by all business owners?
  },
  applicableAts: [],
  applicableAtToFranchiser: {}, //not used here
};

export const FranchisorBussinessSlice = createSlice({
  name: "franchisorBussiness",
  initialState,
  reducers: {
    setTemplatePropertiesFB: (state, action) => {
      state.templateProperties = action.payload;
    },
    resetFB: (state) => initialState,
    setForMultiOwnerAssignmentFB: (state, action) => {
      state.templateProperties.forMultiOwnerAssignment = action.payload;
    },

    setFranchisorRegionData: (state, action) => {
      state.franchisorRegionData = action.payload;
    },
  },
});

export const {
  setForMultiOwnerAssignmentFB,
  setFranchisorRegionData,
  resetFB,
  setTemplatePropertiesFB,
} = FranchisorBussinessSlice.actions;
export default FranchisorBussinessSlice.reducer;
