import { TextField, Typography } from "@mui/material";
import React from "react";

const FormTextField = ({
  type,
  disabled = false,
  placeholder,
  setValueName,
  register,
  errors,
  isMultiLine,
  hideError = false,
  width = "50%",
}) => {
  const TextFieldStyle = { width: { sm: width, xs: "75%" } };
  const isDisabled = disabled ? { disabled } : {};
  const rows = isMultiLine ? { rows: 4 } : {};
  return (
    <>
      <TextField
        inputProps={{ style: { fontSize: "13px" } }}
        id={setValueName}
        type={type}
        fullwidth="true"
        multiline={isMultiLine}
        sx={TextFieldStyle}
        size="small"
        placeholder={placeholder}
        {...register(setValueName)}
        {...rows}
        {...isDisabled}
      />
      {!hideError && errors[setValueName] ? (
        <Typography variant="body1" style={{ color: "#f34f4c" }}>
          {errors[setValueName]?.message}
        </Typography>
      ) : null}
    </>
  );
};

export default FormTextField;
