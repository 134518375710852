import { Stack } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/Loading/Loading";
import { signatorySelectTableDataMapper } from "../../../../utils/apiDataMappers";
import { getSignatorySelectTableThunk } from "../../SignatorySlice";
import SignatorySelectTable from "./SignatorySelectTable";

const SignatorySelectTableWrapper = ({ data, handleSelect }) => {
  const disptch = useDispatch();
  const { signatorySelectTable } = useSelector((state) => state.signatory);
  const {
    selectedBussinessDetails: { accountId, businessId },
  } = useSelector((state) => state.dashboard);

  const [bodyList, setBodyList] = useState("");
  useEffect(() => {
    disptch(
      getSignatorySelectTableThunk({
        accountId: accountId,
        businessId: businessId,
      })
    );
  }, []);

  useEffect(() => {
    signatorySelectTable &&
      signatorySelectTable !== "" &&
      signatorySelectTable.length &&
      setBodyList(signatorySelectTableDataMapper(signatorySelectTable));

    Array.isArray(signatorySelectTable) &&
      signatorySelectTable.length === 0 &&
      setBodyList([]);
  }, [signatorySelectTable]);
  const handleLocalSelect = (row) => {
    if (data.employeeId !== row.employeeId) {
      let temp = {};
      temp["employeeId"] = row.employeeId;
      temp["Individual"] = row.Individual;
      temp["Signatory"] = data.Signatory;
      handleSelect(temp);
    } else {
      handleSelect("");
    }
  };
  return (
    <Stack pt={6} >
      {bodyList !== "" ? (
        <SignatorySelectTable
          bodyList={bodyList}
          handleSelect={handleLocalSelect}
        />
      ) : (
        <Stack
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "65vh" }}
        >
          <Loading />
        </Stack>
      )}
    </Stack>
  );
};

export default SignatorySelectTableWrapper;
