import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  postBusinessLocation,
  getBusinessLocationByBusinessId,
  updateLocation,
  getLocationById,
} from "../../services/businessLocation";

export const getBusinessLocationListByBusinessId = createAsyncThunk(
  "businessLocation/businessDetailsAPI",
  async (businessId, { rejectWithValue }) => {
    try {
      const response = await getBusinessLocationByBusinessId(businessId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveBusinessLocation = createAsyncThunk(
  "businessLocation/createNewBusinessLocation",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postBusinessLocation(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getLocationListById = createAsyncThunk(
  "businessLocation/getLocationListById",
  async (locationId, { rejectWithValue }) => {
    try {
      const response = await getLocationById(locationId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editLocationById = createAsyncThunk(
  "businessLocation/editLocationById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateLocation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: false,
  isError: false,
  errorMessage: "",
  dialogSelectedEntity: "",
  getbusinessLocationsList: "",
  postBusinessLocation: "",
  locationData: "",
  editLocationData: "",
};

export const businessLocationSlice = createSlice({
  name: "businessLocation",
  initialState,
  reducers: {
    setDialogSelectedEntity: (state, action) => {
      state.dialogSelectedEntity = action.payload;
    },
    setGetbusinessLocationsList: (state, action) => {
      state.getbusinessLocationsList = action.payload;
    },
    setLoactinDataById: (state, action) => {
      state.locationData = action.payload;
    },
    setEditActionResponse: (state, action) => {
      state.editLocationData = action.payload;
    },
    setPostLocationResponse: (state, action) => {
      state.postBusinessLocation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getBusinessLocationListByBusinessId.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getBusinessLocationListByBusinessId.fulfilled,
      (state, action) => {
        state.getbusinessLocationsList = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      getBusinessLocationListByBusinessId.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );

    builder.addCase(saveBusinessLocation.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(saveBusinessLocation.fulfilled, (state, action) => {
      state.postBusinessLocation = action.payload;
      state.loading = false;
    });
    builder.addCase(saveBusinessLocation.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload;
    });

    builder.addCase(getLocationListById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLocationListById.fulfilled, (state, action) => {
      state.locationData = action.payload;
      state.loading = false;
    });
    builder.addCase(getLocationListById.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    builder.addCase(editLocationById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editLocationById.fulfilled, (state, action) => {
      state.editLocationData = action.payload;
      state.loading = false;
    });
    builder.addCase(editLocationById.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload;
    });
  },
});
export const {
  setGetbusinessLocationsList,
  setLoactinDataById,
  setEditActionResponse,
  setPostLocationResponse,
  setDialogSelectedEntity,
} = businessLocationSlice.actions;
export default businessLocationSlice.reducer;
