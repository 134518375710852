import { Box, Button, Divider, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import React from "react";

const CommonHeaderButtonIndividual = ({
  submit,
  handelTabChange,
  handleCancel,
  submitFinal,
  index,
}) => {
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Grid container mt={-2}>
      <Grid
        item
        lg={12}
        xs={12}
        sm={12}
        md={12}
        sx={{ direction: "column" }}
        paddingTop={"1em"}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          sx={{ display: "flex", flexWrap: "wrap" }}
        >
          <Box>
            <Tabs
              textColor="secondary"
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                },
              }}
              value={index}
              onChange={handelTabChange}
            >
              <Tab
                id="profile"
                label={
                  <Typography
                    fontSize={"15px"}
                    fontWeight={index === 0 ? 600 : 400}
                  >
                    Profile
                  </Typography>
                }
                {...a11yProps(0)}
              />
              <Tab
                id="locationAndRoles"
                label={
                  <Typography
                    fontSize={"15px"}
                    fontWeight={index === 1 ? 600 : 400}
                  >
                    Location and Roles
                  </Typography>
                }
                {...a11yProps(1)}
              />
              <Tab
                id="documents"
                label={
                  <Typography
                    fontSize={"15px"}
                    fontWeight={index === 2 ? 600 : 400}
                  >
                    Documents
                  </Typography>
                }
                {...a11yProps(2)}
              />
              <Tab
                id="history"
                label={
                  <Typography
                    fontSize={"15px"}
                    fontWeight={index === 3 ? 600 : 400}
                  >
                    History
                  </Typography>
                }
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
          <Stack
            direction={"row"}
            sx={{ marginBottom: "0.5em" }}
            justifyContent="space-between"
          >
            <Button
              id="cancel"
              variant="contained"
              sx={{
                padding: "5px 20px",
                marginRight: "1em",
                color: "black",
                backgroundColor: "#F5F7FA",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              id="save"
              variant="contained"
              onClick={submitFinal}
              sx={{
                padding: "5px 20px",
                color: "white",
                backgroundColor: "#CD99E5",
                ":active": {
                  backgroundColor: "#8D2FBC",
                  color: "white",
                  border: "1px solid #CD99E5",
                },
              }}
            >
              Save
            </Button>
            {/* <Button
              id="finalSubmitForm"
              sx={{ display: "none" }}
              onClick={submit}
            >
              Submitdata
            </Button> */}
          </Stack>
        </Stack>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default CommonHeaderButtonIndividual;
