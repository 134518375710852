import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useMemo, useState } from "react";
import { calculateTotalAmount, subscriptiondata } from "./temp";
import {
  PlansStrings,
  PlansStringsWithoudDiamond,
  PlansStringsWithoudDiamondAndBase,
} from "../../utils/stringToObj";
import theme from "../../theme";
import { calculatePrice } from "./utils";
import { useSelector } from "react-redux";
import useCurrentPrice from "../../api/hooks/useCurrentPrice";
import Loading from "../../components/Loading/Loading";
const SubscriptionTable = ({
  headList = ["Subscriptions", "Plan", "Monthly Dues"],
  isEdit = false,
  setPlanHQ,
}) => {
  const { data, loading, error, fetchData } = useCurrentPrice();
  const pricing = useMemo(() => {
    if (!data?.length) return null;
    let obj = { Location: {}, HQ: {} };
    data?.length &&
      data?.forEach((item) => {
        obj[item.module][item.planType] = item.price;
      });
    return obj;
  }, [data]);
  const { locationDetailsForMap } = useSelector((state) => state.dashboard);
  const { planType } = useSelector((state) => state.login.loggedInUserInfo);
  const locationPlan = useSelector(
    (state) =>
      state?.dashboard?.franchises[0]?.accountBean?.properties?.planType ||
      PlansStrings.BASE
  );
  const [localBodyList, setLocalBodyList] = useState(
    subscriptiondata(
      planType,
      locationDetailsForMap.length,
      locationPlan,
      pricing
    )
  );
  const [totalAmount, setTotalAmount] = useState(
    "$" +
      calculateTotalAmount(
        localBodyList[0]["Monthly Dues"] + localBodyList[1]["Monthly Dues"]
      )
  );

  const handleChange = (e, idx) => {
    e.preventDefault();
    const temp = [...localBodyList];
    temp[idx].Plan = e.target.value;
    temp[idx]["Monthly Dues"] = `$${calculatePrice(
      e.target.value,
      locationDetailsForMap?.length || 0,
      pricing ? pricing["HQ"][e.target.value] : null
    )}`;
    setTotalAmount(
      "$" +
        calculateTotalAmount(temp[0]["Monthly Dues"] + temp[1]["Monthly Dues"])
    );
    setPlanHQ(e.target.value);
    setLocalBodyList(temp);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let calculatePrice = subscriptiondata(
      planType,
      locationDetailsForMap.length,
      locationPlan,
      pricing
    );
    setLocalBodyList(calculatePrice);
    setTotalAmount(
      "$" +
        calculateTotalAmount(
          calculatePrice[0]["Monthly Dues"] + calculatePrice[1]["Monthly Dues"]
        )
    );
  }, [pricing]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Paper
          sx={{
            width: "100%",
            boxShadow: 0,
          }}
        >
          <TableContainer
            sx={{
              overflowY: "auto",

              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: 10,
              },
              "&:hover": {
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#9e9e9e",
                  borderRadius: "50px",

                  ":hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.35)",
                  },
                },
              },
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headList.map((ele, idx) => {
                    return (
                      <TableCell
                        sx={{
                          width: "25%",
                          maxWidth: "25%",
                          justifyContent: "center",
                          flexDirection: "row-reverse",
                          padding: "0.6rem 1rem",
                          border: "0.5px  solid #EEF2F4",
                          backgroundColor: "#FBFBFC",
                        }}
                        key={idx}
                        scope="row"
                        component={"th"}
                      >
                        <Typography>{ele}</Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {localBodyList.length ? (
                  localBodyList.map((row, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          {Object.keys(row).map((key) => (
                            <React.Fragment key={key}>
                              {key === "Plan" && index == 0 ? (
                                <TableCell
                                  sx={{
                                    color: theme.palette.common.grey,
                                    width: "25%",
                                    maxWidth: "25%",
                                    border: "0.5px  solid #EEF2F4",
                                    padding: isEdit ? "0.5em 1em" : "1em 1em",
                                  }}
                                >
                                  {index === 1 ? (
                                    <Select
                                      id="demo-simple-select"
                                      value={row[key]}
                                      fullWidth
                                      size="small"
                                      onChange={(e) => handleChange(e, index)}
                                    >
                                      {Object.keys(
                                        PlansStringsWithoudDiamond
                                      ).map((ele, index) => (
                                        <MenuItem
                                          key={ele}
                                          value={
                                            PlansStringsWithoudDiamond[ele]
                                          }
                                        >
                                          <Typography>
                                            {PlansStringsWithoudDiamond[ele]}
                                          </Typography>
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  ) : (
                                    <Select
                                      id="demo-simple-select"
                                      value={row[key]}
                                      fullWidth
                                      size="small"
                                      onChange={(e) => handleChange(e, index)}
                                    >
                                      {Object.keys(
                                        PlansStringsWithoudDiamondAndBase
                                      ).map((ele, index) => (
                                        <MenuItem
                                          key={ele}
                                          value={
                                            PlansStringsWithoudDiamondAndBase[
                                              ele
                                            ]
                                          }
                                        >
                                          <Typography>
                                            {
                                              PlansStringsWithoudDiamondAndBase[
                                                ele
                                              ]
                                            }
                                          </Typography>
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell
                                  sx={{
                                    color: theme.palette.common.grey,
                                    width: "25%",
                                    maxWidth: "25%",
                                    border: "0.5px  solid #EEF2F4",
                                    padding: isEdit ? "0.5em 1em" : "2em 1em",
                                  }}
                                >
                                  <Typography>{row[key]}</Typography>
                                </TableCell>
                              )}
                            </React.Fragment>
                          ))}
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  <TableRow
                    sx={{
                      border: "0.5px  solid #EEF2F4",
                      height: "2vh",
                      padding: "0.5em 2em",
                    }}
                  >
                    <Typography sx={{ padding: "1em 10%" }}>
                      Nothing To Show here
                    </Typography>
                  </TableRow>
                )}
              </TableBody>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "none" }}></TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    Total Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      borderLeft: "0.5px  solid #EEF2F4",
                      borderRight: "0.5px  solid #EEF2F4",
                      borderBottom: "0.5px  solid #EEF2F4",
                    }}
                  >
                    {totalAmount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  );
};

export default SubscriptionTable;
