import React, { useState } from "react";
import { ajaxHandler } from "../../services/ajaxHandler";
import { paymentListdataMapper } from "../../pages/Billing/utils";

const useCurrentPrice = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await ajaxHandler("get", `focus451/pricing`);

      if (response.status === 200) {
        setData(response.data);
      } else {
        throw new Error(response.data || "Failed to fetch data");
      }
    } catch (error) {
      setError(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchData };
};

export default useCurrentPrice;
