import React from "react";
import Dialog from "../../../../components/Dialog/Dialog";
import BussinessCreateForm from "../BussinessForm/BussinessCreateForm";

const BusinessCreateDialog = ({
  openDialog,

  handleOpenDialog,
  handleCloseDialog,
}) => {
  return (
    <Dialog
      isCreate
      open={openDialog}
      headerText={"New Business"}
      handleClickOpen={handleOpenDialog}
      handleClose={handleCloseDialog}
    >
      <BussinessCreateForm handleCancel={handleCloseDialog} />
    </Dialog>
  );
};

export default BusinessCreateDialog;
