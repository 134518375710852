import { useEffect, useState } from "react";
import {
  enableAllSteps,
  disableAllSteps,
} from "../../../../components/StepperTabs/StepperTabs.slice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/Loading/Loading";
import {
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
  setTemplateDesc,
  setTemplateName,
  setTemplateType,
} from "../../../Templates/TemplateSlice";

const BasicDetails = ({ templateType }) => {
  const radioHashMap = {
    1: "FRANCHISOR_EMPLOYEE",
    2: "EMPLOYEE",
    3: "FRANCHISOR_BUSINESS",
    4: "BUSINESS",
  };
  const dispatch = useDispatch();
  const {
    basicDetailLoader,
    selectedTemplateRow: { action = "", rowdata = {} },
  } = useSelector((state) => state.templates);
  const loggedInUserInfo = useSelector((state) => state.login.loggedInUserInfo);
  const [radioValue, setRadioValue] = useState(
    loggedInUserInfo.accountType == "ROLE_FRANCHISOR_ACCOUNT_ADMIN"
      ? radioHashMap[1]
      : radioHashMap[2]
  );
  // const newTemplateData = store.getState().templates.newTemplateData || {}
  const { newTemplateData } = useSelector((state) => state.templates);

  useEffect(() => {
    if (
      (rowdata?.name && rowdata?.name !== "") ||
      newTemplateData?.name !== ""
    ) {
      dispatch(enableAllSteps());
    } else {
      dispatch(disableAllSteps());
    }
  }, [newTemplateData]);

  const setTempName = (e) => {
    dispatch(setTemplateName(e.target.value));
  };

  const setDesc = (e) => {
    dispatch(setTemplateDesc(e.target.value));
  };

  const setRadioVal = (value) => {
    setRadioValue(value);
    dispatch(setTemplateType(value));
  };

  return (
    <div>
      {basicDetailLoader ? (
        <Loading />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="left"
            sx={{
              width: "100%",
              height: "100%",
              display: "block",
            }}
          >
            <Grid item>
              <Stack
                width={"100%"}
                direction={"row"}
                justifyContent="left"
                alignItems="left"
                sx={{ marginTop: "1em" }}
              >
                <FiberManualRecordIcon
                  sx={{
                    marginTop: "0.18em",
                    //marginLeft: "-1em",
                    marginRight: "0.5em",
                    color: "#CCCCCC",
                    height: "0.5em",
                  }}
                />
                <Stack width={"100%"} spacing={1}>
                  <Typography
                    sx={{ textAlign: "left" }}
                    fontWeight={600}
                    variant="body2"
                  >
                    Title
                  </Typography>
                  {templateType === "view" ? (
                    <TextField
                      sx={{ width: "40%", background: "#f9fafb" }}
                      size="small"
                      defaultValue={rowdata.name}
                      disabled={true}
                    />
                  ) : (
                    <Stack width={"100%"} spacing={2}>
                      <TextField
                        sx={{ width: "40%" }}
                        size="small"
                        placeholder="Individual Arbitration Agreement"
                        onChange={setTempName}
                        disabled={templateType === "edit" ? true : false}
                        defaultValue={newTemplateData?.name}
                        required
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Divider sx={{ borderStyle: "dotted", marginTop: "8px" }} />
            </Grid>

            <Grid item>
              <Stack
                width={"100%"}
                direction={"row"}
                justifyContent="left"
                alignItems="left"
                sx={{ marginTop: "1em" }}
              >
                <FiberManualRecordIcon
                  sx={{
                    marginTop: "0.18em",
                    //marginLeft: "-1em",
                    marginRight: "0.5em",
                    color: "#CCCCCC",
                    height: "0.5em",
                  }}
                />
                <Stack width={"100%"} spacing={1}>
                  <Typography
                    sx={{ textAlign: "left" }}
                    fontWeight={600}
                    variant="body2"
                  >
                    Description
                  </Typography>
                  {templateType === "view" ? (
                    <TextField
                      sx={{ width: "40%", background: "#f9fafb" }}
                      size="small"
                      defaultValue={rowdata.description}
                      disabled={true}
                      multiline={true}
                      rows={4}
                      fullWidth
                      placeholder="Describe the purpose of this template (Optional)"
                    />
                  ) : (
                    <TextField
                      sx={{ width: "40%" }}
                      size="small"
                      multiline={true}
                      rows={4}
                      fullWidth
                      placeholder="Describe the purpose of this template (Optional)"
                      disabled={templateType === "edit" ? true : false}
                      onChange={setDesc}
                      defaultValue={newTemplateData?.description}
                    />
                  )}
                </Stack>
              </Stack>
              <Divider sx={{ borderStyle: "dotted", marginTop: "8px" }} />
            </Grid>

            {loggedInUserInfo.accountType === "FRANCHISOR" ? null : (
              <Grid item>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent="left"
                  alignItems="left"
                  sx={{ marginTop: "1em" }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      marginTop: "0.18em",
                      //marginLeft: "-1em",
                      marginRight: "0.5em",
                      color: "#CCCCCC",
                      height: "0.5em",
                    }}
                  />
                  <Stack width={"100%"} spacing={1}>
                    <Typography
                      sx={{ textAlign: "left" }}
                      fontWeight={600}
                      variant="body2"
                    >
                      This template is applicable to?
                    </Typography>

                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={newTemplateData.templateProperties.templateType}
                      onChange={(e) => setRadioVal(e.target.value)}
                    >
                      {templateType === "view" ? (
                        <>
                          {loggedInUserInfo.accountType === "FRANCHISOR" ? (
                            <FormControlLabel
                              disabled={true}
                              value="FRANCHISOR_EMPLOYEE"
                              control={<Radio />}
                              label={
                                <Typography variant="body2">
                                  Individuals{" "}
                                </Typography>
                              }
                            />
                          ) : (
                            <FormControlLabel
                              disabled={true}
                              value="EMPLOYEE"
                              control={<Radio />}
                              label={
                                <Typography variant="body2">
                                  Individuals{" "}
                                </Typography>
                              }
                            />
                          )}
                          <Typography
                            variant="p"
                            color="textSecondary"
                            sx={{
                              textAlign: "left",
                              fontSize: "0.8rem",
                              paddingLeft: "2rem",
                            }}
                          >
                            This template will be assigned to an individual who
                            will take action/ sign as per template properties.
                          </Typography>

                          {loggedInUserInfo.accountType === "FRANCHISOR" ? (
                            <FormControlLabel
                              disabled={true}
                              value="FRANCHISOR_BUSINESS"
                              control={<Radio />}
                              label={
                                <Typography variant="body2">
                                  Business{" "}
                                </Typography>
                              }
                            />
                          ) : (
                            <FormControlLabel
                              disabled={true}
                              value="BUSINESS"
                              control={<Radio />}
                              label={
                                <Typography variant="body2">
                                  Business{" "}
                                </Typography>
                              }
                            />
                          )}
                          <Typography
                            variant="p"
                            color="textSecondary"
                            sx={{
                              textAlign: "left",
                              fontSize: "0.8rem",
                              paddingLeft: "2rem",
                            }}
                          >
                            This templatewill be assigned to the business owner
                            who is authorized to take action/ sign on behalf of
                            the business.
                          </Typography>
                        </>
                      ) : (
                        <>
                          <FormControlLabel
                            disabled={templateType === "edit" ? true : false}
                            value={
                              loggedInUserInfo.accountType === "FRANCHISOR"
                                ? "FRANCHISOR_EMPLOYEE"
                                : "EMPLOYEE"
                            }
                            control={<Radio />}
                            label={
                              <Typography variant="body2">
                                Individuals{" "}
                              </Typography>
                            }
                          />
                          <Typography
                            variant="p"
                            color="textSecondary"
                            sx={{
                              textAlign: "left",
                              fontSize: "0.8rem",
                              paddingLeft: "2rem",
                            }}
                          >
                            This template will be assigned to an individual who
                            will take action/ sign as per template properties.
                          </Typography>

                          <FormControlLabel
                            disabled={templateType === "edit" ? true : false}
                            value={
                              loggedInUserInfo.accountType === "FRANCHISOR"
                                ? "FRANCHISOR_BUSINESS"
                                : "BUSINESS"
                            }
                            control={<Radio />}
                            label={
                              <Typography variant="body2">Business </Typography>
                            }
                          />
                          <Typography
                            variant="p"
                            color="textSecondary"
                            sx={{
                              textAlign: "left",
                              fontSize: "0.8rem",
                              paddingLeft: "2rem",
                            }}
                          >
                            This templatewill be assigned to the business owner
                            who is authorized to take action/ sign on behalf of
                            the business.
                          </Typography>
                        </>
                      )}
                    </RadioGroup>
                  </Stack>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default BasicDetails;
