const planPrice = {
  PLATINUM: 4,
  GOLD: 3,
  SILVER: 2,
  BRONZE: 1,
  BASE: 0,
};

export const calculatePrice = (plan, noOfLocation, price = null) => {
  if (price) {
    return price * noOfLocation;
  }
  return planPrice[plan] * noOfLocation;
};

export const paymentListdataMapper = (data) => {
  let paymentList = [];
  data.forEach((item) => {
    paymentList.push({
      "HQ Plan": item.hqPlanType,
      "Location Plan": item.locationPlanType,
      "#Loc": item.locationCount,
      "Transaction Date": item.transactionDate,
      "Subscription Change date": item.subscriptionChangeDate,
      "Total Amount Charged": item.amount,
      "Payment Account Used": item.bankAccountName,
      Status: `${item.paymentStatus}  ${
        item.reason === null ? "" : item.reason
      }`,
    });
  });
  return paymentList;
};
