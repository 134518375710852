import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ManagerLocation from "./ManagerLocation";
import { useSelector } from "react-redux";

const IndividualLocationRoleEdit = ({
  isSSOLogin,
  roleData = [],
  setValue,
  errorField,
  setErrorField,
  selectedChecks,
  setSelectedChecks,
  selectedChecksManager,
  setSelectedChecksManager,
  selectParentCheck,
  setSelectParentCheck,
  selectRole,
  setSelectRole,
}) => {
  const {
    loggedInUserInfo: { allowOnlySSOLogin },
  } = useSelector((state) => state.login);
  const handleChangeParentCheckBox = (ele) => {
    let tempData = JSON.parse(JSON.stringify(selectedChecks));
    if (tempData[ele.locationId]) {
      delete tempData[ele.locationId];
      setSelectedChecks(tempData);
    } else {
      tempData[ele.locationId] = { locationId: ele.locationId };
      setSelectedChecks(tempData);
    }
  };
  const handleChangeChildCheckBox = (data, locationId) => {
    let tempData = JSON.parse(JSON.stringify(selectedChecks));
    if (tempData[locationId][data.departmentId]) {
      delete tempData[locationId][data.departmentId];
      setSelectedChecks(tempData);
    } else {
      if (errorField[locationId]) {
        let tempDataError = JSON.parse(JSON.stringify(errorField));
        delete tempDataError[locationId];
        setErrorField(tempDataError);
      }
      tempData[locationId][data.departmentId] = {
        locationId: locationId,
        departmentId: data.departmentId,
      };
      setSelectedChecks(tempData);
    }
  };

  return (
    <Grid container>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <Box sx={{ p: "0 1em" }}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} spacing={2}>
              <Typography variant="h7">
                Assign Individual to Locations and Roles
              </Typography>
              <Box>
                <Table
                  sx={{
                    width: "100%",
                    p: 0,
                    maxHeight: "35vh",
                    overflowY: "auto",
                  }}
                >
                  {roleData.length && Array.isArray(roleData)
                    ? roleData.map((ele, idx) => {
                        return (
                          <TableBody
                            key={idx}
                            sx={{
                              width: "100%",
                              border:
                                errorField[ele.locationId] && !isSSOLogin
                                  ? "0.5px solid #f34f4c"
                                  : "",
                            }}
                          >
                            <TableRow sx={{ width: "100%" }}>
                              <TableCell
                                sx={{
                                  borderTop:
                                    idx === 0 ? "0.5px solid #F3F3F3" : "",
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  padding: "0.2em 2em",
                                  alignItems: "center",
                                  backgroundColor:
                                    selectedChecks &&
                                    selectedChecks[ele.locationId]
                                      ? "#F8FBFA"
                                      : "white",
                                }}
                              >
                                <Checkbox
                                  id={`role-${idx}`}
                                  disabled={allowOnlySSOLogin}
                                  checked={
                                    selectedChecks &&
                                    selectedChecks[ele.locationId]
                                  }
                                  onChange={() =>
                                    handleChangeParentCheckBox(ele)
                                  }
                                />
                                <Typography color={"#666"} variant="body1">
                                  {ele.name}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            {!isSSOLogin &&  selectedChecks[ele.locationId] &&
                            selectedChecks &&
                            selectedChecks[ele.locationId] ? (
                              <>
                                {ele.departments.map((element, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      sx={{ width: "100%" }}
                                    >
                                      <TableCell
                                        sx={{
                                          borderTop:
                                            idx === 0
                                              ? "0.5px solid #F3F3F3"
                                              : "",
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          padding: "0.2em 4em",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Checkbox
                                          id={`location-${index}`}
                                          disabled={allowOnlySSOLogin}
                                          checked={
                                            selectedChecks &&
                                            selectedChecks[ele.locationId][
                                              element.departmentId
                                            ]
                                          }
                                          onChange={() =>
                                            handleChangeChildCheckBox(
                                              element,
                                              ele.locationId
                                            )
                                          }
                                        />
                                        <Typography
                                          color={"#999"}
                                          variant="body1"
                                        >
                                          {element.name}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </>
                            ) : null}
                          </TableBody>
                        );
                      })
                    : null}
                </Table>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <Box sx={{ p: "0 1em" }}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack width={"100%"} marginBottom={"1rem"} spacing={2}>
              <Typography variant="h7">Individual Role</Typography>
              <Stack width={"55%"}>
                <Select
                  id="selectRoleDropdown"
                  value={selectRole}
                  onChange={(e) => {
                    setValue("selectRole", e.target.value);
                    setSelectRole(e.target.value);
                  }}
                  size="small"
                >
                  <MenuItem
                    id="businessManager"
                    sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                    value={"Bussiness"}
                  >
                    Business Manager
                  </MenuItem>
                  <MenuItem
                    id="manager"
                    sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                    value={"Manager"}
                  >
                    Manager (assigns Locations and Roles below)
                  </MenuItem>
                  <MenuItem
                    id="regularManager"
                    sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                    value={"Regular"}
                  >
                    Regular Manager
                  </MenuItem>
                </Select>
              </Stack>
              {selectRole === "Manager" ? (
                <ManagerLocation
                  selectedChecks={selectedChecksManager}
                  setSelectedChecks={setSelectedChecksManager}
                  selectParentCheck={selectParentCheck}
                  setSelectParentCheck={setSelectParentCheck}
                  dataIndividual={roleData}
                />
              ) : null}
            </Stack>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default IndividualLocationRoleEdit;
