import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/Loading/Loading";
import SearchField from "../../../../components/SearchField/SearchField";
import TableCustome from "../../../../components/Table/TableCustome";
import { rolesDataMapper } from "../../../../utils/apiDataMappers";
import {
  getMasterDepartmentListByBusinessId,
  setGetMasterDepartmentsList,
  setPostMasterDepartment,
} from "../../DepartmentSlice";
import { setDialogSelectedEntity } from "../../BusinessLocationSlice";
import { getEmployeeList } from "../../../../services/employee";

const Roles = ({ setIsEdit, setForm }) => {
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState("");
  const { selectedBussinessDetails } = useSelector((state) => state.dashboard);
  const {
    loggedInUserInfo: { role, accountType, allowOnlySSOLogin },
  } = useSelector((state) => state.login);
  const { loading, getMasterDepartmentsList } = useSelector(
    (state) => state.masterDepartment
  );
  const headList = ["Roles", "Status", "Action"];
  const headListHideEdit = ["Roles", "Status"];
  const [bodyList, setBodyList] = useState("");
  useEffect(() => {
    dispatch(setPostMasterDepartment(""));
    setTimeout(() => {
      dispatch(
        getMasterDepartmentListByBusinessId(selectedBussinessDetails.businessId)
      );
    }, 500);
    return () => {
      dispatch(setGetMasterDepartmentsList(""));
    };
  }, []);
  useEffect(() => {
    if (
      getMasterDepartmentsList !== "" ||
      Array.isArray(getMasterDepartmentsList) ||
      getMasterDepartmentsList.length
    ) {
      setBodyList(rolesDataMapper(getMasterDepartmentsList));
    } else if (getMasterDepartmentsList == "") {
      setBodyList("");
    } else {
      setBodyList([]);
    }
  }, [getMasterDepartmentsList]);
  useEffect(() => {
    document.querySelector(".css-19kzrtu").style.padding = "1rem 0";
  }, []);
  const editAction = (data) => {
    setIsEdit(data);
    setForm("roleNew");
    dispatch(setDialogSelectedEntity(data["Roles"]));
  };
  const handleSearch = (data) => {
    if (data.search === "") {
      setBodyList(rolesDataMapper(getMasterDepartmentsList));
    } else {
      let temp = rolesDataMapper(getMasterDepartmentsList)?.filter((ele) =>
        ele.Roles.toLowerCase().includes(data.search.toLowerCase())
      );
      setBodyList(temp);
    }
  };
  return (
    <Grid container rowSpacing={2}>
      <Grid item lg={12} md={12} xs={12} sm={12}>
        <SearchField
          value={searchData}
          setValue={setSearchData}
          action={handleSearch}
          placeholder="Search Roles"
        />
      </Grid>
      {bodyList !== "" && !loading ? (
        <Grid item lg={12} md={12} xs={12} sm={12}>
          <TableCustome
            primeSort={"Roles"}
            primeWidth={"Roles"}
            isEdit={
              role !== "ROLE_ACCOUNT_ADMIN" && accountType !== "FRANCHISOR"
                ? false
                : !allowOnlySSOLogin
            }
            headList={
              role !== "ROLE_ACCOUNT_ADMIN" && accountType !== "FRANCHISOR"
                ? headListHideEdit
                : allowOnlySSOLogin
                ? headListHideEdit
                : headList
            }
            bodyList={bodyList}
            editAction={editAction}
          />
        </Grid>
      ) : (
        <Stack
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "65vh" }}
        >
          <Loading />
        </Stack>
      )}
    </Grid>
  );
};

export default Roles;
