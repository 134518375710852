import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";

import { ReactComponent as Up } from "../../../../assets/NewIcons/Up.svg";
import { ReactComponent as Down } from "../../../../assets/NewIcons/Down.svg";
import { ReactComponent as UpdatedChange } from "../../../../assets/NewIcons/Updated_Change.svg";
import {
  sortByAsceOrder,
  sortByDescOrder,
} from "../../../../utils/tableSorting";
const SignatorySelectTable = ({ bodyList, handleSelect }) => {
  const [isAsce, setIsAsce] = useState("");
  const [bodyListLocal, setBodyListLocal] = useState([]);
  function UpIcon() {
    return <Up style={{ scale: "0.7" }} />;
  }
  function DownIcon() {
    return <Down style={{ scale: "0.7" }} />;
  }
  useEffect(() => {
    if (bodyList && bodyList.length) {
      setBodyListLocal(sortByAsceOrder("Individual", bodyList));
      setIsAsce("desc");
    }
  }, [bodyList]);
  const handleSort = () => {
    if (isAsce === "asce") {
      setBodyListLocal(sortByAsceOrder("Individual", bodyList));
      setIsAsce("desc");
    } else {
      setBodyListLocal(sortByDescOrder("Individual", bodyList));

      setIsAsce("asce");
    }
  };
  return (
    <Paper sx={{ width: "100%", boxShadow: 0 }}>
      <TableContainer sx={{ maxHeight: "70%", overflowY: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "60%",
                  justifyContent: "center",
                  padding: "1em 0em",
                  border: "0.5px  solid #EEF2F4",
                  flexDirection: "row-reverse",
                  backgroundColor: "#FBFBFC",
                }}
              >
                {" "}
                <TableSortLabel
                  active={true}
                  IconComponent={
                    isAsce === "asce"
                      ? DownIcon
                      : isAsce === "desc"
                      ? UpIcon
                      : UpdatedChange
                  }
                  onClick={() => handleSort()}
                >
                  <Typography variant="body1" color={"#333"}>
                    Individual
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  width: "30%",
                  justifyContent: "center",
                  padding: "1em 2em",
                  border: "0.5px  solid #EEF2F4",
                  backgroundColor: "#FBFBFC",
                }}
              >
                <Typography variant="body1" color={"#333"}>
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ width: "100%" }}>
            {bodyListLocal.length ? (
              bodyListLocal.map((ele, key) => {
                return (
                  <TableRow sx={{ width: "100%" }} key={key}>
                    <TableCell
                      sx={{
                        border: "0.5px  solid #EEF2F4",
                        padding: "1em 0.5em",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography variant="body1" color={"#999"}>
                        {ele.Individual}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "0.5px  solid #EEF2F4",
                        padding: "0em 2em",
                      }}
                    >
                      <Button
                        id={`select-${key}`}
                        variant="contained"
                        size="small"
                        sx={{ color: "white", width: "80px" }}
                        onClick={() => handleSelect(ele)}
                      >
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                sx={{
                  border: "0.5px  solid #EEF2F4",
                  height: "2vh",
                  padding: "1em 2em",
                }}
              >
                <Typography sx={{ padding: "1em 10%" }}>
                  Nothing To Show here
                </Typography>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default SignatorySelectTable;
