import { Checkbox, Divider, Grid, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { businessDetailsAPIDataMapper } from "../../../../utils/apiDataMappers";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Loading from "../../../../components/Loading/Loading";
import DialoCodeAutocomplete from "../../../../components/FormComponents/DialoCodeAutocomplete";
import { RoleStrings } from "../../../../utils/stringToObj";

const Profile = ({
  profileMangeCheckbox,
  setProfileMangeCheckbox,
  register,
  reset,
  errors,
  setValue,
}) => {
  const TextFieldStyle = { width: { sm: "350px", xs: "250px" } };
  const { selectedBussinessDetails } = useSelector((state) => state.dashboard);
  const loggedInUserInfo = useSelector((state) => state.login.loggedInUserInfo);
  useEffect(() => {
    if (selectedBussinessDetails.legalName) {
      reset(businessDetailsAPIDataMapper(selectedBussinessDetails));
    }
  }, [selectedBussinessDetails]);
  useEffect(() => {
    document.querySelector(".css-19kzrtu").style.padding = "0rem 1rem";
  }, []);
  return (
    <>
      {selectedBussinessDetails.legalName ? (
        <Grid container>
          {loggedInUserInfo.accountType === "FRANCHISOR" ? (
            <Grid item lg={12} xs={12} sm={12} md={12}>
              <Stack
                width={"100%"}
                direction={"row"}
                justifyContent="flex-start"
                sx={{ marginTop: "1em" }}
              >
                <FiberManualRecordIcon
                  sx={{
                    marginTop: "0.18em",
                    marginLeft: "-1em",
                    marginRight: "0.5em",
                    color: "#CCCCCC",
                    height: "0.5em",
                  }}
                />
                <Stack width={"100%"} spacing={2}>
                  <Typography variant="h7">Brand Name</Typography>
                  <TextField
                    disabled
                    id="businessName"
                    sx={TextFieldStyle}
                    size="small"
                    value={loggedInUserInfo.dashboardHierarchy.franchisorId}
                  />
                </Stack>
              </Stack>
              <Divider sx={{ borderStyle: "dotted", marginLeft: "-2.2em" }} />
            </Grid>
          ) : null}
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: "#CCCCCC",
                  height: "0.5em",
                }}
              />
              <Stack width={"100%"} spacing={2}>
                <Typography variant="h7">Legal Business Name</Typography>
                <TextField
                  disabled={
                    loggedInUserInfo.allowOnlySSOLogin ||
                    loggedInUserInfo.role === RoleStrings.ADMIN
                  }
                  id="businessName"
                  sx={TextFieldStyle}
                  size="small"
                  placeholder="Enter Business Name"
                  {...register("legalName")}
                />
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.legalName?.message}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ borderStyle: "dotted", marginLeft: "-2.2em" }} />
          </Grid>
          {loggedInUserInfo.allowOnlySSOLogin ||
          (loggedInUserInfo.role === RoleStrings.ADMIN &&
            loggedInUserInfo.allowOnlySSOLogin === false &&
            loggedInUserInfo.role === "ROLE_ACCOUNT_ADMIN") ? (
            <Grid item lg={12} xs={12} sm={12} md={12}>
              <Stack
                width={"100%"}
                direction={"row"}
                justifyContent="flex-start"
                sx={{ marginTop: "1em" }}
              >
                <FiberManualRecordIcon
                  sx={{
                    marginTop: "0.18em",
                    marginLeft: "-1em",
                    marginRight: "0.5em",
                    color: "#CCCCCC",
                    height: "0.5em",
                  }}
                />
                <Stack width={"100%"} spacing={2}>
                  <Typography fontWeight={600} variant="body1">
                    Brand Name (Optional)
                  </Typography>
                  <TextField
                    id="brandName"
                    sx={{ width: { sm: "350px", xs: "250px" } }}
                    size="small"
                    disabled={
                      loggedInUserInfo.allowOnlySSOLogin ||
                      loggedInUserInfo.role === RoleStrings.ADMIN
                    }
                    placeholder="Brand Name"
                    {...register("franchiseName")}
                  />
                  <Typography variant="body1" style={{ color: "#f34f4c" }}>
                    {errors.franchiseName?.message}
                  </Typography>
                </Stack>
              </Stack>
              <Divider sx={{ borderStyle: "dotted", marginLeft: "-2.2em" }} />
            </Grid>
          ) : null}
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: "#CCCCCC",
                  height: "0.5em",
                }}
              />
              <Stack width={"100%"} spacing={2}>
                <Typography fontWeight={600} variant="body1">
                  Description (Optional)
                </Typography>
                <TextField
                  disabled={
                    loggedInUserInfo.allowOnlySSOLogin ||
                    loggedInUserInfo.role === RoleStrings.ADMIN
                  }
                  id="description"
                  sx={TextFieldStyle}
                  inputProps={{ style: { fontSize: "13px" } }}
                  size="small"
                  placeholder="Enter Business Description"
                  multiline
                  rows={4}
                  {...register("description")}
                />
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.description?.message}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: "#CCCCCC",
                  height: "0.5em",
                }}
              />
              <Stack width={"100%"} spacing={2}>
                <Typography fontWeight={600} variant="body1">
                  Address
                </Typography>
                <TextField
                  disabled={
                    loggedInUserInfo.allowOnlySSOLogin ||
                    loggedInUserInfo.role === RoleStrings.ADMIN
                  }
                  id="line1"
                  sx={TextFieldStyle}
                  size="small"
                  fullwidth="true"
                  placeholder="Enter Address Line 1"
                  {...register("line1")}
                />
                {errors.line1 ? (
                  <Typography variant="body1" style={{ color: "#f34f4c" }}>
                    {errors.line1?.message}
                  </Typography>
                ) : null}
                <TextField
                  disabled={
                    loggedInUserInfo.allowOnlySSOLogin ||
                    loggedInUserInfo.role === RoleStrings.ADMIN
                  }
                  id="line2"
                  sx={TextFieldStyle}
                  size="small"
                  fullwidth="true"
                  placeholder="Enter Address Line 2"
                  {...register("line2")}
                />
                {errors.line2 ? (
                  <Typography variant="body1" style={{ color: "#f34f4c" }}>
                    {errors.line2?.message}
                  </Typography>
                ) : null}
                <TextField
                  disabled={
                    loggedInUserInfo.allowOnlySSOLogin ||
                    loggedInUserInfo.role === RoleStrings.ADMIN
                  }
                  id="city"
                  sx={TextFieldStyle}
                  size="small"
                  fullwidth="true"
                  placeholder="Enter City"
                  {...register("city")}
                />
                {errors.city ? (
                  <Typography variant="body1" style={{ color: "#f34f4c" }}>
                    {errors.city?.message}
                  </Typography>
                ) : null}
                <TextField
                  disabled={
                    loggedInUserInfo.allowOnlySSOLogin ||
                    loggedInUserInfo.role === RoleStrings.ADMIN
                  }
                  id="state"
                  sx={TextFieldStyle}
                  size="small"
                  fullwidth="true"
                  placeholder="Enter State"
                  {...register("state")}
                />
                {errors.state ? (
                  <Typography variant="body1" style={{ color: "#f34f4c" }}>
                    {errors.state?.message}
                  </Typography>
                ) : null}
                <TextField
                  disabled={
                    loggedInUserInfo.allowOnlySSOLogin ||
                    loggedInUserInfo.role === RoleStrings.ADMIN
                  }
                  id="country"
                  sx={TextFieldStyle}
                  size="small"
                  fullwidth="true"
                  placeholder="Enter country"
                  {...register("country")}
                />
                {errors.country ? (
                  <Typography variant="body1" style={{ color: "#f34f4c" }}>
                    {errors.country?.message}
                  </Typography>
                ) : null}
                <TextField
                  disabled={
                    loggedInUserInfo.allowOnlySSOLogin ||
                    loggedInUserInfo.role === RoleStrings.ADMIN
                  }
                  id="zip"
                  sx={TextFieldStyle}
                  size="small"
                  fullwidth="true"
                  placeholder="Enter pinCode"
                  {...register("pinCode")}
                />
                {errors.pinCode ? (
                  <Typography variant="body1" style={{ color: "#f34f4c" }}>
                    {errors.pinCode?.message}
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
            <Divider
              sx={{
                borderStyle: "dotted",
                marginLeft: "-2.2em",
                paddingTop: "1rem",
              }}
            />
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: "#CCCCCC",
                  height: "0.5em",
                }}
              />
              <Stack width={"100%"} spacing={2}>
                <Typography fontWeight={600} variant="body1">
                  Phone
                </Typography>
                <Stack width={{ sm: "100%", xs: "350px" }} direction={"row"}>
                  <Stack width={"15%"} spacing={1}>
                    <DialoCodeAutocomplete
                      disabled={
                        loggedInUserInfo.allowOnlySSOLogin ||
                        loggedInUserInfo.role === RoleStrings.ADMIN
                      }
                      setValue={setValue}
                      defaultValue="+1"
                    />
                  </Stack>
                  <Stack width={{ sm: "35%", xs: "51%" }} spacing={1}>
                    <TextField
                      disabled={
                        loggedInUserInfo.allowOnlySSOLogin ||
                        loggedInUserInfo.role === RoleStrings.ADMIN
                      }
                      id="phone"
                      sx={{ width: "100%", marginLeft: "20px" }}
                      size="small"
                      {...register("phone")}
                      fullwidth="true"
                      placeholder="Enter Phone Number"
                      type="tel"
                    />
                    <Typography
                      variant="body1"
                      style={{
                        color: "#f34f4c",
                        flexWrap: "wrap",
                        width: "200px",
                        marginLeft: "2em",
                      }}
                    >
                      {errors.phone?.message}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Divider
              sx={{
                borderStyle: "dotted",
                marginLeft: "-2.2em",
                marginTop: "1em",
              }}
            />
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: "#CCCCCC",
                  height: "0.5em",
                }}
              />
              <Stack width={"100%"} spacing={2}>
                <Typography fontWeight={600} variant="body1">
                  Federal Tax Id
                </Typography>
                <TextField
                  disabled={
                    loggedInUserInfo.allowOnlySSOLogin ||
                    loggedInUserInfo.role === RoleStrings.ADMIN
                  }
                  id="tax"
                  sx={TextFieldStyle}
                  size="small"
                  fullwidth="true"
                  placeholder="Enter Federal Tax Id"
                  {...register("federalIdentificationNumber")}
                />
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.federalIdentificationNumber?.message}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ borderStyle: "dotted", marginLeft: "-2.2em" }} />
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            {loggedInUserInfo.role === RoleStrings.ADMIN ? (
              <Stack
                width={"100%"}
                direction={"row"}
                justifyContent="flex-start"
                alignItems={"center"}
                sx={{ marginTop: "0.5em", marginBottom: "2.5rem" }} //added margin so popup gets fixdd
              >
                <FiberManualRecordIcon
                  sx={{
                    marginTop: "0.18em",
                    marginLeft: "-1em",
                    marginRight: "0.5em",
                    color: "#CCCCCC",
                    height: "0.5em",
                  }}
                />
                <Checkbox
                  id="profileManage"
                  checked={profileMangeCheckbox}
                  onChange={() =>
                    setProfileMangeCheckbox(!profileMangeCheckbox)
                  }
                  disabled={
                    loggedInUserInfo.role === RoleStrings.ADMIN &&
                    (loggedInUserInfo.planType === "PLATINUM" ||
                      loggedInUserInfo.planType === "DIAMOND")
                      ? false
                      : true
                  }
                />
                <Typography fontWeight={600} variant="body1">
                  Allow Business Manager to manage employees
                </Typography>
              </Stack>
            ) : null}

            <Divider
              sx={{
                borderStyle: "dotted",
                marginLeft: "-2.2em",
                marginBottom: "2rem",
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Stack
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "65vh" }}
        >
          <Loading />
        </Stack>
      )}
    </>
  );
};

export default Profile;
