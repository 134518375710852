import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import theme from "../../../../theme";
import {
  Grid,
  Divider,
  Stack,
  Checkbox,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import DialoCodeAutocomplete from "../../../../components/FormComponents/DialoCodeAutocomplete";
import { saveNewBusiness } from "../../NewBusinessSlice";
import CommonFormHeadButton from "../../../../components/CommonTableHead/CommonFormHeadButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Notification from "../../../../components/Notification/Notification";
import SNACKBAR_VARIATIONS from "../../../../constants/snackbar-variations";
import { useDispatch, useSelector } from "react-redux";
import { RoleStrings } from "../../../../utils/stringToObj";

const BussinessCreateForm = ({ handleCancel, setIsEdit }) => {
  const dispatch = useDispatch();
  const loggedInUserInfo = useSelector((state) => state.login.loggedInUserInfo);
  const [snackbarOpen, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const closeSnackbar = () => setOpenSnackbar(false);

  const schema = yup.object().shape({
    legalName: yup
      .string()
      .nullable()
      .required("Legal Business Name is required"),
    franchiseName: yup.string().nullable("Brand Name is required"),
    description: yup.string(),
    line1: yup.string().nullable().required("Address Line 1 is required"),
    line2: yup.string(),
    city: yup.string().nullable().required("City is required"),
    state: yup.string().nullable().required("State is required"),
    country: yup.string().nullable().required("Country is required"),
    pinCode: yup
      .string()
      .nullable()
      .typeError("Zip is requried")
      .required("Zip is requried"),
    dialCode: yup.string().nullable("").required(""),
    phone: yup
      .string()
      .nullable("")
      .typeError("Phone Number is required")
      .required("Phone Number is required"),
    federalIdentificationNumber: yup
      .string()
      .nullable("")
      .typeError("Federal Tax Id is required")
      .required("Federal Tax Id is required"),
    managerCanManageEmployee: yup
      .bool()
      .nullable("Required Field")
      .typeError("Required Field")
      .required("Required Field"),
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { legalName: "" },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const handleSnackbar = (message, severity) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
  };

  const submit = async (data) => {
    const formatedPayload = {
      properties: {
        address: {
          line1: data.line1,
          country: data.country,
          state: data.state,
          city: data.city,
          pinCode: data.pinCode,
          line2: data.line2 || "",
        },
        federalIdentificationNumber: data.federalIdentificationNumber,
        franchiseName: data.franchiseName,
        phone: data.phone,
        managerCanManageEmployee: data.managerCanManageEmployee,
        description: data.description,
      },
      legalName: data.legalName,
      accountId: loggedInUserInfo.accountId,
    };

    const res = await dispatch(saveNewBusiness(formatedPayload))
      .unwrap()
      .then((originalPromiseResult) => {
        originalPromiseResult.status === 201
          ? handleCancel(originalPromiseResult)
          : originalPromiseResult.status === 422 &&
            originalPromiseResult.data.code === 1003 &&
            handleSnackbar(
              `${originalPromiseResult.data.validationErrors[0].message}`,
              SNACKBAR_VARIATIONS.ERROR
            );
      })
      .catch((rejectedValueOrSerializedError) => {});
  };
  const TextFieldStyle = { width: { sm: "350px", xs: "220px" } };

  return (
    <Box sx={{ width: "100%" }}>
      {snackbarOpen && (
        <Notification
          openSnackbar={snackbarOpen}
          severity={snackbarSeverity}
          message={snackbarMessage}
          handleClose={closeSnackbar}
        />
      )}
      <div>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <CommonFormHeadButton
            setIsEdit={setIsEdit}
            title={"Create a new Business"}
            submit={handleSubmit(submit)}
            handleCancel={handleCancel}
            buttonText={"Create"}
          />
        </Grid>
      </div>
      <Box
        sx={{
          overflowX: "hidden",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "end",
          height: {
            lg: "75vh",
            ipadMini: "75vh",
            ipadAir: "75vh",
            bigScreen: "75vh",
          },
          maxHeight: {
            lg: "75vh",
            ipadMini: "75vh",
            ipadAir: "75vh",
            bigScreen: "75vh",
          },
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&:hover": {
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#9e9e9e",
              borderRadius: "50px",
              ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.35)",
              },
            },
          },
        }}
      >
        <Grid container position={"-webkit-sticky"}>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Stack
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em", marginLeft: "2em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: theme.palette.common.greyish,
                  height: "0.5em",
                }}
              />{" "}
              <Stack spacing={2}>
                <Typography variant="h7">Legal Business Name </Typography>
                <TextField
                  inputProps={{ id: "legalName" }}
                  id="legalName"
                  sx={TextFieldStyle}
                  size="small"
                  placeholder="Enter Business Name"
                  {...register("legalName")}
                />
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.legalName?.message}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ borderStyle: "dotted" }} />
          </Grid>
          {loggedInUserInfo.role === RoleStrings.ADMIN &&
          loggedInUserInfo.allowOnlySSOLogin === false &&
          loggedInUserInfo.role === "ROLE_ACCOUNT_ADMIN" ? (
            <Grid item lg={12} xs={12} sm={12} md={12}>
              <Stack
                direction={"row"}
                justifyContent="flex-start"
                sx={{ marginTop: "1em", marginLeft: "2em" }}
              >
                <FiberManualRecordIcon
                  sx={{
                    marginTop: "0.18em",
                    marginLeft: "-1em",
                    marginRight: "0.5em",
                    color: theme.palette.common.greyish,
                    height: "0.5em",
                  }}
                />
                <Stack spacing={2}>
                  <Typography variant="h7">Brand Name (Optional) </Typography>
                  <TextField
                    id="brandName"
                    sx={{
                      backgroundColor: "#f9fafb",
                      width: { sm: "350px", xs: "220px" },
                    }}
                    disabled={true}
                    size="small"
                    placeholder="Brand Name"
                    {...register("franchiseName")}
                  />
                  <Typography variant="body1" style={{ color: "#f34f4c" }}>
                    {errors.franchiseName?.message}
                  </Typography>
                </Stack>
              </Stack>
              <Divider sx={{ borderStyle: "dotted" }} />
            </Grid>
          ) : null}
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Stack
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em", marginLeft: "2em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: theme.palette.common.greyish,
                  height: "0.5em",
                }}
              />
              <Stack spacing={2}>
                <Typography variant="h7">Description (Optional) </Typography>
                <TextField
                  id="businessDesc"
                  sx={TextFieldStyle}
                  size="small"
                  placeholder="Enter Business Description"
                  multiline
                  rows={4}
                  {...register("description")}
                />{" "}
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.description?.message}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ borderStyle: "dotted" }} />
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Stack
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em", marginLeft: "2em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: theme.palette.common.greyish,
                  height: "0.5em",
                }}
              />
              <Stack spacing={2}>
                <Typography variant="h7">Address </Typography>
                <TextField
                  id="line1"
                  sx={TextFieldStyle}
                  size="small"
                  fullwidth
                  placeholder="Enter Address Line 1"
                  {...register("line1")}
                />{" "}
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.line1?.message}
                </Typography>
                <TextField
                  id="line2"
                  sx={TextFieldStyle}
                  size="small"
                  fullWidth
                  placeholder="Enter Address Line 2"
                  {...register("line2")}
                />{" "}
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.line2?.message}
                </Typography>
                <TextField
                  id="city"
                  sx={TextFieldStyle}
                  size="small"
                  fullWidth
                  placeholder="Enter City"
                  {...register("city")}
                />{" "}
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.city?.message}
                </Typography>
                <TextField
                  id="state"
                  sx={TextFieldStyle}
                  size="small"
                  fullWidth
                  placeholder="Enter State"
                  {...register("state")}
                />{" "}
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.state?.message}
                </Typography>
                <TextField
                  id="country"
                  sx={TextFieldStyle}
                  size="small"
                  fullWidth
                  placeholder="Enter country"
                  {...register("country")}
                />{" "}
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.country?.message}
                </Typography>
                <TextField
                  id="zip"
                  sx={TextFieldStyle}
                  size="small"
                  fullWidth
                  placeholder="Enter Zip"
                  {...register("pinCode")}
                />{" "}
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.pinCode?.message}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ borderStyle: "dotted" }} />
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Stack
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em", marginLeft: "2em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: theme.palette.common.greyish,
                  height: "0.5em",
                }}
              />
              <Stack spacing={2}>
                <Typography variant="h7">Phone </Typography>
                <Stack direction={"row"}>
                  <Stack spacing={1}>
                    <DialoCodeAutocomplete setValue={setValue} />
                    <Typography
                      variant="body1"
                      style={{
                        color: "#f34f4c",
                        flexWrap: "wrap",
                      }}
                    >
                      {errors.dialCode?.message}
                    </Typography>
                  </Stack>
                  <Stack spacing={1}>
                    <TextField
                      id="phone"
                      sx={{
                        width: { sm: "250px", xs: "120px" },
                        marginLeft: "1em",
                      }}
                      size="small"
                      {...register("phone")}
                      fullWidth
                      placeholder="Enter Phone Number"
                      type="text"
                    />{" "}
                    <Typography
                      variant="body1"
                      style={{
                        color: "#f34f4c",
                        flexWrap: "wrap",
                        width: "200px",
                        marginLeft: "2em",
                      }}
                    >
                      {errors.phone?.message}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Divider sx={{ borderStyle: "dotted", marginTop: "1em" }} />
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Stack
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em", marginLeft: "2em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: theme.palette.common.greyish,
                  height: "0.5em",
                }}
              />
              <Stack spacing={2}>
                <Typography variant="h7">Federal Tax Id </Typography>
                <TextField
                  id="tax"
                  sx={TextFieldStyle}
                  size="small"
                  fullWidth
                  placeholder="Enter Federal Tax Id"
                  {...register("federalIdentificationNumber")}
                />{" "}
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.federalIdentificationNumber?.message}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ borderStyle: "dotted" }} />
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Stack
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em", marginLeft: "2em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: theme.palette.common.greyish,
                  height: "0.5em",
                }}
              />
              <Stack spacing={2} direction={"row"}>
                <Checkbox
                  id="manageEmployees"
                  sx={{
                    marginTop: "-8px",
                    marginLeft: "-10px",
                    marginRight: "-10px",
                  }}
                  {...register("managerCanManageEmployee")}
                ></Checkbox>
                <Typography variant="body1" style={{ color: "#f34f4c" }}>
                  {errors.managerCanManageEmployee?.message}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: { sm: 450, xs: 15 },
                    fontSize: { sm: "16px", xs: "15px" },
                  }}
                >
                  Allow Business Manager to manage employees
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ borderStyle: "dotted", marginBottom: "10px" }} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BussinessCreateForm;
