import { createTheme } from "@mui/material/styles";

const Blue = "#36bfeb";
const DarkBlue = "#164e5f";
const White = "#ffffff";
const Purple = "#93c";
const LightPurple = "#cc99e5";
const Red = "#f34f4c";
const Grey = "#999";
const Greyish = "#CCCCCC";
const BorderBlack = "#666666";
const DocumentWizard = {
  employee: "#4a8ff7",
  manager: "#9acd32",
  bussiness: "#2FCF5F",
  location: "#39C6C5",
  customFields: "#999",
};
export default createTheme({
  shadows: Array(25).fill("none"),
  breakpoints: {
    values: {
      xs: 0,
      msm: 400,
      sm: 600,
      ipadMini: 744,
      ipadAir: 820,
      iphoneProLand: 844,
      md: 900,
      ipadAirLand: 1180,
      lg: 1200,
      xl: 1536,
      bigScreen: 2048,
    },
  },
  palette: {
    common: {
      blue: `${Blue}`,
      darkblue: `${DarkBlue}`,
      white: `${White}`,
      purple: `${Purple}`,
      red: `${Red}`,
      greyish: `${Greyish}`,
      grey: `${Grey}`,
      borderBlack: `${BorderBlack}`,
      lightPurple: `${LightPurple}`,
      documentWizard: {
        ...DocumentWizard,
      },
    },
    primary: {
      main: `${Blue}`,
    },
    secondary: {
      main: `${DarkBlue}`,
    },
  },
  typography: {
    fontFamily: "Open Sans",
    button: {
      textTransform: "none",
      fontFamily: "Open Sans",
    },
  },

  button: {
    textTransform: "none",
    fontFamily: "Open Sans",
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: "13px",
        },
      },
    },
    Mui: {
      styleOverrides: {
        selected: {
          fontWeight: 600,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          color: Grey,
          selected: {
            fontWeight: 600,
          },
        },
      },
    },
    // Name of the component

    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "13px",
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "13px",
        },
      },
    },
    //MuiMenuItem
    MuiSelect: {
      styleOverrides: {
        select: {
          // Some CSS
          "& input::placeholder": {
            fontSize: "13px",
          },
          "& input": {
            fontSize: "13px",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // Some CSS
          "& input::placeholder": {
            fontSize: "13px",
            // color:'#999'
          },
          "& input": {
            fontSize: "13px",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        // Name of the slot
        body1: {
          // Some CSS
          fontSize: "13px",
        },
        subtitle1: {
          fontSize: "17px",
          color: "#333333",
          fontWeight: "400 !important",
        },
        h7: {
          fontSize: "13px",
          color: "#333333",
          fontWeight: "700 !important",
        },
      },
    },
  },
});
