import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import DialoCodeAutocomplete from "./DialoCodeAutocomplete";
import FormTextField from "./FormTextField";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const PhoneFields = ({
  register,
  errors,
  setValue,
  defaultValue,
  disabled,
}) => {
  return (
    <Box sx={{ p: "0 1em" }}>
      <Stack
        width={"100%"}
        direction={"row"}
        justifyContent="flex-start"
        sx={{ marginTop: "1em" }}
      >
        <FiberManualRecordIcon
          sx={{
            marginTop: "0.18em",
            marginLeft: "-1em",
            marginRight: "0.5em",
            color: "#CCCCCC",
            height: "0.5em",
          }}
        />
        <Stack width={"100%"} spacing={2}>
          <Typography variant="h7">Phone</Typography>
          <Stack width={"100%"} direction={"row"} spacing={2}>
            <Stack width={"15%"} spacing={1}>
              <DialoCodeAutocomplete
                setValue={setValue}
                defaultValue={defaultValue}
                disabled={disabled}
              />
              {/* <span style={{ color: "#f34f4c", flexWrap: "wrap", width: "100px" }}>
                {errors.dialCode?.message}
              </span> */}
            </Stack>
            <Stack width={"65%"} spacing={1}>
              <FormTextField
                placeholder={"Enter Mobile Number"}
                register={register}
                errors={errors}
                setValueName={"phone"}
                disabled={disabled}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PhoneFields;
