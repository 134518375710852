import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FormTextField from "./FormTextField";

const AddressFields = ({ register, errors, disabled }) => {
  return (
    <Box sx={{ p: "0 1em" }}>
      <Stack
        width={"100%"}
        direction={"row"}
        justifyContent="flex-start"
        sx={{ marginTop: "1em" }}
      >
        <FiberManualRecordIcon
          sx={{
            marginTop: "0.18em",
            marginLeft: "-1em",
            marginRight: "0.5em",
            color: "#CCCCCC",
            height: "0.5em",
          }}
        />
        <Stack width={"100%"} spacing={2}>
          <Typography variant="h7">Address </Typography>
          <FormTextField
            id="Line1"
            placeholder={"Enter Address Line 1"}
            register={register}
            errors={errors}
            setValueName={"line1"}
            disabled={disabled}
          />
          <FormTextField
            id="Line2"
            placeholder={"Enter Address Line 2"}
            register={register}
            errors={errors}
            setValueName={"line2"}
            disabled={disabled}
          />
          <FormTextField
            id="city"
            placeholder={"Enter City"}
            register={register}
            errors={errors}
            setValueName={"city"}
            disabled={disabled}
          />
          <FormTextField
            id="state"
            placeholder={"Enter State"}
            register={register}
            errors={errors}
            setValueName={"state"}
            disabled={disabled}
          />
          <FormTextField
            id="country"
            placeholder={"Enter Country"}
            register={register}
            errors={errors}
            setValueName={"country"}
            disabled={disabled}
          />
          <FormTextField
            id="zip"
            placeholder={"Enter Zip"}
            register={register}
            errors={errors}
            setValueName={"pinCode"}
            disabled={disabled}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default AddressFields;
