import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { CircularProgress } from "@mui/material";
import { ajaxHandler } from "../../services/ajaxHandler";
import { useSelector } from "react-redux";

const PladHook = ({ linkToken, setLinkToken, fetchData }) => {
  const { accountId } = useSelector((state) => state.login.loggedInUserInfo);

  const handleSubmit = async (public_token) => {
    try {
      const response = await ajaxHandler(
        "post",
        `focus451/paymentGateway/accessToken?publicToken=${public_token}&userClientId=${accountId}`
      );
      setLinkToken(response.data.linkToken);
      fetchData();
    } catch (error) {
      console.error("Error getting link token:", error);
    }
  };

  const [publicss, setPublic] = useState("");
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: (public_token, metadata) => {
      setPublic(public_token);
      handleSubmit(public_token);
      setLinkToken(null);
    },
    onExit: () => {
      setLinkToken(null);
    },
  });
  useEffect(() => {
    ready && open();
  }, [ready]);
  return <CircularProgress />;
};

export default PladHook;
