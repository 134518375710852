import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  postEmployee,
  getEmployeeList,
  getEmployeeById,
  updateEmployee,
  findByBusinessAndEmployeeRole,
  getIndividualHistory,
  getIndividualDocs,
  getPreviewDoc,
  getPreviewDocHistory,
  downloadDoc,
  downloadDocPDF,
  getEmployeeListFranchisor,
  getLocationFranchisor,
  resendOTP,
} from "../../services/employee";

export const getEmployeeListById = createAsyncThunk(
  "businessLocation/getEmployeeListById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getEmployeeList(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEmployeeListFranchisorThunk = createAsyncThunk(
  "businessLocation/getEmployeeListFranchisor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getEmployeeListFranchisor(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getIndividualHistoryThunk = createAsyncThunk(
  "businessLocation/getIndividualHistory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getIndividualHistory(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveEmployee = createAsyncThunk(
  "businessLocation/createNewEmployee",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postEmployee(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getEmployee = createAsyncThunk(
  "businessLocation/getEmployee",
  async (locationId, { rejectWithValue }) => {
    try {
      const response = await getEmployeeById(locationId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editEmployeeById = createAsyncThunk(
  "businessLocation/editEmployeeById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateEmployee(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getByBusinessAndEmployeeRole = createAsyncThunk(
  "businessLocation/getByBusinessAndEmployeeRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await findByBusinessAndEmployeeRole(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLocationFranchisorThunk = createAsyncThunk(
  "businessLocation/getLocationFranchisor",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getLocationFranchisor(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getIndividualDocsThunk = createAsyncThunk(
  "businessLocation/getIndividualDocsThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getIndividualDocs(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPreviewDocThunk = createAsyncThunk(
  "businessLocation/getPreviewDocThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getPreviewDoc(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPreviewDocHistoryThunk = createAsyncThunk(
  "businessLocation/getPreviewDocHistoryThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getPreviewDocHistory(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const downloadDocThunk = createAsyncThunk(
  "businessLocation/downloadDocThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await downloadDoc(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const downloadDocPDFThunk = createAsyncThunk(
  "businessLocation/downloadDocPDFThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await downloadDocPDF(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resendOTPThunk = createAsyncThunk(
  "businessLocation/resendOTP",
  async (data, { rejectWithValue }) => {
    try {
      const response = await resendOTP(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  downloadDocPDFResponse: "",
  previewDocHistory: "",
  loading: false,
  isError: false,
  errorMessage: "",
  individualDocs: "",
  getEmployeeList: "",
  postEmployee: "",
  individualHistory: "",
  employeeData: "",
  editEmployeeData: "",
  docPreview: "",
  businessAndEmpRoleData: [],
  downloadDocRes: "",
  getLocationFranchisorResponse: "",
  resendOTPResponse: "",
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    resetDownloadDocPdf: (state, action) => {
      state.downloadDocRes = "";
    },
    setgetLocationFranchisorResponse: (state, action) => {
      state.getLocationFranchisorResponse = action.payload;
    },
    setgetEmployeeList: (state, action) => {
      state.getEmployeeList = action.payload;
    },
    setSaveEmployeeResponse: (state, action) => {
      state.postEmployee = action.payload;
    },
    setIndividualData: (state, action) => {
      state.employeeData = action.payload;
    },
    setEditEmployeeDataResponse: (state, action) => {
      state.editEmployeeData = action.payload;
    },
    setIndividualHistory: (state, action) => {
      state.individualHistory = action.payload;
    },
    setIndividulHistory: (state, action) => {
      state.individualDocs = action.payload;
    },
    setDocPreview: (state, action) => {
      state.docPreview = action.payload;
    },
    setPreviewDocHistory: (state, action) => {
      state.previewDocHistory = action.payload;
    },
    setDownloadDocResponse: (state, action) => {
      state.downloadDocRes = action.payload;
    },
    setDownloadDocPDFResponse: (state, action) => {
      state.downloadDocPDFResponse = action.payload;
    },
    setResendOTPResponse: (state, action) => {
      state.resendOTPResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployeeListById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getEmployeeListById.fulfilled, (state, action) => {
      state.getEmployeeList = action.payload;
      state.loading = false;
    });
    builder.addCase(getEmployeeListById.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    builder.addCase(getEmployeeListFranchisorThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      getEmployeeListFranchisorThunk.fulfilled,
      (state, action) => {
        state.getEmployeeList = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      getEmployeeListFranchisorThunk.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.getEmployeeList = [];
      }
    );

    builder.addCase(saveEmployee.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(saveEmployee.fulfilled, (state, action) => {
      state.postEmployee = action.payload;
      state.loading = false;
    });
    builder.addCase(saveEmployee.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.postEmployee = action.payload;
    });

    builder.addCase(getEmployee.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getEmployee.fulfilled, (state, action) => {
      state.employeeData = action.payload;
      state.loading = false;
    });
    builder.addCase(getEmployee.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    builder.addCase(editEmployeeById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editEmployeeById.fulfilled, (state, action) => {
      state.editEmployeeData = action.payload;
      state.loading = false;
    });
    builder.addCase(editEmployeeById.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.editEmployeeData = action.payload;
    });

    builder.addCase(getByBusinessAndEmployeeRole.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getByBusinessAndEmployeeRole.fulfilled, (state, action) => {
      state.businessAndEmpRoleData = action.payload;
      state.loading = false;
    });
    builder.addCase(getByBusinessAndEmployeeRole.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });
    builder.addCase(getIndividualHistoryThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getIndividualHistoryThunk.fulfilled, (state, action) => {
      state.individualHistory = action.payload;
      state.loading = false;
    });
    builder.addCase(getIndividualHistoryThunk.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });
    builder.addCase(getIndividualDocsThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getIndividualDocsThunk.fulfilled, (state, action) => {
      state.individualDocs = action.payload;
      state.loading = false;
    });
    builder.addCase(getIndividualDocsThunk.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload;
    });
    builder.addCase(getPreviewDocThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPreviewDocThunk.fulfilled, (state, action) => {
      state.docPreview = action.payload;
      state.loading = false;
    });
    builder.addCase(getPreviewDocThunk.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload;
    });
    builder.addCase(getPreviewDocHistoryThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPreviewDocHistoryThunk.fulfilled, (state, action) => {
      state.previewDocHistory = action.payload;
      state.loading = false;
    });
    builder.addCase(getPreviewDocHistoryThunk.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload;
    });
    builder.addCase(downloadDocThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(downloadDocThunk.fulfilled, (state, action) => {
      state.downloadDocRes = action.payload;
      state.loading = false;
    });
    builder.addCase(downloadDocThunk.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload;
    });
    builder.addCase(downloadDocPDFThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(downloadDocPDFThunk.fulfilled, (state, action) => {
      state.downloadDocRes = action.payload;
      state.loading = false;
    });
    builder.addCase(downloadDocPDFThunk.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload;
    });
    builder.addCase(getLocationFranchisorThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLocationFranchisorThunk.fulfilled, (state, action) => {
      state.getLocationFranchisorResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(getLocationFranchisorThunk.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.getLocationFranchisorResponse = "";
    });

    builder.addCase(resendOTPThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resendOTPThunk.fulfilled, (state, action) => {
      state.resendOTPResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(resendOTPThunk.rejected, (state, action) => {
      state.resendOTPResponse = action.payload;

      state.loading = false;
    });
  },
});
export const {
  setResendOTPResponse,
  setPreviewDocHistory,
  setDocPreview,
  setIndividualHistory,
  setgetEmployeeList,
  setgetLocationFranchisorResponse,
  setEditEmployeeDataResponse,
  setSaveEmployeeResponse,
  setIndividualData,
  setIndividulHistory,
  setDownloadDocResponse,
  setDownloadDocPDFResponse,
  resetDownloadDocPdf,
} = employeeSlice.actions;
export default employeeSlice.reducer;
