import React, { useState } from "react";
import PladHook from "./PladHook";
import { Button, Stack } from "@mui/material";
import { ajaxHandler } from "../../services/ajaxHandler";
import { useSelector } from "react-redux";

const PaymentButton = ({ fetchData, isDisable }) => {
  const [linkToken, setLinkToken] = useState(null);
  const loggedInUserInfo = useSelector((state) => state.login.loggedInUserInfo);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await ajaxHandler(
        "post",
        "focus451/paymentGateway/linktoken",
        {
          userClientId: loggedInUserInfo.username,
          currency: "usd",
        }
      );
      setLinkToken(response.data.linkToken);
    } catch (error) {
      console.error("Error getting link token:", error);
    }
  };

  return (
    <Stack flexDirection={"row"} alignItems={"center"}>
      <Button
        disabled={isDisable}
        variant="contained"
        size="large"
        sx={{ color: "white", backgroundColor: "#93c", marginRight: "0.5rem" }}
        onClick={handleSubmit}
      >
        Add Account
      </Button>

      {linkToken ? (
        <PladHook
          linkToken={linkToken}
          setLinkToken={setLinkToken}
          fetchData={fetchData}
        />
      ) : null}
    </Stack>
  );
};

export default PaymentButton;
