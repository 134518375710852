import { Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { localToIsoStirng } from "../../utils/localToISODateString";
const CustomeDatePicker = ({
  defaultValue,
  setValue,
  setValueName,
  placeholder,
  label,
  disabled,
}) => {
  const [openCalender, setOpenCalender] = useState(false);
  const [dateState, setDateState] = useState(
    defaultValue ? new Date(defaultValue) : null
  );
  const handleDateChange = (data) => {
    setDateState(data);
    setValue(setValueName, localToIsoStirng(data.toLocaleString()));
  };
  return (
    <Stack
      width={"100%"}
      direction={"row"}
      justifyContent="left"
      alignItems="left"
    >
      <Stack width={"100%"} spacing={1}>
        <Typography sx={{ textAlign: "left" }} variant="h7">
          {label}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            disabled={disabled}
            open={openCalender}
            onClose={() => setOpenCalender(false)}
            inputFormat="MM/DD/YYYY"
            value={dateState}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField
                onClick={() => setOpenCalender(true)}
                size="small"
                sx={{ width: "330px", textAlign: "center" }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
    </Stack>
  );
};

export default CustomeDatePicker;
