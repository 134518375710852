export const itemsTemplatesForNetwork = [
  {
    itemName: "Owner Dashboard",
    selected: true,
    path: "/dashboard",
    children: [],
  },
  {
    itemName: "BUSINESSES",
    selected: false,
    children: [
      {
        businessName: "Child 1",
        selected: false,
      },
      {
        businessName: "Child 2",
        selected: false,
      },
      {
        businessName: "Child 3",
        selected: false,
      },
    ],
  },
  // {
  //   itemName: "NETWORK",
  //   selected: false,
  //   path: "/network",
  //   children: [],
  // },
  {
    itemName: "TEMPLATES",
    selected: false,
    path: "/templates",
    children: [],
  },
];

export const itemsTemplates = [
  {
    itemName: "Owner Dashboard",
    selected: true,
    path: "/dashboard",
    children: [],
  },
  {
    itemName: "BUSINESSES",
    selected: false,
    children: [
      {
        businessName: "Child 1",
        selected: false,
      },
      {
        businessName: "Child 2",
        selected: false,
      },
      {
        businessName: "Child 3",
        selected: false,
      },
    ],
  },
  {
    itemName: "TEMPLATES",
    selected: false,
    path: "/templates",
    children: [],
  },
];

export const userDashBoardItems = [
  {
    itemName: "Dashboard",
    selected: true,
    path: "/dashboard",
    children: [],
  },
];

export const itemsWithoutTemplates = [
  {
    itemName: "Dashboard",
    selected: true,
    path: "/dashboard",
    children: [],
  },
  {
    itemName: "BUSINESSES",
    selected: false,
    children: [
      {
        businessName: "Child 1",
        selected: false,
      },
      {
        businessName: "Child 2",
        selected: false,
      },
      {
        businessName: "Child 3",
        selected: false,
      },
    ],
  },
];

export const appHeaderItems = [
  {
    name: "Management Dashboard",
    selected: true,
  },
  {
    name: "My Dashboard",
    selected: false,
  },
];
export const appHeaderItemsUser = [
  {
    name: "Management Dashboard",
    selected: false,
  },
  {
    name: "My Dashboard",
    selected: true,
  },
];
export const employeeNavItems = [
  {
    itemName: "Dashboard",
    selected: true,
    path: "/dashboard",
    children: [],
  },
];
