import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const SignatoryTable = ({ bodyList, handleChange }) => {
  return (
    <Paper sx={{ width: "100%", boxShadow: 0 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  width: "30%",
                  justifyContent: "center",
                  padding: "0.5em 0.5em",
                  border: "0.5px  solid #EEF2F4",
                  backgroundColor: "#FBFBFC",
                  color: "#999",
                  fontSize: "13px",
                  fontWeight: 700,
                }}
              >
                Signatory
              </TableCell>
              <TableCell
                sx={{
                  width: "60%",
                  justifyContent: "center",
                  padding: "0.5em 0.5em",
                  border: "0.5px  solid #EEF2F4",
                  backgroundColor: "#FBFBFC",
                  color: "#999",
                  fontSize: "13px",
                  fontWeight: 700,
                }}
              >
                Individual
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ width: "100%" }}>
            {bodyList.length ? (
              bodyList.map((ele, key) => {
                return (
                  <TableRow sx={{ width: "100%" }} key={key}>
                    <TableCell
                      sx={{
                        border: "0.5px  solid #EEF2F4",
                        padding: "0.7em 0.5em",
                      }}
                    >
                      <Stack
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography color="#999" fontSize="13px">
                          {ele.Signatory}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "0.5px  solid #EEF2F4",
                        padding: "0.05em 0.5em",
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems={"center"}
                        p={0}
                        spacing={1}
                      >
                        <Typography color="#999" fontSize="13px">
                          {ele.Individual}
                        </Typography>
                        <Button
                          sx={{ color: "#93c", fontWeight: 700 }}
                          onClick={() => handleChange(ele)}
                        >
                          Change
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                sx={{
                  border: "0.5px  solid #EEF2F4",
                  height: "2vh",
                  padding: "0.5em 0.5em",
                }}
              >
                <Typography sx={{ padding: "1em 10%" }}>
                  Nothing To Show here
                </Typography>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default SignatoryTable;
