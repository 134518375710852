import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import React from "react";

const SearchField = ({
  action,
  page = 1,
  value,
  setValue,
  placeholder = "Search",
}) => {
  React.useEffect(() => {
    const getData = setTimeout(() => {
      action({ page: page, search: value });
    }, 500);

    return () => clearTimeout(getData);
  }, [value]);
  return (
    <TextField
      id='search'
      sx={{ width: "100%" }}
      size="small"
      fullwidth="true"
      placeholder={placeholder}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

export default SearchField;
