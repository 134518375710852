import { ajaxHandler } from "./ajaxHandler";
export const getSignatoryMainTable = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451/business/getAllBusinessSignatories?accountId=${payload.accountId}&businessId=${payload.businessId}`
  );
  return response.data;
};

export const getSignatorySelectTable = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451/employee/getAllEmployeesByHireStage?accountId=${payload.accountId}&businessid=${payload.businessId}&employeeHireStage=MANAGE`
  );
  return response.data;
};

export const saveSignatory = async (payload) => {
  const response = await ajaxHandler(
    "post",
    `focus451/business/saveBusinessSignatory?accountId=${payload.accountId}&employeeId=${payload.employeeId}&name=${payload.name}`
  );
  return response;
};
