import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hide: true,
  userLoggedIn: false,
  isLoading: false,
  isMobileScreen: false,
  isIpadScreen: false,
};

export const counterSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    hideNav: (state) => {
      state.hide = true;
    },
    showNav: (state) => {
      state.hide = false;
    },
    setIsMobileScreen: (state, action) => {
      state.isMobileScreen = action.payload;
    },
    setIsIpadScreen: (state, action) => {
      state.isIpadScreen = action.payload;
    },
    setIsGlobalLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  hideNav,
  showNav,
  setIsGlobalLoading,
  setIsMobileScreen,
  setIsIpadScreen,
} = counterSlice.actions;

export default counterSlice.reducer;
