import React, { lazy, Suspense } from "react";
import { useRoutes } from "react-router";
import Redirect from "./pages/Redirect/Redirect";
import { useSelector } from "react-redux";
import { isTokenValid } from "./utils/checkTokenValidity";
import Loading from "./components/Loading/Loading";
import SSOLoader from "./pages/Login/SSOLoader";
import SignUp from "./pages/Login/SignUp";
import EmailVerification from "./pages/Login/EmailVerificiation";
import OTPVerfication from "./pages/Login/OTPVerification";
import SignUpPage from "./pages/Login/SignUpPage";
import Billing from "./pages/Billing/Billing";
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Login = lazy(() => import("./pages/Login/Login"));
const Templates = lazy(() => import("./pages/Templates/Templates"));
const NewTemplate = lazy(() => import("./pages/NewTemplate/NewTemplate"));
const DocumentWizard = lazy(() =>
  import("./pages/NewTemplate/components/DocumentWizard/DocumentWizard")
);
const TaskSign = lazy(() => import("./components/DocumentTasks/TaskSign"));
const AgreementPage = lazy(() => import("./pages/Agreement/AgreementPage"));
// const Agreement = lazy(() => import("./pages/agreement-new/agreement-new"));
const ForgotPage = lazy(() => import("./pages/ForgotPassWord/ForgotPage"));
const ResetPassWordUserPage = lazy(() =>
  import("./pages/ResetNewUserAgreement/ResetPassWordUserPage")
);

const ResetPassWordPage = lazy(() =>
  import("./pages/ResetUserPassWord/ResetPassWordPage")
);
const RENewTemplate = lazy(() => import("./pages/RENewTemplate/RENewTemplate"));

const Admin = lazy(() => import("./pages/admin"));

const User = lazy(() => import("./pages/admin/user"));

const OtpPage = lazy(() => import("./pages/OTP/OtpPage"));

const AppRouter = ({ setHide }) => {
  const {
    selectedTemplateRow: { action = "", rowdata = {} },
  } = useSelector((state) => state.templates);
  const {
    fullTemplate: { name },
  } = useSelector((state) => state.reNewTemplate);
  const userLoggedIn = useSelector((state) => state.login.userLoggedIn);
  const paymentStatus = useSelector(
    (state) => state.login.loggedInUserInfo.failedPaymentStatus
  );

  const isUserAuthentic = () =>
    !paymentStatus && userLoggedIn && isTokenValid();
  const accountDisabled = () => paymentStatus && userLoggedIn && isTokenValid();
  const testroutes = [
    {
      path: "/",
      element: <Redirect />,
    },
    {
      path: "resetpassword/new/:id",
      element: (
        <Suspense fallback={<Loading />}>
          <ResetPassWordUserPage />
        </Suspense>
      ),
    },
    {
      path: "resetpassword/exist/:id",
      element: (
        <Suspense fallback={<Loading />}>
          <ResetPassWordPage />
        </Suspense>
      ),
    },
    {
      path: "signuppage",
      element: (
        <Suspense fallback={<Loading />}>
          <SignUpPage />
        </Suspense>
      ),
    },
    {
      path: "signup",
      element: (
        <Suspense fallback={<Loading />}>
          <SignUp />
        </Suspense>
      ),
    },
    {
      path: "billing",
      element:
        isUserAuthentic() || accountDisabled() ? (
          <Suspense fallback={<Loading />}>
            <Billing />
          </Suspense>
        ) : (
          <Redirect />
        ),
    },
    // {
    //   path: "otpverify",
    //   element: (
    //     <Suspense fallback={<Loading />}>
    //       <OTPVerfication />
    //     </Suspense>
    //   ),
    // },
    {
      path: "emailverify",
      element: (
        <Suspense fallback={<Loading />}>
          <EmailVerification />
        </Suspense>
      ),
    },
    {
      path: "forgotpassword",
      element: (
        <Suspense fallback={<Loading />}>
          <ForgotPage />
        </Suspense>
      ),
    },
    {
      path: "login/*",
      element: (
        <Suspense fallback={<Loading />}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: "ssologin/infiniti",
      element: (
        <Suspense fallback={<Loading />}>
          <SSOLoader />
        </Suspense>
      ),
    },
    {
      path: "otp",
      element: (
        <Suspense fallback={<Loading />}>
          <OtpPage />{" "}
        </Suspense>
      ),
    },
    {
      path: "agreement",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <AgreementPage />{" "}
        </Suspense>
      ) : (
        <Redirect />
      ),
    },
    {
      path: "renewtemplate/new",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <RENewTemplate key={"new"} />
        </Suspense>
      ) : (
        <Redirect />
      ),
    },
    {
      path: "dashboard",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <Dashboard />
        </Suspense>
      ) : (
        <Redirect />
      ),
      children: [],
    },
    {
      path: "admin",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <Admin />
        </Suspense>
      ) : (
        <Redirect />
      ),
    },
    {
      path: "admin/user",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <User />
        </Suspense>
      ) : (
        <Redirect />
      ),
    },
    {
      path: "templates",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <Templates />
        </Suspense>
      ) : (
        <Redirect />
      ),
    },
    {
      path: "templates/new",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <RENewTemplate />{" "}
        </Suspense>
      ) : (
        <Redirect />
      ),
    },
    {
      path: "document-wizard",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <DocumentWizard />{" "}
        </Suspense>
      ) : (
        <Redirect />
      ),
    },
    {
      path: "template-wizard",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <TaskSign key={"taskSign"} />
        </Suspense>
      ) : (
        <Redirect />
      ),
    },
    {
      path: "templates/view",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <RENewTemplate
            key={"view"}
            PageHeaderMessage={`View A Template:`}
            templateFormType="view"
          />{" "}
        </Suspense>
      ) : (
        <Redirect />
      ),
    },
    {
      path: "templates/newversion",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <RENewTemplate
            key={"newversion"}
            PageHeaderMessage={`New Version A Template:`}
            templateFormType="newversion"
          />{" "}
        </Suspense>
      ) : (
        <Redirect />
      ),
    },
    {
      path: "templates/edit",
      element: isUserAuthentic() ? (
        <Suspense fallback={<Loading />}>
          <RENewTemplate
            key={"edit"}
            PageHeaderMessage={`Edit A Template:`}
            templateFormType="edit"
          />
        </Suspense>
      ) : (
        <Redirect />
      ),
    },
  ];
  const routes = useRoutes(testroutes);
  return routes;
};

export default AppRouter;
