import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { closeSnackbar } from "../../components/Notification/Notification.slice";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = ({
  openSnackbar,
  severity,
  message,
  handleClose,
  vertical = "top",
  horizontal = "center",
  autoHideDuration = 6000,
}) => {
  const dispatch = useDispatch();

  const closeGlobalSnackbar = () => {
    dispatch(closeSnackbar());
  };
  return (
    <Stack spacing={2} sx={{ width: "100%", textAlign: "center" }}>
      <Snackbar
        anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
        open={openSnackbar}
        autoHideDuration={autoHideDuration}
        onClose={handleClose ? handleClose : closeGlobalSnackbar}
      >
        <Alert
          onClose={handleClose ? handleClose : closeGlobalSnackbar}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
export default Notification;
