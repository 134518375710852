import { ajaxHandler } from "./ajaxHandler";
export const getAllAccounts = async (payload) => {
  const response = await ajaxHandler("get", `focus451/account`);
  return response;
};
export const getSingleAccount = async (payload) => {
  const response = await ajaxHandler("get", `focus451/account/${payload}`);
  return response;
};
export const putSingleAccount = async (payload) => {
  const response = await ajaxHandler(
    "put",
    `focus451/account/${payload.id}`,
    payload.data
  );
  return response;
};
