import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { hideNav } from "../../App.reducer";
import EmailIcon from "@mui/icons-material/Email";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { ReactComponent as Logo } from "../../assets/complianser-logo-with-text.svg";
import TextField from "@mui/material/TextField";
import { Divider, Stack, Typography } from "@mui/material";
import { emailVerification, resetLogin, setEmailUser } from "./LoginSlice";
import Notification from "../../components/Notification/Notification";
import SNACKBAR_VARIATIONS from "../../constants/snackbar-variations";
import "./EmailVerify.css";
import Footer from "../../components/Footer/Footer";
import { resetAgreement } from "../Agreement/AgreementSlice";
import { resetDashboard } from "../Dashboard/DashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { resetTemplates } from "../Templates/TemplateSlice";
import ReCAPTCHA from "react-google-recaptcha";
import OTPVerfication from "./OTPVerification";
import { env } from "../../env";
import storage from "redux-persist/lib/storage";

const EmailVerification = () => {
  const [snackbarOpen, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const closeSnackbar = () => setOpenSnackbar(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const hide = useSelector((state) => state.common.hide);
  const [isOtpScreen, setIsOtpScreen] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState("");
  const recaptchaRef = useRef(null);
  const { isMobileScreen } = useSelector((state) => state.common);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  useEffect(() => {
    dispatch(resetDashboard());
    storage.removeItem("persist:root");
    clearCacheData();
    localStorage.clear();
    dispatch(resetLogin());
    dispatch(resetAgreement());
    dispatch(resetTemplates());
  }, []);
  useEffect(() => {
    if (!hide) {
      dispatch(hideNav());
    }
  }, []);

  const handleLogin = async (data) => {
    let token = recaptchaRef.current.getValue();
    if (token !== "") {
      const result = await dispatch(emailVerification(data));
      const res = unwrapResult(result);
      if (res.status && res.status === 201) {
        dispatch(setEmailUser(data));
        setIsOtpScreen(true);
        setDefaultEmail(data);
      }
    } else {
      handleSnackbar("Please Enter Valid reCAPTCHA", SNACKBAR_VARIATIONS.ERROR);
    }
  };

  const handleSnackbar = (message, severity) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
  };

  const displaySnackbar = () => {
    return (
      <Notification
        openSnackbar={snackbarOpen}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    );
  };
  return (
    <>
      {isOtpScreen ? (
        <OTPVerfication defaultEmail={defaultEmail} />
      ) : (
        <div className="login-container">
          <div className="logo-container">
            <Logo className="logoStyle" height={"34%"} />
          </div>
          <div className="login-card-container" style={{ marginTop: "0rem" }}>
            <Card
              sx={{
                width: { sm: 400, xs: 300 },
                marginTop: errors.emailId
                  ? {
                      sm: "-3.15rem",
                      ipadAir: "-3.15rem",
                      xs: "-3.15rem",
                      ipadMini: "-3.15rem",
                    }
                  : {
                      sm: "-3.15rem",
                      xs: "-3.15rem",
                      ipadMini: "-3.15rem",
                      ipadAir: "-3.15rem",
                    },
                flexFlow: "column",
              }}
            >
              {snackbarOpen ? displaySnackbar() : null}

              <CardHeader
                title="Welcome"
                titleTypographyProps={{ fontSize: "14px" }}
                sx={{
                  backgroundColor: "#164e5f",
                  color: "white",
                }}
              />
              <CardContent>
                <Typography>
                  Let's start with your business email below.
                </Typography>
                <form
                  className="login-form"
                  onSubmit={handleSubmit(handleLogin)}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <TextField
                    id="username"
                    label="Email Address"
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: isMobileScreen ? "83%" : "68%",
                    }}
                    {...register("emailId", {
                      required: "Please Enter Valid Email",
                    })}
                  />
                  {errors.emailId && (
                    <Typography
                      variant="body1"
                      className="error-message"
                      sx={{
                        width: "100%",
                        paddingLeft: isMobileScreen ? "4rem" : "5.5rem",
                      }}
                    >
                      Required field<sup>*</sup>
                    </Typography>
                  )}

                  <ReCAPTCHA
                    ref={recaptchaRef} // Set a ref for the ReCAPTCHA component
                    data-type="image"
                    sitekey={env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                  />

                  <div className="action-items"></div>
                  <Button
                    id="submit"
                    type="submit"
                    color="success"
                    variant="contained"
                    sx={{
                      width: "80%",
                      color: "white",
                      backgroundColor: "#29C34F",
                      ":hover": {
                        backgroundColor: "#2CAB4B",
                      },
                    }}
                  >
                    Start my free trial
                  </Button>
                </form>
              </CardContent>
            </Card>
          </div>
          <Stack
            sx={{
              backgroundColor: "#F5F8FA",
              height: "5vh",
              position: "fixed",
              bottom: "0",
              width: "100%",
            }}
          >
            <Divider />
            <Footer />
          </Stack>
        </div>
      )}
    </>
  );
};

export default EmailVerification;
