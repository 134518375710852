import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import CommonReducer from "./App.reducer";
import LoginReducer from "./pages/Login/LoginSlice";
import DashboardReducer from "./pages/Dashboard/DashboardSlice";
import MasterDepartmentReducer from "./pages/Dashboard/DepartmentSlice";
import TemplateReducer from "./pages/Templates/TemplateSlice";
import businessLocationReducer from "./pages/Dashboard/BusinessLocationSlice";
import StepperTabsReducer from "./components/StepperTabs/StepperTabs.slice";
import EmployeeReducer from "./pages/Dashboard/EmployeeSlice";
import NewBusinessReducer from "./pages/Dashboard/NewBusinessSlice";
import thunk from "redux-thunk";
import SignatorySlice from "./pages/Dashboard/SignatorySlice";
import AgreementReducer from "./pages/Agreement/AgreementSlice";
import NotificationReducer from "./components/Notification/Notification.slice";
import ReNewTemplateReducer from "./pages/RENewTemplate/ReNewTemplateSlice";
import AdminBussinessReducer from "./pages/RENewTemplate/AdminBussiness/AdminBussinessSlice";
import AdminIndividualReducer from "./pages/RENewTemplate/AdminIndividual/AdminIndividualSlice";
import FranchisorIndividualReducer from "./pages/RENewTemplate/FranchisorIndividual/FranchisorIndividualSlice";
import FranchisorBussinessReducer from "./pages/RENewTemplate/FranchisorBussiness/FranchisorBussinessSlice";
import ShareReducer from "./pages/RENewTemplate/ShareSlice";
import adminslice from "./pages/admin/adminslice";
import alertSlice from "./core-ui/alert/alert-slice";
import { reTableSlice } from "./pages/ReTable/redux/reTableSlice";
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  common: CommonReducer,
  login: LoginReducer,
  dashboard: DashboardReducer,
  templates: TemplateReducer,
  masterDepartment: MasterDepartmentReducer,
  businessLocation: businessLocationReducer,
  stepperTabs: StepperTabsReducer,
  employee: EmployeeReducer,
  agreement: AgreementReducer,
  signatory: SignatorySlice,
  newBusiness: NewBusinessReducer,
  notification: NotificationReducer,
  reNewTemplate: ReNewTemplateReducer,
  adminBussiness: AdminBussinessReducer,
  adminIndividual: AdminIndividualReducer,
  franchisorIndividual: FranchisorIndividualReducer,
  franchisorBussiness: FranchisorBussinessReducer,
  share: ShareReducer,
  admin: adminslice,
  alert: alertSlice,
  reTable: reTableSlice,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
