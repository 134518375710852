import NavDrawer from "./components/NavDrawer/NavDrawer";
import AppRouter from "./AppRouter";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import GlobalLoader from "./components/Common/GlobalLoader";
import { appHeaderItems, appHeaderItemsUser } from "./mock-data/mock";
import { setSelectedDashboard } from "./pages/Dashboard/DashboardSlice";
import MultiNotification from "./components/Notification/MultiNotification";
import { setIsIpadScreen, setIsMobileScreen } from "./App.reducer";
import { useMediaQuery } from "@mui/material";

function App() {
  const dispatch = useDispatch();
  const loggedInUserInfo = useSelector((state) => state.login.loggedInUserInfo);
  if (loggedInUserInfo.role === "ROLE_USER") {
    dispatch(
      setSelectedDashboard({
        name: "My Dashboard",
        selected: true,
      })
    );
  }
  dispatch(setIsIpadScreen(useMediaQuery("(min-width: 840px)")));
  dispatch(setIsMobileScreen(useMediaQuery("(min-width: 430px)")));
  const { hide, isLoading } = useSelector((state) => state.common);
  return (
    <div
      className="App"
      style={{ backgroundColor: !hide ? "rgb(245, 247, 250)" : "white" }}
    >
      <MultiNotification />
      {!hide ? (
        <NavDrawer
          appHeaderItems={
            loggedInUserInfo.role === "ROLE_USER"
              ? appHeaderItemsUser
              : appHeaderItems
          }
        />
      ) : (
        <AppRouter />
      )}

      {<GlobalLoader isLoading={isLoading} />}
    </div>
  );
}

export default App;
