import React, { useState } from "react";
import { ajaxHandler } from "../../services/ajaxHandler";
import { useSelector } from "react-redux";

const useBankAccounts = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const clientId = useSelector(
    (state) => state.login.loggedInUserInfo.accountId
  );
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await ajaxHandler(
        "get",
        `focus451/paymentGateway/accounts?userClientId=${clientId}`
      );

      if (response.status === 200) {
        setData(response.data);
      } else {
        throw new Error(response.data || "Failed to fetch data");
      }
    } catch (error) {
      setError(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchData };
};

export default useBankAccounts;
