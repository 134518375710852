import "./Signup.css";
import "./Login.css";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import BusinessIcon from "@mui/icons-material/Business";
import StoreIcon from "@mui/icons-material/Store";
import PersonIcon from "@mui/icons-material/Person";
import {
  PlansStrings,
  PlansStringsWithoudDiamondAndBase,
} from "../../utils/stringToObj";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import Email from "@mui/icons-material/Email";
import DialoCodeAutocomplete from "../../components/FormComponents/DialoCodeAutocomplete";
import { postSignUpDateForFranchsior } from "../../utils/apiDataMappers";
import { emailVerification, signUpUserThunk } from "./LoginSlice";
import {
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../components/Notification/Notification.slice";
import { useEffect } from "react";
import Notification from "../../components/Notification/Notification";
const SignUpFrom = ({
  defaultEmail = "",
  formRef,
  isCreateNewUser = false,
  isOptInForNewuser = false,
  isNetwork = false,
  isSignUpFormPage = false,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [snackNotificationBar, setSnackNotificationBar] = useState(false);
  const closeSnackbar = () => setOpenSnackbar(false);
  const { emailAfterVerifcation } = useSelector((state) => state.login);
  const { isMobileScreen } = useSelector((state) => state.common);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [planValue, setValue] = useState("PLATINUM");

  const {
    register,
    handleSubmit,
    setValue: setFormValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      countryCode: "+1",
      email: emailAfterVerifcation,
    },
  });
  const handleLogin = async (data) => {
    //fixed login
    data.planType = planValue;
    const tempData = postSignUpDateForFranchsior(data, isNetwork);

    const result = await dispatch(signUpUserThunk(tempData));
    if (result.payload.status === 201) {
      if (!isCreateNewUser && !isNetwork) {
        setSnackNotificationBar(true);
      } else {
        setSnackNotificationBar(false);
        dispatch(setMultiNotificationVariant("success"));
        dispatch(
          setMultiNotificationData([
            {
              propertyValue: `Hooray! Your account is created! Please check your email for details`,
            },
          ])
        );
      }
    } else {
      if (!isCreateNewUser && !isNetwork) {
        dispatch(setMultiNotificationVariant("error"));
        dispatch(
          setMultiNotificationData([
            {
              propertyValue: `Oh snap! Something broke on our end. Let’s try again.`,
            },
          ])
        );
      } else {
        setSnackNotificationBar(false);
        dispatch(setMultiNotificationVariant("error"));
        dispatch(
          setMultiNotificationData([
            {
              propertyValue: `Oh snap! Something broke on our end. Let’s try again.`,
            },
          ])
        );
      }
    }
  };
  useEffect(() => {
    if (openSnackbar === false) {
      navigate("/login");
    }
  }, [openSnackbar]);
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFilledInput-root": {
        background: "rgb(232, 241, 250)",
      },
    },
  }));

  const classes = useStyles();
  return (
    <Card
      className="card-container"
      sx={
        !isCreateNewUser && !isNetwork
          ? {
              width: {
                l: 480,
                sm: 350,
                ipadAir: 480,

                ipadMini: 350,
                // s : 300
              },
              position: isSignUpFormPage ? "relative" : "fixed",
              overflowY: "scroll",
              height: "auto",
              maxHeight: "91vh",
              flexFlow: "column",
              height: "83%",
              minHeight: "0",
              direction: "flex",
              flexWrap: "wrap",
            }
          : {
              width: "100%",
            }
      }
    >
      {snackNotificationBar ? (
        <Notification
          openSnackbar={openSnackbar}
          severity={snackNotificationBar === true ? "success" : "error"}
          message={
            snackNotificationBar === true
              ? `Hooray! Your account is created! Please check your email for details`
              : `Oh snap! Something broke on our end. Let’s try again.`
          }
          handleClose={closeSnackbar}
        />
      ) : null}

      {!isCreateNewUser && !isNetwork && (
        <CardHeader
          title="Welcome"
          titleTypographyProps={{ fontSize: "14px" }}
          sx={{
            backgroundColor: "#164e5f",
            color: "white",
            textAlign: "center",
          }}
        />
      )}

      <CardContent
        sx={{
          marginTop: isCreateNewUser ? "0rem" : "-0.2rem",

          paddingBottom: isCreateNewUser ? "1rem" : "0rem",
          paddingTop: "0.9rem",
          marginBottom: isNetwork ? "2rem" : "-2rem",
          height: isCreateNewUser ? "41vh" : "100%",
        }}
      >
        {!isCreateNewUser && !isNetwork && (
          <Typography sx={{ textAlign: "center" }}>Sign Up Below</Typography>
        )}

        <form
          className="login-form"
          id="hook-form"
          onSubmit={handleSubmit(handleLogin)}
          style={{
            marginTop: "0.8rem",
            marginBottom: "-.5rem",
            paddingBottom: "0.1rem",
          }}
        >
          <TextField
            id="firstName"
            label="Enter First Name"
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            {...register("firstName", { required: true })}
          />
          {errors.firstName && (
            <Typography
              variant="body1"
              sx={{
                padding: "0rem",
                marginTop: "-.7rem",
                marginBottom: "-.7rem",
              }}
              className="error-message"
            >
              Required field<sup>*</sup>
            </Typography>
          )}
          <TextField
            id="lastName"
            label="Enter Last Name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            {...register("lastName", { required: true })}
          />
          {errors.lastName && (
            <Typography
              variant="body1"
              sx={{
                padding: "0rem",
                marginTop: "-.7rem",
                marginBottom: "-.7rem",
              }}
              className="error-message"
            >
              Required field<sup>*</sup>
            </Typography>
          )}
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            size="small"
            type="email"
            defaultValue={emailAfterVerifcation}
            disabled={emailVerification !== "" ? true : false}
            fullWidth
            InputProps={{
              placeholder: "Enter Your Email",
              endAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            aria-invalid={errors.email ? "true" : "false"}
            {...register("email", { required: true })}
          />
          {errors.email && (
            <Typography
              variant="body1"
              sx={{
                padding: "0rem",
                marginTop: "-.7rem",
                marginBottom: "-.7rem",
              }}
              className="error-message"
            >
              Required field<sup>*</sup>
            </Typography>
          )}
          <TextField
            id="companyName"
            label="Company Legal Name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            InputProps={{
              className: classes.input,
              endAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon />
                </InputAdornment>
              ),
            }}
            {...register("companyName", { required: true })}
          />
          {errors.companyName && (
            <Typography
              variant="body1"
              sx={{
                padding: "0rem",
                marginTop: "-.7rem",
                marginBottom: "-.7rem",
              }}
              className="error-message"
            >
              Required field<sup>*</sup>
            </Typography>
          )}
          <TextField
            id="brandName"
            label="Brand Name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <StoreIcon />
                </InputAdornment>
              ),
            }}
            {...register("brandName", { required: true })}
          />
          {errors.brandName && (
            <Typography
              variant="body1"
              sx={{
                padding: "0rem",
                marginTop: "-.7rem",
                marginBottom: "-.4rem",
              }}
              className="error-message"
            >
              Required field<sup>*</sup>
            </Typography>
          )}
          <FormControl size="small">
            <InputLabel
              sx={{ fontSize: ".8rem", color: "#A9A9A9" }}
              id="demo-select-small-label"
            >
              Select Plan
            </InputLabel>
            {isCreateNewUser || isNetwork ? (
              isNetwork ? (
                <Select
                  onChange={(e) => setValue(e.target.value)}
                  value={planValue}
                  labelId="demo-select-small-label"
                  id="demo-simple-select"
                  sx={{ textAlign: "left" }}
                  label="Select Plan"
                  style={{ marginBottom: "0rem" }}
                >
                  {Object.values(PlansStrings).map((ele) => (
                    <MenuItem value={ele} key={ele}>
                      {ele}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Select
                  onChange={(e) => setValue(e.target.value)}
                  value={planValue}
                  labelId="demo-select-small-label"
                  id="demo-simple-select"
                  sx={{ textAlign: "left" }}
                  label="Select Plan"
                  style={{ marginBottom: "0rem" }}
                >
                  {Object.values(PlansStringsWithoudDiamondAndBase).map(
                    (ele) => (
                      <MenuItem value={ele} key={ele}>
                        {ele}
                      </MenuItem>
                    )
                  )}
                </Select>
              )
            ) : (
              <Select
                onChange={(e) => setValue(e.target.value)}
                value={planValue}
                labelId="demo-select-small-label"
                id="demo-simple-select"
                sx={{ textAlign: "left" }}
                label="Select Plan"
                style={{ marginBottom: "0rem" }}
              >
                {Object.values(PlansStringsWithoudDiamondAndBase).map((ele) => (
                  <MenuItem value={ele} key={ele}>
                    {ele}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          <Stack width={"198%"} spacing={2}>
            <Stack width={"97.5%"} direction={"row"}>
              <Stack width={{ sm: "15%", xs: "10%" }} spacing={1}>
                <DialoCodeAutocomplete
                  setValue={setFormValue}
                  register={register("countryCode", { required: true })}
                  defaultValue={"+1"}
                />
              </Stack>
              <Stack
                sx={{
                  width: isMobileScreen
                    ? {
                        sm: "36.5%",
                        xs: "25%",
                        ipadAir: "36.5%",
                        ipadMini: "36.5%",
                        md: "36.5%",
                        lg: "36.6%",
                      }
                    : {
                        sm: "36.5%",
                        xs: "27%",
                        ipadAir: "36.5%",
                        ipadMini: "36.5%",
                        md: "36.5%",
                        lg: "36.6%",
                      },
                }}
                spacing={1}
              >
                <TextField
                  id="phone"
                  sx={{ marginLeft: "20px" }}
                  size="small"
                  fullWidth
                  placeholder="Enter Phone Number"
                  type="number"
                  aria-invalid={errors.phoneNumber ? "true" : "false"}
                  {...register("phoneNumber", {
                    required: true,
                    maxLength: 10,
                  })}
                />
              </Stack>
            </Stack>
            {errors.phoneNumber && errors.phoneNumber.type === "maxLength" && (
              <Typography
                variant="body1"
                sx={{
                  padding: "0rem",
                  marginTop: "-.8rem",
                  marginBottom: "-.9rem",
                }}
                className="error-message"
              >
                Number lenght should of 10 digit.
              </Typography>
            )}
            {errors.phoneNumber && errors.phoneNumber.type === "required" && (
              <Typography
                variant="body1"
                sx={{
                  padding: "0rem",
                  marginTop: "-.8rem",
                  marginBottom: "-.9rem",
                }}
                className="error-message"
              >
                Required field<sup>*</sup>
              </Typography>
            )}
          </Stack>
          {!isCreateNewUser && !isNetwork ? (
            <>
              {!isCreateNewUser && !isNetwork && (
                <>
                  <FormGroup>
                    <FormControlLabel
                      style={{
                        marginTop: "-.5rem",
                      }}
                      control={<Checkbox />}
                      {...register("optIn", { required: true })}
                      label={
                        <Typography
                          variant="h7"
                          sx={{ alignItems: "center", marginTop: "-.1rem" }}
                        >
                          Opt-in to receive text messages on this mobile number{" "}
                        </Typography>
                      }
                      sx={{
                        paddingBottom: "-2rem",
                        marginBottom: "-1rem",
                        marginTop: ".5rem",
                        fontWeight: "400",
                        fontSize: "2rem",
                      }}
                    />
                  </FormGroup>

                  <Stack
                    spacing={2}
                    direction={"row"}
                    sx={{ marginLeft: "-0.2rem" }}
                  >
                    <Typography
                      sx={{
                        marginLeft: ".5rem !important",
                        marginTop: "-10rem",
                        paddingTop: "0.1rem",
                        textAlign: "left",
                      }}
                    >
                      <>
                        Opt-in is required to send critical transactional
                        messages such as One Time Passcode (OTP), change of
                        mobile number notification, MFA etc.
                      </>
                    </Typography>
                  </Stack>
                </>
              )}
              {errors.optIn && (
                <Typography
                  sx={{
                    padding: "0rem",
                    marginTop: "-.8rem",
                    marginBottom: "-.8rem",
                  }}
                  variant="body1"
                  className="error-message"
                >
                  Please check this box<sup>*</sup>
                </Typography>
              )}
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "-.4rem",
                  marginLeft: "-.65rem",
                }}
              >
                <Checkbox
                  sx={{ height: "fit-content" }}
                  {...register("iAccept", {
                    required: true,
                  })}
                />
                <Typography
                  sx={{
                    padding: "0rem",
                    marginTop: ".55rem",
                    color: "#000000",
                    marginLeft: "-.1rem",
                  }}
                >
                  Under penalty of perjury, I declare that I am the owner,
                  office or an authorized agent for the company with legal
                  authority to request and manage the company account with
                  FOCUS451 and all of the above information is current and will
                  keep it current. If my status with the company above changes,
                  I will transfer my credentials to an appropriate individual
                  responsible within the company. Further, I agree to FOCUS451’s
                  standard legal terms located at{" "}
                  <Link>https://www.FOCUS451.com/legal/terms. </Link>
                </Typography>
              </Stack>

              {errors.iAccept && (
                <Typography
                  variant="body1"
                  sx={{
                    padding: "0rem",
                    marginTop: "-.7rem",
                    marginBottom: "-.7rem",
                  }}
                  className="error-message"
                >
                  Please check this box<sup>*</sup>
                </Typography>
              )}
            </>
          ) : (
            <>
              {!isCreateNewUser && !isNetwork && (
                <>
                  <FormGroup>
                    <FormControlLabel
                      style={{
                        marginTop: "-.5rem",
                      }}
                      control={<Checkbox />}
                      label={
                        <Typography
                          variant="h7"
                          sx={{ alignItems: "center", marginTop: "-.1rem" }}
                        >
                          Opt-in to receive text messages on this mobile number{" "}
                        </Typography>
                      }
                      sx={{
                        paddingBottom: "-2rem",
                        marginBottom: "-1rem",
                        marginTop: ".5rem",
                        fontWeight: "400",
                        fontSize: "2rem",
                      }}
                    />
                  </FormGroup>

                  <Stack
                    spacing={2}
                    direction={"row"}
                    sx={{ marginLeft: "-0.2rem" }}
                  >
                    <Checkbox
                      id="manageEmployees"
                      sx={{
                        padding: "0px",
                        height: "fit-content",
                      }}
                    ></Checkbox>

                    <Typography
                      sx={{
                        marginLeft: ".5rem !important",
                        marginTop: "-10rem",
                        paddingTop: "0.1rem",
                        textAlign: "left",
                      }}
                    >
                      <>
                        When Opt-in is selected, the system will send critical
                        transactional messages such as One Time Passcode (OTP),
                        change of mobile number notification, MFA etc. Opt-in is
                        recommended to keep your account secure.
                      </>
                      <>
                        If Opt-in is not selected, all such messages will be
                        sent to the user’s current email on record. Opt-out is
                        not recommended.
                      </>
                    </Typography>
                  </Stack>
                </>
              )}
            </>
          )}

          <Button
            ref={formRef}
            id="submit"
            color="success"
            type="submit"
            variant="contained"
            sx={{
              width: "100%",
              color: "white",
              backgroundColor: "#29C34F",
              ":hover": {
                backgroundColor: "#2CAB4B",
              },
              marginTop: ".2rem",
              marginBottom: "1.5rem",
              display: isCreateNewUser || isNetwork ? "none" : "inline-flex",
            }}
          >
            Sign Up
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default SignUpFrom;
