import { Card, Stack } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "../../components/Dialog/Dialog";
import Loading from "../../components/Loading/Loading";
import Notification from "../../components/Notification/Notification";
import {
  eSignAgreementDashboardUser,
  eSignAgreementDataMapper,
} from "../../utils/apiDataMappers";
import { setAgreementPopUp } from "../Dashboard/DashboardSlice";
import { setIsEsign } from "../Login/LoginSlice";
import Agreement from "./Agreement";
import { eSignAgreementThunk, setAgreementNotify } from "./AgreementSlice";
import { pagesData, userPagesData } from "./AllTabData";
const AgreementDialog = ({}) => {
  const dispatch = useDispatch();
  const [loadingLocal, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const { agreementPopState } = useSelector((state) => state.dashboard);
  const {
    confirmUserResponse,
    optIn,
    eSignAgreementResponse,
    agreementNotification,
    loading,
  } = useSelector((state) => state.agreement);

  const {
    loggedInUserInfo: { role, accountType, employeeId, allowOnlySSOLogin },
  } = useSelector((state) => state.login);
  useEffect(() => {
    if (eSignAgreementResponse && eSignAgreementResponse.status === 200) {
      dispatch(setIsEsign(allowOnlySSOLogin));
      handleCloseDialog();
    } else if (eSignAgreementResponse) {
      dispatch(
        setAgreementNotify({
          message: "Something went wrong",
          openSnackbar: true,
          severity: "error",
        })
      );
    }
  }, [eSignAgreementResponse]);

  const handleClose = () => {
    dispatch(setAgreementPopUp(false));
  };
  const loadingFun = () => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const handleSubmit = () => {
    if (role === "ROLE_USER") {
      if (pageIndex !== 2) {
        setPageIndex((prev) => prev + 1);
        setLoading(true);
        loadingFun();
      } else {
        dispatch(eSignAgreementThunk(eSignAgreementDashboardUser(employeeId)));
      }
    } else {
      if (pageIndex !== 4) {
        setPageIndex((prev) => prev + 1);
        setLoading(true);
        loadingFun();
      } else {
        dispatch(
          eSignAgreementThunk(
            eSignAgreementDataMapper({
              employeeId: employeeId,
              optIn: optIn,
            })
          )
        );
      }
    }
  };
  const handleCloseDialog = () => {
    dispatch(
      setAgreementNotify({
        message: "",
        openSnackbar: false,
        severity: "success",
      })
    );
    dispatch(setAgreementPopUp(false));
  };

  return (
    <Dialog
      open={agreementPopState}
      headerText={
        loadingLocal ? "" : `Please sign ${pagesData[pageIndex].title}`
      }
      handleClose={handleCloseDialog}
    >
      {agreementNotification.openSnackbar ? (
        <Stack
          sx={{
            position: "fixed",
            zIndex: 12,
            direction: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Notification
            autoHideDuration={3000}
            vertical="top"
            message={agreementNotification.message}
            openSnackbar={agreementNotification.openSnackbar}
            severity={agreementNotification.severity}
            handleClose={handleClose}
          />
        </Stack>
      ) : null}
      <div>
        {loadingLocal ? (
          <Card
            sx={{
              width: "100%",
              boxShadow: 0,
            }}
          >
            <Stack
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                height: {
                  lg: "62vh",
                  md: "62vh",
                  sm: "61.2vh",
                  ipadAir: "50vh",
                  ipadMini: "52vh",
                  ipadAirLand: "60vh",
                  xs: "60vh",
                  bigScreen: "52vh",
                },
              }}
            >
              <Loading />
            </Stack>
          </Card>
        ) : (
          <Agreement
            optIn={optIn}
            role={role}
            pagesData={
              confirmUserResponse.accountOwner === true
                ? pagesData
                : userPagesData
            }
            page={pageIndex}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </Dialog>
  );
};

export default AgreementDialog;
