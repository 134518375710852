import { ajaxHandler } from "./ajaxHandler";
export const getBusinessLocationByBusinessId = async (businessId) => {
  const response = await ajaxHandler(
    "get",
    `focus451/location/findByBusiness/${businessId}`
  );
  return response.data;
};

export const postBusinessLocation = async (payload) => {
  const response = await ajaxHandler("post", `focus451/location/`, payload);
  return response;
};

export const getLocationById = async (locationId) => {
  const response = await ajaxHandler("get", `focus451/location/${locationId}`);
  return response.data;
};

export const updateLocation = async (payload) => {
  const response = await ajaxHandler(
    "put",
    `focus451/location/${payload.locationId}`,
    payload
  );
  return response;
};
