import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { hideNav } from "../../App.reducer";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { ReactComponent as Logo } from "../../assets/complianser-logo-with-text.svg";
import { Divider, Stack, Typography } from "@mui/material";
import {
  otpRegenerate,
  otpVerification,
  resetLogin,
  setEmailUser,
} from "./LoginSlice";
import Notification from "../../components/Notification/Notification";
import SNACKBAR_VARIATIONS from "../../constants/snackbar-variations";
import "./EmailVerify.css";
import Footer from "../../components/Footer/Footer";
import { resetAgreement } from "../Agreement/AgreementSlice";
import { resetDashboard } from "../Dashboard/DashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { resetTemplates } from "../Templates/TemplateSlice";
import SignUp from "./SignUp";
import { parseInt } from "lodash";
import { MuiOtpInput } from "mui-one-time-password-input";
import storage from "redux-persist/lib/storage";

const OTPVerfication = (defaultEmail) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const hide = useSelector((state) => state.common.hide);

  const [snackbarOpen, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const closeSnackbar = () => setOpenSnackbar(false);
  const [otpValue, setOtpValue] = useState();
  const [signUpForm, setSignUpPage] = useState(false);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  useEffect(() => {
    dispatch(resetDashboard());
    storage.removeItem("persist:root");
    clearCacheData();

    localStorage.clear();
    dispatch(resetLogin());
    dispatch(resetAgreement());
    dispatch(resetTemplates());
  }, []);

  useEffect(() => {
    if (!hide) {
      dispatch(hideNav());
    }
  }, []);

  const handleOTP = (e) => {
    setOtpValue(e);
  };
  const handleLogin = async (data) => {
    const result = await dispatch(
      otpVerification({
        emailId: defaultEmail.defaultEmail.emailId,
        otp: otpValue,
      })
    );
    const res = unwrapResult(result);
    if (res.status && res.status === 200) {
      dispatch(setEmailUser(res.data.data));
      navigate("/signup");
      setSignUpPage(true);
    } else {
      handleSnackbar("Please enter correct OTP", SNACKBAR_VARIATIONS.ERROR);
    }
  };
  const handleSendOTP = async () => {
    const result = await dispatch(
      otpRegenerate({
        emailId: defaultEmail.defaultEmail.emailId,
      })
    );
    const res = unwrapResult(result);
    if (res.status && res.status === 200) {
      handleSnackbar(
        "OTP has been sent successfully. Please check your email",
        SNACKBAR_VARIATIONS.SUCCESS
      );
    }
  };

  const handleSnackbar = (message, severity) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
  };

  const displaySnackbar = () => {
    return (
      <Notification
        openSnackbar={snackbarOpen}
        severity={snackbarSeverity}
        message={snackbarMessage}
        handleClose={closeSnackbar}
      />
    );
  };
  return (
    <>
      {signUpForm ? (
        <SignUp defaultEmail={defaultEmail} />
      ) : (
        <div className="login-container">
          <div className="logo-container">
            <Logo className="logoStyle" height={"34%"} />
          </div>
          <div className="login-card-container" style={{ marginTop: "0rem" }}>
            <Card
              sx={{
                width: { sm: 400, xs: 300 },
                // position: "fixed",
                marginTop: errors.defaultEmail
                  ? {
                      sm: "-4rem",
                      ipadAir: "0.2rem",
                      xs: "-3.6rem",
                      ipadMini: "0rem",
                    }
                  : {
                      sm: "-3.35rem",
                      xs: "-3.35rem",
                      ipadMini: "-3.35rem",
                      ipadAir: "-3.35rem",
                    },
                flexFlow: "column",
              }}
            >
              {snackbarOpen ? displaySnackbar() : null}

              <CardHeader
                title="Check Your Email"
                titleTypographyProps={{ fontSize: "16px" }}
                sx={{
                  backgroundColor: "#164e5f",
                  color: "white",
                }}
              />
              <CardContent>
                <Typography
                  sx={{
                    paddingLeft: "0px",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Please enter the verification code we sent to:<br></br>
                  <strong style={{ fontSize: "14px" }}>
                    {defaultEmail.defaultEmail.emailId}
                  </strong>
                </Typography>
                <Typography
                  sx={{ textAlign: "left", marginTop: "1.4rem" }}
                  variant={"body2"}
                >
                  Verification Code{" "}
                </Typography>
                <form
                  className="login-form"
                  onSubmit={handleSubmit(handleLogin)}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <MuiOtpInput
                    length={6}
                    value={otpValue}
                    onChange={handleOTP}
                  />
                  {errors.defaultEmail && (
                    <Typography variant="body1" className="error-message">
                      This field is required
                    </Typography>
                  )}

                  <div className="action-items">
                    <Button onClick={handleSendOTP}>
                      <Typography
                        sx={{
                          color: "#909fa7",
                          ":hover": { textDecoration: "underline" },
                        }}
                      >
                        Resend OTP to your Email address?
                      </Typography>
                    </Button>
                  </div>
                  <Button
                    id="submit"
                    type="submit"
                    color="success"
                    variant="contained"
                    sx={{
                      width: "100%",
                      color: "white",
                      backgroundColor: "#29C34F",
                      ":hover": {
                        backgroundColor: "#2CAB4B",
                      },
                    }}
                  >
                    Verify OTP
                  </Button>
                </form>
              </CardContent>
            </Card>
          </div>
          <Stack
            sx={{
              backgroundColor: "#F5F8FA",
              height: "5vh",
              position: "fixed",
              bottom: "0",
              width: "100%",
            }}
          >
            <Divider />
            <Footer />
          </Stack>
        </div>
      )}
    </>
  );
};

export default OTPVerfication;
