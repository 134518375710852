import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTemplatesData,
  getTemplatesInitiatedByEmployees,
  getEmployeesForManualTaskAssignment,
  confirmIfDuplicateTask,
  makeTempInactive,
  getCreateTemplateBusinessData,
  getMasterDepartment,
  updateTemplateAPI,
  saveDocProgressTaskAPI,
  uploadDocument,
  createNewTemplateAPI,
  getLocationsForManualTaskAssignment,
} from "../../services/templates";

export const getTemplates = createAsyncThunk(
  "templates/fetchTemplates",
  async (accountId, { rejectWithValue }) => {
    try {
      const response = await getTemplatesData(accountId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEmployeesForManualTaskAssignmentAction = createAsyncThunk(
  "templates/getEmployeesForManualTaskAssignment",
  async ({ employeeId, templateId }, { rejectWithValue }) => {
    try {
      const response = await getEmployeesForManualTaskAssignment(
        employeeId,
        templateId
      );
      response.data.forEach((employee) => {
        Array.isArray(employee?.departments)
          ? (employee["ROLES"] = employee.departments.reduce((prev, curr) => {
              prev += curr.name + "\n";
              return prev;
            }, ""))
          : (employee["ROLES"] = "");
        Array.isArray(employee?.locations)
          ? (employee["LOCATIONS"] = employee.locations.reduce((prev, curr) => {
              prev += curr.name + "\n";
              return prev;
            }, ""))
          : (employee["LOCATIONS"] = "");
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getLocationsForManualTaskAssignmentAction = createAsyncThunk(
  "templates/getLocationsForManualTaskAssignment",
  async ({ templateId }, { rejectWithValue }) => {
    try {
      const response = await getLocationsForManualTaskAssignment(templateId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const confirmIfDuplicateTaskAction = createAsyncThunk(
  "templates/confirmDuplicateTask",
  async ({ employeeId, templateId }, { rejectWithValue }) => {
    try {
      const response = await confirmIfDuplicateTask(employeeId, templateId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTemplatedInitiatedByEmployeesAction = createAsyncThunk(
  "templates/getTemplatedInitiatedByEmployees",
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await getTemplatesInitiatedByEmployees(employeeId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const makeTempInActive = createAsyncThunk(
  "templates/makeTempInActive",
  async (templateId, { rejectWithValue }) => {
    try {
      const response = await makeTempInactive(templateId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewTemplate = createAsyncThunk(
  "templates/createNewTemplate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await createNewTemplateAPI(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadDocumentSlice = createAsyncThunk(
  "templates/uploadDocumentSlice",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await uploadDocument(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveDocProgressTask = createAsyncThunk(
  "templates/saveDocProgressTask",
  async (data, { rejectWithValue }) => {
    try {
      const response = await saveDocProgressTaskAPI(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTemplate = createAsyncThunk(
  "templates/updateTemplate",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await updateTemplateAPI(id, payload);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMasterDept = createAsyncThunk(
  "templates/getMasterDept",
  async (businessId, { rejectWithValue }) => {
    try {
      const response = await getMasterDepartment(businessId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCreateTemplateBusiness = createAsyncThunk(
  "templates/getCreateTemplateBusiness",
  async (accountId, { rejectWithValue }) => {
    try {
      let response = await getCreateTemplateBusinessData(accountId);
      if (Array.isArray(response.data) && response.data.length) {
        await Promise.all(
          response.data.map(async (ins) => {
            let gmd = await getMasterDepartment(ins?.businessId);
            ins["whichRole"] = gmd.data;
            ins["tab"] = "location";
          })
        );
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  loading: false,
  premiumTabs: [],
  premium1: [],
  premium2: [],
  seletectedTemplateType: "new",
  selectedTemplateDocument: "",
  templateLoading: false,
  templates: [],
  toaster: "",
  loaderDoc: true,
  templateMetadata: {},
  uploadDocumentData: [],
  documentTemplates: [],
  documentDisplayView: true,
  confirmationView: false,
  employeeListView: false,
  locationListView: false,
  employeesForTaskAssignment: [],
  locationsForTaskAssignment: [],
  isLocationsFetched: false,
  isEmployeesFetched: false,
  isTaskDuplicate: false,
  isError: false,
  docFillableFields: {},
  docFieldsDataRadiobutton: {},
  errorMessage: "",
  selectedTemplateRow: {},
  tempBtnloading: false,
  businessTemplateLoader: false,
  businessTemplateData: [],
  newTemplateData: {
    templateProperties: {
      templateType: "EMPLOYEE",
      isBusinessCompliant: "no",
      isFranchisorCompliant: "no",
      visibleToEmployee: "no",
      employeeCanInitiate: "no",
      multiAssignable: "no",
      workflowDirection: "EMPLOYEE_ONLY",
      notificationPeriod: 30,
      expiryMonths: 0,
      employeeHireStageApplicable: "PRE_HIRE",
      forMultiOwnerAssignment: false,
      forManualAssignment: false,
      actionRequired: "READ_AND_FILL",
      supportingDocRequired: false,
      version: "DRAFT",
      pdfName: "",
    },
    applicableAts: null,
    applicableAtToFranchiser: {},
    fillableFieldPropertiesList: [],
    supportingDocuments: [],
    effectiveFromTime: null,
    templateStatus: "INACTIVE",
    name: "",
    description: "",
    accountId: 0,
    supplementalDocFileArr: [],
  },
  basicDetailLoader: false,
  expiryDate: { 0: new Date() },
  selectedChecksState: {},
  isReload: false,
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setPremium1: (state, action) => {
      state.premium1 = action.payload;
    },
    setPremium2: (state, action) => {
      state.premium2 = action.payload;
    },
    setSelectedTemplateType: (state, action) => {
      state.seletectedTemplateType = action.payload;
    },
    setSelectedTemplateDocument: (state, action) => {
      state.selectedTemplateDocument = action.payload;
    },
    resetSelectedTemplateDocument: (state) => {
      state.selectedTemplateDocument = "";
    },
    setIsReload: (state, action) => {
      state.isReload = action.payload;
    },
    setExpiryDate: (state, action) => {
      state.expiryDate = action.payload;
    },
    setLoaderDoc: (state, action) => {
      state.loaderDoc = action.payload;
    },
    resetExpiryDate: (state, action) => {
      state.expiryDate = null;
    },
    setUploadDocumentData: (state, action) => {
      state.uploadDocumentData = action.payload;
    },
    setpremiumTabs: (state, action) => {
      state.premiumTabs = action.payload;
    },
    resetTemplates: (state) => initialState,
    resetFillableProperties: (state) => {
      state.newTemplateData.fillableFieldPropertiesList = [];
    },
    setAccountId: (state, action) => {
      state.newTemplateData.accountId = action.payload;
    },
    setSelectedChecksState: (state, action) => {
      state.selectedChecksState = action.payload;
    },
    setSupplementalDocFileArr: (state, action) => {
      state.supplementalDocFileArr = action.payload;
    },
    setSupportingDocuments: (state, action) => {
      state.newTemplateData.supportingDocuments = action.payload;
    },
    setdocFillableFields: (state, action) => {
      state.docFillableFields = action.payload;
    },
    setDocFieldsDataRadiobutton: (state, action) => {
      state.docFieldsDataRadiobutton = action.payload;
    },
    setTemplateType: (state, action) => {
      state.newTemplateData.templateProperties.templateType = action.payload;
    },
    setTemplateActionRequired: (state, action) => {
      state.newTemplateData.templateProperties.actionRequired = action.payload;
    },
    setTemplateWorkFlowDirection: (state, action) => {
      state.newTemplateData.templateProperties.workflowDirection =
        action.payload;
    },
    setEmployeeHireStageApplicable: (state, action) => {
      state.newTemplateData.templateProperties.employeeHireStageApplicable =
        action.payload;
    },
    setSelectedTemplateRow: (state, action) => {
      state.selectedTemplateRow = action.payload;
    },
    setTemplateName: (state, action) => {
      state.newTemplateData.name = action.payload;
    },
    setTemplateDesc: (state, action) => {
      state.newTemplateData.description = action.payload;
    },
    setNotificationPeriod: (state, action) => {
      state.newTemplateData.templateProperties.notificationPeriod =
        action.payload;
    },
    setExpiryMonths: (state, action) => {
      state.newTemplateData.templateProperties.expiryMonths = action.payload;
    },
    setBusinessCompliant: (state, action) => {
      state.newTemplateData.templateProperties.isBusinessCompliant =
        action.payload;
    },
    setFranchisorCompliant: (state, action) => {
      state.newTemplateData.templateProperties.isFranchisorCompliant =
        action.payload;
    },
    setVisibleToEmployee: (state, action) => {
      state.newTemplateData.templateProperties.visibleToEmployee =
        action.payload;
    },
    setEmployeeCanInitiate: (state, action) => {
      state.newTemplateData.templateProperties.employeeCanInitiate =
        action.payload;
    },
    setMultiAssignable: (state, action) => {
      state.newTemplateData.templateProperties.multiAssignable = action.payload;
    },
    setBusinessTemplateData: (state, action) => {
      state.businessTemplateData = action.payload;
    },
    setApplicableAts: (state, action) => {
      state.newTemplateData.applicableAts = action.payload;
    },
    setNewTemplateData: (state, action) => {
      state.newTemplateData = action.payload;
    },
    setFillableProperties: (state, action) => {
      state.newTemplateData.fillableFieldPropertiesList = [...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(makeTempInActive.pending, (state, action) => {
      state.tempBtnloading = true;
    });
    builder.addCase(makeTempInActive.fulfilled, (state, action) => {
      state.tempBtnloading = false;
    });
    builder.addCase(makeTempInActive.rejected, (state, action) => {
      state.isError = true;
      state.tempBtnloading = false;
      state.errorMessage = action.payload.detailMessage;
    });

    builder.addCase(getCreateTemplateBusiness.pending, (state, action) => {
      state.businessTemplateLoader = true;
    });
    builder.addCase(getCreateTemplateBusiness.fulfilled, (state, action) => {
      state.businessTemplateData = action.payload.data;
      state.businessTemplateLoader = false;
    });
    builder.addCase(getCreateTemplateBusiness.rejected, (state, action) => {
      state.isError = true;
      state.businessTemplateLoader = false;
      // state.errorMessage = action.payload.detailMessage;
    });

    builder.addCase(getTemplates.pending, (state, action) => {
      state.templateLoading = true;
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      const { content, ...restOfData } = action.payload;
      state.templates = content.map((row) => {
        row.templateStage = row.templateProperties.employeeHireStageApplicable;
        return row;
      });
      state.templateMetadata = restOfData;
      state.templateLoading = false;
    });
    builder.addCase(getTemplates.rejected, (state, action) => {
      state.isError = true;
      state.templateLoading = false;
      state.errorMessage = action.payload.detailMessage;
    });
    builder.addCase(
      getTemplatedInitiatedByEmployeesAction.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getTemplatedInitiatedByEmployeesAction.fulfilled,
      (state, action) => {
        state.documentTemplates = action.payload.data;
        state.loading = false;
      }
    );
    builder.addCase(
      getTemplatedInitiatedByEmployeesAction.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );
    builder.addCase(
      getEmployeesForManualTaskAssignmentAction.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getEmployeesForManualTaskAssignmentAction.fulfilled,
      (state, action) => {
        state.employeesForTaskAssignment = action.payload.data;
        state.documentDisplayView = false;
        state.employeeListView = true;
        state.isEmployeesFetched = true;
        state.loading = false;
      }
    );
    builder.addCase(
      getEmployeesForManualTaskAssignmentAction.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        state.errorMessage = action.payload.detailMessage;
      }
    );

    builder.addCase(
      getLocationsForManualTaskAssignmentAction.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getLocationsForManualTaskAssignmentAction.fulfilled,
      (state, action) => {
        state.locationsForTaskAssignment = action.payload.data;
        state.documentDisplayView = false;
        state.locationListView = true;
        state.isLocationsFetched = true;
        state.loading = false;
      }
    );
    builder.addCase(
      getLocationsForManualTaskAssignmentAction.rejected,
      (state, action) => {
        state.isError = true;
        state.loading = false;
        // state.errorMessage = action.payload.detailMessage;
      }
    );

    builder.addCase(uploadDocumentSlice.pending, (state, action) => {
      // state.loading = true;
      state.toaster = true;
    });
    builder.addCase(uploadDocumentSlice.fulfilled, (state, action) => {
      state.toaster = false;
    });
    builder.addCase(uploadDocumentSlice.rejected, (state, action) => {
      state.toaster = true;
    });

    builder.addCase(confirmIfDuplicateTaskAction.fulfilled, (state, action) => {
      state.isTaskDuplicate = action.payload.data;
      state.confirmationView = true;
      state.employeeListView = false;
      state.locationListView = false;
    });

    builder.addCase(confirmIfDuplicateTaskAction.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
      state.errorMessage = action.payload.detailMessage;
    });
  },
});

export const {
  setSelectedTemplateType,
  setEmployeeCanInitiate,
  setFranchisorCompliant,
  setVisibleToEmployee,
  setBusinessCompliant,
  setNotificationPeriod,
  resetTemplates,
  setExpiryMonths,
  setSelectedTemplateRow,
  setPremium1,
  setPremium2,
  setMultiAssignable,
  setTemplateName,
  setTemplateDesc,
  setBusinessTemplateData,
  setApplicableAts,
  setEmployeeHireStageApplicable,
  setTemplateType,
  setNewTemplateData,
  setSupportingDocuments,
  setSupplementalDocFileArr,
  setIsReload,
  setSelectedChecksState,
  setAccountId,
  setFillableProperties,
  setUploadDocumentData,
  setExpiryDate,
  setdocFillableFields,
  setDocFieldsDataRadiobutton,
  resetExpiryDate,
  setTemplateActionRequired,
  setTemplateWorkFlowDirection,
  resetFillableProperties,
  setLoaderDoc,
  setSelectedTemplateDocument,
  resetSelectedTemplateDocument,
  setpremiumTabs,
} = templatesSlice.actions;
export default templatesSlice.reducer;
