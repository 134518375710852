import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FormFields from "../../../../components/FormComponents/FormFields";
import PhoneFields from "../../../../components/FormComponents/PhoneFields";
import AddressFields from "../../../../components/FormComponents/AddressFields";
import SelectCustome from "../../../../components/FormComponents/SelectCustome";
import EmergencyContact from "../../../../components/FormComponents/EmergencyContact";
import EmployeementNextStage from "../../../../components/FormComponents/EmployeementNextStage";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DateField from "../../../../components/FormComponents/DateField";
import CustomeDatePicker from "../../../../components/FormComponents/CustomeDatePicker";
import { useSelector } from "react-redux";
const IndividualProfileEdit = ({
  register,
  employeeData,
  errors,
  setValue,
  handleReSendPassword,
  editData = { employmentStage: "HIRE" },
}) => {
  const selectObjStatic = {
    PRE_HIRE: {
      HIRE: "Hire",
      NOT_HIRED: "Not Hired",
    },
    NOT_HIRED: {
      NOT_HIRED: "Not Hired",
    },
    TERMINATED: {
      TERMINATED: "TERMINATED",
    },
    HIRE: {
      MANAGE: "Manage (Active)",
      NOT_HIRED: "Not Hired",
    },
    RETIRE: {
      MANAGE: "Manage (Active)",
      TERMINATED: "Terminated",
    },
    MANAGE: {
      RETIRE: "Retire (Active)",
      TERMINATED: "Terminated",
    },
  };
  const [selectObj, setSelectObj] = useState(
    selectObjStatic[editData["employeementStage"]]
  );
  const [radioState, setRadioState] = useState(editData.canBeReHired);
  const handleChange = (e, data) => {
    setValue("canBeReHired", data);
    setRadioState(data);
  };
  const {
    loggedInUserInfo: { allowOnlySSOLogin },
  } = useSelector((state) => state.login);
  useEffect(() => {
    editData.employmentStage &&
      setSelectObj(selectObjStatic[editData.employmentStage]);
    setRadioState(editData.canBeReHired);
  }, [editData]);
  return (
    <Grid container>
      {employeeData && employeeData.password === null && !allowOnlySSOLogin ? (
        <Grid item lg={12} xs={12} sm={12} md={12} pt={-12}>
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems="center"
            paddingLeft={"1rem"}
            paddingTop={"1rem"}
          >
            <Button
              id="resendPassword"
              onClick={handleReSendPassword}
              sx={{ color: "#93c" }}
            >
              Click here
            </Button>
            <Typography> to resend account activation E-mail.</Typography>
          </Stack>
        </Grid>
      ) : null}
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <FormFields
          register={register}
          errors={errors}
          setValueName={"firstName"}
          placeholder={"Enter First Name"}
          label="First Name"
          disabled={allowOnlySSOLogin}
        />
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <FormFields
          register={register}
          errors={errors}
          setValueName={"lastName"}
          placeholder={"Enter Last Name"}
          label="Last Name"
          disabled={allowOnlySSOLogin}
        />
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <FormFields
          register={register}
          errors={errors}
          setValueName={"email"}
          placeholder={"Enter Email Address"}
          label="Email"
          disabled={allowOnlySSOLogin}
        />
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <AddressFields
          register={register}
          errors={errors}
          disabled={allowOnlySSOLogin}
        />
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <PhoneFields
          setValue={setValue}
          register={register}
          errors={errors}
          defaultValue={editData.dialCode ? editData.dialCode : "+1"}
          disabled={allowOnlySSOLogin}
        />
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <SelectCustome
          defaultValue={editData.employmentStage}
          disabled={true}
          label={"Employment Stage"}
          setValueName="employmentStage"
          errors={errors}
          setValue={setValue}
        />
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        {editData.employmentStage !== "TERMINATED" &&
        editData.employmentStage !== "NOT_HIRED" ? (
          <EmployeementNextStage
            disabled={allowOnlySSOLogin}
            defaultValueSelect={
              editData.nextHireStage ? editData.nextHireStage : "se"
            }
            register={register}
            setValue={setValue}
            errors={errors}
            selectObj={selectObj}
            defaultDate={editData.hireStageChangeDate}
          />
        ) : editData.employmentStage === "TERMINATED" ? (
          <Box sx={{ p: "0 1em" }}>
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent="flex-start"
              sx={{ marginTop: "1em" }}
            >
              <FiberManualRecordIcon
                sx={{
                  marginTop: "0.18em",
                  marginLeft: "-1em",
                  marginRight: "0.5em",
                  color: "#CCCCCC",
                  height: "0.5em",
                }}
              />
              <Stack width={"100%"} spacing={2}>
                <Typography variant="h7">Eligible for Re-Hire</Typography>
                <RadioGroup
                  value={radioState}
                  onChange={handleChange}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                    disabled={allowOnlySSOLogin}
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </Stack>
            </Stack>
          </Box>
        ) : null}
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <FormFields
          disabled
          register={register}
          errors={errors}
          setValueName={"loginId"}
          placeholder={"Enter Login Id"}
          label="Login ID"
        />
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <Box sx={{ p: "0 1em" }}>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <CustomeDatePicker
              defaultValue={editData.dob}
              setValue={setValue}
              setValueName={"dob"}
              placeholder={"Enter Social Security Number"}
              label="Date of Birth"
              disabled={allowOnlySSOLogin}
            />
          </Stack>
        </Box>
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <FormFields
          register={register}
          errors={errors}
          setValueName={"ssn"}
          placeholder={"Enter Social Security Number"}
          disabled={allowOnlySSOLogin}
          label="Social Security Number"
        />
      </Grid>
      <Grid
        paddingBottom={{ bigScreen: "1rem" }}
        item
        lg={12}
        xs={12}
        sm={12}
        md={12}
      >
        <EmergencyContact
          setValue={setValue}
          register={register}
          disabled={allowOnlySSOLogin}
          errors={errors}
          defaultValueDial={
            editData.emergencyDialCode ? editData.emergencyDialCode : "+1"
          }
        />
      </Grid>
    </Grid>
  );
};

export default IndividualProfileEdit;
