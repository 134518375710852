import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllBrand } from "../../services/share";
const initialState = {
  isShareConcent: false,
  shareConcentIntial: "",
  isShareOption: "COMMUNITY",
  selectedTemplate: null,
  isImportSelected: false,
  allBrand: null,
  selectedShareBrand: [],
  selectedSteps: "Shared with",
  activeStep: 0,
};
export const getAllBrandThunk = createAsyncThunk(
  "share/getAllBrand",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllBrand(data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const ShareSlice = createSlice({
  name: "share",
  initialState,
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setSelectedSteps: (state, action) => {
      state.selectedSteps = action.payload;
    },
    setSelectedShareBrand: (state, action) => {
      state.selectedShareBrand = action.payload;
    },
    clearSelectedShareBrand: (state, action) => {
      state.selectedShareBrand = [];
    },
    setIsImportSelected: (state, action) => {
      state.isImportSelected = action.payload;
    },
    resetShare: (state) => initialState,
    setIsShareConcent: (state, action) => {
      state.isShareConcent = action.payload;
    },
    setshareConcentIntial: (state, action) => {
      state.shareConcentIntial = action.payload;
    },
    setIsShareOption: (state, action) => {
      state.isShareOption = action.payload;
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBrandThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllBrandThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.allBrand = action.payload;
    });
    builder.addCase(getAllBrandThunk.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  setIsImportSelected,
  setIsShareConcent,
  setSelectedTemplate,
  setIsShareOption,
  setshareConcentIntial,
  resetShare,
  setSelectedShareBrand,
  clearSelectedShareBrand,
  setSelectedSteps,
  setActiveStep,
} = ShareSlice.actions;
export default ShareSlice.reducer;
