import axios from "axios";
import { isTokenValid } from "../utils/checkTokenValidity";
import { env } from "../env";

const baseUrl = env.REACT_APP_BASE_URL;
export const ajaxHandler = async (method, url, request, header) => {
  const axiosInstance = axios.create();
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");
  if (
    token &&
    refreshToken &&
    !url.includes("login") &&
    !url.includes("logout") &&
    !url.includes("validateSSO") &&
    !isTokenValid(token)
  ) {
    const refreshResponse = await refreshApiCall(refreshToken);
    if (refreshResponse.status !== 200) {
      return refreshResponse;
    }
  }

  axiosInstance.interceptors.request.use((config) => {
    config.method = method;
    config.url = `${baseUrl}${url}`;
    config.data = request;
    if (header !== undefined && header !== null && header.length > 0) {
      config.headers = header;
    }
    if (localStorage.getItem("token")) {
      config.headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
    }
    return config;
  });

  try {
    const res = await axiosInstance();
    return res;
  } catch (err) {
    return err.response;
  }
};

const refreshApiCall = async (refreshToken) => {
  try {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use((config) => {
      config.method = "get";
      config.url = `${baseUrl}focus451/tokenService/refreshjwt`;
      config.data = {
        jwt: refreshToken,
      };
      if (localStorage.getItem("token")) {
        config.headers = {
          "x-auth-token": localStorage.getItem("token"),
          "x-refresh-token": refreshToken,
        };
      }
      return config;
    });
    const res = await axiosInstance();
    if (res.status === 200) {
      const token = res.data.jwt;
      localStorage.setItem("token", token);
      return res;
    }
    return false;
  } catch (err) {
    return err.status;
  }
};
