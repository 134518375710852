import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PostNewBusiness } from "../../services/newbusiness";

export const saveNewBusiness = createAsyncThunk(
    "business/createNewBusiness",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await PostNewBusiness(payload);
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {
    loading: false,
    isError: false,
    errorMessage: "",
    createBusinessData: "",
    PostNewBusiness: [],

};

export const newBusinessSlice = createSlice({
    name: "newBusiness",
    initialState,
    reducers: {
        setNewBusiness: (state, action) => {
            state.PostNewBusiness = action.payload;
        },
        setCreateActionResponse: (state, action) => {
            state.createBusinessData = action.payload;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(saveNewBusiness.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(saveNewBusiness.fulfilled, (state, action) => {
            state.loading = false;
            state.createBusinessData = "success";
            state.PostNewBusiness = action.payload
        });
        builder.addCase(saveNewBusiness.rejected, (state, action) => {
            state.isError = true;
            state.loading = false;
            state.errorMessage = action.payload;
        });

    },
});

export const { setNewBusiness, setCreateActionResponse } = newBusinessSlice.actions;
export default newBusinessSlice.reducer;
