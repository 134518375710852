import { ajaxHandler } from "./ajaxHandler";
export const getMasterDepartmentsListByBusiness = async (businessId) => {
  const response = await ajaxHandler(
    "get",
    `focus451/department/masterDepartment/${businessId}`
  );
  return response.data;
};

export const postMasterDepartment = async (payload) => {
  const response = await ajaxHandler(
    "post",
    `focus451/department/saveMasterDepartment/`,
    payload
  );
  return response;
};

export const getMasterDepartmentsListById = async (departmentId) => {
  const response = await ajaxHandler(
    "get",
    `focus451/department/getMasterDepartmentById/${departmentId}`
  );
  return response.data;
};

export const updateMasterDepartment = async (payload) => {
  const response = await ajaxHandler(
    "put",
    `focus451/department/updateMasterDepartment/${payload.masterDepartmentId}`,
    payload
  );
  return response;
};
