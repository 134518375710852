import React, { useEffect, useState } from "react";
import CommonHeaderButtonIndividual from "./CommonHeaderButtonIndividual";
import IndividualProfileEdit from "./IndividualProfileEdit";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  editEmployeeById,
  getByBusinessAndEmployeeRole,
  getEmployee,
  getLocationFranchisorThunk,
  resendOTPThunk,
  setEditEmployeeDataResponse,
  setIndividualData,
} from "../../EmployeeSlice";
import {
  getIndividualDataMapper,
  getIndividualFranchisorDataMapper,
  individualPutFinalDataMapper,
  individualPutFinalFranchisorDataMapper,
  managerDepartmentMapper,
} from "../../../../utils/apiDataMappers";
import IndividualLocationRoleEdit from "./IndividualLocationRoleEdit";
import IndividualHistory from "./IndividualHistory";
import DocumentsWrapper from "./Docs/DocumentsWrapper";
import { getIndividualDocsThunk } from "../../EmployeeSlice";
import Loading from "../../../../components/Loading/Loading";
import IndividualLocationRoleEditFranchisor from "./IndividualLocationRoleEditFranchisor";
import { setBussinessNotification } from "../../DashboardSlice";
import {
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../../../components/Notification/Notification.slice";
import { setDialogSelectedEntity } from "../../BusinessLocationSlice";
const IndividulaEditForm = ({ setIsEdit, setSelected, editData, setIndex }) => {
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .nullable("First Name is required")
      .required("First Name is required"),
    lastName: yup
      .string()
      .nullable("Last Name is required")
      .required("Last Name is required"),
    email: yup
      .string()
      .nullable("Email Address is required")
      .required("Email Address is required"),
    line1: yup
      .string()
      .nullable("Address line 1 is required")
      .required("Address line 1 is required"),
    line2: yup.string(),
    city: yup
      .string()
      .nullable("City is required")
      .required("City is required"),
    state: yup
      .string()
      .nullable("State is required")
      .required("State is required"),
    country: yup
      .string()
      .nullable("Country is required")
      .required("Country is required"),
    pinCode: yup
      .number("Zip Code must be a number")
      .typeError("Please enter valid number")
      .nullable("Zip Code is required")
      .required("Zip Code is required"),
    // dialCode: yup
    //   .string()
    //   .nullable("Dial Code is required")
    //   .required("Dial Code is required"),
    phone: yup
      .string()
      .nullable("Phone is required")
      .required("Phone is required"),
    loginId: yup
      .string()
      .nullable("Login Id is Required")
      .required("Login Id is Required"),
    ssn: yup
      .string()
      .nullable()
      .required("Please enter Social Security Number"),
    emergencyName: yup
      .string()
      .nullable()
      .required("Emergency Contact Name is required"),
    emergencyAddress: yup
      .string()
      .nullable()
      .required("Emergency Contact Address is required"),
    emergencyEmail: yup
      .string()
      .nullable()
      .email("Please enter valid email address")
      .required("Emergency Contact Email is required"),
    // emergencyDialCode: yup
    //   .string()
    //   .required("Emergency Dial Code is required "),
    emergencyPhone: yup
      .string()
      .nullable()
      .required("Emergency Phone Number is required"),
    dob: yup.string().nullable().required("Please Select Date Of Birth"),
  });
  const ssoSchema = yup.object().shape({});
  const intialData = {
    dob: "",
    emergencyPhone: "",
    emergencyEmail: "",
    emergencyAddress: "",
    emergencyName: "",
    ssn: "",
    loginId: "",
    phone: "",
    dialCode: "+1",
    pinCode: "",
    country: "",
    state: "",
    city: "",
    line1: "",
    line2: "",
    email: "",
    lastName: "",
    firstName: "",
    isFormClear: false,
    departmentArray: [],
    locationArrayObj: {},
  };
  const {
    loggedInUserInfo: {
      allowOnlySSOLogin,
      employeeId,
      accountType,
      dashboardHierarchy: { franchisorId },
    },
  } = useSelector((state) => state.login);
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: intialData,
    resolver: yupResolver(allowOnlySSOLogin ? ssoSchema : schema),
    mode: "onChange",
  });
  const [selectRole, setSelectRole] = useState("Regular");
  const [editTabIndex, setEditTabIndex] = useState(0);
  const [localProfileEditData, setLocalProfileEditData] = useState("");
  const [selectedChecksManager, setSelectedChecksManager] = useState({});
  const [errorField, setErrorField] = useState({});
  const dispatch = useDispatch();
  const [selectedChecks, setSelectedChecks] = useState({});
  const [selectParentCheck, setSelectParentCheck] = useState({});
  const { selectedBussinessDetails } = useSelector((state) => state.dashboard);
  const {
    employeeData,
    businessAndEmpRoleData,
    editEmployeeData,
    getLocationFranchisorResponse,
  } = useSelector((state) => state.employee);
  useEffect(() => {
    dispatch(getEmployee(editData.id));
    if (accountType === "FRANCHISOR") {
      dispatch(getLocationFranchisorThunk(franchisorId));
    } else {
      dispatch(
        getByBusinessAndEmployeeRole({
          userId: employeeId,
          businessId: selectedBussinessDetails.businessId,
        })
      );
    }
  }, [editData]);
  useEffect(() => {
    if (editEmployeeData && editEmployeeData.status === 200) {
      dispatch(setMultiNotificationVariant("success"));
      dispatch(
        setMultiNotificationData([
          {
            propertyValue: `Individual Updated Successfully`,
          },
        ])
      );
      handleCancel();
    } else if (editEmployeeData) {
      dispatch(setMultiNotificationVariant("error"));
      let errorArray = editEmployeeData?.data?.validationErrors?.length
        ? editEmployeeData?.data.validationErrors
        : [{ propertyValue: "Something went Wrong" }];
      dispatch(setMultiNotificationData(errorArray));
    }
  }, [editEmployeeData]);
  const handleCancel = () => {
    dispatch(setDialogSelectedEntity(""));
    dispatch(setEditEmployeeDataResponse(""));
    dispatch(setIndividualData(""));
    if (accountType === "FRANCHISOR") {
      setIndex(2);
    } else {
      setIndex(3);
    }
    setSelected("");
    setIsEdit(false);
    reset();
  };
  useEffect(() => {
    if (employeeData.email) {
      if (accountType === "FRANCHISOR") {
        let temp = getIndividualFranchisorDataMapper(employeeData);
        setLocalProfileEditData(temp);
        reset(temp);
        setSelectRole(temp.selectRole);
        setSelectedChecks(temp.selectedRegion);
      } else {
        let temp = getIndividualDataMapper(employeeData, allowOnlySSOLogin);
        setLocalProfileEditData(temp);
        reset(temp);
        rolesSubmit(temp.locationAndRoles);
        setSelectedChecks(temp.locationAndRoles);
        setSelectParentCheck(temp.managerLocationSelect);
        setSelectRole(temp.selectRole);
        setSelectedChecksManager(
          Array.isArray(temp.mangerDepartmentSelect)
            ? managerDepartmentMapper(
                temp.mangerDepartmentSelect,
                businessAndEmpRoleData
              )
            : {}
        );
      }
    }
  }, [employeeData]);

  const submit = (data) => {
    setValue("isFormClear", true);
  };

  const rolesSubmit = (selectedChecks) => {
    let departments = [];
    let locationArrayObj = {};
    let flag = true;
    if (selectedChecks && Object.values(selectedChecks).length) {
      let looperData = Object.values(selectedChecks);
      for (let i = 0; i < looperData.length; i++) {
        if (Object.keys(looperData[i]).length === 1) {
          setErrorField((prev) => ({
            ...prev,
            [looperData[i].locationId]: true,
          }));
          flag = false;
          break;
        } else {
          let temp = JSON.parse(JSON.stringify(looperData[i]));
          if (!locationArrayObj[temp.locationId]) {
            locationArrayObj[temp.locationId] = true;
          }
          delete temp["locationId"];
          departments = [...departments, ...Object.values(temp)];
        }
      }
      setValue("departmentsArray", departments);
      setValue("locationArrayObj", locationArrayObj);
    } else {
      setValue("departmentsArray", []);
      setValue("locationArrayObj", {});
    }
    return {
      isClear: flag,
      departmentsArray: departments,
      location: locationArrayObj,
    };
  };
  const isError = () => {
    setValue("isFormClear", false);
  };
  const handelTabChange = async (e, selectedIndex) => {
    if (allowOnlySSOLogin) {
      setEditTabIndex(selectedIndex);
      return;
    }
    if (accountType !== "FRANCHISOR") {
      if (editTabIndex === 1) {
        if (rolesSubmit(selectedChecks).isClear) {
          setEditTabIndex(selectedIndex);
        } else {
          return;
        }
      }
    }
    if (editTabIndex === 0) {
      await handleSubmit(submit, isError)();
      if (getValues("isFormClear")) {
        setEditTabIndex(selectedIndex);
      } else {
        return;
      }
    }
    setEditTabIndex(selectedIndex);
  };
  const finalSubmit = async (isFinal = false) => {
    if (editTabIndex === 0) {
      await handleSubmit(submit, isError)();
      if (!getValues("isFormClear")) {
        return;
      }
    }
    if (accountType !== "FRANCHISOR" && !allowOnlySSOLogin) {
      if (editTabIndex === 1) {
        if (!rolesSubmit(selectedChecks).isClear) {
          return;
        }
      }
    }
    if (accountType === "FRANCHISOR") {
      dispatch(
        editEmployeeById({
          employeeId: editData.id,
          payload: individualPutFinalFranchisorDataMapper(
            employeeData,
            getValues(),
            selectedChecks
          ),
        })
      );
    } else {
      dispatch(
        editEmployeeById({
          employeeId: editData.id,
          payload: individualPutFinalDataMapper(
            employeeData,
            getValues(),
            selectParentCheck,
            selectedChecksManager,
            allowOnlySSOLogin
          ),
        })
      );
    }
  };
  const handleReSendPassword = () => {
    dispatch(resendOTPThunk(employeeData.employeeId));
  };
  return (
    <div style={{ width: "100%" }}>
      {localProfileEditData.email ? (
        <div>
          <Stack width={"100%"}>
            <CommonHeaderButtonIndividual
              index={editTabIndex}
              submitFinal={finalSubmit}
              submit={handleSubmit(submit)}
              handleCancel={handleCancel}
              handelTabChange={handelTabChange}
            />
          </Stack>
          <Grid
            container
            sx={{
              height: {
                md: "65vh",
                xs: "160vh",
                lg: "70vh",
                ipadMini: "75vh",
                ipadAir: "70vh",
                bigScreen: "69h",
                sm: "65vh",
              },
              maxHeight: {
                md: "65vh",
                sm: "65vh",
                xs: "160vh",
                lg: "70vh",
                ipadMini: "75vh",
                ipadAir: "70vh",
                bigScreen: "69vh",
              },
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: 10,
              },
              "&:hover": {
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#9e9e9e",
                  borderRadius: "50px",
                  ":hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.35)",
                  },
                },
              },
            }}
          >
            <Grid item lg={12} xs={12} sm={12} md={12}>
              {editTabIndex === 0 ? (
                <IndividualProfileEdit
                  editData={localProfileEditData.email && localProfileEditData}
                  setValue={setValue}
                  register={register}
                  errors={errors}
                  employeeData={employeeData}
                  reset={reset}
                  handleReSendPassword={handleReSendPassword}
                />
              ) : editTabIndex === 1 ? (
                accountType === "FRANCHISOR" ? (
                  <IndividualLocationRoleEditFranchisor
                    setSelectRole={setSelectRole}
                    setErrorField={setErrorField}
                    selectedChecksManager={selectedChecksManager}
                    setSelectedChecksManager={setSelectedChecksManager}
                    selectParentCheck={selectParentCheck}
                    setSelectParentCheck={setSelectParentCheck}
                    selectedChecks={selectedChecks}
                    setSelectedChecks={setSelectedChecks}
                    errorField={errorField}
                    roleData={businessAndEmpRoleData}
                    setValue={setValue}
                    selectRole={selectRole}
                  />
                ) : (
                  <IndividualLocationRoleEdit
                    isSSOLogin={allowOnlySSOLogin}
                    selectRole={selectRole}
                    setSelectRole={setSelectRole}
                    setErrorField={setErrorField}
                    selectedChecksManager={selectedChecksManager}
                    setSelectedChecksManager={setSelectedChecksManager}
                    selectParentCheck={selectParentCheck}
                    setSelectParentCheck={setSelectParentCheck}
                    selectedChecks={selectedChecks}
                    setSelectedChecks={setSelectedChecks}
                    errorField={errorField}
                    roleData={businessAndEmpRoleData}
                    setValue={setValue}
                  />
                )
              ) : editTabIndex === 3 ? (
                <IndividualHistory id={employeeData.employeeId} />
              ) : editTabIndex === 2 ? (
                <DocumentsWrapper id={employeeData.employeeId} />
              ) : null}
            </Grid>
          </Grid>
        </div>
      ) : (
        <Stack
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ height: "65vh" }}
        >
          <Loading />
        </Stack>
      )}
    </div>
  );
};

export default IndividulaEditForm;
