import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { dialCodeList } from "../../utils/dialCodes";

const DialoCodeAutocomplete = ({
  register,
  lable = "",
  setValue,
  defaultValue = "+1",
  dialCode = "dialCode",
  disabled,
  size = "small",
}) => {
  const [selected, setSelected] = useState(defaultValue ? defaultValue : "+1");
  const handleChange = (data) => {
    setSelected(data);
    setValue(dialCode, data);
  };
  useEffect(() => {
    setValue(dialCode, defaultValue);
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <Autocomplete
      fullwidth="true"
      value={selected}
      size={size}
      id="dialcode-field"
      options={dialCodeList}
      disableClearable={true}
      disabled={disabled}
      sx={{ width: "100%" }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography variant="body1">{option}</Typography>
        </Box>
      )}
      renderInput={(params) => {
        return (
          <TextField
            inputProps={{ style: { fontSize: "13px" } }}
            id="dialCode"
            {...params}
            label={lable}
            {...register}
          />
        );
      }}
      onChange={(e, data) => handleChange(data)}
    />
  );
};

export default DialoCodeAutocomplete;
