export const businessDetailsAPIDataMapper = (data) => {
  let obj = {};
  obj["legalName"] = data.legalName;
  obj["dialCode"] = data.properties.dialCode ? data.properties.dialCode : "+1";
  obj["description"] = data.properties.description;
  obj["franchiseName"] = data.properties.franchiseName;
  obj["description"] = data.properties.description;
  obj["federalIdentificationNumber"] =
    data.properties.federalIdentificationNumber;
  obj["stateIdentificationNumber"] = data.properties.stateIdentificationNumber;
  obj["phone"] = data.properties.phone;
  obj["line1"] = data.properties.address.line1;
  obj["line2"] = data.properties.address.line2;
  obj["city"] = data.properties.address.city;
  obj["state"] = data.properties.address.state;
  obj["country"] = data.properties.address.country;
  obj["pinCode"] = data.properties.address.pinCode;
  return obj;
};

export const locationListDataMapper = (data) => {
  let obj;
  if (typeof data === "string") {
    return "";
  } else {
    let temp = data?.map((ele) => {
      obj = {};
      obj["id"] = ele.locationId;
      obj["Locations"] = ele.name;

      obj["Status"] = ele.activeFlag === 0 ? "Active" : "Inactive";
      return obj;
    });
    return temp;
  }
};

export const rolesDataMapper = (data) => {
  let obj;
  if (typeof data === "string") {
    return "";
  } else {
    let temp = data?.map((ele) => {
      obj = {};

      obj["id"] = ele.departmentId
        ? ele.departmentId
        : ele.masterDepartmentId
        ? ele.masterDepartmentId
        : "";
      obj["accountId"] = ele.accountId ? ele.accountId : "";
      obj["businessId"] = ele.businessId ? ele.businessId : "";
      obj["Roles"] = ele.name;

      obj["Status"] = ele.activeFlag === 0 ? "Active" : "Inactive";

      return obj;
    });
    return temp;
  }
};

export const postLocationDataMapper = (data, accountId, businessId) => {
  let obj = {};
  obj["name"] = data.bussinessName;
  obj["accountId"] = accountId;
  obj["businessId"] = businessId;
  obj["properties"] = {
    address: {
      line1: data.line1,
      line2: data.line2,
      city: data.city,
      country: data.country,
      pinCode: data.pinCode,
      state: data.state,
    },
    dialCode: data.dialCode,
    phone: data.phone,
    franchiseLocationNumber: data.brandLocationNumber,
  };
  return obj;
};
export const postUpdateLocationDataMapper = (data, apiData) => {
  let obj = JSON.parse(JSON.stringify(apiData));
  obj["name"] = data.locationName;
  obj["accountId"] = data.accountId;
  obj["businessId"] = data.businessId;
  obj["properties"] = {
    address: {
      line1: data.line1,
      line2: data.line2,
      city: data.city,
      country: data.country,
      pinCode: data.pinCode,
      state: data.state,
    },
    dialCode: data.dialCode,
    phone: data.phone,
    franchiseLocationNumber: data.brandLocationNumber,
  };
  return obj;
};

export const getLocationByIdDataMapper = (data) => {
  if (data.accountId) {
    let obj = {};
    obj["locationId"] = data.locationId;
    obj["accountId"] = data.accountId;
    obj["bussinessName"] = data.name;
    obj["phone"] = data.properties.phone;
    obj["dialCode"] = data.properties.dialCode;
    obj["brandLocationNumber"] = data.properties.franchiseLocationNumber;
    obj["state"] = data.properties.address.state;
    obj["country"] = data.properties.address.country;
    obj["city"] = data.properties.address.city;
    obj["line1"] = data.properties.address.line1;
    obj["line2"] = data.properties.address.line2;
    obj["pinCode"] = data.properties.address.pinCode;
    obj["businessId"] = data.businessId;
    return obj;
  }
};

export const rolesUpdateDataMapper = (data, apiData) => {
  let obj = JSON.parse(JSON.stringify(apiData));
  obj["name"] = data;
  return obj;
};

export const postIndividualDataMapper = (data, accountId, businessId) => {
  let obj = {};
  if (data.email) {
    obj["properties"] = {
      dialCode: data.dialCode,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
    };
    obj["username"] = data.loginId;
    obj["email"] = data.email;
    obj["accountId"] = accountId;
    obj["businessId"] = businessId;
    obj["employeeHireStage"] = data.employmentStage;
  }

  return obj;
};

export const getIndividualListMapper = (data, accountId, businessId) => {
  let obj = {};

  if (typeof data === "string") {
    return "";
  } else {
    let temp = data?.map((ele) => {
      obj = {};
      obj["accountId"] = accountId;
      obj["businessId"] = businessId;
      obj["id"] = ele.employeeId;
      obj["Individual"] = ele.employeeName;
      obj["Locations"] = ele.locationName ? ele.locationName.toString() : "";
      obj["Roles"] = ele.departmentName ? ele.departmentName.toString() : "";

      return obj;
    });

    return temp;
  }
};

export const getIndividualDataMapper = (data, isSSOLogin) => {
  let obj = {};
  obj["firstName"] = data.properties.firstName;
  obj["lastName"] = data.properties.lastName;
  obj["email"] = data.email;
  obj["line1"] = data.properties?.address?.line1
    ? data.properties.address.line1
    : "";

  obj["line2"] = data.properties?.address?.line2
    ? data.properties.address.line2
    : "";

  obj["city"] = data.properties?.address?.city
    ? data.properties.address.city
    : "";
  obj["state"] = data.properties?.address?.state
    ? data.properties.address.state
    : "";
  obj["country"] = data.properties?.address?.country
    ? data.properties.address.country
    : "";
  obj["pinCode"] = data.properties?.address?.pinCode
    ? data.properties.address.pinCode
    : "";
  obj["dialCode"] = data.properties.dialCode ? data.properties.dialCode : "+1";
  obj["phone"] = data.properties.phone ? data.properties.phone : "";
  obj["loginId"] = data.username ? data.username : "";

  obj["employmentStage"] = data.employeeHireStage;
  obj["nextHireStage"] = data.nextHireStage ? data.nextHireStage : "se";
  obj["ssn"] = data.properties.ssn ? data.properties.ssn : "";

  obj["emergencyName"] = data.properties.emergencyContact?.name
    ? data.properties.emergencyContact.name
    : "";
  obj["emergencyDialCode"] = data.properties.emergencyContact?.dialCode
    ? data.properties.emergencyContact.dialCode
    : "";
  obj["emergencyPhone"] = data.properties.emergencyContact?.phone
    ? data.properties.emergencyContact.phone
    : "";
  obj["emergencyEmail"] = data.properties.emergencyContact?.email
    ? data.properties.emergencyContact.email
    : "";
  obj["emergencyAddress"] = data.properties.emergencyContact?.address
    ? data.properties.emergencyContact.address
    : "";
  obj["canBeReHired"] = data.properties.canBeReHired
    ? data.properties.canBeReHired
    : "yes";
  obj["hireStageChangeDate"] = data.hireStageChangeDate
    ? data.hireStageChangeDate
    : new Date().toISOString();
  obj["dob"] = data.properties.dob;
  let managerLocationSelectObj = {};
  if (
    data.roles.length &&
    (data.roles[0].locationId !== null || data.roles[0].departmentId !== null)
  ) {
    obj["selectRole"] = "Manager";
    let mangerDepartmentSelect = [];
    data.roles.forEach((ele) => {
      if (ele.departmentId) {
        mangerDepartmentSelect.push(ele);
      } else {
        managerLocationSelectObj[ele.locationId] = true;
      }
    });
    obj["managerLocationSelect"] = managerLocationSelectObj;
    obj["mangerDepartmentSelect"] = mangerDepartmentSelect;

    managerLocationSelectObj = {};
  } else if (data.roles.length && data.roles[0].businessId !== null) {
    obj["selectRole"] = "Bussiness";
    obj["managerLocationSelect"] = {};
  } else {
    obj["selectRole"] = "Regular";
    obj["managerLocationSelect"] = {};
  }
  obj["mangerDepartmentSelect"] =
    data.roles.length &&
    data.roles.filter((element) => {
      if (element.departmentId) {
        return element;
      }
    });
  managerLocationSelectObj = {};
  if (data.departments !== null && data.departments?.length) {
    data.departments.forEach((ele) => {
      if (managerLocationSelectObj[ele.locationId]) {
        managerLocationSelectObj[ele.locationId][ele.departmentId] = {
          locationId: ele.locationId,
          departmentId: ele.departmentId,
        };
      } else {
        managerLocationSelectObj[ele.locationId] = {
          locationId: ele.locationId,
        };
        managerLocationSelectObj[ele.locationId][ele.departmentId] = {
          locationId: ele.locationId,
          departmentId: ele.departmentId,
        };
      }
    });
  }
  if (isSSOLogin && data.locations.length && data.departments === null) {
    data.locations.map((ele) => {
      managerLocationSelectObj[ele.locationId] = {
        locationId: ele.locationId,
      };
    });
  }
  obj["locationAndRoles"] = managerLocationSelectObj;
  return obj;
};
export const getIndividualFranchisorDataMapper = (data) => {
  let obj = {};
  obj["firstName"] = data.properties.firstName;
  obj["lastName"] = data.properties.lastName;
  obj["email"] = data.email;
  obj["line1"] = data.properties?.address?.line1
    ? data.properties.address.line1
    : "";

  obj["line2"] = data.properties?.address?.line2
    ? data.properties.address.line2
    : "";

  obj["city"] = data.properties?.address?.city
    ? data.properties.address.city
    : "";
  obj["state"] = data.properties?.address?.state
    ? data.properties.address.state
    : "";
  obj["country"] = data.properties?.address?.country
    ? data.properties.address.country
    : "";
  obj["pinCode"] = data.properties?.address?.pinCode
    ? data.properties.address.pinCode
    : "";
  obj["dialCode"] = data.properties.dialCode ? data.properties.dialCode : "+1";
  obj["phone"] = data.properties.phone ? data.properties.phone : "";
  obj["loginId"] = data.username ? data.username : "";

  obj["employmentStage"] = data.employeeHireStage;
  obj["nextHireStage"] = data.nextHireStage ? data.nextHireStage : "se";
  obj["ssn"] = data.properties.ssn ? data.properties.ssn : "";

  obj["emergencyName"] = data.properties.emergencyContact?.name
    ? data.properties.emergencyContact.name
    : "";
  obj["emergencyDialCode"] = data.properties.emergencyContact?.dialCode
    ? data.properties.emergencyContact.dialCode
    : "";
  obj["emergencyPhone"] = data.properties.emergencyContact?.phone
    ? data.properties.emergencyContact.phone
    : "";
  obj["emergencyEmail"] = data.properties.emergencyContact?.email
    ? data.properties.emergencyContact.email
    : "";
  obj["emergencyAddress"] = data.properties.emergencyContact?.address
    ? data.properties.emergencyContact.address
    : "";
  obj["canBeReHired"] = data.properties.canBeReHired
    ? data.properties.canBeReHired
    : "yes";
  obj["hireStageChangeDate"] = data.hireStageChangeDate
    ? data.hireStageChangeDate
    : new Date().toISOString();
  obj["dob"] = data.properties.dob;
  if (data.franchisorRoles && data.franchisorRoles.length) {
    let region = {};
    data.franchisorRoles.forEach((ele) => {
      region[ele.regionId] = true;
    });
    obj["selectRole"] = "Region";
    obj["selectedRegion"] = region;
  } else {
    obj["selectRole"] = "Regular";
    obj["selectedRegion"] = {};
  }
  return obj;
};

export const managerDepartmentMapper = (data, apiList) => {
  let obj = {};
  let apilistObj = {};
  apiList.forEach((ele) => {
    ele.departments.forEach((element) => {
      apilistObj[element.departmentId] = element.locationId;
    });
  });
  let locationId = "";
  data.forEach((depart) => {
    locationId = apilistObj[depart.departmentId];
    if (obj[locationId]) {
      obj[locationId][depart.departmentId] = {
        locationId: locationId,
        departmentId: depart.departmentId,
      };
    } else {
      obj[locationId] = { locationId: locationId };
      obj[locationId][depart.departmentId] = {
        locationId: null,
        departmentId: depart.departmentId,
      };
    }
  });
  return obj;
};

export const individualPutFinalDataMapper = (
  apiData,
  formdata,
  manageSelectCheck,
  manageDrawerChecks,
  isSSOLogin
) => {
  let deleteKeys = ["otp", "otpCreateTime", "password"];
  let temp = JSON.parse(JSON.stringify(apiData));
  temp["username"] = formdata.loginId;
  temp["email"] = formdata.email;
  temp.properties["ssn"] = formdata.ssn;
  temp.properties["dialCode"] = formdata.dialCode;
  temp.properties["phone"] = formdata.phone;
  temp.properties["dob"] = formdata.dob;
  temp.properties["lastName"] = formdata.lastName;
  temp.properties["firstName"] = formdata.firstName;
  temp.properties["canBeReHired"] = formdata.canBeReHired;
  if (
    temp.properties.emergencyContact === null ||
    temp.properties.emergencyContact === undefined
  ) {
    temp.properties["emergencyContact"] = {};
  }
  temp.properties.emergencyContact["name"] = formdata.emergencyName;
  temp.properties.emergencyContact["dialCode"] = formdata.emergencyDialCode;
  temp.properties.emergencyContact["phone"] = formdata.emergencyPhone;
  temp.properties.emergencyContact["email"] = formdata.emergencyEmail;
  temp.properties.emergencyContact["address"] = formdata.emergencyAddress;
  if (
    temp.properties.address === null ||
    temp.properties.address === undefined
  ) {
    temp.properties["address"] = {};
  }
  temp.properties.address["line1"] = formdata.line1;
  temp.properties.address["line2"] = formdata.line2;
  temp.properties.address["city"] = formdata.city;
  temp.properties.address["state"] = formdata.state;
  temp.properties.address["country"] = formdata.country;
  temp.properties.address["pinCode"] = formdata.pinCode;
  if (
    formdata.employmentStage !== "TERMINATED" &&
    formdata.employmentStage !== "NOT_HIRED" &&
    formdata.nextHireStage !== "se"
  ) {
    temp["nextHireStage"] =
      formdata.nextHireStage === "se" ? null : formdata.nextHireStage;
    temp["hireStageChangeDate"] = formdata.hireStageChangeDate
      ? new Date(formdata.hireStageChangeDate).toISOString()
      : null;
  } else {
    temp["nextHireStage"] = null;
    temp["hireStageChangeDate"] = null;
  }
  if (formdata.selectRole === "Manager") {
    let rolesArray = [];
    if (Object.keys(manageSelectCheck).length !== 0) {
      rolesArray = Object.keys(manageSelectCheck).map((locationId) => {
        return { departmentId: null, locationId: locationId };
      });
    }
    let looperData = Object.values(manageDrawerChecks);
    for (let i = 0; i < looperData.length; i++) {
      if (Object.keys(looperData[i]).length !== 1) {
        let temp = JSON.parse(JSON.stringify(looperData[i]));
        delete temp["locationId"];
        rolesArray = [...rolesArray, ...Object.values(temp)];
      }
    }
    temp["roles"] = rolesArray;
  } else if (formdata.selectRole === "Bussiness") {
    temp["roles"] = [
      {
        departmentId: null,
        locationId: null,
        businessId: temp.businessId,
      },
    ];
  } else {
    temp["roles"] = [];
  }
  temp["departments"] = isSSOLogin
    ? apiData.departments
    : formdata.departmentsArray;
  temp["locations"] = isSSOLogin
    ? apiData.locations
    : formdata.locationArrayObj && Object.keys(formdata.locationArrayObj).length
    ? Object.keys(formdata.locationArrayObj).map((ele) => {
        return { locationId: ele };
      })
    : [];
  deleteKeys.forEach((ele) => {
    delete temp[ele];
  });
  return temp;
};

export const individualPutFinalFranchisorDataMapper = (
  apiData,
  formdata,
  selectedChecks
) => {
  let deleteKeys = ["otp", "otpCreateTime", "password"];

  let temp = JSON.parse(JSON.stringify(apiData));
  temp["username"] = formdata.loginId;
  temp["email"] = formdata.email;
  if (!temp.properties) {
    temp["properties"] = {};
  }
  temp.properties["ssn"] = formdata.ssn ? formdata.ssn : "";
  temp.properties["dialCode"] = formdata.dialCode;
  temp.properties["phone"] = formdata.phone;
  temp.properties["dob"] = formdata.dob;
  temp.properties["lastName"] = formdata.lastName;
  temp.properties["firstName"] = formdata.firstName;
  temp.properties["canBeReHired"] = formdata.canBeReHired;
  if (
    temp.properties.emergencyContact === null ||
    temp.properties.emergencyContact === undefined
  ) {
    temp.properties["emergencyContact"] = {};
  }
  temp.properties.emergencyContact["name"] = formdata.emergencyName;
  temp.properties.emergencyContact["dialCode"] = formdata.emergencyDialCode;
  temp.properties.emergencyContact["phone"] = formdata.emergencyPhone;
  temp.properties.emergencyContact["email"] = formdata.emergencyEmail;
  temp.properties.emergencyContact["address"] = formdata.emergencyAddress;
  if (
    temp.properties.address === null ||
    temp.properties.address === undefined
  ) {
    temp.properties["address"] = {};
  }
  temp.properties.address["line1"] = formdata.line1;
  temp.properties.address["line2"] = formdata.line2;
  temp.properties.address["city"] = formdata.city;
  temp.properties.address["state"] = formdata.state;
  temp.properties.address["country"] = formdata.country;
  temp.properties.address["pinCode"] = formdata.pinCode;
  if (
    formdata.employmentStage !== "TERMINATED" &&
    formdata.employmentStage !== "NOT_HIRED" &&
    formdata.nextHireStage !== "se"
  ) {
    temp["nextHireStage"] =
      formdata.nextHireStage === "se" ? null : formdata.nextHireStage;
    temp["hireStageChangeDate"] = formdata.hireStageChangeDate
      ? new Date(formdata.hireStageChangeDate).toISOString()
      : null;
  } else {
    temp["nextHireStage"] = null;
    temp["hireStageChangeDate"] = null;
  }
  if (formdata.selectRole === "Region") {
    let franchiseRole = [];
    Object.keys(selectedChecks).map((ele) => {
      let objData = {
        businessId: null,
        departmentId: null,
        locationId: null,
        regionId: ele,
      };
      franchiseRole.push(objData);
    });
    temp["franchisorRoles"] = franchiseRole;
  } else {
    temp["franchisorRoles"] = [];
  }
  deleteKeys.forEach((ele) => {
    delete temp[ele];
  });
  return temp;
};

export const signatoryMainTableDataMapper = (data) => {
  if (!Array.isArray(data) && !data.length) {
    return [];
  }
  let obj = {};
  let temp = data.map((ele) => {
    obj = {};
    obj["employeeId"] = ele.signatory.employeeId;
    obj["Signatory"] = ele.name;
    obj["Individual"] =
      ele.signatory.properties.firstName +
      " " +
      ele.signatory.properties.lastName;
    return obj;
  });
  return temp;
};
export const signatorySelectTableDataMapper = (data) => {
  if (!Array.isArray(data) && !data.length) {
    return [];
  }
  let obj = {};
  let temp = data.map((ele) => {
    obj = {};
    obj["employeeId"] = ele.employeeId;
    obj["Individual"] =
      ele.properties.firstName + " " + ele.properties.lastName;
    return obj;
  });
  return temp;
};

export const getTemplatesFanchisorLocationList = (data) => {
  let obj = {};
  obj[" "] = {};
  let locationSelected = {};
  data.forEach((ele) => {
    ele.locations.forEach((locations) => {
      if (locations.properties.address) {
        if (locations.properties.regionName === null) {
          if (obj[" "][locations.properties.address.state] === undefined) {
            obj[" "][locations.properties.address.state] = {};
          }
          obj[" "][locations.properties.address.state][locations.locationId] = {
            businessId: locations.businessId,
            locationId: locations.locationId,
            name: locations.name,
          };
          locationSelected[locations.locationId] = {
            businessId: locations.businessId,
            locationId: locations.locationId,
            isSelected: false,
          };
        } else if (obj[locations.properties.regionName]) {
          if (
            obj[locations.properties.regionName][
              locations.properties.address.state
            ] === undefined
          ) {
            obj[locations.properties.regionName][
              locations.properties.address.state
            ] = {};
          }

          obj[locations.properties.regionName][
            locations.properties.address.state
          ][locations.locationId] = {
            businessId: locations.businessId,
            locationId: locations.locationId,
            name: locations.name,
          };
          locationSelected[locations.locationId] = {
            businessId: locations.businessId,
            locationId: locations.locationId,
            isSelected: false,
          };
        } else {
          obj[locations.properties.regionName] = {};
          obj[locations.properties.regionName][
            locations.properties.address.state
          ] = {};
          obj[locations.properties.regionName][
            locations.properties.address.state
          ][locations.locationId] = {
            businessId: locations.businessId,
            locationId: locations.locationId,
            name: locations.name,
          };
          locationSelected[locations.locationId] = {
            businessId: locations.businessId,
            locationId: locations.locationId,
            isSelected: false,
          };
        }
      }
    });
  });
  return { data: obj, select: locationSelected };
};

export const eSignAgreementDataMapper = (data) => {
  let obj = {
    employeeId: data.employeeId,
    agreements: [
      {
        acceptanceDate: null,
        hasAccepted: "yes",
        name: "eSignAgreement",
      },
      {
        acceptanceDate: null,
        name: "privacyPolicyAgreement",
        hasAccepted: "yes",
      },
      {
        acceptanceDate: null,
        name: "termsOfUseAgreement",
        hasAccepted: "yes",
      },
      {
        acceptanceDate: null,
        name: "saasAgreement",
        hasAccepted: "yes",
      },
      {
        acceptanceDate: null,
        name: "betaTestingAgreement",
        hasAccepted: "yes",
      },
    ],
    properties: {
      optInConsentProvided: data.optIn,
    },
  };
  return obj;
};

export const eSignAgreementNewUserDataMapper = (data) => {
  if (data.confirmUserResponse.length !== 5) {
    let obj = {
      userIdentifier: data.setPassowrd.userIdentifier,
      password: data.setPassowrd.password,
      confirmpassword: data.setPassowrd.confirmpassword,
      otp: data.setPassowrd.otp,
      properties: {
        secretQuestionAndAnswers: [
          {
            question: data.qna.question1,
            answer: data.qna.answer1,
          },
          {
            question: data.qna.question2,
            answer: data.qna.answer2,
          },
          {
            question: data.qna.question3,
            answer: data.qna.answer2,
          },
        ],
        optInConsentProvided: data.optIn,
      },

      agreements: [
        {
          name: "eSignAgreement",
          hasAccepted: "yes",
        },
        {
          name: "privacyPolicyAgreement",
          hasAccepted: "yes",
        },
        {
          name: "termOfUseAgreement",
          hasAccepted: "yes",
        },
      ],
    };
    return obj;
  } else {
    let obj = {
      userIdentifier: data.setPassowrd.userIdentifier,
      password: data.setPassowrd.password,
      confirmpassword: data.setPassowrd.confirmpassword,
      otp: data.setPassowrd.otp,
      properties: {
        secretQuestionAndAnswers: [
          {
            question: data.qna.question1,
            answer: data.qna.answer1,
          },
          {
            question: data.qna.question2,
            answer: data.qna.answer2,
          },
          {
            question: data.qna.question3,
            answer: data.qna.answer2,
          },
        ],
        optInConsentProvided: data.optIn,
      },

      employeeId: data.employeeId,
      agreements: [
        {
          acceptanceDate: null,
          hasAccepted: "yes",
          name: "eSignAgreement",
        },
        {
          acceptanceDate: null,
          name: "privacyPolicyAgreement",
          hasAccepted: "yes",
        },
        {
          acceptanceDate: null,
          name: "termsOfUseAgreement",
          hasAccepted: "yes",
        },
        {
          acceptanceDate: null,
          name: "saasAgreement",
          hasAccepted: "yes",
        },
        {
          acceptanceDate: null,
          name: "betaTestingAgreement",
          hasAccepted: "yes",
        },
      ],
    };
    return obj;
  }
};

export const eSignAgreementDashboardUser = (id) => {
  return {
    agreements: [
      {
        acceptanceDate: null,
        name: "eSignAgreement",
        hasAccepted: "yes",
      },
      {
        acceptanceDate: null,
        name: "privacyPolicyAgreement",
        hasAccepted: "yes",
      },
      {
        acceptanceDate: null,
        name: "termOfUseAgreement",
        hasAccepted: "yes",
      },
    ],
    employeeId: id,
  };
};

export const bussinessProfileEditDataMapper = (apiData, formData, checkBox) => {
  let temp = JSON.parse(JSON.stringify(apiData));
  temp["legalName"] = formData.legalName;
  temp.properties.dialCode = formData.dialCode;
  temp.properties.description = formData.description;
  temp.properties.phone = formData.phone;
  temp.properties.federalIdentificationNumber =
    formData.federalIdentificationNumber;
  temp.properties.stateIdentificationNumber =
    formData.stateIdentificationNumber;
  temp.franchiseName = formData.franchiseName;
  temp.properties.address.line1 = formData.line1;
  temp.properties.address.line2 = formData.line2;
  temp.properties.address.city = formData.city;
  temp.properties.address.state = formData.state;
  temp.properties.address.country = formData.country;
  temp.properties.address.pinCode = formData.pinCode;
  temp.properties.managerCanManageEmployee = checkBox;
  return temp;
};

export const postSignUpDateForFranchsior = (data, isNetwork = true) => {
  let obj = {};
  const capitalizeLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const accountName = `${capitalizeLetter(data.firstName)} ${capitalizeLetter(
    data.lastName
  )}`;
  obj["accountName"] = accountName;
  if (isNetwork === false) {
    obj["accountType"] = "FRANCHISOR";
  } else {
    obj["accountType"] = "FRANCHISEE";
  }
  obj["activeFlag"] = 0;
  obj["email"] = data.email;
  obj["employeeBean"] = {};
  obj["employeeBean"]["accountOwner"] = true;
  obj["employeeBean"]["activeFlag"] = 0;
  obj["employeeBean"]["email"] = data.email;
  obj["employeeBean"]["employeeHireStage"] = "MANAGE";
  obj["employeeBean"]["employeeType"] = "EMPLOYEE";
  if (isNetwork === false) {
    obj["employeeBean"]["franchisorRoles"] = [
      {
        franchisorApplicationRole: {
          applicationRoleId: 1,
          rank: "ROLE_FRANCHISOR_ACCOUNT_ADMIN",
          roleName: "ROLE_FRANCHISOR_ACCOUNT_ADMIN",
        },
      },
    ];
  }

  obj["employeeBean"]["managerCanManageEmployee"] = true;
  obj["employeeBean"]["properties"] = {};
  obj["employeeBean"]["properties"]["accountOwner"] = true;
  obj["employeeBean"]["properties"]["dialCode"] = data.countryCode;
  obj["employeeBean"]["properties"]["firstName"] = `${capitalizeLetter(
    data.firstName
  )}`;
  obj["employeeBean"]["properties"]["lastName"] = `${capitalizeLetter(
    data.lastName
  )}`;
  obj["employeeBean"]["properties"]["phone"] = data.phoneNumber;
  obj["employeeBean"]["username"] = `${data.firstName}.${data.lastName}`;
  obj["employeeBean"]["versionNum"] = 0;

  obj["properties"] = {};
  obj["properties"]["allowfranchiseeCustomDocument"] = true;
  obj["properties"]["legalName"] = data.companyName;
  obj["properties"]["planType"] = data.planType;
  obj["properties"]["franchisorId"] = data.brandName;
  obj["properties"]["templateLibraryType"] = "CUSTOM";
  obj["versionNum"] = 0;

  return obj;
};
