export const localToIsoStirng = (str) => {
  const format = (d) => (d < 10 ? "0" : "") + d;
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [day, d, m, yyyy, ...time] = str.split(" ");

  const mm = format(months.indexOf(m) + 1);
  const dd = format(d);

  return `${yyyy}-${mm}-${dd}T00:00:00.000Z`;
};
