import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  bussinessSelectedChecksFI: {},
  fullTemplate: {
    templateProperties: {
      visibleToEmployee: "no", // Is this template visible to Individuals?
      employeeCanInitiate: "no", //Can this template be initiated by Individuals?
      employeeHireStageApplicable: "PRE_HIRE",
      multiAssignable: "no", //Assign duplicate documents if an Individual belongs to more than  one location under the same business?
      forMultiOwnerAssignment: false, //not used
    },
    applicableAts: null, //not used here
    applicableAtToFranchiser: {},
  },
};

export const FranchisorIndividualSlice = createSlice({
  name: "franchisorIndividual",
  initialState,
  reducers: {
    setFullTemplateFI: (state, action) => {
      state.fullTemplate = action.payload;
    },
    resetFI: (state) => initialState,
    setBussinessSelectedChecksFI: (state, action) => {
      state.bussinessSelectedChecksFI = action.payload;
    },
    setEmployeeHireStageApplicableFI: (state, action) => {
      state.fullTemplate.templateProperties.employeeHireStageApplicable =
        action.payload;
    },
    setVisibleToEmployeeFI: (state, action) => {
      state.fullTemplate.templateProperties.visibleToEmployee = action.payload;
    },
    setEmployeeCanInitiateFI: (state, action) => {
      state.fullTemplate.templateProperties.employeeCanInitiate =
        action.payload;
    },
    setMultiAssignableFI: (state, action) => {
      state.fullTemplate.templateProperties.multiAssignable = action.payload;
    },
  },
});

export const {
  setMultiAssignableFI,
  resetFI,
  setFullTemplateFI,
  setVisibleToEmployeeFI,
  setEmployeeCanInitiateFI,
  setBussinessSelectedChecksFI,
  setEmployeeHireStageApplicableFI,
} = FranchisorIndividualSlice.actions;
export default FranchisorIndividualSlice.reducer;
