import { ajaxHandler } from "./ajaxHandler";

export const getTemplatesData = async (accountId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/template/${accountId}/list?page=1&limit=10`
  );
  return response.data;
};

export const getTemplatesInitiatedByEmployees = async (employeeId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/template/getTemplateInitiatedByEmployee/${employeeId}`
  );
  return response;
};

export const getEmployeesForManualTaskAssignment = async (
  employeeId,
  templateId
) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/task/getEmployeesForManualTaskAssignment/${employeeId}/${templateId}`
  );

  return response;
};
export const getLocationsForManualTaskAssignment = async (
  templateId
) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/task/getLocationsForManualTaskAssignment/${templateId}`
  );

  return response;
};

export const confirmIfDuplicateTask = async (employeeId, templateId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/task/isTaskAssignedAlready/${employeeId}/${templateId}`
  );

  return response;
};

export const makeTempInactive = async (templateId) => {
  const response = await ajaxHandler(
    "get",
    `focus451-document/template/makeTemplateInactive/${templateId}`
  );
  return response;
};

export const createNewTemplateAPI = async (payload) => {
  const response = await ajaxHandler(
    "post",
    `focus451-document/template/`,
    payload
  );
  return response;
};

export const updateTemplateAPI = async (id, payload) => {
  const response = await ajaxHandler(
    "put",
    `focus451-document/template/${id}`,
    payload
  );
  return response;
};

export const uploadDocument = async (payload) => {
  const response = await ajaxHandler(
    "post",
    `focus451-document/task/${payload.taskId}/upload/${payload.supportId}`,
    payload.data
  );
  return response;
};

export const saveDocProgressTaskAPI = async (data) => {
  const response = await ajaxHandler(
    "put",
    `focus451-document/task/${data.id}/${data.progressStatus}`,
    data.data
  );
  return response;
}

export const getCreateTemplateBusinessData = async (accountId) => {
  const response = await ajaxHandler(
    "get",
    `focus451/business/findByAccount/${accountId}`
  );
  return response;
};

export const getMasterDepartment = async (businessId) => {
  const response = await ajaxHandler(
    "get",
    `focus451/department/masterDepartment/${businessId}`
  );
  return response;
};

export const assignTaskManually = async (
  loggedInUserId,
  assigneeId,
  templateDetails
) => {
  const response = await ajaxHandler(
    "post",
    `focus451-document/task/manualTaskAssignment/${loggedInUserId}?assigneeIds=${assigneeId}`,
    templateDetails
  );
  return response;
};

export const assignTaskManuallyFranchisor = async (
  locationId,
  templateDetails
) => {
  const response = await ajaxHandler(
    "post",
    `focus451-document/franchisor/task/manualTaskAssignment?locationIds=${locationId}`,
    templateDetails
  );
  return response;
};
