import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";

const CommonFormHeadButton = ({
  setIndex,
  setSelected,
  submit,
  title,
  setIsEdit,
  showSave = true,
  handleCancel,
  buttonText = "Save",
}) => {
  return (
    <Grid container mt={-2}>
      <Grid
        p={0}
        item
        lg={12}
        xs={12}
        sm={12}
        md={12}
        direction="column"
        paddingTop={"1em"}
      >
        <Stack
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{ color: "#164E5F", paddingTop: "0.5em", fontSize: "15px" }}
          >
            {title}
          </Typography>
          <Stack
            direction={"row"}
            sx={{ marginBottom: "0.5em" }}
            justifyContent="space-between"
          >
            <Button
              id="cancel"
              variant="contained"
              sx={{
                padding: "5px 20px",
                marginRight: "1em",
                color: "black",
                backgroundColor: "#F5F7FA",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {showSave ? (
              <Button
                id="submit"
                onClick={submit}
                variant="contained"
                sx={{
                  padding: "5px 20px",
                  color: "white",
                  backgroundColor: "#CD99E5",
                  ":active": {
                    backgroundColor: "#8D2FBC",
                    color: "white",
                    border: "1px solid #CD99E5",
                  },
                }}
              >
                {buttonText}
              </Button>
            ) : null}
          </Stack>
        </Stack>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default CommonFormHeadButton;
