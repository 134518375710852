import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  bussinessSelectedChecksAB: {},
  templateProperties: {
    templateType: "BUSINESS",
    visibleToEmployee: "no", //not used
    employeeCanInitiate: "no", //not used
    employeeHireStageApplicable: "PRE_HIRE", //not used
    multiAssignable: "no", //used for below question
    forMultiOwnerAssignment: false, // Is this template required to be signed by all business owners?
  },
  applicableAts: [],
  applicableAtToFranchiser: {}, //not used
};

export const AdminBussinessSlice = createSlice({
  name: "adminBussiness",
  initialState,
  reducers: {
    resetAB: (state) => initialState,
    setForMultiOwnerAssignment: (state, action) => {
      state.templateProperties.multiAssignable = action.payload;
      if (action.payload === "no") {
        state.templateProperties.forMultiOwnerAssignment = false;
      } else {
        state.templateProperties.forMultiOwnerAssignment = true;
      }
    },
    setTemplatePropertiesAB: (state, action) => {
      state.templateProperties = action.payload;
    },
    setBussinessSelectedChecks: (state, action) => {
      state.bussinessSelectedChecksAB = action.payload;
    },
  },
});

export const {
  resetAB,
  setForMultiOwnerAssignment,
  setBussinessSelectedChecks,
  setTemplatePropertiesAB,
} = AdminBussinessSlice.actions;
export default AdminBussinessSlice.reducer;
