import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Slide, Divider, Typography, Stack } from "@mui/material";
import "../../../src/components/Dialog/Dialog.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  getLocationsForCompliant,
  setTabsData,
} from "../../pages/Dashboard/DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { PlansStrings } from "../../utils/stringToObj";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, isCloseButtonHidden, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          id="close"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 6,
            top: 6,
            color: (theme) => theme.palette.grey[500],
            display: isCloseButtonHidden ? "none" : "block",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function BootstrapDialogTitle1(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        paddingLeft: 0.1,
        paddingRight: 0.1,
        paddingTop: 0,
        paddingBottom: 2,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          id="close"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 6,
            top: 6,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle1.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CustomizedDialogs = ({
  isCreateNewUser = false,
  headerText,
  open,
  handleClickOpen,
  handleClose,
  children,
  fontSize = "17px",
  fontColor = "#333333",
  headerButtons = [],
  height = "85%",
  size = "md",
  isCreate = false,
  isBussiness = false,
  isArrowIcon = false,
  id,
  flexDirection = "row",
  isCloseButtonHidden = false,
}) => {
  const { agreementPopState } = useSelector((state) => state.dashboard);
  const { dialogSelectedEntity } = useSelector(
    (state) => state.businessLocation
  );
  const loggedInUserInfo = useSelector((state) => state.login.loggedInUserInfo);

  return (
    <div>
      <BootstrapDialog
        fullWidth
        PaperProps={{
          sx: {
            maxWidth: "none",
            width: isCreateNewUser ? "600px" : "750px",
            minHeight: agreementPopState
              ? {
                  xs: "85%",
                  ipadMini: "60%",
                  ipadAir: "55%",
                  md: "68%",
                  lg: "75%",
                  xl: "78%",
                  bigScreen: "61%",
                }
              : height,
            maxHeight: agreementPopState
              ? {
                  xs: "85%",
                  ipadMini: "60%",
                  ipadAir: "55%",
                  md: "68%",
                  lg: "75%",
                  xl: "78%",
                  bigScreen: "61%",
                }
              : height,
            minHeight: isCreateNewUser
              ? "auto"
              : {
                  xs: "85%",
                  ipadMini: "60%",
                  ipadAir: "55%",
                  md: "68%",
                  lg: "75%",
                  xl: "78%",
                  bigScreen: "61%",
                },
          },
        }}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={size}
      >
        <BootstrapDialogTitle
          isCloseButtonHidden={isCloseButtonHidden}
          onClose={handleClose}
          className="dialog-header"
        >
          <Grid
            container
            justifyContent="space-between"
            style={{
              width: isCloseButtonHidden ? "102%" : "100%",
              paddingLeft: isCloseButtonHidden ? "1rem" : "0rem",
            }}
          >
            <Grid
              item
              style={{
                color: "#164E5F",
                display: "flex",
                alignItems: "center",
              }}
            >
              {dialogSelectedEntity ? (
                <Stack direction={"row"}>
                  <Typography sx={{ color: "#999", fontSize: "15px" }}>
                    {headerText}
                  </Typography>
                  <ArrowRightIcon sx={{ color: "#999" }} />
                  <Typography
                    sx={{ color: "#164E5F", fontSize: "15px", fontWeight: 600 }}
                  >
                    {dialogSelectedEntity}
                  </Typography>
                </Stack>
              ) : isCreate ? (
                <Stack direction={"row"}>
                  <ArrowRightIcon sx={{ color: "#999999" }} />
                  <Typography
                    sx={{ color: "#164e5f", fontSize: "15px", fontWeight: 600 }}
                  >
                    {headerText}
                  </Typography>
                </Stack>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ color: fontColor, fontSize: fontSize }}>
                    {headerText}
                  </Typography>
                  {isArrowIcon ? (
                    <ArrowRightIcon sx={{ color: "#999999" }} />
                  ) : null}
                </div>
              )}
            </Grid>
            {headerButtons && (
              <Grid item sx={{ marginRight: "5%" }}>
                <span>
                  {headerButtons.map(
                    ({
                      btnName,
                      btnIcon = null,
                      onClick,
                      btnColor = "#93c",
                      btnTextColor = "#fff",
                    }) => {
                      return (
                        <Button
                          id={`${btnName}`}
                          type="submit"
                          variant="contained"
                          onClick={() => onClick()}
                          sx={{
                            // backgroundColor: "transparent",
                            backgroundColor: `${btnColor}`,
                            color: `${btnTextColor}`,
                            marginLeft: "5px",
                          }}
                        >
                          {btnIcon}&nbsp;{btnName}
                        </Button>
                      );
                    }
                  )}
                </span>
              </Grid>
            )}
          </Grid>
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent:
              flexDirection === "column" ? "flex-start" : "center",
            alignItems: flexDirection === "column" ? "center" : "flex-start",

            overflow: isBussiness ? "hidden" : "auto",
            flexDirection,
          }}
        >
          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export const HistoryCustomizedDialog = ({
  headerText,
  open,
  handleClickOpen,
  handleClose,
  children,
  headerButtons = [],
  size = "md",
}) => {
  return (
    <div>
      <BootstrapDialog
        fullWidth
        PaperProps={{
          sx: {
            maxWidth: "none",
            width: "800px",
            minHeight: "87%",
            maxHeight: "85%",
            boxshadow: " 0 5px 15px rgb(0 0 0 / 50 %)",
            boxSizing: "border-box",
          },
        }}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={size}
      >
        <BootstrapDialogTitle1
          onClose={handleClose}
          className="dialog-header"
          style={{ backgroundColor: "#36bfeb" }}
        >
          <Grid
            container
            justifyContent="space-between"
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 10,
              paddingBottom: 15,
              fontWeight: "normal",
              fontSize: "16px",
              margin: "0",
              backgroundColor: "#36bfeb",
            }}
          >
            <Grid item sx={{ paddingTop: 1, color: "#fff" }}>
              {headerText}
            </Grid>
            {headerButtons && (
              <Grid item sx={{ marginRight: "4%" }}>
                <span>
                  {headerButtons.map(
                    ({
                      btnName,
                      btnIcon,
                      onClick,
                      btnColor = "#93c",
                      btnTextColor = "#fff",
                    }) => {
                      return (
                        <Button
                          id={`${btnName}`}
                          variant="contained"
                          onClick={() => onClick()}
                          sx={{
                            marginRight: 2,
                            paddingRight: 3,
                            backgroundColor: "transparent",
                            backgroundColor: `${btnColor}`,
                            color: `${btnTextColor}`,
                            marginLeft: "5px",
                          }}
                        >
                          {btnIcon}&nbsp;{btnName}
                        </Button>
                      );
                    }
                  )}
                </span>
              </Grid>
            )}
          </Grid>
        </BootstrapDialogTitle1>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export const MapCustomizedDialogs = ({
  headerText,
  headerTab0,
  headerTab1,
  headerTab2,
  open,
  handleClickOpen,
  handleClose,
  value,
  setValue,
  children,
  headerButtons = [],
  location,
  size = "md",
  setDialogLoader,
  position = null,
}) => {
  const dispatch = useDispatch();
  const locationId = location.locationId;
  const handleRoleLocationClicked = async () => {
    setDialogLoader(true);
    const resEmployee = await dispatch(
      getLocationsForCompliant({ id: locationId, status: "employee" })
    );
    dispatch(setTabsData(resEmployee.payload));
    if (resEmployee?.payload && resEmployee.payload.length) {
      setDialogLoader(false);
    }
  };
  const handleLocationClicked = async () => {
    setDialogLoader(true);

    const resCorporate = await dispatch(
      getLocationsForCompliant({ id: locationId, status: "corporate" })
    );
    dispatch(setTabsData(resCorporate.payload));
    if (resCorporate?.payload && resCorporate.payload.length) {
      setDialogLoader(false);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <BootstrapDialog
        fullWidth
        PaperProps={{
          sx: {
            maxWidth: "none",
            width: "750px",
            minHeight: "87%",
            maxHeight: "85%",
            position: position || undefined,
          },
        }}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={size}
      >
        <BootstrapDialogTitle onClose={handleClose} className="dialog-header">
          <Grid container justifyContent="left" sx={{ color: "#164e5f" }}>
            <Grid
              item
              fontSize={"15px"}
              width={"100 %"}
              sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
            >
              <Button
                id={`${headerText}`}
                style={{
                  color: "#164e5f",
                  fontWeight: 600,
                  "&:hover": { cursor: "default", color: "#164e5f" },
                  fontSize: "15px",
                }}
                onClick={handleLocationClicked}
              >
                {headerText}
              </Button>
              <Tabs
                padding={"4px 0 16px"}
                value={value}
                onChange={handleChange}
                textColor="secondary"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  id="roleLocaion"
                  //added
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 0 ? 600 : 400}
                    >
                      {headerTab0}
                    </Typography>
                  }
                  style={{
                    fontSize: "16px",
                    writingMode: "horizontal-tb !important",
                  }}
                  onClick={handleRoleLocationClicked}
                />
                <Tab
                  id="location"
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 1 ? 600 : 400}
                    >
                      {headerTab1}
                    </Typography>
                  }
                  style={{
                    fontSize: "16px",
                    writingMode: "horizontal-tb !important",
                  }}
                  onClick={handleLocationClicked}
                />
                <Tab
                  label={
                    <Typography
                      fontSize={"15px"}
                      fontWeight={value === 1 ? 600 : 400}
                    >
                      {headerTab2}
                    </Typography>
                  }
                  style={{
                    fontSize: "16px",
                    writingMode: "horizontal-tb !important",
                  }}
                />
              </Tabs>
            </Grid>
            {headerButtons && (
              <Grid item sx={{ marginRight: "4%" }}>
                <Divider sx={{ borderStyle: "dotted" }} />
                <span>
                  {headerButtons.map(
                    ({
                      btnName,
                      btnIcon,
                      onClick,
                      btnColor = "#93c",
                      btnTextColor = "#fff",
                    }) => {
                      return (
                        <Button
                          id={`${btnName}`}
                          variant="contained"
                          onClick={() => onClick()}
                          sx={{
                            backgroundColor: "transparent",
                            backgroundColor: `${btnColor}`,
                            color: `${btnTextColor}`,
                            marginLeft: "5px",
                          }}
                        >
                          {btnIcon}&nbsp;{btnName}
                        </Button>
                      );
                    }
                  )}
                </span>
              </Grid>
            )}
          </Grid>
          {/* <Row /> */}
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            overflowY: "hidden",
          }}
        >
          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export const CustomizedDialogsforReassign = ({
  headerText,
  open,
  handleClickOpen,
  handleClose,
  children,
  headerButtons = [],
  size = "md",
}) => {
  const { agreementPopState } = useSelector((state) => state.dashboard);

  return (
    <div>
      <BootstrapDialog
        fullWidth
        PaperProps={{
          sx: {
            maxWidth: "none",
            width: "45%",
            position: "fixed",
            top: 26,
          },
        }}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={size}
      >
        <BootstrapDialogTitle onClose={handleClose} className="dialog-header">
          <Grid
            container
            justifyContent="space-between"
            style={{ width: "100%" }}
          >
            <Grid
              item
              style={{
                color: "#333",
                fontWeight: 600,
                fontSize: "17px",
                paddingTop: "0.5rem",
                paddingBottom: "0.25rem",
              }}
            >
              {headerText}
            </Grid>
          </Grid>
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default CustomizedDialogs;
