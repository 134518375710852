import { Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch, useSelector } from "react-redux";
import CommonFormHeadButton from "../../../../components/CommonTableHead/CommonFormHeadButton";
import {
  editMasterDepartment,
  getMasterDepartmentsById,
  saveMasterDepartment,
  setEditMasterDepartmentData,
  setPostMasterDepartment,
} from "../../DepartmentSlice";
import { rolesUpdateDataMapper } from "../../../../utils/apiDataMappers";
import { setBussinessNotification } from "../../DashboardSlice";
import {
  setMessageKey,
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../../../components/Notification/Notification.slice";
import { setDialogSelectedEntity } from "../../BusinessLocationSlice";
const RoleForm = ({ editData = "", setSelected, setIndex, setIsEdit }) => {
  const [roleData, setRoleData] = useState(editData.role ? editData.role : "");
  const [errorMessage, setErrorMessage] = useState(false);
  const dispatch = useDispatch();
  const {
    masterDepartmentData,
    editMasterDepartmentData,
    postMasterDepartment,
  } = useSelector((state) => state.masterDepartment);
  const { selectedBussinessDetails } = useSelector((state) => state.dashboard);
  const {
    loggedInUserInfo: { role, accountType },
  } = useSelector((state) => state.login);
  useEffect(() => {
    if (editData) {
      editData.id && setRoleData(editData.id.Roles);
      editData.id.id && dispatch(getMasterDepartmentsById(editData.id.id));
    }
  }, [editData]);
  useEffect(() => {
    if (editMasterDepartmentData.status === 200) {
      dispatch(setMessageKey("propertyValue"));

      dispatch(setMultiNotificationVariant("success"));
      dispatch(
        setMultiNotificationData([
          {
            propertyValue: `Role Updated Successfully`,
          },
        ])
      );
      dispatch(setEditMasterDepartmentData(""));
      handleCancel();
    }
    if (
      editMasterDepartmentData !== "" &&
      editMasterDepartmentData.status !== 200
    ) {
      dispatch(setMessageKey("message"));
      dispatch(setMultiNotificationVariant("error"));
      let errorArray = editMasterDepartmentData?.data?.validationErrors?.length
        ? editMasterDepartmentData?.data.validationErrors
        : [{ message: "Something went Wrong" }];
      dispatch(setMultiNotificationData(errorArray));
      dispatch(setEditMasterDepartmentData(""));
    }
  }, [editMasterDepartmentData]);
  useEffect(() => {
    if (postMasterDepartment.status === 200) {
      dispatch(setMessageKey("propertyValue"));
      dispatch(setMultiNotificationVariant("success"));
      dispatch(
        setMultiNotificationData([
          {
            propertyValue: `Role Added Successfully`,
          },
        ])
      );
      dispatch(setPostMasterDepartment(""));
      handleCancel();
    }
    if (postMasterDepartment !== "" && postMasterDepartment.status !== 200) {
      dispatch(setMessageKey("message"));
      dispatch(setMultiNotificationVariant("error"));
      let errorArray = postMasterDepartment?.data?.validationErrors?.length
        ? postMasterDepartment?.data.validationErrors
        : [{ message: "Something went Wrong" }];
      dispatch(setMultiNotificationData(errorArray));
      dispatch(setEditMasterDepartmentData(""));
      dispatch(setPostMasterDepartment(""));
    }
  }, [postMasterDepartment]);
  const handleChange = (data) => {
    if (data && data.trim) {
      setRoleData(data);
      setErrorMessage(false);
    } else {
      setRoleData(data);
      setErrorMessage(true);
    }
  };
  const submit = () => {
    if (roleData && roleData.trim) {
      if (editData.id) {
        dispatch(
          editMasterDepartment(
            rolesUpdateDataMapper(roleData, masterDepartmentData)
          )
        );
      } else {
        dispatch(
          saveMasterDepartment({
            accountId: selectedBussinessDetails.accountId,
            businessId: selectedBussinessDetails.businessId,
            name: roleData,
          })
        );
      }

      setErrorMessage(false);
    } else {
      setErrorMessage(true);
    }
  };
  const handleCancel = () => {
    dispatch(setDialogSelectedEntity(""));
    if (accountType === "FRANCHISOR") {
      setIndex(1);
    } else {
      setIndex(2);
    }
    setSelected("");
    setIsEdit(false);
  };
  return (
    <div style={{ width: "100%" }}>
      <CommonFormHeadButton
        handleCancel={handleCancel}
        setIsEdit={setIsEdit}
        title={editData !== "" ? "Edit Role" : "New Role"}
        submit={submit}
        setIndex={setIndex}
        setSelected={setSelected}
      />
      <Grid container>
        <Grid p={2} item lg={12} xs={12} sm={12} md={12} direction="column">
          <Stack
            direction={"row"}
            justifyContent="flex-start"
            sx={{ marginTop: "1em" }}
          >
            <FiberManualRecordIcon
              sx={{
                marginTop: "0.18em",
                marginLeft: "-1em",
                marginRight: "0.5em",
                color: "#CCCCCC",
                height: "0.5em",
              }}
            />
            <Stack spacing={2}>
              <Typography variant="h7">Role Name</Typography>
              <TextField
                id="role"
                value={roleData}
                sx={{ width: "350px" }}
                size="small"
                placeholder="Enter Role Name"
                onChange={(e) => handleChange(e.target.value)}
              />
              <Typography variant="body1" style={{ color: "#f34f4c" }}>
                {errorMessage && "Role is required"}
              </Typography>
            </Stack>
          </Stack>
          <Divider
            sx={{
              borderStyle: "dotted",
              marginLeft: "-1.2em",
              marginTop: "1em",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default RoleForm;
