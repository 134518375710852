import axios from "axios";
import { ajaxHandler } from "./ajaxHandler";

export const loginUser = async (credentials) => {
  const params = new URLSearchParams();
  params.append("username", credentials?.username);
  params.append("password", credentials?.password);
  const response = await ajaxHandler("post", `focus451/login`, params);
  return response;
};

export const emailVerify = async (email) => {
  const params = new URLSearchParams();
  params.append("emailId", email?.emailId);
  const response = await ajaxHandler(
    "post",
    `focus451/account/generateOtp`,
    params
  );
  return response;
};

export const otpVerify = async (data) => {
  const params = new URLSearchParams();
  params.append("emailId", data?.emailId);
  params.append("otp", data?.otp);
  const response = await ajaxHandler(
    "get",
    `focus451/account/verifyEmail?${params}`
  );
  return response;
};

export const otpReg = async (data) => {
  const params = new URLSearchParams();
  params.append("emailId", data?.emailId);
  // params.append("otp", data?.otp);
  const response = await ajaxHandler(
    "post",
    `focus451/account/regenerateOtp`,
    params
  );
  return response;
};

export const ssoLoginUser = async (credentials) => {
  const response = await ajaxHandler(
    "post",
    `focus451/employee/validateSSO/prismSSOService?peoId=${credentials?.peo_id}&token=${credentials?.key}`
  );
  return response;
};

export const logoutUser = async () => {
  const response = await ajaxHandler("get", `focus451/logout`);
  return response;
};
export const getForgetPass = async (payload) => {
  const response = await ajaxHandler(
    "get",
    `focus451/employee/forgotPassword/${payload}/`
  );
  return response;
};

export const signUpUser = async (payload) => {
  const response = await ajaxHandler(
    "post",
    `focus451/account/create`,
    payload
  );
  return response;
};
