import { createSlice, createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import {
  emailVerify,
  getForgetPass,
  loginUser,
  logoutUser,
  otpReg,
  otpVerify,
  signUpUser,
  ssoLoginUser,
} from "../../services/authentication";
import { PlansStrings } from "../../utils/stringToObj";

export const login = createAsyncThunk(
  "login/loginAPI",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await loginUser(credentials);
      if (response?.status && response?.status !== 200) {
        return response;
      }

      const token = response.headers["x-auth-token"];
      localStorage.setItem("token", token);
      const refreshToken = response.headers["x-refresh-token"];
      localStorage.setItem("refreshToken", refreshToken);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const emailVerification = createAsyncThunk(
  "login/emailAPI",
  async (email, { rejectWithValue }) => {
    try {
      const response = await emailVerify(email);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const otpVerification = createAsyncThunk(
  "login/otpVefication",
  async (data, { rejectWithValue }) => {
    try {
      const response = await otpVerify(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const otpRegenerate = createAsyncThunk(
  "login/otpRegenerate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await otpReg(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// export const https://infinitihr.focus451.com/focus451/employee/validateSSO/prismSSOService?peoId=350*HSG&token=SSOOUTBOUNDaBGa3vXoZehCNvpQ960340055

export const ssoLoginValidate = createAsyncThunk(
  "login/ssoLoginAPI",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await ssoLoginUser(credentials);
      if (response.status !== 200) {
        return response;
      }

      const token = response.headers["x-auth-token"];
      localStorage.setItem("token", token);
      const refreshToken = response.headers["x-refresh-token"];
      localStorage.setItem("refreshToken", refreshToken);
      return response;
    } catch (error) {
      return error.response;
    }
  }
);

export const logout = createAsyncThunk(
  "login/logoutAPI",
  async (_, { rejectWithValue }) => {
    try {
      const response = await logoutUser();
      return response.status;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getForgetPassThunk = createAsyncThunk(
  "login/getForgetPass",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getForgetPass(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const signUpUserThunk = createAsyncThunk(
  "login/signUpThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await signUpUser(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loggedInUserInfo: { allowOnlySSOLogin: false, signedESignAgreements: false },
  userLoggedIn: false,
  isError: false,
  getForgetPassResponse: "",
  errorMessage: "",
  loading: false,
  plan: PlansStrings.GOLD,
  currentTemplateTabIndex: 0,
  selectedTab: "CUSTOME",
  signUpLoading: false,
  signUpData: {},
  isCreateNewUserAdmin: false,
  emailAfterVerifcation: {},
  emailForVerify: {},
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setIsCreateNewUserAdmin: (state, action) => {
      state.isCreateNewUserAdmin = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setEmailUser: (state, action) => {
      state.emailAfterVerifcation = action.payload;
    },
    resetLogin: (state) => initialState,
    setIsEsign: (state, action) => {
      state.loggedInUserInfo.allowOnlySSOLogin = action.payload;
      state.loggedInUserInfo.signedESignAgreements = true;
    },
    setUserLoggedIn: (state, action) => {
      state.userLoggedIn = action.payload;
    },
    setLoggedInUserBusinesses: (state, action) => {
      state.loading = true;
      state.loggedInUserInfo.dashboardHierarchy.businesses = [
        ...state.loggedInUserInfo.dashboardHierarchy.businesses,
        action.payload,
      ];
      state.loading = false;
    },
    setCurrentTemplateTabIndex: (state, action) => {
      state.currentTemplateTabIndex = action.payload;
    },
    setGetForgotPassResponse: (state, action) => {
      state.getForgetPassResponse = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setLoggedInUserInfo: (state, action) => {
      state.loggedInUserInfo = action.payload;
    },
    setSSOLogin: (state, action) => {
      state.loggedInUserInfo.allowOnlySSOLogin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.userLoggedIn = true;
      if (action.payload.status === 200) {
        state.loggedInUserInfo = action.payload.data;
      } else {
        state.isError = true;
        state.loggedInUserInfo = action.payload.data;
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isError = true;
      state.errorMessage = action.payload.shortMessage;
    });
    builder.addCase(emailVerification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(emailVerification.fulfilled, (state, action) => {
      state.emailForVerify = action.payload;
      state.loading = false;
    });
    builder.addCase(emailVerification.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
    });
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(getForgetPassThunk.fulfilled, (state, action) => {
      state.getForgetPassResponse = action.payload;
    });
    builder.addCase(getForgetPassThunk.rejected, (state, action) => {
      state.isError = true;
      state.getForgetPassResponse = action.payload;
    });
    builder.addCase(ssoLoginValidate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(ssoLoginValidate.fulfilled, (state, action) => {
      state.userLoggedIn = true;
      state.loading = false;
    });
    builder.addCase(ssoLoginValidate.rejected, (state, action) => {
      state.isError = true;
      state.loading = false;
    });
    builder.addCase(signUpUserThunk.pending, (state, action) => {
      state.signUpLoading = true;
    });
    builder.addCase(signUpUserThunk.fulfilled, (state, action) => {
      state.signUpLoading = false;
      // state.signUpData = action.payload;
    });
    builder.addCase(signUpUserThunk.rejected, (state, action) => {
      state.signUpLoading = false;
    });
  },
});
export const {
  setGetForgotPassResponse,
  setPlan,
  resetLogin,
  setUserLoggedIn,
  setLoggedInUserBusinesses,
  setIsEsign,
  setCurrentTemplateTabIndex,
  setSelectedTab,
  setLoggedInUserInfo,
  setSSOLogin,
  setIsCreateNewUserAdmin,
  setEmailUser,
} = loginSlice.actions;
export default loginSlice.reducer;
