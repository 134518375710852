import React, { useState } from "react";

import {
  Box,
  Checkbox,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import CustomeAddIcon from "./CustomeAddIcon";
import CustomeMinusIcon from "./CustomeMinusIcon";
import { managerDepartmentMapper } from "../../../../utils/apiDataMappers";
import Add from "../../../../assets/NewIcons/Add.svg";
import Minus from "../../../../assets/NewIcons/Minus.svg";
const ManagerLocation = ({
  dataIndividual = [],
  selectedChecks,
  setSelectedChecks,
  selectParentCheck,
  setSelectParentCheck,
}) => {
  const handleParentCheck = (id) => {
    let tempData = JSON.parse(JSON.stringify(selectParentCheck));
    if (tempData[id]) {
      delete tempData[id];
      setSelectParentCheck(tempData);
    } else {
      if (selectedChecks[id]) {
        let button = document.getElementById(`Drawer${id}`);
        button.click();
      }
      tempData[id] = true;
      setSelectParentCheck(tempData);
    }
  };
  const handleChangeParentCheckBox = (ele) => {
    let tempData = JSON.parse(JSON.stringify(selectedChecks));
    if (tempData[ele.locationId]) {
      delete tempData[ele.locationId];
      setSelectedChecks(tempData);
    } else {
      if (selectParentCheck[ele.locationId]) {
        let check = document.getElementById(`Check${ele.locationId}`);
        check.click();
      }
      tempData[ele.locationId] = { locationId: ele.locationId };
      setSelectedChecks(tempData);
    }
  };
  const handleChangeChildCheckBox = (data, locationId) => {
    let tempData = JSON.parse(JSON.stringify(selectedChecks));
    if (tempData[locationId][data.departmentId]) {
      delete tempData[locationId][data.departmentId];
      setSelectedChecks(tempData);
    } else {
      tempData[locationId][data.departmentId] = {
        locationId: null,
        departmentId: data.departmentId,
      };
      setSelectedChecks(tempData);
    }
  };
  return (
    <Box>
      <Stack direction={"row"}>
        <Typography fontWeight={"bold"} variant="body1">
          Tip :
        </Typography>
        &nbsp;
        <Typography color={"#333"} variant="body1">
          Select an entire location or click to expand and select specific Roles
          this person can manage.
        </Typography>
      </Stack>
      <Table
        sx={{
          width: "100%",
          p: 0,
          maxHeight: "35vh",
          overflowY: "auto",
        }}
      >
        {dataIndividual.length && Array.isArray(dataIndividual)
          ? dataIndividual.map((ele, idx) => {
              return (
                <TableBody key={idx} sx={{ width: "100%" }}>
                  <TableRow sx={{ width: "100%" }}>
                    <TableCell
                      sx={{
                        borderTop: idx === 0 ? "0.5px solid #F3F3F3" : "",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        padding: "0.2em 2em",
                        alignItems: "center",
                        backgroundColor: selectedChecks[ele.locationId]
                          ? "#F8FBFA"
                          : "white",
                      }}
                    >
                      <Checkbox
                        id={`Check${ele.locationId}`}
                        checked={selectParentCheck[ele.locationId]}
                        onChange={() => handleParentCheck(ele.locationId)}
                      />
                      <IconButton
                        id={`Drawer${ele.locationId}`}
                        disableRipple
                        style={{ padding: "0px 13px 0px 5px" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleChangeParentCheckBox(ele)}
                      >
                        {selectedChecks[ele.locationId] ? (
                          <img src={Minus} />
                        ) : (
                          <img src={Add} />
                        )}
                      </IconButton>
                      {/* <div
                        id={`Drawer${ele.locationId}`}
                        style={{ scale: "1.25", paddingTop: "0.1em" }}
                        onClick={() => handleChangeParentCheckBox(ele)}
                      >
                        {selectedChecks[ele.locationId] ? (
                          <CustomeMinusIcon />
                        ) : (
                          <CustomeAddIcon />
                        )}
                      </div> */}
                      <Typography
                        sx={{ paddingTop: "0.1em", marginLeft: "0.8em" }}
                        color={"#666"}
                        variant="body1"
                      >
                        {ele.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {selectedChecks[ele.locationId] ? (
                    <>
                      {ele.departments.map((element, index) => {
                        return (
                          <TableRow key={index} sx={{ width: "100%" }}>
                            <TableCell
                              sx={{
                                borderTop:
                                  idx === 0 ? "0.5px solid #F3F3F3" : "",
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                                padding: "0.2em 4em",
                                alignItems: "center",
                              }}
                            >
                              <Checkbox
                                id={`department-${index}`}
                                checked={
                                  selectedChecks[ele.locationId][
                                    element.departmentId
                                  ]
                                }
                                onChange={() =>
                                  handleChangeChildCheckBox(
                                    element,
                                    ele.locationId
                                  )
                                }
                              />
                              <Typography color={"#999"} variant="body1">
                                {element.name}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : null}
                </TableBody>
              );
            })
          : null}
      </Table>
    </Box>
  );
};

export default ManagerLocation;
