import {
    TableBody, TableCell, TableRow 
} from "@mui/material";
const PrintHistory = (history) => {
    const documentHistory = history.history;
return(
    <div id="printt" style={{display:"none"}}>
                    <div>
                        <h3>{documentHistory.name}</h3>
                        <br />
                        <div>
                            <span class="key">Business Name: </span>
                            <span>{documentHistory.businessName}</span>
                        </div>
                        <div>
                            <span class="key">Description: </span>
                            <span>{documentHistory.description}</span>
                        </div>
                        <div>
                            <span class="key">Created Time: </span>
                            <span>{documentHistory.createdTime}</span>
                        </div>
                        <div>
                            <span class="key">Time Zone: </span>
                            <span>UTC</span>
                        </div>
                        <br />
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>UserID</th>
                                    <th>User Name</th>
                                    <th>Action</th>
                                    <th>IP</th>
                                    <th>Session ID</th>
                                </tr>
                            </thead>
                            <TableBody id="bodyy">
                  {Array.isArray(documentHistory.auditTrailBean) &&
                    documentHistory.auditTrailBean.map((historytable) => (
                      <TableRow box-sizing={"border-box"}>
                        <TableCell
                          style={{
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                          }}
                        >
                          {historytable.createdTime}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                          }}
                        >
                          {historytable.loginId}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                          }}
                        >
                          {historytable.userName}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                          }}
                        >
                          {historytable.operation}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                          }}
                        >
                          {historytable.ipAddress}
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: history.width ? history.width : "150px",
                            fontFamily: "sans-serif",
                            color: "#999",
                            padding: "15px 10px 15px 0",
                            fontSize: "13px",
                            whiteSpace: "nowrap",
                            flexWrap: "nowrap",
                            overflowX: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {historytable.sessionId}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                        </table>

                    </div>
                </div>
)
}
export default PrintHistory;