import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { dateFormat } from "../../utils/dateFormatter";
import { localToIsoStirng } from "../../utils/localToISODateString";
const EmployeementNextStage = ({
  register,
  disabled,
  setValue,
  defaultDate = null,
  selectObj = { hello: "hi" },
  defaultValueSelect = "se",
}) => {
  const [valueSelect, setValueSelect] = useState(defaultValueSelect);
  const [dateState, setDateState] = useState(new Date(defaultDate));
  const handleDateChange = (data) => {
    setDateState(data);
    setValue("hireStageChangeDate", localToIsoStirng(data.toLocaleString()));
  };
  const [openCalender, setOpenCalender] = useState(false);
  const handleChangeSelect = (data) => {
    setValue("nextHireStage", data);
    setValueSelect(data);
  };
  useEffect(() => {
    setValueSelect(defaultValueSelect);
  }, [defaultValueSelect]);
  return (
    <Box sx={{ p: "0 1em" }}>
      <Stack
        width={"100%"}
        direction={"row"}
        justifyContent="flex-start"
        sx={{ marginTop: "1em" }}
      >
        <FiberManualRecordIcon
          sx={{
            marginTop: "0.18em",
            marginLeft: "-1em",
            marginRight: "0.5em",
            color: "#CCCCCC",
            height: "0.5em",
          }}
        />
        <Stack width={"100%"} spacing={2}>
          <Typography variant="h7">{"Employment Next Stage"}</Typography>
          <Stack sx={{ width: { sm: "50%", xs: "75%" } }}>
            <Select
              id="employementNextStageDropdown"
              size="small"
              disabled={disabled}
              value={valueSelect}
              onChange={(e) => handleChangeSelect(e.target.value)}
            >
              <MenuItem
                id="selectEmployement"
                sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                key={"idx"}
                value={"se"}
              >
                Select Employment Next Stage
              </MenuItem>
              {Object.keys(selectObj).map((ele, idx) => {
                return (
                  <MenuItem
                    id={selectObj[ele]}
                    sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                    key={idx}
                    value={ele}
                  >
                    {selectObj[ele]}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>

          <Typography
            sx={{ textAlign: "left" }}
            fontWeight={400}
            variant="body2"
          >
            Enter Next Stage Start Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disabled={disabled}
              open={openCalender}
              onClose={() => setOpenCalender(false)}
              inputFormat="MM/DD/YYYY"
              value={dateState}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  onClick={() => setOpenCalender(true)}
                  size="small"
                  sx={{ width: "330px", textAlign: "center" }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EmployeementNextStage;
