import {
  Box,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const SelectCustome = ({
  defaultValue = "",
  label,
  setValueName,
  errors,
  setValue,
  disabled,
}) => {
  const [data, setData] = useState(defaultValue);
  const handleChange = (data) => {
    setData(data);
    setValue(setValueName, data);
  };
  useEffect(() => {
    setData(defaultValue);
    setValue(setValueName, defaultValue);
  }, [defaultValue]);
  return (
    <Box p={"0 1em"} width={"100%"}>
      <Stack
        width={"100%"}
        direction={"row"}
        justifyContent="flex-start"
        sx={{ marginTop: "1em" }}
      >
        <FiberManualRecordIcon
          sx={{
            marginTop: "0.18em",
            marginLeft: "-1em",
            marginRight: "0.5em",
            color: "#CCCCCC",
            height: "0.5em",
          }}
        />
        <Stack width="100%" spacing={2}>
          <Typography variant="h7">{label}</Typography>
          <Stack direction={"row"}>
            <Stack width={{ sm: "50%", xs: "75%" }} spacing={1}>
              <Select
                inputProps={{ style: { fontSize: "13px" } }}
                id="selectEmployementDropdown"
                disabled={disabled}
                fullwidth="true"
                defaultValue={"se"}
                size="small"
                value={data}
                onChange={handleChange}
                sx={{
                  "& #my-simple-select": {
                    fontSize: "13px",
                  },
                }}
              >
                <MenuItem
                  id="selectEmployement"
                  sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                  value="se"
                >
                  Select Employment Stage
                </MenuItem>
                <MenuItem
                  id="preHire"
                  sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                  value={"PRE_HIRE"}
                >
                  Pre-Hire
                </MenuItem>
                <MenuItem
                  id="hire"
                  sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                  value="HIRE"
                >
                  {" "}
                  Hire
                </MenuItem>
                <MenuItem
                  id="notHired"
                  sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                  value="NOT_HIRED"
                >
                  Not Hired
                </MenuItem>
                <MenuItem
                  id="manage"
                  sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                  value="MANAGE"
                >
                  Manage (Active)
                </MenuItem>
                <MenuItem
                  id="retire"
                  sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                  value="RETIRE"
                >
                  {" "}
                  Retire (Active)
                </MenuItem>
                <MenuItem
                  id="terminated"
                  sx={{ ":hover": { backgroundColor: "rgba(0,0,0,0.15)" } }}
                  value="TERMINATED"
                >
                  {" "}
                  Terminated
                </MenuItem>
              </Select>
              <Typography variant="body1" style={{ color: "#f34f4c" }}>
                {errors[setValueName]?.message}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SelectCustome;
