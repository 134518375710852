import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  confirmUser,
  eSignAgreement,
  eSignAgreementNewUser,
  eSignAgreementOldUser,
  verifyOTP,
} from "../../services/agreement";

export const verifyOTPThunk = createAsyncThunk(
  "agreement/verifyOTP",
  async (data, { rejectWithValue }) => {
    try {
      const response = await verifyOTP(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const confirmUserThunk = createAsyncThunk(
  "agreement/confirmUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await confirmUser(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const eSignAgreementThunk = createAsyncThunk(
  "agreement/eSignAgreement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await eSignAgreement(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const eSignAgreementNewUserThunk = createAsyncThunk(
  "agreement/eSignAgreementNewUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await eSignAgreementNewUser(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const eSignAgreementOldUserThunk = createAsyncThunk(
  "agreement/eSignAgreementOldUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await eSignAgreementOldUser(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  agreementNotification: {
    message: "",
    openSnackbar: false,
    severity: "success",
  },
  loading: false,
  confirmUserResponse: "",
  verifyOTPResponse: "",
  optIn: false,
  isUser: false,
  userFetchData: "",
  userIdentifier: "",
  eSignAgreementResponse: "",
  eSignAgreementUserResponse: "",
  eSignAgreementOldUserResponse: "",
  isReload: true,
};
export const agreementSlice = createSlice({
  name: "agreement",
  initialState,
  reducers: {
    setOptIn: (state, action) => {
      state.optIn = action.payload;
    },
    resetOptIn: (state, action) => {
      state.optIn = false;
    },
    setconfirmUserResponse: (state, action) => {
      state.confirmUserResponse = action.payload;
    },
    setIsReload: (state, action) => {
      state.isReload = action.payload;
    },
    resetAgreement: () => initialState,
    setPostNewUserResetPassResponse: (state, action) => {
      state.postNewUserResetPassResponse = action.payload;
    },
    setIsUser: (state, action) => {
      state.isUser = action.payload;
    },
    setUserFetchData: (state, action) => {
      state.userFetchData = action.payload;
    },
    setUserIdentifier: (state, action) => {
      state.userIdentifier = action.payload;
    },
    setAgreementNotify: (state, action) => {
      state.agreementNotification = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyOTPThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(verifyOTPThunk.fulfilled, (state, action) => {
      state.verifyOTPResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(verifyOTPThunk.rejected, (state, action) => {
      state.verifyOTPResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(confirmUserThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(confirmUserThunk.fulfilled, (state, action) => {
      if (action.payload.status !== 412) {
        state.confirmUserResponse = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(confirmUserThunk.rejected, (state, action) => {
      if (action.payload.status !== 412) {
        state.confirmUserResponse = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(eSignAgreementThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(eSignAgreementThunk.fulfilled, (state, action) => {
      state.eSignAgreementResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(eSignAgreementThunk.rejected, (state, action) => {
      state.eSignAgreementResponse = action.payload;
      state.loading = false;
    });

    builder.addCase(eSignAgreementNewUserThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(eSignAgreementNewUserThunk.fulfilled, (state, action) => {
      state.eSignAgreementUserResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(eSignAgreementNewUserThunk.rejected, (state, action) => {
      state.eSignAgreementUserResponse = action.payload;
      state.loading = false;
    });

    builder.addCase(eSignAgreementOldUserThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(eSignAgreementOldUserThunk.fulfilled, (state, action) => {
      state.eSignAgreementOldUserResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(eSignAgreementOldUserThunk.rejected, (state, action) => {
      state.eSignAgreementOldUserResponse = action.payload;
      state.loading = false;
    });
  },
});

export const {
  setOptIn,
  setIsReload,
  resetAgreement,
  setPostNewUserResetPassResponse,
  setIsUser,
  setUserFetchData,
  setUserIdentifier,
  setAgreementNotify,
  setconfirmUserResponse,
  resetOptIn,
} = agreementSlice.actions;
export default agreementSlice.reducer;
