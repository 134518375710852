import React, { useState } from "react";
import { ajaxHandler } from "../../services/ajaxHandler";
import { getEmailFromToken } from "../../utils/getEmailFromToken";
import {
  setMessageKey,
  setMultiNotificationData,
  setMultiNotificationVariant,
} from "../../components/Notification/Notification.slice";
import { useDispatch } from "react-redux";

const useGenerateOTP = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const email = getEmailFromToken();
  const dispatch = useDispatch();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await ajaxHandler(
        "post",
        `focus451/account/generateOtp?emailId=${email}&flag=PAYMENT`
      );

      if (response.status === 201) {
        setData(response.data);
        dispatch(setMessageKey("propertyValue"));
        dispatch(setMultiNotificationVariant("success"));
        dispatch(
          setMultiNotificationData([
            {
              propertyValue: `OTP sent to your registered email`,
            },
          ])
        );
      } else {
        throw new Error(response.data || "Failed to fetch data");
      }
    } catch (error) {
      setError(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchData };
};

export default useGenerateOTP;
