import React, { useState } from "react";
import { ajaxHandler } from "../../services/ajaxHandler";
import { getEmailFromToken } from "../../utils/getEmailFromToken";

const useValidateOTP = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const email = getEmailFromToken();

  const validateOTP = async ({
    otp = null,
    onSuccess = null,
    onError = null,
  }) => {
    setLoading(true);
    try {
      const response = await ajaxHandler(
        "get",
        `focus451/account/verifyEmail?emailId=${email}&otp=${otp}`
      );

      if (response.status === 200) {
        setData(response.data);
        if (onSuccess) {
          onSuccess();
        }
      } else {
        throw new Error(response.data || "Failed to fetch data");
      }
    } catch (error) {
      setError(error.message || "An error occurred");
      onError && onError();
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, validateOTP };
};

export default useValidateOTP;
