import { createSlice } from "@reduxjs/toolkit";

export const alertType = {
    error: "error",
    success: "success",
    warning: "warning",
    info: "info"
}

const initialAlertState = {
    type: alertType.error,
    message: "",
    status: false
}

export const alertSlice = createSlice({
    name: "alert",
    initialState: initialAlertState,
    reducers: {
        showAlert: (state, action) => {
            state.message = action.payload.message
            state.status = action.payload.status
            state.type = action.payload.type
        },
        closeAlert: (state) => {
            state.message = initialAlertState.message
            state.status = initialAlertState.status
            state.type = initialAlertState.type
        }
    }
})

export const {
    showAlert, closeAlert
} = alertSlice.actions;

export default alertSlice.reducer