import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { sortByAsceOrder, sortByDescOrder } from "../../utils/tableSorting";
import { ReactComponent as Up } from "../../assets/NewIcons/Up.svg";
import { ReactComponent as Down } from "../../assets/NewIcons/Down.svg";
import { ReactComponent as UpdatedChange } from "../../assets/NewIcons/Updated_Change.svg";
import theme from "../../theme";
const TableCustome = ({
  headList,
  bodyList,
  isEdit = false,
  editAction,
  primeWidth,
  primeSort,
  buttonName = "Edit",
}) => {
  const [isAsce, setIsAsce] = useState({});

  const [localBodyList, setLocalBodyList] = useState(bodyList);
  function UpIcon() {
    return <Up style={{ scale: "0.7" }} />;
  }
  function DownIcon() {
    return <Down style={{ scale: "0.7" }} />;
  }
  useEffect(() => {
    setLocalBodyList(sortByAsceOrder(primeWidth, bodyList));
    setIsAsce({ [primeWidth]: "desc" });
  }, [bodyList]);
  const handleSort = (key) => {
    if (isAsce[key] && isAsce[key] === "asce") {
      setLocalBodyList(sortByAsceOrder(key, bodyList));
      let updatedValue = {};
      updatedValue[key] = "desc";
      setIsAsce(updatedValue);
    } else {
      setLocalBodyList(sortByDescOrder(key, bodyList));
      let updatedValue = {};
      updatedValue[key] = "asce";
      setIsAsce(updatedValue);
    }
  };
  return (
    <Paper
      sx={{
        width: "100%",
        boxShadow: 0,
      }}
    >
      <TableContainer
        sx={{
          height: {
            lg: "62vh",
            ipadMini: "65vh",
            ipadAir: "65vh",
            bigScreen: "65vh",
            md: "60vh",
          },
          maxHeight: {
            lg: "62vh",
            ipadMini: "65vh",
            ipadAir: "65vh",
            bigScreen: "65vh",
            md: "60vh",
          },
          overflowY: "auto",
          height: "65vh",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&:hover": {
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#9e9e9e",
              borderRadius: "50px",

              ":hover": {
                backgroundColor: "rgba(0, 0, 0, 0.35)",
              },
            },
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headList.map((ele, idx) => {
                return (
                  <TableCell
                    sx={{
                      width: ele === primeWidth ? "50%" : "15%",
                      maxWidth: ele === primeWidth ? "50%" : "15%",
                      justifyContent: "center",
                      flexDirection: "row-reverse",
                      padding: primeSort?.includes(ele)
                        ? "0.6rem 0rem"
                        : "0.6rem 1rem",
                      border: "0.5px  solid #EEF2F4",
                      backgroundColor: "#FBFBFC",
                    }}
                    key={idx}
                    scope="row"
                    component={"th"}
                  >
                    {primeSort?.includes(ele) ? (
                      <TableSortLabel
                        active={true}
                        IconComponent={
                          isAsce[ele] === "asce"
                            ? DownIcon
                            : isAsce[ele] === "desc"
                            ? UpIcon
                            : UpdatedChange
                        }
                        onClick={() => handleSort(ele)}
                      >
                        <Typography> {ele}</Typography>
                      </TableSortLabel>
                    ) : (
                      <Typography>{ele}</Typography>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {localBodyList.length ? (
              localBodyList.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {Object.keys(row).map((key) => (
                      <React.Fragment key={key}>
                        {key !== "id" &&
                          key !== "accountId" &&
                          key !== "businessId" && (
                            <TableCell
                              sx={{
                                color: theme.palette.common.grey,
                                width: key === primeWidth ? "50%" : "15%",
                                maxWidth: key === primeWidth ? "50%" : "15%",
                                border: "0.5px  solid #EEF2F4",
                                padding: isEdit ? "0.5em 1em" : "1em 1em",
                              }}
                            >
                              {row[key]?.split(",").length > 1 ? (
                                <>
                                  {row[key].split(",").map((values, vIdx) => {
                                    return (
                                      <Typography key={vIdx}>
                                        {values}
                                      </Typography>
                                    );
                                  })}
                                </>
                              ) : (
                                <Typography>{row[key]}</Typography>
                              )}
                            </TableCell>
                          )}
                      </React.Fragment>
                    ))}
                    {isEdit ? (
                      <TableCell
                        sx={{
                          width: "15%",
                          border: "0.5px  solid #EEF2F4",
                          padding: "0.5em 1em",
                        }}
                      >
                        <Button
                          id={`edit-${index}`}
                          onClick={() => editAction(row)}
                          variant="contained"
                          sx={{ p: "0.2rem", m: "0.2rem", color: "white" }}
                        >
                          {buttonName}
                        </Button>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                sx={{
                  border: "0.5px  solid #EEF2F4",
                  height: "2vh",
                  padding: "0.5em 2em",
                }}
              >
                <Typography sx={{ padding: "1em 10%" }}>
                  Nothing To Show here
                </Typography>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableCustome;
