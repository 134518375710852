import * as React from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setMessageKey, setMultiNotificationData } from "./Notification.slice";
import SnackbarCloseButton from "./SnackbarCloseButton";
function MyApp() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    multiNotificationData: arr,
    multiNotificationVariant,
    messageKey = "propertyValue",
  } = useSelector((state) => state.notification);
  React.useEffect(() => {
    arr &&
      Array.isArray(arr) &&
      arr.length &&
      arr.map((ele) => {
        enqueueSnackbar(
          ele[messageKey] ? ele[messageKey] : "Something went wrong",
          {
            variant: `${multiNotificationVariant}`,
          }
        );
      });
  }, [arr]);
  return <></>;
}

export default function MultiNotification() {
  const dispatch = useDispatch();
  const { verticalPosition, horizontalPosition } = useSelector(
    (state) => state.notification
  );
  return (
    <SnackbarProvider
      action={(snackbarKey) => (
        <SnackbarCloseButton snackbarKey={snackbarKey} />
      )}
      onClose={() => {
        dispatch(setMultiNotificationData([]));
        dispatch(setMessageKey("propertyValue"));
      }}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: verticalPosition,
        horizontal: horizontalPosition,
      }}
      maxSnack={5}
    >
      <MyApp />
    </SnackbarProvider>
  );
}
