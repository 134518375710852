import { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Link,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import Loading from "../Loading/Loading";

const ViewPendingDocs = (props) => {
  const { locationdocsmap } = useSelector((state) => state.dashboard);
  const [loading, setLoading] = useState(false);
  const columns = [
    { id: "supplementaldocs", label: "Supplemental Documents" },
    { id: "expirydate", label: "Expiry Date" },
    { id: "required", label: "Required?" },
  ];
  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, []);

  return (
    <Grid
      style={{
        width: "100%",
        minWidth: "700px",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "10px 0px 0px 4px",
      }}
    >
      <TableContainer>
        {loading ? (
          <Loading />
        ) : (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return (
                    <TableCell
                      sx={{
                        borderLeft: "0.25px solid lightgrey",
                        borderRight: "0.25px solid lightgrey",
                        borderTop: "1px solid lightgrey",
                        fontFamily: "sans-serif",
                        fontWeight: "lighter",
                        background: "#fafbfd",
                        fontSize: "13px",
                        padding: "15px 10px",
                        verticalAlign: "bottom",
                        borderCollapse: "collapse",
                      }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            {locationdocsmap.supportingDocuments === null ? (
              <TableBody>
                <TableRow
                  style={{
                    borderLeft: "1px solid lightgrey",
                    borderRight: "1px solid lightgrey",
                  }}
                >
                  <TableCell
                    colSpan={3}
                    style={{ textAlign: "center", color: "#999" }}
                  >
                    Nothing to show here
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {Array.isArray(locationdocsmap.supportingDocuments) &&
                  locationdocsmap.supportingDocuments.map((pendingTable) => (
                    <TableRow
                      sx={{
                        borderLeft: "1px solid lightgrey",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      <TableCell
                        style={{
                          fontFamily: "sans-serif",
                          padding: "15px 10px",
                          width: "65%",
                          borderLeft: "1px solid lightgrey",
                          borderRight: "1px solid lightgrey",
                          fontSize: "13px",
                        }}
                      >
                        {pendingTable.s3Folder === null ? (
                          <Link
                            style={{
                              color: "#93c",
                              textDecorationColor: "none",
                              textDecoration: "none",
                            }}
                          >
                            {pendingTable.name}
                          </Link>
                        ) : (
                          <Link
                            style={{
                              color: "#93c",
                              textDecorationColor: "none",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              props.downloadFile(pendingTable);
                            }}
                          >
                            {pendingTable.name}
                          </Link>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "sans-serif",
                          color: "#999",
                          padding: "15px 10px",
                          width: "25%",
                          fontSize: "13px",
                          borderCollapse: "collapse",
                        }}
                      >
                        {pendingTable.expiryDate
                          ? new Date(pendingTable.expiryDate)
                              .toISOString()
                              .slice(0, 10)
                          : "No"}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "sans-serif",
                          color: "#999",
                          padding: "15px 10px",
                          width: "10%",
                          borderLeft: "1px solid lightgrey",
                          borderRight: "1px solid lightgrey",
                          fontSize: "13px",
                        }}
                      >
                        {pendingTable.mandatory ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        )}
      </TableContainer>
    </Grid>
  );
};

export default ViewPendingDocs;
