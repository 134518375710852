import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./Footer.css";
const Footer = (props) => {
  return (
    <Grid
      container
      sx={{
        paddingX: "1rem",
        justifyContent: "space-between",
        alignItems: props.isDashboard ? "end" : "center",
        height: "100% ",
        paddingBottom: props.isDashboard ? "1rem" : 0,
      }}
    >
      <Grid item>
        <Typography
          variant="body2"
          align="center"
          fontSize="12px"
          color="#999"
          {...props}
        >
          © {new Date().getFullYear()} All Rights Reserved. FOCUS451 Inc.
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontSize="12px" variant="body2" align="center">
          <Link
            className="links"
            onClick={(event) => {
              window.open("https://focus451.com/terms/");
            }}
          >
            Terms and Conditions
          </Link>
          |
          <Link
            className="links"
            onClick={(event) => {
              window.open("https://focus451.com/privacy-policy/");
            }}
          >
            {" "}
            Privacy Policy
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
