import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getSingleAccount,
  getAllAccounts,
  putSingleAccount,
} from "../../services/admin";

export const getAllAccountsThunk = createAsyncThunk(
  "admin/getAllAccounts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAllAccounts(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSingleAccountThunk = createAsyncThunk(
  "admin/getSingleAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getSingleAccount(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const putSingleAccountThunk = createAsyncThunk(
  "admin/putSingleAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await putSingleAccount(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: false,
  allAccounts: "",
  singleAccount: "",
  isEdit: false,
  selectedUserAccountId: "",
  isAdminSubmit: false,
  locationValue: 0,
  isEditButtonLocation: false,
};

export const signatorySlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetAdmin: (state) => initialState,
    setAllAccount: (state, action) => {
      state.allAccounts = action.payload;
    },
    setSingleAccount: (state, action) => {
      state.singleAccount = action.payload;
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    setIsEditButtonLocation: (state, action) => {
      state.isEditButtonLocation = action.payload;
    },
    setSelectedUserAccountId: (state, action) => {
      state.selectedUserAccountId = action.payload;
    },
    setIsAdminSubmit: (state, action) => {
      state.isAdminSubmit = action.payload;
    },
    setLocationValue: (state, action) => {
      state.locationValue = action.payload;
    },
    resetLoading: (state, action) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAccountsThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllAccountsThunk.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAllAccountsThunk.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getSingleAccountThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSingleAccountThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.singleAccount = action.payload.data;
    });
    builder.addCase(getSingleAccountThunk.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(putSingleAccountThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(putSingleAccountThunk.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(putSingleAccountThunk.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  setAllAccount,
  setSingleAccount,
  resetAdmin,
  setIsEdit,
  setSelectedUserAccountId,
  setIsAdminSubmit,
  setLocationValue,
  setIsEditButtonLocation,
  resetLoading,
} = signatorySlice.actions;
export default signatorySlice.reducer;
