import { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  Stack,
  TextField,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
} from "@mui/material";

import theme from "../../../../../theme";
import { CheckBox } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import RestoreIcon from "@mui/icons-material/Restore";
import { setLoaderDoc } from "../../../../Templates/TemplateSlice";
import {
  getLocationsDocumentsForCompliant,
  downloadLocationsDocumentsForCompliant,
  getTaskHistorybyId,
  getDoctemplateonLocation,
  downloadLocationSupportingDocumentsforCompliant,
  resetGetDocTemplate,
  resetLocationDocMap,
  resetDownloadLocationDocs,
  resetdownloadlocationsupportingdocs,
} from "../../../DashboardSlice";
import Loading from "../../../../../components/Loading/Loading";
import { Box } from "@mui/system";
import ViewTask from "../../../../../components/Row/VIewTaskDetail";
import PendingTable from "../../../../../components/Row/PendingTable";
import * as React from "react";
import { HistoryCustomizedDialog } from "../../../../../components/Dialog/Dialog";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PrintHistory from "../../../../../components/Common/CommonDocTable/print";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getIndividualDocsThunk,
  resetDownloadDocPdf,
} from "../../../EmployeeSlice";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};
const url = `https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

const DocumentsWrapper = ({ id }) => {
  let dispatch = useDispatch();
  const [documents, setDocuments] = useState("");

  const { locationdocsmap } = useSelector((state) => state.dashboard);
  const { individualDocs } = useSelector((state) => state.employee);
  const { downloadlocationdocs } = useSelector((state) => state.dashboard);
  const { docshistorybyid } = useSelector((state) => state.dashboard);
  const { getDocTemplatelocation } = useSelector((state) => state.dashboard);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [blobDoc, setBlobDoc] = useState(null);
  const inputRef = useRef({});
  const [tableLoader, setTableLoader] = useState(true);
  const [isDocLoading, setIsDocLoading] = useState(true);
  const [pendingTableLoader, setpendingTableLoader] = useState(true);

  const inputRefDirectBlob = useRef({});
  const [templateID, setTemplateID] = useState("");
  let datetime = "";
  let ind = "";

  let dc = JSON.parse(JSON.stringify(locationdocsmap));
  const setDateTime = (data, index) => {
    datetime = data;
    ind = index;
    if (ind !== null || ind !== "") {
      dc.fillableFields[ind].filledOnDate = datetime;
    }
    return data;
  };

  const presentDate = new Date();
  const currTime =
    presentDate.toLocaleDateString() +
    " " +
    presentDate.getHours() +
    ":" +
    presentDate.getMinutes();

  const getdocs = async (row) => {
    setTemplateID(row);
    if (
      row.actionRequired === "READ_AND_FILL" ||
      row.actionRequired === "READ_AND_ACKNOWLEDGE"
    ) {
      if (row.complianceStatus === "Compliant") {
        await dispatch(downloadLocationsDocumentsForCompliant(row.taskId));
        console.log("1 called binary");
      } else {
        const res = await dispatch(getDoctemplateonLocation(row.templateId));
        if (res.payload.success) {
          console.log("2 called stream");
          setIsLoading(false);
        }
      }
    } else if (row.actionRequired === "NONE") {
      console.log("3");
      setIsLoading(false);
    }
    const res = await dispatch(getLocationsDocumentsForCompliant(row.taskId));
    const result = unwrapResult(res);
    if (result.status === 200) {
      const originalResult = result.data;
      console.log("4");
      setIsLoading(false);

      if (originalResult.supportingDocuments) {
        for (let i = 0; i < originalResult.supportingDocuments.length; i++) {
          const supportDocs = originalResult.supportingDocuments[i];
          if (supportDocs.downloadS3Location !== null) {
            const res = await dispatch(
              getDoctemplateonLocation(row.templateId)
            );
            const unWrapResult = unwrapResult(res);
            if (unWrapResult.status === 200) {
              console.log("5");
              setIsLoading(false);
            }
          } else if (supportDocs.downloadS3Location === null) {
            console.log("6");
            setIsLoading(false);
            setpendingTableLoader(false);
          }
          if (supportDocs.s3Folder) {
            const res = await dispatch(
              downloadLocationSupportingDocumentsforCompliant({
                status: "task",
                taskId: row.taskId,
                supportId: supportDocs.id,
              })
            );
            const fileData = await res.payload;
            if (fileData instanceof Blob) {
              inputRefDirectBlob.current = {
                ...inputRefDirectBlob.current,
                [supportDocs.id]: fileData,
              };
              const reader = new FileReader();
              reader.readAsDataURL(fileData);
              reader.onloadend = function () {
                inputRef.current = {
                  ...inputRef.current,
                  [supportDocs.id]: reader.result,
                };
              };
            }
            console.log("7");
            setIsLoading(false);
            setpendingTableLoader(false);
          }
        }
      } else {
        setpendingTableLoader(false);
      }
    }
  };
  useEffect(() => {
    reponseFunction();
    return () => {
      dispatch(resetDownloadLocationDocs());
      dispatch(resetDownloadDocPdf());
      dispatch(resetLocationDocMap());
      dispatch(resetGetDocTemplate());
      dispatch(resetdownloadlocationsupportingdocs());
    };
  }, []);
  useEffect(() => {
    if (
      getDocTemplatelocation?.data &&
      selectedDocument.complianceStatus !== "Compliant"
    ) {
      setDocuments(
        "data:application/pdf;base64," + getDocTemplatelocation?.data
      );
    }
  }, [getDocTemplatelocation?.data]);
  const reponseFunction = async () => {
    await dispatch(getIndividualDocsThunk(id));
    setTableLoader(false);
  };

  function onDocumentLoadSuccess({ numPages }) {
    console.log("8");
    setIsLoading(false);
    setNumPages(numPages);
    setIsDocLoading(false);
  }

  const historyTab = async (row) => {
    dispatch(setLoaderDoc(true));
    const res = await dispatch(getTaskHistorybyId(locationdocsmap.id));
    console.log("9");
    setIsLoading(false);
    setOpenDialog(true);
  };

  const dispatchfunction = (props) => {
    const fileName = props.s3Folder;
    const tempLink = document.createElement("a");
    tempLink.href = window.URL.createObjectURL(
      inputRefDirectBlob.current[props.id]
    );
    tempLink.download = `${fileName}`;
    tempLink.click();
  };

  function openPDFv2(docName) {
    const tempLink = document.createElement("a");
    tempLink.href = window.URL.createObjectURL(blobDoc);
    tempLink.download = `${docName}` + ".pdf";
    tempLink.click();
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    localStorage.setItem("fileContent", "");
    setOpenDialog(false);
  };
  useEffect(() => {
    if (downloadlocationdocs instanceof Blob) {
      setBlobDoc(downloadlocationdocs);
      const reader = new FileReader();
      reader.readAsDataURL(downloadlocationdocs);
      reader.onloadend = function () {
        setDocuments(reader.result);
      };
    }
  }, [downloadlocationdocs]);

  const handlePrint = () => {
    var printContents = document.getElementById("printt").innerHTML;
    var a = window.open("", "", "height=700, width=700");
    a.document.write("<html><head>");
    a.document.write("</head><body>");
    a.document.write(printContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  };

  const columns = [
    { id: "documentName", label: "Document" },
    { id: "dueDate", label: "Due Date" },
    { id: "complianceStatus", label: "Compliance Status" },
  ];
  const headerButton1 = [
    {
      btnName: "History",
      btnIcon: <RestoreIcon />,
      onClick: () => {
        historyTab();
      },
      btnColor: "#93c",
      btnTextColor: "#fff",
    },
  ];
  const headerButtons = [
    {
      btnName: "Download PDF",
      btnIcon: <CloudDownloadIcon />,
      onClick: () => {
        openPDFv2(selectedDocument.documentName);
      },
      btnColor: "#93c",
      btnTextColor: "#fff",
    },
    {
      btnName: "History",
      btnIcon: <RestoreIcon />,
      onClick: () => {
        historyTab();
      },
      btnColor: "#93c",
      btnTextColor: "#fff",
    },
  ];

  const headerbtn = [
    {
      btnName: "Print",
      onClick: () => {
        handlePrint();
      },
      btnColor: "#93c",
      btnTextColor: "#fff",
    },
  ];

  return (
    <>
      {openDialog ? (
        <HistoryCustomizedDialog
          open={openDialog}
          headerText={"Document History"}
          headerButtons={headerbtn}
          handleClickOpen={handleOpenDialog}
          handleClose={handleCloseDialog}
        >
          <PrintHistory history={docshistorybyid} />
          <ViewTask history={docshistorybyid} />
        </HistoryCustomizedDialog>
      ) : null}
      <Grid
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          fontFamily: "sans-serif",
          borderBottom: "thin rgba(0, 0, 0, 0.15)",
          paddingTop: "0.5rem",
        }}
      >
        <TableContainer
          sx={{
            maxHeight: "100%",
            boxSizing: "border-box",
            overflow: "hidden",
          }}
        >
          {tableLoader ? (
            <Loading />
          ) : (
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    return (
                      <TableCell
                        sx={{
                          borderLeft: "1px solid lightgrey",
                          borderTop: "1px solid lightgrey",
                          borderRight: "1px solid lightgrey",
                          background: "#fafbfd",
                          fontFamily: "sans-serif",
                          fontWeight: 400,
                          fontSize: "13px",
                          color: "#333",
                        }}
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody sx={{ padding: "8px 12px" }}>
                {Array.isArray(individualDocs) && individualDocs.length ? (
                  individualDocs.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        border: "1px solid lightgrey",
                        padding: "8px 12px",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          fontSize: "13px",
                          borderLeft: "1px solid lightgrey",
                          borderRight: "1px solid lightgrey",
                          padding: "8px 12px",
                          color: "#333",
                          width: "56%",
                          size: "small",
                        }}
                      >
                        <Link
                          // href="#"
                          style={{ color: "#93c", textDecorationColor: "none" }}
                          onClick={() => {
                            setSelectedDocument(row);
                            getdocs(row);
                          }}
                        >
                          {row.documentName}
                        </Link>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "13px",
                          fontFamily: "sans-serif",
                          color: "#999",
                          padding: "0 0 0 1em",
                          width: "22%",
                        }}
                      >
                        {row.dueDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "13px",
                          fontFamily: "sans-serif",
                          color: "#999",
                          display: "flex",
                          justifyContent: "space-between",
                          borderLeft: "1px solid lightgrey",
                          borderRight: "1px solid lightgrey",
                          position: "relative",
                        }}
                      >
                        <Grid container justifyContent="space-between">
                          <Grid
                            item
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>{row.complianceStatus}</Box>
                            <Grid item>
                              {row.complianceStatus === "Compliant" && (
                                <CheckCircleOutlineIcon
                                  style={{ color: "green" }}
                                />
                              )}
                              {row.complianceStatus === "Pending" && (
                                <ScheduleRoundedIcon
                                  style={{ color: "orange" }}
                                />
                              )}
                              {row.complianceStatus === "Overdue" && (
                                <ErrorOutlineRoundedIcon
                                  style={{ color: "#f34f4c" }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Typography sx={{ padding: " 1em 10%" }}>
                    Nothing to Show here
                  </Typography>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Grid>
      {selectedDocument &&
        (selectedDocument.actionRequired === "READ_AND_ACKNOWLEDGE" ||
        selectedDocument.actionRequired === "READ_AND_FILL" ? (
          <HistoryCustomizedDialog
            key={"DOCViewer"}
            open={Object.keys(selectedDocument).length}
            headerText={
              selectedDocument.documentName &&
              selectedDocument.documentName.length > 26
                ? `Preview Document - ${
                    selectedDocument.documentName.substring(0, 26) + "..."
                  }`
                : `Preview Document - ${selectedDocument.documentName}`
            }
            headerButtons={
              selectedDocument.complianceStatus === "Compliant"
                ? headerButtons
                : headerButton1
            }
            handleClickOpen={true}
            handleClose={() => {
              setSelectedDocument(null);
              dispatch(resetGetDocTemplate());
              setDocuments("");
              setIsDocLoading(true);
              dispatch(resetDownloadLocationDocs());
              dispatch(resetDownloadDocPdf());
              dispatch(resetLocationDocMap());
              dispatch(resetdownloadlocationsupportingdocs());
              setIsLoading(true);
              setIsDocLoading(true);
              setpendingTableLoader(true);
            }}
            size="md"
            overlayStyle={{ backgroundColor: "transparent" }}
          >
            <div
              style={{ position: "relative", height: "700px", width: "100%" }}
            >
              {isLoading ? (
                <>
                  <Loading />
                </>
              ) : (
                <>
                  {documents ? (
                    <Document
                      file={documents}
                      loading={<Loading />}
                      error={<Loading />}
                      options={options}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {selectedDocument.complianceStatus !== "Compliant" ? (
                        <Stack key={"container2-stack-sign"}>
                          {locationdocsmap.fillableFields?.map((data, idx) => (
                            <button
                              style={{
                                background: "none",
                                top: data.yposition + "px",
                                position: "absolute",
                                width: data.width + "px",
                                height: data.height + "px",
                                left: data.xposition - 6.5 + "px",
                                border: "none",
                                textAlign: "left",
                                zIndex: 3,
                                color: theme.palette.common.darkblue,
                              }}
                            >
                              <>
                                {data.editable &&
                                data.name !== "Radiobutton" &&
                                data.name !== "Dropdown" ? (
                                  <>
                                    {data.name !== "Date" ? (
                                      <>
                                        {!data.content ? (
                                          <textarea
                                            style={{
                                              width: data.width + "px",
                                              height: data.height + "px",
                                            }}
                                            disabled
                                            placeholder={"Text"}
                                          />
                                        ) : (
                                          <span style={{ color: " black" }}>
                                            {data.content}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <Stack key={`Stack${idx}`}>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <DesktopDatePicker
                                            disabled
                                            value={data.content || null}
                                            inputFormat="YYYY-MM-DD"
                                            renderInput={(params) => (
                                              <TextField
                                                size="small"
                                                sx={{
                                                  textAlign: "center",
                                                }}
                                                {...params}
                                                inputProps={{
                                                  ...params.inputProps,
                                                  placeholder: "Date",
                                                }}
                                              />
                                            )}
                                            disableOpenPicker
                                          />
                                        </LocalizationProvider>
                                      </Stack>
                                    )}
                                  </>
                                ) : null}

                                {data.useWidget && data.name !== "Checkbox" ? (
                                  <>
                                    {locationdocsmap.fillableFields[idx]
                                      .useWidget ? (
                                      <button
                                        style={{
                                          color: "#fff",
                                          backgroundColor: "#93c",
                                          borderRadius: "2px",
                                          fontSize: "13px",
                                          outline: "none",
                                          borderColor: "none",
                                          width: "97%",
                                          height: "95%",
                                          display: "table-row",
                                          backgroundImage: "none",
                                          border: "1px solid transparent",
                                          whiteSpace: "nowrap",
                                          overflow: "visible",
                                          cursor: "pointer",
                                        }}
                                        disabled
                                      >
                                        {data.name === "Initials"
                                          ? "Is"
                                          : data.name}
                                      </button>
                                    ) : (
                                      <span>
                                        {data.name === "Signature" ? (
                                          <span>
                                            <span className="font-Calligraffitti">
                                              {data.content}
                                            </span>
                                            <br />
                                            E-signed{" "}
                                            {data.filledOnDate != null
                                              ? data.filledOnDate
                                              : setDateTime(currTime, idx)}
                                          </span>
                                        ) : (
                                          <span className="data.name == 'Initials'?'font-Calligraffitti':''">
                                            {data.content}
                                          </span>
                                        )}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                                {data.name === "Radiobutton" ? (
                                  <label>
                                    <input
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                      type="radio"
                                      disabled
                                      checked={data.selected}
                                    />
                                  </label>
                                ) : null}

                                {data.name === "Dropdown" ? (
                                  <select
                                    value={
                                      locationdocsmap.fillableFields[idx]
                                        .content
                                    }
                                    disabled
                                  >
                                    <option value={"null"}>Select</option>
                                    {Array.isArray(data.options) &&
                                      data.options.map((options) => (
                                        <option value={options}>
                                          <>{options}</>
                                        </option>
                                      ))}
                                  </select>
                                ) : null}
                                {!data.editable && !data.useWidget ? (
                                  <span>
                                    {data.name === "Signature" ? (
                                      <span>
                                        <span className="font-Calligraffitti">
                                          {data.content}
                                        </span>
                                        <br />
                                        E-signed{" "}
                                        {data.filledOnDate != null
                                          ? data.filledOnDate
                                          : setDateTime(currTime, idx)}
                                      </span>
                                    ) : (
                                      <span
                                        className="data.name == 'Initials'?'font-Calligraffitti':''"
                                        style={{
                                          color: "#164e5f",
                                          fontSize: "15px",
                                        }}
                                      >
                                        {data.content}
                                      </span>
                                    )}
                                  </span>
                                ) : null}

                                {data.name === "Checkbox" ? (
                                  <>
                                    {locationdocsmap.fillableFields[idx]
                                      .useWidget ? (
                                      <CheckBox
                                        style={{
                                          fontSize: "250%",
                                          color: theme.palette.common.purple,
                                          cursor: "pointer",
                                        }}
                                        disabled
                                        checked={
                                          locationdocsmap.fillableFields[idx]
                                            .useWidget
                                        }
                                      />
                                    ) : (
                                      <CheckBox
                                        checked={
                                          locationdocsmap.fillableFields[idx]
                                            .useWidget
                                        }
                                        disabled
                                      />
                                    )}
                                  </>
                                ) : null}
                              </>
                            </button>
                          ))}
                        </Stack>
                      ) : null}
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          scale={1.305}
                        />
                      ))}
                    </Document>
                  ) : (
                    <Loading />
                  )}

                  {isDocLoading ? null : (
                    <PendingTable
                      tasknumber={selectedDocument.taskId}
                      downloadStatus={locationdocsmap.supportingDocuments}
                      downloadFile={dispatchfunction}
                    />
                  )}
                </>
              )}
            </div>
          </HistoryCustomizedDialog>
        ) : (
          <HistoryCustomizedDialog
            open={Object.keys(selectedDocument).length}
            headerText={`Preview Document - ${selectedDocument.documentName}`}
            headerButtons={headerButton1}
            handleClickOpen={true}
            handleClose={() => {
              setSelectedDocument({});
              dispatch(resetGetDocTemplate());
              setDocuments("");
              setIsDocLoading(true);
              dispatch(resetDownloadLocationDocs());
              dispatch(resetDownloadDocPdf());
              dispatch(resetLocationDocMap());
              dispatch(resetdownloadlocationsupportingdocs());
              setIsLoading(true);
              setIsDocLoading(true);
              setpendingTableLoader(true);
            }}
            size="md"
            overlayStyle={{ backgroundColor: "transparent" }}
          >
            {pendingTableLoader ? (
              <Loading />
            ) : (
              <div>
                <PendingTable
                  tasknumber={selectedDocument.taskId}
                  downloadStatus={locationdocsmap.supportingDocuments}
                  downloadFile={dispatchfunction}
                />
              </div>
            )}
          </HistoryCustomizedDialog>
        ))}
    </>
  );
};

export default DocumentsWrapper;
