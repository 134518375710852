import jwt_decode from "jwt-decode";

export const getEmailFromToken = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  const decodedToken = jwt_decode(token);
  if (decodedToken?.email) {
    return decodedToken?.email;
  }
  return true;
};
