import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getSignatoryMainTable,
  getSignatorySelectTable,
  saveSignatory,
} from "../../services/signatory";

export const getSignatoryMainTableThunk = createAsyncThunk(
  "signatory/getSignatoryMainTable",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getSignatoryMainTable(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSignatorySelectTableThunk = createAsyncThunk(
  "signatory/getSignatorySelectTable",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getSignatorySelectTable(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveSignatoryThunk = createAsyncThunk(
  "signatory/saveSignatory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await saveSignatory(data); //data is businessId, userId,
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const initialState = {
  loading: false,
  signatoryMainTable: "",
  signatorySelectTable: "",
  saveSignatoryResponse: "",
};

export const signatorySlice = createSlice({
  name: "signatory",
  initialState,
  reducers: {
    resetSignatory: (state) => initialState,
    setSignatoryMainTable: (state, action) => {
      state.signatoryMainTable = action.payload;
    },
    setSignatorySelectTable: (state, action) => {
      state.signatorySelectTable = action.payload;
    },
    setSaveSignatoryResponse: (state, action) => {
      state.saveSignatoryResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSignatoryMainTableThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSignatoryMainTableThunk.fulfilled, (state, action) => {
      state.signatoryMainTable = action.payload;
      state.loading = false;
    });
    builder.addCase(getSignatoryMainTableThunk.rejected, (state, action) => {
      state.signatoryMainTable = action.payload;
      state.loading = false;
    });
    builder.addCase(getSignatorySelectTableThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSignatorySelectTableThunk.fulfilled, (state, action) => {
      state.signatorySelectTable = action.payload;
      state.loading = false;
    });
    builder.addCase(getSignatorySelectTableThunk.rejected, (state, action) => {
      state.signatorySelectTable = action.payload;
      state.loading = false;
    });
    builder.addCase(saveSignatoryThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(saveSignatoryThunk.fulfilled, (state, action) => {
      state.saveSignatoryResponse = action.payload;
      state.loading = false;
    });
    builder.addCase(saveSignatoryThunk.rejected, (state, action) => {
      state.saveSignatoryResponse = action.payload;
      state.loading = false;
    });
  },
});

export const {
  setSignatoryMainTable,
  setSignatorySelectTable,
  setSaveSignatoryResponse,
  resetSignatory,
} = signatorySlice.actions;
export default signatorySlice.reducer;
