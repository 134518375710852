import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./Footer.css";
const FooterNavbar = (props) => {
  return (
    <Grid
      container
      direction={"column"}
      sx={{
        justifyContent: "space-between",
        alignItems: props.isDashboard ? "end" : "center",
        height: "100% ",
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="body2"
          align="center"
          fontSize="12px"
          color="#999"
          {...props}
        >
          © {new Date().getFullYear()} All Rights Reserved.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          align="center"
          fontSize="12px"
          color="#999"
          {...props}
        >
          FOCUS451 Inc.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontSize="12px" variant="body2" align="center">
          <Link
            className="links"
            onClick={(event) => {
              window.open("https://focus451.com/terms/");
            }}
          >
            Terms and Conditions
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography fontSize="12px" variant="body2" align="center">
          <Link
            className="links"
            onClick={(event) => {
              window.open("https://focus451.com/privacy-policy/");
            }}
          >
            {" "}
            Privacy Policy
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FooterNavbar;
